const initalState = {
    queryResolvedLoading: false,
    queryResolvedError:null,
    queryResolvedSuccess: null
  }
  
  export const queryresolved = (state = initalState, action) => {
    switch (action.type) {
      case "QUERY_RESOLVE_REQUEST": {
        return { ...state, loading: true,queryResolvedError:null,queryResolvedSuccess: null }
      }
      case "QUERY_RESOLVE_SUCCESS": {
        return { ...state, queryResolvedError:null,loading: false,queryResolvedSuccess: action.payload}
      }
      case "QUERY_RESOLVE_FAILURE": {
        return { ...state, loading: false,queryResolvedError:action.payload, queryResolvedSuccess: null}
      }
      default: {
        return state
      }
    }
  }