const styles = (theme) => ({
    head: {
        display:"flex",
        justifyContent:"space-between",
        textAlign:"end",
         alignItems: 'center'
     },
    cardDiv:{
        width:'100%'
    },
    carretDownIcon: {
        width: 9,
        float: 'right',
        marginTop: 3
    },
    chartHeader: {
        display: 'flex',
        width: '100%'
    },
    datechartHeader:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    chartTitle: {
        width: '50%',
        font: 'normal normal bold 15px/15px Roboto'
    },
    chartLeftBtns: {
        width: '50%',
        display: 'flex',
    },
    chartTitleFullWidth: {
        width: '100%',
        font: 'normal normal bold 15px/15px Roboto'
    },
    chartRightDiv: {
        width: '50%',
        textAlign: 'right'
    },
    chartRightDiv1: {
        width: '100%',
    },
   
    chartRightDiv2: {
        width: '100%',
        textAlign: 'end'
    },
    datechartRightDiv:{
        display: 'flex',
        alignItems: 'center'
    },
    datepickerstyle : {
        display: "table-cell !important",
        flex: "1 1 auto",
        minWidth: 185,
        marginBottom: "10px"
    },
    chartRightChild: {
        marginRight: '5px',
        font: 'normal normal normal 13px/15px Roboto'
    },
    CalendarIcon: {
        position: 'relative',
        top: 5,
        width: 15,
        marginRight: 3
    },
    DownloadIcon: {
        position: 'relative',
        top: 2,
        width: 15
    },
    viewGraphBtn: {
        width: 9
    },
    row: {
        width: '100%',
        position:"relative",
    },
    col60: {
        width:"63%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'right',
        marginBottom: '3%'
    },
    iconDiv:{
        width: 30,
        height: 30,
        background: '#FF9FAE 0% 0% no-repeat padding-box',
        borderRadius: 5,
        margin: 'auto',
        padding: 6,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    col30: {
        width:"35%",
        height: 450,
        float: 'left',
        marginBottom: '3%'
    },
    colLeft30: {
        width:"35%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'right',
        marginBottom: '3%'
    },
    colRight60: {
        width:"63%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'left',
        marginBottom: '3%'
    },
    colLeft301: {
        width:"35%",
        height: 500,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'right',
        marginBottom: '3%'
    },
    colRight601: {
        width:"63%",
        height: 500,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'left',
        marginBottom: '3%'
    },
    DoubleWhiteCardChild: {
        position:"relative",
        width:"100%",
        height: 500,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #DFDFDF',
        borderRadius: 10,
        padding: '2% 3% 2% 3%',
        marginBottom: '1%',
        marginTop: '2%'
    },
    wrapperClassNameSalesVolume: {
        "& ul":{
            // display: "none",
        },
        "& .MuiPaper-elevation5":{
            position:"absolute",
            right:0
        },
    },
    loaderDiv: {
        display: 'flex', 
        justifyContent: 'center', 
        transform: 'translateY(460%)'
    },
    nodataText: {
        fontSize: 20, font: 'normal normal normal 30px Roboto'
    }
})

export default styles