import React from 'react'
import { styled } from '@mui/system';
import {ErrorBlock, PaginationController} from "components"
import { ReactComponent as ImpersonateIcon } from "assets/icons/impersonate.svg";
import { ReactComponent as TickIcon } from "assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import { ReactComponent as LockIcon } from "assets/icons/key.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as PictureIcon } from "assets/icons/picture.svg";
import { ReactComponent as ThemeIcon } from "assets/icons/fill.svg";
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import {TextField,InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useTranslation} from "react-i18next";
import CachedIcon from '@mui/icons-material/Cached';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const Container = styled('div')(({ theme,height }) => ({
    position:"relative",
    width:"100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px #00000029",
    borderRadius: 5,
    opacity: 1,
    display:"flex",
    flexDirection:"column",
    minHeight:height,
    "& .table-container":{
        padding:"20px 36px 80px 36px"
    },
    '& .table-header':{
        display:"flex",
        alignItems:"center",
        marginBottom:30,
    },
    '& .table-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:700,
        fontSize:15
    },
    '& .table-header-right-container':{
        // backgroundColor:"yellow"
        "& button":{
            marginTop:0,
            "& svg":{
                marginRight:10
            }
        }
    },
    '& .table-header-right-container-filter':{
        // backgroundColor:"yellow"
        "& button":{
            marginRight:20,
            marginTop: 'unset !important',
            "& svg":{
                marginRight:10
            }
        }
    },
    [theme.breakpoints.down('xl')]: {
        "& .table-container":{
            padding:"15px 31px 80px 31px"
        },
        '& .table-header':{
            marginBottom:20,
        },
        '& .table-title':{
            fontSize:15
        },
    },
    [theme.breakpoints.up('xl')]: {
        '& .table-title':{
            fontSize:23
        },
    }
}))
  

const StyledTextField = styled(TextField)(({ theme,height,helpertextcolor,placeholdercolor ,inputtextcolor,inputbackgroundcolor}) => ({
    borderRadius:2,
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        color:helpertextcolor
      },
      "& .MuiInputBase-root":{
        borderRadius:2,
      },
      "& .MuiInputBase-input:after":{
        borderBottom: 'unset'
      },
      '& .MuiInputBase-input:before': {
         borderBottom: 'unset !important'
      },
      "& .MuiInputAdornment-root":{
        position: 'absolute',
        marginLeft: 10
      },
      "& .search-icon":{
        color:"#C8102E",
    },
    input:{
        fontFamily:"Roboto",
        fontSize:14,
        fontWeight:300,
        border: "1px solid #E3E3E3",
        height:`${height-2}px`,
        padding:"0px 0px 0px 40px !important",
        borderRadius:2,
        color:inputtextcolor,
        "&::placeholder":{
            color:placeholdercolor?placeholdercolor:inputtextcolor
        }, 
        [theme.breakpoints.up("xl")]:{
            fontSize:"18px !important",
            
        }
    },
    [theme.breakpoints.down("xl")]:{
        minHeight: "unset",
        width: 200,
        minWidth: 200,
        input:{
            fontSize:13,
            padding:"0px 37px",
            height:`${height-18}px`,
            borderRadius:2,
        },
    },
    [theme.breakpoints.up("xl")]:{
        minHeight: "unset",
        width: 300,
        minWidth: 300,
        input:{
            fontSize:13,
            padding:"0px 37px",
            height:`${height+4}px`,
            borderRadius:2,
        },
    },
    p: {
        [theme.breakpoints.up("xl")]:{
            fontSize: 16   
        }
    },
    

}))

const TableHead = styled('div')(({ theme }) => ({
    width:"100%",
    display:"flex",
    //height: 52,
    backgroundColor: "#DDDDDD",
    borderRadius: 10,
    opacity: 1,
    fontFamily:"Roboto",
    fontSize:16,
    color:"#36333D",
    fontWeight:700,
    padding:"17px 20px",
    alignItems:"center",
    [theme.breakpoints.down('xl')]: {
        fontSize:14,
        padding:"12px 15px",
    },
    [theme.breakpoints.up('xl')]: {
        fontSize:19,
        padding:"12px 15px",
    }
})) 

const TableData = styled('div')(({ theme }) => ({
    width:"100%",
    border: "1px solid #EAEAEA",
    borderRadius: 10,
    opacity: 1,
    padding:"20px 20px",
    display:"flex",
    alignItems: 'center',
    fontFamily:"Roboto",
    fontSize:16,
    color:"#36333D",
    fontWeight:400,
    marginTop:20, 
    
    '& .action-icon':{
        // marginLeft:17,
        width: 14,
        height: 14
    },
    '& .crossicon': {
        height: "14px !important",
        width: "14px !important"
    },
    '& .sentDiv' : {
        background: '#C8102E', 
        width: 15,
        height: 15, 
        borderRadius: 10, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    '& .circularprogress': {
        width: 50,
        height: 50,
        [theme.breakpoints.up('xl')]: {
            width: "60px !important",
            height: "60px !important",
        }
    },
    '& .notSentdiv': {
        background: '#36333D', 
        width: 15, 
        height: 15, 
        borderRadius: 10, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    '& .sentText': {
        marginLeft: 10, 
        color: '#C8102E'
    },
    '& .notsentText': {
        marginLeft: 10, 
    },
    '& .icon-background': {
        "&:hover":{
            cursor:"pointer",
            background: '#c8102e',
            marginRight:14,
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            "& svg g path":{
                fill:"#ffff !important"
            },
            "& svg path":{
                fill:"#ffff !important"
            },
            [theme.breakpoints.up('xl')]: {
                width: "30px !important",
                height: "30px !important",
            }
        }
    },
    '& .hoverText' : {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    '& .hoverTextCursor' : {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    "&:hover":{
        backgroundColor:"#E6E6E6",
        // color:"#fff",
        // padding:"25px 15px",
        // fontSize:14,
        // marginTop:20,
        '& .hoverTextCursor' : {
            color: '#c8102e',
            fontWeight: 'bold',
            cursor:"pointer",
            overflow: "visible",
            whiteSpace: "unset"
        },
        '& .hoverText' : {
            fontWeight: 'bold',
            overflow: "visible",
            whiteSpace: "unset"
        },
        
        '& .notsentText': {
            color: '#000 !important',
            marginLeft: 10, 
        },
        '& .circularprogress .CircularProgressbar .CircularProgressbar-path':{
            stroke: "#645a5a !important"
        }
    },
    [theme.breakpoints.down('xl')]: {
        fontSize:12,
        padding:"12px 15px",
        marginTop:20,
        '& .icon-background': {
            marginRight:14,
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .action-icon':{
            // marginLeft:12,
            width: 14,
            height: 14
        },
        '& .delete-icon':{
            width: 14,
            height: 14
        },
        '& .crossicon': {
            height: "10px !important",
        },
    },
    [theme.breakpoints.up('xl')]: {
        fontSize:18,
        '& .icon-background': {
            marginRight:14,
            width: 30,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .action-icon':{
            // marginLeft:12,
            width: 22,
            height: 22
        },
        '& .delete-icon':{
            width: 22,
            height: 22
        },
        '& .crossicon': {
            height: "10px !important",
        },
    }
}))



export default function CustomTable({
    title,
    thead,
    tdata,
    maxRow,
    handleRowClick,
    height,
    action,
    handleEditClick,
    handleLockClick,
    handleDeleteClick,
    handleImpersonateClick,
    headerActions,
    handleDownloadclick,
    handlePictureClick,
    handleThemeClick,
    totalPageCount,
    loadPagination,
    loading,
    currentPage,
    downloadLoader,
    currentItem,
    search,
    onChange,
    value,
    searchLabel,
    headerFilter,
    clickableColumn,
    errorStatus,
    errorBlockBtnClick,
    errorTopheight,
    handleResetClick,
    handleReopenClick
}) {
    const {t} = useTranslation('common');
    const [page,setPage] = React.useState(1);

    const totalPage = React.useMemo(()=>{
        if(totalPageCount > 0){
            return (Math.floor((totalPageCount-1)/maxRow))+1
        }
        return 1
    },[maxRow,totalPageCount])

    const indexStart = React.useMemo(()=>{
        return ((page-1)*maxRow)
    },[maxRow,page])

    const indexEnd = React.useMemo(()=>{
        return indexStart+maxRow
    },[maxRow,indexStart])



    const dateFormat = (datestring, timestamp) => {
        var formattedDate
        if(timestamp){
            function pad2(n) {
                return (n < 10 ? '0' : '') + n;
              }
              
              var date = new Date(datestring)
              var month = pad2(date.getMonth()+1);//months (0-11)
              var day = pad2(date.getDate());//day (1-31)
              var year= date.getFullYear();
              
              const timeString12hr = new Date(datestring)
              .toLocaleTimeString([],
                  {hour:'2-digit',minute:'2-digit'}
              );
              formattedDate =  day + "-" + month + "-" + year + " " + timeString12hr
              return formattedDate
        }else{
            var time = datestring.split(',')[0] +  ", " + datestring.split(',')[1] + ", "
            // function pad2(n) {
            //     return (n < 10 ? '0' : '') + n;
            //   }
            //   var date = new Date(datestring)
            // //   var month = pad2(date.getMonth()+1);//months (0-11)
            // //   var day = pad2(date.getDate());//day (1-31)
            // //   var year= date.getFullYear();
              const timeString12hr = new Date(datestring)
              .toLocaleTimeString('en-US',
                  {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'}
              );
              
            formattedDate = time +  " " + timeString12hr;
            return formattedDate
        }
        
    }
    
  return (
    <Container height={height}>
        {
        errorStatus === 0 ?
          <ErrorBlock height={errorTopheight} btnClick={errorBlockBtnClick} />
                  :
        <>
        <div className='table-container'>
            <div className='table-header'>
                <div className='table-title'>
                    {title}
                </div>
                {headerFilter && 
                <div className='table-header-right-container-filter'>
                    {headerFilter}
                </div>
                }
                {search && 
                    <StyledTextField
                        autoComplete={'off'}
                        variant="standard"
                        height={50}
                        name={"name"}
                        type={"text"}
                        placeholder={searchLabel}
                        onChange={onChange}
                        value={value}
                        placeholdercolor="#000000"
                        inputtextcolor="#000000"
                        inputbackgroundcolor="#FFF"
                        fullWidth={50}  
                        InputProps={{
                            disableUnderline: true,
                            startAdornment:
                              <InputAdornment position='start'>
                                <SearchIcon  className="search-icon"/>
                              </InputAdornment>
                             
                          }}           
                >
                    </StyledTextField>
                }
               
                {headerActions && 
                <div className='table-header-right-container'>
                    {headerActions}
                </div>
                }
            </div>
            <TableHead>
                {thead.map((item,index) => {
                    return(
                        <span style={{width:`${item.width}%`}}>
                            {item.displayName}
                        </span>
                    )
                })}
                
            </TableHead> 
            {loading? <Stack sx={{ width: '100%', color: 'grey.500', marginTop:3 }} spacing={3}>
            <LinearProgress color="inherit" sx={{height:60,borderRadius: 3}}/>
            <LinearProgress color="inherit" sx={{height:60,borderRadius: 3}}/>
            <LinearProgress color="inherit" sx={{height:60,borderRadius: 3}}/>
            <LinearProgress color="inherit" sx={{height:60,borderRadius: 3}}/>
            <LinearProgress color="inherit" sx={{height:60,borderRadius: 3}}/>
            <LinearProgress color="inherit" sx={{height:60,borderRadius: 3}}/>
            </Stack> :
            tdata.slice(indexStart,indexEnd).map((data,mainIndex) => { 
                return(
                    <TableData >
                        { thead.map((heading, subIndex) => { 
                            if(heading?.type === "boolean")
                                return(
                                    <span style={{width:`${heading?.width}%`, display: 'flex', justifyContent: 'center'}}>
                                        {data[heading?.name] ? <TickIcon className='crossicon'/> : <CrossIcon className='crossicon'/>}
                                    </span>
                                    )
                            else if(heading?.type === "action")
                                return(
                                    <span style={{width:`${heading?.width}%`, display: 'flex', justifyContent: 'center'}}>
                                        {data?.role==="admin"&&(action?.[data?.role]).map((item,index) => {
                                            if(item==="edit")
                                                return (
                                                <Tooltip title={t('common.Change')}>
                                                    <div className="icon-background" onClick={handleEditClick(data)}>
                                                    <EditIcon className={subIndex>0 && 'action-icon'} />
                                                    </div>
                                                </Tooltip>
                                                )
                                            else if(item ==="reloadVersion")
                                            return(
                                                <Tooltip title={t('common.Applysettings')}>
                                                    <div className="icon-background" onClick={handleEditClick(data)}>
                                                    <EditIcon className={subIndex>0 && 'action-icon'} />
                                                    </div>
                                                </Tooltip>
                                                )
                                            else if(item==="lock")
                                            return( 
                                                <Tooltip title={t('common.ResetPassword')}>
                                                <div className="icon-background"  onClick={handleLockClick(data)} >
                                                 <LockIcon className={subIndex>0 && 'action-icon'}/>
                                                 </div>
                                                </Tooltip>
                                                )
                                            else if(item==="download")
                                                return(
                                                     
                                                    downloadLoader === true && data === currentItem ?
                                                    <CircularProgress style={{width: 15, height: 15, marginRight: 10, marginLeft: 10, marginTop: 3}} sx={{color:'#36333d'}}/>:
                                                    <Tooltip title={t('common.Download')}>
                                                        <div className="icon-background"  onClick={handleDownloadclick(data)}>
                                                        <DownloadIcon className={subIndex>0 && 'action-icon'} />
                                                        </div>
                                                    </Tooltip>
                                                ) 
                                            else if(item==="picture")
                                                return (
                                                    <Tooltip title={t('common.showlogos')}>
                                                        <div className="icon-background"  onClick={handlePictureClick(data)}>
                                                         <PictureIcon className={subIndex>0 && 'action-icon'} />
                                                         </div>
                                                    </Tooltip>
                                            )  
                                            else if(item==="themeView")
                                                return (
                                                    <Tooltip title={t('common.showcolors')}>
                                                        <div className="icon-background" onClick={handleThemeClick(data)}>
                                                        <ThemeIcon className={subIndex>0 && 'action-icon'}  />
                                                        </div>
                                                    </Tooltip>
                                                )
                                            else if(item ==="refresh")
                                                return(
                                                    <Tooltip title={t('common.resendotp')}>
                                                        <div className="icon-background" onClick={handleResetClick(data)}>
                                                        <CachedIcon style={{color:"#C8102E"}} className={subIndex>0 && 'action-icon'} />
                                                        </div>
                                                    </Tooltip>
                                                )
                                            else if(item ==="reopen")
                                                return(
                                                    <Tooltip title={t('common.resendQuery')}>
                                                        <div className="icon-background" onClick={handleReopenClick(data)}>
                                                        <RestartAltRoundedIcon style={{color:"#C8102E"}} className={subIndex>0 && 'action-icon'} />
                                                        </div>
                                                    </Tooltip>
                                                )
                                            return(
                                            <Tooltip title={t('common.delete')}>
                                                <div className="icon-background" onClick={handleDeleteClick(data)}>

                                                <DeleteIcon  className={subIndex>0 ? 'action-icon': 'delete-icon'}  />
                                                </div>
                                            </Tooltip>
                                            )
                                        })}
                                        {data?.role!=="admin"&&(action?.["default"]).map((item,index) => {
                                           if(item==="edit")
                                            return (
                                                <Tooltip title={t('common.Change')}>
                                                    <div className="icon-background" onClick={handleEditClick(data)}>
                                                    <EditIcon className={subIndex>0 && 'action-icon'} />
                                                    </div>
                                                </Tooltip>
                                            )
                                            else if(item ==="reloadVersion")
                                            return(
                                                <Tooltip title={t('common.Applysettings')}>
                                                    <div className="icon-background" onClick={handleEditClick(data)}>
                                                    <EditIcon className={subIndex>0 && 'action-icon'} />
                                                    </div>
                                                </Tooltip>
                                                )
                                            else if(item==="lock")
                                                return (
                                                    <Tooltip title={t('common.ResetPassword')}>
                                                        <div className="icon-background" onClick={handleLockClick(data)}>
                                                        <LockIcon className={subIndex>0 && 'action-icon'}  />
                                                        </div>
                                                    </Tooltip>
                                                )
                                            else if(item==="picture")
                                                return (
                                                    <Tooltip title={t('common.showlogos')}>
                                                        <div className="icon-background" onClick={handlePictureClick(data)}>
                                                        <PictureIcon className={subIndex>0 && 'action-icon'}  />
                                                        </div>
                                                    </Tooltip>
                                                )
                                            else if(item==="themeView")
                                            return(
                                                    <Tooltip title={t('common.showcolors')}>
                                                        <div className="icon-background" onClick={handleThemeClick(data)}>
                                                        <ThemeIcon className={subIndex>0 && 'action-icon'}  />
                                                        </div>
                                                    </Tooltip>
                                                )
                                                else if(item==="download")    
                                                return (
                                                    downloadLoader === true && data === currentItem ?
                                                   
                                                    <CircularProgress style={{width: 15, height: 15, marginRight: 10, marginLeft: 10, marginTop: 3}} sx={{color:'#36333d'}}/>
                                                    :
                                                    <Tooltip title={t('common.Download')}>
                                                        <div className="icon-background" onClick={handleDownloadclick(data)}>
                                                        <DownloadIcon color='#C8102E' className={subIndex>0 && 'action-icon'}  />
                                                        </div>
                                                    </Tooltip>
                                                ) 
                                                else if(item ==="refresh")
                                                return(
                                                    <Tooltip title={t('common.resendotp')}>
                                                        <div className="icon-background" onClick={handleResetClick(data)}>
                                                        <CachedIcon style={{color:"#C8102E"}} className={subIndex>0 && 'action-icon'} />
                                                        </div>
                                                    </Tooltip>
                                                )
                                                else if(item ==="reopen")
                                                return(
                                                    <Tooltip title={t('common.resendQuery')}>
                                                        <div className="icon-background" onClick={handleReopenClick(data)}>
                                                        <RestartAltRoundedIcon style={{color:"#C8102E"}} className={subIndex>0 && 'action-icon'} />
                                                        </div>
                                                    </Tooltip>
                                                )
                                                
                                            else if(item==="impersonate")  
                                                return (
                                                <Tooltip title={t('common.Impersonate')}>
                                                    <div className="icon-background" onClick={handleImpersonateClick(data)}>
                                                    <ImpersonateIcon color='#C8102E' className={subIndex>0 && 'action-icon'}  />   
                                                    </div>
                                                </Tooltip>  
                                                )
                                            return (
                                                <Tooltip title={t('common.delete')}>
                                                    <div className="icon-background" onClick={handleDeleteClick(data)}>
                                                    <DeleteIcon  className={subIndex>0 ? 'action-icon': "delete-icon"}  />
                                                    </div>
                                                </Tooltip>
                                            )
                                            
                                        })}
                                    </span>
                                    )
                            else if(heading?.type === "queryownicon")
                                return(
                            
                                    <span style={{width:`${heading?.width}%`, display: 'flex', justifyContent: 'center'}}>
                                        {data[heading?.name] ?  <AdminPanelSettingsIcon color='success'/> : <NoAccountsIcon style={{color: '#C8102E'}}/>}
                                    </span>
                                    )
                                    
                            return (
                                <span style={{width:`${heading?.width}%`, wordWrap: "break-word",  paddingRight: 8}} className={clickableColumn !== -1 ? heading?.type === "text" || "number" ? clickableColumn === subIndex ?  "hoverTextCursor" : "hoverText" : null: null} onClick={subIndex === clickableColumn ? handleRowClick(data): null}>
                                    { 
                                    heading?.type === "date" ? dateFormat(data[heading?.name], false): 
                                    heading?.type === "timestamp" ? dateFormat(data[heading?.name], true):
                                    heading?.type === "status" ? 
                                    <span style={data[heading?.name] === true ? {background: '#FFBEC9', padding: 5, borderRadius: 10, color: '#fff', marginLeft: 10, display: 'flex',alignItems: 'center', width: 110}: {background: '#F3F4F8', padding: 5, borderRadius: 10, marginLeft: 10,  display: 'flex', alignItems: 'center', width: 110}}>
                                        
                                        {data[heading?.name] === true ? 
                                        <>
                                        <div className='sentDiv'>
                                            <AiOutlineCheck color='#FFF'/>
                                        </div>
                                        <span className='sentText'>{t('common.Sent')}</span>
                                        </> :
                                        <>
                                        <div className='notSentdiv'>
                                            <AiOutlineClose color='#FFF'/>
                                        </div>
                                        <span className='notsentText'>{t('common.NotSent')}</span>
                                        </> 
                                        }</span>:
                                        heading?.type === "progressbar" ?
                                        <div className='circularprogress'>
                                        <CircularProgressbar value={data[heading?.name]} text={`${(data[heading?.name] - Math.floor(data[heading?.name])) !== 0 ?data[heading?.name].toFixed(2):data[heading?.name]}%`}
                                        styles={buildStyles({
                                            // Text size
                                            textSize: data[heading?.name]?.toString().length > 5 ? '22px' : '25px',
                                            // Colors
                                            pathColor: data[heading?.name] < 50 ? `rgba(243, 113, 1, ${data[heading?.name] / 100})`: 
                                            data[heading?.name] < 80 && data[heading?.name] >= 50 ? `rgba(21, 181, 107, ${data[heading?.name] / 100})`:
                                            `rgba(200, 16, 46, ${data[heading?.name] / 100})`,
                                            textColor: '#000',
                                          })} />
                                        </div>:
                                        heading?.type === "machineStatus" ?
                                        data[heading?.name] === "Online" ?
                                        <div style={{width: 10, height: 10, borderRadius: 10, background: 'green'}}></div>:
                                        data[heading?.name] === "Offline" ?
                                        <div style={{width: 10, height: 10, borderRadius: 10, background: 'red'}}></div>:
                                        <p style={{padding: 5, background: '#FFB9C5', color: '#C8102E', borderRadius: 2, width: 'fit-content'}}>{data[heading?.name]}</p>:
                                            data[heading?.name]}                                    
                                            {heading?.latestTag && mainIndex === 0 && currentPage === 1 &&
                                            <span style={{background: '#36333D', padding: 5, borderRadius: 3, color: '#fff', marginLeft: 10}}>{t('common.Latest')}</span>
                                        }
                                    
                                </span>)
                        }
                        )}
                    </TableData>
                )
            })
            }
            
        </div>
        
            {totalPage > 1 &&
                <PaginationController  totalCount={totalPage} setPage={setPage} loadPagination={loadPagination} page={currentPage}/>
            }
            </>
      }
    </Container>
  )
}
