import React from 'react'
import { styled } from '@mui/system';
import { Spinner } from "reactstrap"

const Container = styled('div')(({ theme,height }) => ({
    position:"relative",
    width:"100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px #00000029",
    borderRadius: 5,
    opacity: 1,
    display:"flex",
    flexDirection:"column",
    minHeight:height,
    "& .table-container":{
        padding:"20px 36px 80px 36px"
    },
    '& .table-header':{
        display:"flex",
        alignItems:"center",
        marginBottom:30,
    },
    '& .table-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:700,
        fontSize:20
    },
    '& .table-header-right-container':{
        // backgroundColor:"yellow"
        "& button":{
            marginTop:0,
            "& svg":{
                marginRight:10
            }
        }
    },
    [theme.breakpoints.down('xl')]: {
        "& .table-container":{
            padding:"15px 31px"
        },
        '& .table-header':{
            marginBottom:20,
        },
        '& .table-title':{
            fontSize:18
        },
    }
}))

const TableHead = styled('div')(({ theme }) => ({
    width:"100%",
    display:"flex",
    //height: 52,
    backgroundColor: "#DDDDDD",
    borderRadius: 10,
    opacity: 1,
    fontFamily:"Roboto",
    fontSize:16,
    color:"#36333D",
    fontWeight:700,
    padding:"17px 20px",
    alignItems:"center",
    [theme.breakpoints.down('xl')]: {
        fontSize:14,
        padding:"12px 15px",
    }
})) 

const TableData = styled('div')(({ theme }) => ({
    cursor:"pointer",
    width:"100%",
    display:"flex",
    fontFamily:"Roboto",
    fontSize:16,
    color:"#36333D",
    fontWeight:400,
    marginTop:'10%',
    textAlign:'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xl')]: {
        fontSize:14,
        padding:"25px 15px",
        marginTop:20,
        '& .action-icon':{
            marginLeft:12
        },
    }
}))


export default function CustomTableLoader({
    title,
    thead,
    height
}) {
    
  return (
    <Container height={height}>        
        <div className='table-container'>
            <div className='table-header'>
                <div className='table-title'>
                    {title}
                </div>
            </div>
            <TableHead>
                {thead.map((item,index) => {
                    return(
                        <span style={{width:`${item.width}%`}}>
                            {item.displayName}
                        </span>
                    )
                })}                
            </TableHead>
            <TableData>
                <Spinner />
            </TableData>
                       
            
        </div>
    </Container>
  )
}
