import React from 'react'
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { useDispatch } from "react-redux"
import { logoutWithJWT } from "../../redux/actions/auth/loginActions"
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(styles)

const Logoutbtn = styled('div')(({ theme }) => ({
  background: '#c8102e',
  borderRadius: 5, 
  flex: 'none', 
  fontFamily: 'Roboto', 
  cursor: 'pointer', 
  marginLeft: 10,
  fontSize: 12,
  "&:hover":{
    background: "#eb314f",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
  },
  [theme.breakpoints.up("xl")]:{
    fontSize:19,
  }
})) 

export default function UserInfo({name,email,isImpersonate}) {
  const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const classes = useStyles()
    let navigate = useNavigate();
    // const [userInfoDropdown, setUserInfoDropdown] = useState(null);

    const resetImpersonate = () => {
      dispatch({
        type: "REQUEST_RESET_IMPERSONATE",
        payload: null
      }) 
      navigate("/dashboard/admin")  
    }

    // useEffect(() => {  
    //   if(userInfoDropdown === "logout"){
    //     dispatch(logoutWithJWT())
    //   }
    // }, [userInfoDropdown])

  return (
    <div className={classes.userCard}>
      <div className={classes.userInfo}>
        <div className={classes.name}>{name}</div>
        <div className={classes.email}>{email}</div>
      </div>
      {/* <KeyboardArrowDownOutlinedIcon className={classes.icon} onClick={keyOpen}/> */}
      {/* <Select onChange={(e) => setUserInfoDropdown(e.target.value)}>
       <MenuItem value={'logout'}>Logout</MenuItem>  
      </Select> */}
      <Logoutbtn>
        {isImpersonate?
        <div className={classes.logoutbtn} onClick={resetImpersonate} style={{padding: 10}}>
          {t('common.BackToMyself')}
        </div>:
        <div onClick={() => dispatch(logoutWithJWT())} style={{padding: 10}}>        
          {t('common.LogOut')}
        </div>}
      </Logoutbtn>
    </div>
  )
}
