import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

  export const getCCCountriesNoOfMachines = payload => {    
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_CC_COUNTRIES_NO_OF_MACHINES"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .get(api_settings.url_base + api_endpoints.cc_adminDashboard_CountriesNoOfMachines, config)
        .then(response => {
          
          dispatch({
            type: "REQUEST_CC_COUNTRIES_NO_OF_MACHINES_SUCCESS",
            payload: response.data
          });
          // 
        })
        .catch(err => { 
          console.log("countiries", err)
          if(err.response.status === 401){
            logoutRedirect(dispatch)
          }
          dispatch({
            type: "REQUEST_CC_COUNTRIES_NO_OF_MACHINES_FAILED",
            payload: err.response.status 
          })
        })
    }
  }

  export const getCCTopTenCountries = payload => {    
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_CC_TOP_TEN_COUNTRIES"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .get(api_settings.url_base + api_endpoints.cc_adminDashboard_TopTenCountries, config)
        .then(response => {
          
          dispatch({
            type: "REQUEST_CC_TOP_TEN_COUNTRIES_SUCCESS",
            payload: response.data
          });
          // 
        })
        .catch(err => { 
         
          if(err.response.status === 401){
            logoutRedirect(dispatch)
          }
          dispatch({
            type: "REQUEST_CC_TOP_TEN_COUNTRIES_FAILED",
            payload: err.response.status 
          })
        })
    }
  }

  export const getCCTopTenCustomers = payload => {    
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_CC_TOP_TEN_CUSTOMERS"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .get(api_settings.url_base + api_endpoints.cc_adminDashboard_TopTenCustomers, config)
        .then(response => {
          
          dispatch({
            type: "REQUEST_CC_TOP_TEN_CUSTOMERS_SUCCESS",
            payload: response.data
          });
          // 
        })
        .catch(err => { 
         
          if(err.response.status === 401){
            logoutRedirect(dispatch)
          }
          dispatch({
            type: "REQUEST_CC_TOP_TEN_CUSTOMERS_FAILED",
            payload: err.response.status 
          })
        })
    }
  }

  export const getCCCustomersList = payload => {    
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_CUSTOMERS_LIST"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .get(api_settings.url_base + api_endpoints.cc_adminDashboard_CustomersList + "?search=" + payload.search + "&offset=" +  payload.offset, config)
        .then(response => {
          
          dispatch({
            type: "REQUEST_CC_CUSTOMERS_LIST_SUCCESS",
            payload: response.data
          });
          // 
        })
        .catch(err => { 
         
          if(err.response.status === 401){
            logoutRedirect(dispatch)
          }
          dispatch({
            type: "REQUEST_CC_CUSTOMERS_LIST_FAILED",
            payload: err.response.status
          })
        })
    }
  }

  export const getCCUserMachineStat = payload => {            
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_USER_MACHINE_STAT"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .get(api_settings.url_base + api_endpoints.cc_adminDashboard_UserMachineStat + payload.customer_id + "/", config)
        .then(response => {          
          dispatch({
            type: "REQUEST_USER_MACHINE_STAT_SUCCESS",
            payload: response.data
          });
        })
        .catch(err => { 
          dispatch({
            type: "REQUEST_USER_MACHINE_STAT_FAILED",
            payload: err.response.status
          })
        })
    }
  }

  export const getCCDataUsage = payload => {                     
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_USER_DATA_USAGE"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .get(api_settings.url_base + api_endpoints.cc_adminDashboard_UserDataUsage + payload.customer_id + "/", config)
        .then(response => {          
          dispatch({
            type: "REQUEST_USER_DATA_USAGE_SUCCESS",
            payload: response.data
          });
        })
        .catch(err => { 
          dispatch({
            type: "REQUEST_USER_DATA_USAGE_FAILED",
            payload: err.response.status
          })
        })
    }
  }

  export const CCResetDataUsage = payload => {                     
    return dispatch => {
      const { user } = store.getState().auth.login;
      const { impersonateUserInfo } = store.getState().impersonate.impersonate;
      let authorizationAccessToken = user.access;
      if(impersonateUserInfo?.status === "success"){
        let impersonateUserInfoData = impersonateUserInfo?.data;
        if(impersonateUserInfoData?.user_access_token){
          authorizationAccessToken = impersonateUserInfoData.user_access_token;
        }     
      }
      dispatch({
        type: "REQUEST_RESET_USER_DATA_USAGE"
      })
      const config = {
        headers: {        
          "Accept": 'application/json',
          "Authorization": ''+authorizationAccessToken+''
        },
      }
      axios
        .post(api_settings.url_base + api_endpoints.cc_adminDashboard_ResetUserDataUsage + payload + "/",{
          limit: 0
        }, config)
        .then(response => {          
          dispatch({
            type: "REQUEST_RESET__USER_DATA_USAGE_SUCCESS",
            payload: response.data
          });
        })
        .catch(err => {
          
          dispatch({
            type: "REQUEST_RESET__USER_DATA_USAGE_FAILED",
            payload: err.response.data.error
          })
        })
    }
  }