import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'


export const CreateQuery = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "CREATE_REQUEST_USERQUERY"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    var bodyFormData = new FormData();
    bodyFormData.append('query', payload.query);
    bodyFormData.append('description', payload.description);
    bodyFormData.append('otp_send', payload.accept);
    bodyFormData.append('filedata', payload.filedata);
    axios
      .post(api_settings.url_base + api_endpoints.userQuery,  bodyFormData, config)
      .then(response => {
        dispatch({
          type: "CREATE_REQUEST_USERQUERY_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {    
          if(err.response.status === 500){
            dispatch({
              type: "CREATE_REQUEST_USERQUERY_FAILED_500",
              payload: true
            })
          }     
          dispatch({
            type: "CREATE_REQUEST_USERQUERY_FAILED",
            payload: err.response.data
          })
      })
  }
}


export const getAllWorkspaceUsersquery = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_QUERY"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.userQuery +'?limit='+payload.limit+'&offset='+payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_QUERY_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        else{
          dispatch({
            type: "REQUEST_ALL_QUERY_FAILED",
            payload: err.response.status
          })
        }
        
      })
  }
}

export const deleteWorkspaceUserQuery = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "DELETE_REQUEST_WORKSPACEUSER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.deleteuserQuery + payload.id, config)
      .then(response => {
        dispatch({
          type: "DELETE_REQUEST_USERQUERY_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "DELETE_REQUEST_USERQUERY_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "DELETE_REQUEST_USERQUERY_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const resetWorkspaceUserOtp = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "UPDATE_REQUEST_USERQUERY"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.resetotpuserQuery, {       
        "id": payload.id
      }, config)
      .then(response => {
        dispatch({
          type: "UPDATE_REQUEST_USERQUERY_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 500){
          dispatch({
            type: "UPDATE_REQUEST_USERQUERY_FAILED_500",
            payload: true
          })
        }
          dispatch({
            type: "UPDATE_REQUEST_USERQUERY_FAILED",
            payload: err.response.data.error
          })
        
      })
  }
}

export const reopenWorkspaceUserOtp = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REOPEN_REQUEST_USERQUERY"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.reopenuserQuery, {       
        "id": payload.id
      }, config)
      .then(response => {
        dispatch({
          type: "REOPEN_REQUEST_USERQUERY_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 500){
          dispatch({
            type: "REOPEN_REQUEST_USERQUERY_FAILED_500",
            payload: true
          })
        }
          dispatch({
            type: "REOPEN_REQUEST_USERQUERY_FAILED",
            payload: err.response.data.error
          })
        
      })
  }
}