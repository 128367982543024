import React,{ useState, useEffect } from 'react';
import { DashboardLayout } from 'layouts';
import { BreadCrumb,CustomTable } from 'components';
import Modals from 'components/Modal';
import Badge from 'assets/icons/badge.svg';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/auth/allUserslistActions";
import { getImpersonateUserData } from "../../redux/actions/impersonate/impersonateActions";
import {useTranslation} from "react-i18next";
import { Spinner } from "reactstrap"
import Divider from '@mui/material/Divider';
import { CustomTextField } from 'elements';
import {  string } from "yup";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from 'sweetalert2'
import Condition from 'yup/lib/Condition';
const useStyles = makeStyles(styles)


export default function Admin() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName:t('AllUsers.Admin'),
        link:"/admin"
    },
    {
      displayName:t('AllUsers.AllUsers'),
      link:"/admin/all users"
  },
]

const thead = [
    {
      name:"company_name",
      displayName:t('AllUsers.Company'),
      type:"text",
      width:40, 
    },
    {
      name:"email",
      displayName:t('AllUsers.Email'),
      type:"text",
      width:50
    },
    {
      name:"actions",
      displayName:t('AllUsers.Actions'),
      type:"action",
      width:10
    },
]  

  const classes = useStyles();   
  const [allusers, setAllUserslist] = useState([]);
  const [allusersCount, setAllUsersCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [impersonateUser, setImpersonateUser] = useState(null);
  const [otpCode, setOTP] = useState("");
  const theme = useTheme();
  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading,users, error } = useSelector((state) => state.auth.allUsers);
  const { impersonateUserInfo, impersonateError } = useSelector((state) => state.impersonate.impersonate);
  const [searchText, setSearchText] = useState("");
  

  const handleRowClick = (item) => () => { 
    // 
  }

  const handleChange = (event) => {
    setOTP(event.target.value);
  };


  const formik = useFormik({
    initialValues: {
      otp: otpCode,
    },
    validationSchema: Yup.object({
      otp: string().required(t('AllUsers.Pleaseenterotp'))                    
    }),
    onSubmit : (values, formikHelpers)=> {

      }
    });

  const impersonate = () => {
    let impersonateUserData = {
      id : impersonateUser.id,
      otp : otpCode
    }
    if(impersonateUserData.otp !== "" && impersonateUserData.otp.length === 8){
      dispatch(getImpersonateUserData(impersonateUserData))
      setOTP("")
    }
  }

  const handleClose = () => {
    setImpersonateUser(null)
    setOpen(false);
    setOTP("")
  }

  const handleImpersonateClick = (item) => () => {
    setImpersonateUser(item)
    setOpen(true);
    setOTP("")
  }



  useEffect(() => {
    if(impersonateError){
      Swal.fire({
        title: 'Error',
        text: impersonateError === t('AllUsers.failToImp')?t('AllUsers.failToImp'): impersonateError === t('AllUsers.validateOTP')?t('AllUsers.validateOTP'):null,
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',
        
      })
      dispatch({
        type: "REQUEST_IMPERSONATE_USER_DATA_FAILED",
        payload: null
      })
    }

  },[impersonateError, dispatch, t]);

  useEffect(() => { 
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
      search: searchText
    }  
    dispatch(getAllUsers(payload))
  },[page, searchText, dispatch]);
  
  
  useEffect(() => {
    if(users?.data){
      setAllUserslist(users.data)
      setAllUsersCount(users.count)
    }
  }, [users])

  useEffect(() => {
    if(impersonateUserInfo?.status === "success"){
      setOpen(false);
      let impersonateUserInfoData = impersonateUserInfo?.data
      if(impersonateUserInfoData?.is_workspace_admin){
        navigate("/dashboard/workspace-users")
      }else if(impersonateUserInfoData?.is_colordb_editor){
        navigate("/dashboard/xdata/buckets")
      }else if(impersonateUserInfoData?.is_historians){
        navigate("/dashboard/xdata/analytics")
      }else if(impersonateUserInfoData?.is_theme_editor){
        navigate("/dashboard/themes")
      }else{
        navigate("/dashboard/403")
      }
    }
  },[impersonateUserInfo, navigate]);

  //  added Html for add token
  const bigHtmlContentnew = (
    <>
        <div className={classes.logo1}>
         <img src={Badge} alt='logo' /> 
      </div>
      <div className={classes.warning}>
          {t('AllUsers.AreYouSure')} 
          </div>
          <div className={classes.text} >             
          {t('AllUsers.YouareabouttoimpersonateanotheruserWhendoneclicktheBackToMyself')}  
          {/* <span className={classes.span}>"Back To Myself"</span>            */}
          </div>
        <Divider  className={classes.divide}/>
        <form onSubmit={formik.handleSubmit}>
        <Grid className={classes.main}>
          <CustomTextField              
              label={t('AllUsers.enterOtp')}
              name="otp"
              type="name"
              placeholder= {t('AllUsers.Otp')}
              onChange= {handleChange}
              onBlur={formik.handleBlur}
              value={otpCode}
              // error={formik.touched.otp && Boolean(formik.errors.otp)}
              // helperText={formik.touched.otp && formik.errors.otp} 
              height={45}
              labelColor="#000"
              labelTextSize={16}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E"  
              inputProps={{ minLength:8, maxLength: 8}}
              required={true}
          />
          <label style ={{color: "#C8102E"}}>{t('AllUsers.entereightdigitotp')}</label>
          </Grid>  
          <div className={classes.buttons} style={{display: 'flex', marginTop: 20, justifyContent: 'center'}}>                   
              <Button         
                  size="small"
                  className='appBtnCancel'
                  onClick={handleClose}
              >
                  {t('AllUsers.Cancel')}
              </Button>                                          
              <div className={classes.sub}>
                  <Button                  
                    type="submit"
                    size="meduim"
                    className='appBtn'
                    onClick={impersonate}
                  >
                    {t('AllUsers.OK')}
                  </Button>
              </div>                                         
          </div> 
        </form> 
    </>
  )


  const onSearchChange = (e) => {
    setPage(1)
    setSearchText(e.target.value)
  }

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
      search: searchText
    }  
    dispatch(getAllUsers(payload))
  }

  return (
    <DashboardLayout>
        <BreadCrumb linkData={linkData}/>
        <CustomTable 
            search={true}
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('AllUsers.AllUsers')}
            thead={thead}
            tdata={allusers}
            maxRow={10}
            handleRowClick={handleRowClick}
            handleImpersonateClick={handleImpersonateClick}
            height={isSmallerDesktop?617:767}
            loadPagination={setPage}
            action={
              { 
                default: ["impersonate"],
              }
            }
            onChange={onSearchChange}
            value={searchText}
            searchLabel={t('AllUsers.SearchUsers')}
            clickableColumn={-1}
            currentPage={page}
            errorStatus={error}
            errorBlockBtnClick={errorBlockBtnClick}
        />
        <Modals 
          handleclose={handleClose} 
          open={open} 
          content={bigHtmlContentnew}
        />                  
    </DashboardLayout>
  )
}

