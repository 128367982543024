const initalState = {
    loading: false,
    legacydb: null,
    error:null,
    createLoader: false,
    createdata: null,
    createError: null,
    createError500: null,
    deleteLoader: false,
    deleteError: null,
    deleteError500: null,
    deleteSuccess: null,
    detailsLoader: false,
    detailsError: null,
    databaseDetails: null,

    uploadDbLoader: false,
    uploadDbError: null,
    uploadDbError500: null,
    uploadDbDetails: null,

    deleteDbVersionLoader: false,
    deleteDbVersionError: null,
    deleteDbVersionError500: null,
    deleteDbVersionDetails: null,

    downloadLogLoader: false,
    downloadLogError: null,
    downloadLogDetails: null,

    downloadDBLoader: false,
    downloadDBError: null,
    downloadDBError500: null,
    downloadDBDetails: null,
  }
  
  export const database = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_DATABASE": {
        return { ...state, loading: true, error: null }
      }
      case "REQUEST_DATABASE_SUCCESS": {
        return { ...state, legacydb: action.payload, error: null, loading: false, }
      }
      case "REQUEST_DATABASE_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }


      case "REQUEST_CREATE_DATABASE": {
        return { ...state, createLoader: true, createError: null }
      }
      case "REQUEST_CREATE_DATABASE_SUCCESS": {
        return { ...state, createdata:action.payload,  createError: null, createLoader: false, }
      }
      case "REQUEST_CREATE_DATABASE_FAILED": {
        return { ...state, createLoader: false, createError: action.payload }
      }
      case "REQUEST_CREATE_DATABASE_FAILED_500": {
        return { ...state, createLoader: false, createError500: action.payload }
      }

      case "REQUEST_DELETE_DATABASE": {
        return { ...state, deleteLoader: true, deleteError: null }
      }
      case "REQUEST_DELETE_DATABASE_SUCCESS": {
        return { ...state, deleteSuccess: action.payload,  deleteError: null, deleteLoader: false, }
      }
      case "REQUEST_DELETE_DATABASE_FAILED": {
        return { ...state, deleteLoader: false, deleteError: action.payload }
      }
      case "REQUEST_DELETE_DATABASE_FAILED_500": {
        return { ...state, deleteLoader: false, deleteError500: action.payload }
      }


      case "REQUEST_DATABASE_DETAILS": {
        return { ...state, detailsLoader: true, detailsError: null }
      }
      case "REQUEST_DATABASE_DETAILS_SUCCESS": {
        return { ...state, databaseDetails: action.payload, detailsError: null, detailsLoader: false, }
      }
      case "REQUEST_DATABASE_DETAILS_FAILED": {
        return { ...state, detailsLoader: false, detailsError: action.payload }
      }

      case "REQUEST_UPLOAD_DATABASE": {
        return { ...state, uploadDbLoader: true, uploadDbError: null }
      }
      case "REQUEST_UPLOAD_DATABASE_SUCCESS": {
        return { ...state, uploadDbDetails: action.payload, uploadDbError: null, uploadDbLoader: false, }
      }
      case "REQUEST_UPLOAD_DATABASE_FAILED": {
        return { ...state, uploadDbLoader: false, uploadDbError: action.payload }
      }
      case "REQUEST_UPLOAD_DATABASE_FAILED_500": {
        return { ...state, uploadDbLoader: false, uploadDbError500: action.payload }
      }

      case "REQUEST_DATABASE_VERSION_DELETE": {
        return { ...state, deleteDbVersionLoader: true, deleteDbVersionError: null }
      }
      case "REQUEST_DATABASE_VERSION_DELETE_SUCCESS": {
        return { ...state, deleteDbVersionDetails: action.payload, deleteDbVersionError: null, deleteDbVersionLoader: false, }
      }
      case "REQUEST_DATABASE_VERSION_DELETE_FAILED": {
        return { ...state, deleteDbVersionLoader: false, deleteDbVersionError: action.payload }
      }
      case "REQUEST_DATABASE_VERSION_DELETE_FAILED_500": {
        return { ...state, deleteDbVersionLoader: false, deleteDbVersionError500: action.payload }
      }

      case "REQUEST_DOWNLOAD_LOG": {
        return { ...state, downloadLogLoader: true, downloadLogError: null }
      }
      case "REQUEST_DOWNLOAD_LOG_SUCCESS": {
        return { ...state, downloadLogDetails: action.payload, downloadLogError: null, downloadLogLoader: false, }
      }
      case "REQUEST_DOWNLOAD_LOG_FAILED": {
        return { ...state, downloadLogLoader: false, downloadLogError: action.payload }
      }

      case "REQUEST_DOWNLOAD_DB_VERSION": {
        return { ...state, downloadDBLoader: true, downloadDBError: null }
      }
      case "REQUEST_DOWNLOAD_DB_VERSION_SUCCESS": {
        return { ...state, downloadDBDetails: action.payload, downloadDBError: null, downloadDBLoader: false, }
      }
      case "REQUEST_DOWNLOAD_DB_VERSION_FAILED": {
        return { ...state, downloadDBLoader: false, downloadDBError: action.payload, downloadDBDetails: null }
      }
      case "REQUEST_DOWNLOAD_DB_VERSION_FAILED_500": {
        return { ...state, downloadDBLoader: false, downloadDBError500: action.payload, downloadDBDetails: null }
      }

      case "DB_ANALYTICS_DOWNLOAD_RESET": {
        return { ...state,       
          downloadLogLoader: false,
          downloadLogError: null,
          downloadLogDetails: null, }
      }
      
      default: {
        return state
      }
    }
  }

  