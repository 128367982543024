const styles = (theme) => ({
    head:{
        fontSize: 20,
        marginBottom: 20,
        fontFamily:"Roboto",
        fontWeight:"400",  
        [theme.breakpoints.up("xl")]:{
            fontSize: 30,
         },
    },
    divide:{
        marginBottom:"20px !important"
    },
    buttons: {
        display: 'flex',
        
    },
    sub:{
        marginLeft: 20
    },
    warning:{
        textAlign: 'center',
        fontSize: 20,
        color:"#36333D",
        
        fontFamily:"Roboto",
        fontWeight:"600", 
        [theme.breakpoints.up('xl')]: {
            fontSize: 35,
        }
    },
    text:{
        textAlign: 'center',
        fontSize: 14,
        padding:"5%",
        fontFamily:"Roboto",
        fontWeight:"600",
        color:"#36333D",
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        }
    },
    button2:{
        display:'flex',
        justifyContent: 'center'
    },
    logo1: {
        textAlign: 'center',
        padding: 10       
    },
    ok: {
        marginLeft: 10
    },
    colar: {
        backgroundColor: "#A2AAAD !important"
    },
    upload: {
        marginLeft: "70%! important"
    },
    popper: {
        marginBottom: 15
    },
    tick: {
        display:'flex',
        justifyContent: 'center',
    
    },
    popperText: {
        padding: 10,
        marginLeft: 10,
        fontFamily:"Roboto",
        fontWeight:"500",
    },
    success: {
        fontFamily:"Roboto",
        fontWeight:"600",
        marginBottom:8
    },
    circle: {
        marginTop: 15
    }


})

export default styles