import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";


 export const forgotWithJWT = (user) => {
  
  
  return dispatch => {
    dispatch({
      type: "FORGOT_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.forgot, {
        email: user.email,
        language: user.language
      }, config)
      .then(response => {
        dispatch({
          type: "FORGOT_SUCCESS",
          payload: response.data
        });
        
        
      })
      .catch(err => {
        if(err.response.status === 0 || err.response.status === 500){
          dispatch({
            type: "FORGOT_FAILED_STATUS_500",
            payload: true
          })
        } 
        dispatch({
          type: "FORGOT_FAILURE",
          payload: err.response.data.error[0]
        })
      })
  }
}
