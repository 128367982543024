import React,{useState, useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery,Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { styled } from '@mui/system';
import InputFile from 'components/InputFile';
import DetailsHead from 'components/DetailsHead';
import { AiOutlineCloseCircle} from 'react-icons/ai';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {getDatabaseDetails, uploadDb, deleteDbVersion, downloadDatabase} from "../../redux/actions/legacyDatabase/databaseAction"
import { useFormik } from "formik";
import Modals from 'components/Modal';
import {RiErrorWarningLine} from "react-icons/ri";
import { Spinner } from "reactstrap";
import toast, { Toaster } from 'react-hot-toast';
import Accordion from '@mui/material/Accordion';
import {useTranslation} from "react-i18next";




const Container = styled('div')(({ theme,height }) => ({
    position:"relative",
    width:"100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px #00000029",
    borderRadius: 5,
    opacity: 1,
    display:"flex",
    minHeight:height,
    flexDirection:"column",
    "& .box-container":{
        padding:"20px 36px"
    },
    "& .text-color": {
        fontSize: 16,
        color: '#C8102E',
        marginTop: 20
    },
    '& .box-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:700,
        fontSize:14,
        [theme.breakpoints.up("xl")]:{
          fontSize: 20,
       },
       [theme.breakpoints.down("xl")]:{
        fontSize: 15,
     },
    },
    [theme.breakpoints.down('xl')]: {
        "& .box-container":{
            padding:"15px 31px"
        },
    }
}))

const useStyles = makeStyles(styles)

export default function LegacyDatabaseDetailDetail() {
  const {t} = useTranslation('common');
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const [historyList, setHistorylist] = useState([]);
  const [page, setPage] = useState(1);
  const [allusersCount, setAllUsersCount] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentDbVersion, setCurrentDbVersion] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [dbError, setDbError] = useState();
  const [downloadingItem, setDownloadingItem] = useState(null);
  const { 
    loading,
    databaseDetails,
    uploadDbLoader,
    uploadDbDetails,
    deleteDbVersionDetails,
    uploadDbError,
    downloadDBError,
    downloadDBLoader,
    downloadDBDetails,
    uploadDbError500,
    downloadDBError500,
    deleteDbVersionError500,
    detailsError
  } = useSelector((state) => state.legacy.database);

  const {code} = useParams();
  const errors = {};
  

  const validate = values => {
    if (values.blob === "") {
      errors.name = t('legacyDatabaseDetails.selectFile')
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {    
      blob: "", 
      colordb_id: code,
    },
    validate,
    // validationSchema: Yup.object({        
    //   blob: mixed().required("Please choose a file.")                                           
    // }),
    onSubmit : (values, formikHelpers)=> {        
        dispatch(uploadDb(values)) 
    }
  });

  const linkData = [ 
    {
        displayName: t('legacyDatabaseDetails.legacyDatabase'),
        link:"/legacy-databases"
    },
    {
        displayName: t('legacyDatabaseDetails.db'),
        link:"/dashboard/legacy/databases",
        linkClick: true
    },
    {
        displayName: databaseDetails?.data.bucket_info.db_name,
        link:"/legacy/databases"
    },
]

const thead = [
  {
    name:"version",
    displayName:"#",
    type:"slno",
    latestTag: true,
    width:20,  //percentage
  },
  {
    name:"blob_original_name",
    displayName: t('legacyDatabaseDetails.name'),
    type:"text",
    width:50,  //percentage
  },
  {
    name:"size",
    displayName: t('legacyDatabaseDetails.size'),
    type:"number",
    width:20
  },
  {
    name:"uploaded",
    displayName: t('legacyDatabaseDetails.uploadDate'),
    type:"date",
    width:40
  },
  {
    name:"actions",
    displayName: t('legacyDatabaseDetails.actions'),
    type:"action",
    width:10
  },
]

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const handleRowClick = (item) => () => {
    
  }

  const handleDeleteClick = (item) => () =>{        
    setCurrentDbVersion(item)
    setDeleteModal(!deleteModal)
  }

  const deleteContent = (
    <>
    <div className={classes.logo1}>
        <RiErrorWarningLine color="#C8102E" size={"100px"} />
    </div>
    <div className={classes.warning}>
        {t('legacyDatabaseDetails.areYouSure')}
    </div>
    <div className={classes.text} >
        {t('legacyDatabaseDetails.deleteConfirmation')}
    </div>
    <div className={classes.button2}>
            <Button  
            onClick={()=> {
              setCurrentDbVersion()
              setDeleteModal(!deleteModal)}}  
              className='appBtnCancel'                                 
              size="large"
            >
               {t('legacyDatabaseDetails.cancel')}
            </Button>
            <div className={classes.ok}>
                <Button  
                className='appBtn'                       
                  size="large"
                  onClick={() =>   dispatch(deleteDbVersion(currentDbVersion))}
                >
                    {t('legacyDatabaseDetails.ok')}
                </Button>
            </div>
    </div>
  </>     
  )


  const errorContent = (
    <div style={{width: 500, fontFamily:"Roboto",}}>
    
    <div className={classes.logo1}>
        <AiOutlineCloseCircle color="#C8102E" size={"100px"} />
    </div>
    <Accordion style={{boxShadow: 'unset', background: "#f2dede", padding:15}}>
        <div>
            <p >{t('legacyDatabaseDetails.dbConversionFailed')}</p>
            <p style={{marginTop: 10}}>{dbError ? dbError[0] === "blob : Wrong file format, must be either a database or a zip archive" ? t('legacyDatabaseDetails.dbUploadError1') : dbError[0] === "blob : Provided archive does not contain a GDATA database" ? t('legacyDatabaseDetails.dbUploadError2'): dbError[0]: null}</p>
            {/* <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{marginTop: 0}}
                >
                <div style={{background: '#c8102e', borderRadius: 5, padding: 5, color: '#fff'}}>
                    Toggle logs
                </div>
            </AccordionSummary> */}
                
            </div>
            
        </Accordion>
    <div className={classes.button2}>
           
            <div className={classes.ok}>
                <Button                         
                  size="large"
                  onClick={() => setErrorModal(false)}
                >
                    {t('legacyDatabaseDetails.ok')}
                </Button>
            </div>
    </div>
  </div>     
  )

  const [anchorElTemp, setAnchorElTemp] = useState(null);

  const handleClick = (event) => {
    setAnchorElTemp(event.currentTarget);
  };

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      bucket_code: code,
      offset: paginationOffset,
      limit: 10
    }  
    dispatch(getDatabaseDetails(payload))
  },[page, uploadDbError, uploadDbDetails]);

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      bucket_code: code,
      offset: paginationOffset,
      limit: 10
    }  
    dispatch(getDatabaseDetails(payload))
  }

  useEffect(() => {  
    
    if(deleteDbVersionDetails?.status === 204) {
      toast.success(t('legacyDatabaseDetails.colorDbVersion') + currentDbVersion.version + t('legacyDatabaseDetails.deleted'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      setCurrentDbVersion()
      setDeleteModal(!deleteModal)
      let payload = {
        bucket_code: code,
        offset: 0,
        limit: 10
      }  
      setPage(1)
      dispatch(getDatabaseDetails(payload))
      dispatch({
        type: "REQUEST_DATABASE_VERSION_DELETE_SUCCESS",
        payload: null
      })
    }
    if(uploadDbError) {
      setErrorModal(true)
      setDbError(uploadDbError)
      formik.resetForm()
      dispatch({
        type: "REQUEST_UPLOAD_DATABASE_FAILED",
        payload: null
      })
    }
  },[deleteDbVersionDetails, uploadDbError]);

  
  const handleDownloadclick = (item) => () => {
    setDownloadingItem(item)
    dispatch(downloadDatabase(item.slug))
  }

  useEffect(() => {      
    if(databaseDetails) {
      setHistorylist(databaseDetails?.data.history)
      setAllUsersCount(databaseDetails?.count)
    }
  },[databaseDetails]);

  useEffect(() => {  
    if(downloadDBError) {
      toast.error(downloadDBError.error === "colordb version not found" ? t('legacyDatabaseDetails.colordbNotFound'): null, {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DOWNLOAD_DB_VERSION_FAILED",
        payload: null
      })
    }
    if(downloadDBDetails){
      var base64replace1 = downloadDBDetails.data.data.replace("b'", "");
      var base64replace2 = base64replace1.replace("'", "");
      download1(downloadingItem?.blob_original_name + ".db", base64replace2)
      dispatch({
        type: "REQUEST_DOWNLOAD_DB_VERSION_SUCCESS",
        payload: null
      });
    }
  },[downloadDBError, downloadDBDetails]);

  const download1 =(filename, data) =>  {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;base64,' + data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }

  

  useEffect(() => {   
    if(uploadDbDetails?.status === 201) {
      toast.success(t('legacyDatabaseDetails.newCreated'), {
        duration: 8000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
      dispatch({
        type: "REQUEST_UPLOAD_DATABASE_SUCCESS",
        payload: null
      });
      formik.resetForm();
    }
  }, [uploadDbDetails]);

  useEffect(() => {  
    if(uploadDbError500){
      toast.error(t('legacyDatabaseDetails.creationFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_UPLOAD_DATABASE_FAILED_500",
        payload: null
      })
    }

    if(downloadDBError500){
      toast.error(t('legacyDatabaseDetails.downloadFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DOWNLOAD_DB_VERSION_FAILED_500",
        payload: null
      })
    }

    if(deleteDbVersionError500){
      toast.error(t('legacyDatabaseDetails.deleteFailed')  , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DATABASE_VERSION_DELETE_FAILED_500",
        payload: null
      })
    }
    
  }, [uploadDbError500, downloadDBError500, deleteDbVersionError500])
     
  return (
    <DashboardLayout pageLoading={uploadDbLoader}>
      <BreadCrumb linkData={linkData}/>
        <DetailsHead
            column1={{label: t('legacyDatabaseDetails.dbName'), value: databaseDetails?.data.bucket_info.db_name}}
            column2={{label: t('legacyDatabaseDetails.dbCode'), value: databaseDetails?.data.bucket_info.db_code}}
        />

        <Container style={{marginTop: 50}}>
        <form onSubmit={formik.handleSubmit}>
            <Grid className='box-container' container direction="row" justifyContent="center" alignItems="center">
            
            <Grid className={classes.grid} item xs={12}>
                    <h3 className='box-title'>{t('legacyDatabaseDetails.dbUpload')}</h3> 
                </Grid>
                <Grid item sm={6}>
                    <InputFile 
                        
                        btnText={t('legacyDatabaseDetails.chooseFile')}
                        // inputWarning={"* For better results, upload an image with an aspect ratio of approximately 20:3 (e.g. 2000x300 px); Max pixel size: 4096 x1024 px, Max file size: 1000 kB"}
                        formik={formik}
                        onBlur={formik.handleBlur} 
                        value={formik.values.blob}
                        name="blob"
                        error={formik.touched.database && Boolean(formik.errors.database)}
                        helperText={formik.touched.database && formik.errors.database} 
                        refresh={databaseDetails}
                    />
                </Grid>
                <Grid item sm={6} style={{display: 'flex', justifyContent: 'end'}}>
                    <Button  
                    className="appBtn"                
                      size="small"
                      type="submit"
                      onClick={handleClick}
                    >
                      {uploadDbLoader ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('legacyDatabaseDetails.uploadDb')} 
                       
                    </Button> 
                    
                </Grid>
            </Grid>
            </form>
           
        </Container>

        <div style={{marginTop: 50}}>
        <Toaster position="bottom-center"/>
            <CustomTable 
                loading={loading}
                totalPageCount={allusersCount}
                title ={t('legacyDatabaseDetails.history')}
                thead={thead}
                tdata={historyList}
                maxRow={10}
                handleRowClick={handleRowClick}
                handleDownloadclick={handleDownloadclick}
                handleDeleteClick={handleDeleteClick}
                height={isSmallerDesktop?150:413}
                action={
                  {
                    default:["download","delete"]
                  }
                }
                loadPagination={setPage}
                currentPage={page}
                downloadLoader={downloadDBLoader} 
                currentItem ={downloadingItem}
                clickableColumn={-1}
                errorStatus={detailsError}
                errorBlockBtnClick={errorBlockBtnClick}
                errorTopheight={"translateY(50%)"}
            />
            <Modals 
                handleclose={() => setDeleteModal(!deleteModal)} 
                open={deleteModal} 
                content={deleteContent}                   
            />

            <Modals 
                handleclose={() => setErrorModal(!errorModal)} 
                open={errorModal} 
                content={errorContent}                   
            />
        </div>
        
    </DashboardLayout>
    
  )
}