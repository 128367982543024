import { combineReducers } from "redux"
import { analytics } from './analyticsReducer'
import { locations } from './locationAnalatycsReducer'
import { admin } from './adminDashboardReducer'

const analyticsReducers = combineReducers({
    analytics,
    locations,
    admin
})

export default analyticsReducers