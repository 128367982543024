import React,{useState, useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable } from 'components';
import { useDispatch, useSelector } from "react-redux"
import { getAllLocations } from "../../redux/actions/analytics/locationAnalyticsAction"
import { Grid,Button,IconButton} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AnalyticsModal from 'components/Modal/AnalyticsModal';
import styles from "./style";
import { makeStyles } from '@mui/styles';
import {AiFillCloseSquare, AiOutlineClear} from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import Tooltip from '@mui/material/Tooltip';
import {search} from '../../components/Search/Search'
import {TextField,InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';


import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { getAllBuckets } from 'redux/actions/xdata/bucketActions';




/* name is key which is taking from BE */

  const useStyles = makeStyles(styles)
  const StyledTextField = styled(TextField)(({ theme,height,helpertextcolor,placeholdercolor ,inputtextcolor,inputbackgroundcolor}) => ({
    borderRadius:2,
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        color:helpertextcolor
      },
      "& .MuiInputBase-root":{
        borderRadius:2,
      },
      "& .MuiInputBase-input:after":{
        borderBottom: 'unset'
      },
      '& .MuiInputBase-input:before': {
         borderBottom: 'unset !important'
      },
      "& .MuiInputAdornment-root":{
        position: 'absolute',
        marginLeft: 10
      },
      "& .search-icon":{
        color:"#C8102E",
    },
    input:{
        fontFamily:"Roboto",
        fontSize:14,
        fontWeight:300,
        border: "1px solid #E3E3E3",
        height:`${height-2}px`,
        padding:"0px 0px 0px 40px !important",
        borderRadius:2,
        color:inputtextcolor,
        "&::placeholder":{
            color:placeholdercolor?placeholdercolor:inputtextcolor
        }, 
        [theme.breakpoints.up("xl")]:{
            fontSize:"18px !important",
            
        }
    },
    [theme.breakpoints.down("xl")]:{
        minHeight: "unset",
        width: 200,
        minWidth: 200,
        input:{
            fontSize:13,
            padding:"0px 37px",
            height:`${height-18}px`,
            borderRadius:2,
        },
    },
    [theme.breakpoints.up("xl")]:{
        minHeight: "unset",
        width: 300,
        minWidth: 300,
        input:{
            fontSize:13,
            padding:"0px 37px",
            height:`${height+4}px`,
            borderRadius:2,
        },
    },
    p: {
        [theme.breakpoints.up("xl")]:{
            fontSize: 16   
        }
    },
    

}))


export default function XDataAnalyticsLocations() {
  const location1 = useLocation();
  const [code, setCode] = useState();
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('common');
  const [page, setPage] = useState(1)
  const [locationList, setLocationlist] = useState([])
  const [bucketList, setBucketList] = useState([])
  const [checkedBucketList, setCheckedBucketList] = useState([])
  let navigate = useNavigate();
  const [posPage, setPosPage] = useState(1)
  const [posNextPage, setPosNextPage] = useState("");
  const [filterClick, setFilterClick] = useState(false);
  
  
  
  const [locationsCount, setLocationsCount] = useState(0);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [bucketSearch, setBucketSearch] = useState("");
  const [bucketItems, setBucketItems] = useState([]);
  const classes = useStyles() 

    const theme = useTheme();
    const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
    const { loading, location, error} = useSelector((state) => state.analytics.locations);
    const { loading:bucketLoading, buckets} = useSelector((state) => state.xdata.buckets);

    const linkData = [ 
      {
          displayName: t('xdataLocation.xdata'),
          link:"/x-data"
      },
      {
          displayName:t('XdataAnalyticsDetail.Analytics'),
          link:"/dashboard/xdata/analytics",
          linkClick:true
      },
      {
          displayName: t('xdataLocation.locations'),
          link:"/xdata/analytics/locations/"
      }
  ]

    const thead = [
      {
        name:"pos_name",
        displayName: t('xdataLocation.locationName'),
        type:"text",
        width:30
      },
      {
        name:"pos_address",
        displayName: t('xdataLocation.locationAddress'),
        type:"text",
        width:30
      },
      {
        name:"pos_country",
        displayName: t('xdataLocation.locationCountry'),
        type:"text",
        width:30
      },
      {
        name:"dealer_code",
        displayName: t('xdataLocation.dealerid'),
        type:"text",
        width:20,  //percentage
      },
      {
        name:"bucket_id",
        displayName: t('xdataLocation.bucket'),
        type:"text",
        width:10
      },
    ]

    const handleRowClick = (item) => () => {
        navigate("/dashboard/xdata/analytics-location/"+item.bucket_id+"/",{
          state:item
        }) 
    }

    const handleClose = () =>{
      setBucketSearch("")
      const filterFromStorage = localStorage.getItem("filter")
      
      var is_same = true
      if(filterFromStorage != null){
        is_same = (JSON.parse(filterFromStorage).length == bucketItems.length) && JSON.parse(filterFromStorage).every(function(element, index) {
          return element.code === bucketItems[index]; 
        });
      }

      if(code !== ""){
       setOpen(false)
      }
      else if(filterFromStorage === null){
        setBucketList([])
        setBucketItems([])
        setOpen(false)
      }
      else if(is_same === false){
        setBucketList(JSON.parse(filterFromStorage))
        setCheckedBucketList(JSON.parse(filterFromStorage))
        var exArray = []
        JSON.parse(filterFromStorage).forEach((item, index) => {
          if(item.checked === true){
            exArray.push(item.code)
          }
        })
        setBucketItems(exArray)
        setOpen(false)
      }
    } 

    useEffect(() => {  
      
      const parsed = queryString.parse(window.location.search);
      if(window.location.search === "") {
        setCode("")
        localStorage.removeItem("filter")
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset,
          search: searchText,
          bucketItems: bucketItems.toString()
        }  
        dispatch(getAllLocations(payload)) 
      }
      else{
        setCode(parsed.code)
        setBucketItems([parsed.code])
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset,
          search: searchText,
          bucketItems: parsed.code
        }  
        dispatch(getAllLocations(payload)) 
        
      }
      
    },[]);

    useEffect(() => {  
      if(location?.data){
        var searchResult =  search(location?.data, searchText)
        if(searchResult && searchText !== ""){
          var firstIndex = (page * 10) - 10
          var lastIndex = (page * 10)
          const productArrayFiltered = searchResult.slice(firstIndex, lastIndex);
          setLocationlist(productArrayFiltered)
          setLocationsCount(searchResult.length)
        }
        else{
            var firstIndex = (page * 10) - 10
            var lastIndex = (page * 10)
            const productArrayFiltered = location.data.slice(firstIndex, lastIndex);
            setLocationlist(productArrayFiltered)
            setLocationsCount(location.data.length)
          }
        }
        if(error === 404) {
          setLocationlist([])
        }
      },[page, location, searchText, error]);


    const onSearchChange = (e) => {
      setPage(1)
      setSearchText(e.target.value)
    } 

    const headerFilter = (
      <>
          {
            bucketItems.length > 0 ? 
            <Button style={{marginLeft:30}} onClick={() => clearFilter()}>{t('xdataLocation.clearFilter')}</Button>: 
            null
          }
          <Button style={{marginLeft:2}} onClick={() => filterOpen()}>{t('xdataLocation.filter')}</Button>
      </>
  )

  const filterOpen = () => {
    if(bucketItems.length > 0) {
      
      if(code !== ""){
        setBucketList([])
       setCheckedBucketList([])
        let bucketPayload = {
          offset: 0
        }  
        dispatch(getAllBuckets(bucketPayload)) 
      }
      setOpen(true)
    }
    else {
      setBucketList([])
      setCheckedBucketList([])
      let bucketPayload = {
        offset: 0
      }  
      dispatch(getAllBuckets(bucketPayload)) 
      setOpen(true)
    }
    
  }

  const removeFilter = () => {
        setBucketItems([])
        setBucketList([])
        setCheckedBucketList([])
        localStorage.removeItem("filter")
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset,
          search: searchText,
          bucketItems: ""
        }
          
      dispatch(getAllLocations(payload))
      let bucketPayload = {
        offset: 0
      }  
      dispatch(getAllBuckets(bucketPayload)) 
      navigate("/dashboard/xdata/analytics/locations/")
  }

  const clearFilter = () => {
    if(filterClick === false){
      setFilterClick(true)
    }
      setBucketItems([])
      let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset,
          search: searchText,
          bucketItems: ""
        }
          
        dispatch(getAllLocations(payload))
        setBucketList([])
        setCheckedBucketList([])
        localStorage.removeItem("filter")
        
    navigate("/dashboard/xdata/analytics/locations/")
  }

  const onChangeCheck = (item, key) => () => {
   
      if(item.checked === true){
        var input = [...bucketList]
        input[key].checked = false
        setBucketList(input)
        setCheckedBucketList(input)
        // var input1 = [...checkedBucketList]
        // input1[key].checked = true
        // 
      }
      else{
        var input = [...bucketList]
        input[key].checked = true
        setBucketList(input)
        setCheckedBucketList(input)
        // var input1 = [...checkedBucketList]
        // input1[key].checked = false
        // setCheckedBucketList(input1)
      }
      var exArray = [...bucketItems]
      let already_in = false
      exArray.map((itemv,keyi)=>{ 
        if(itemv===item.code){
          already_in = true;
          exArray.splice(keyi, 1);
        }
      })
      if(already_in === false){
        exArray.push(item.code)
      }
      setBucketItems(exArray)
  }

  const onFilterSubmit = () => {
   
    
    let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset,
        search: searchText,
        bucketItems: bucketItems.toString()
      }  
      dispatch(getAllLocations(payload)) 
      setOpen(!open)
      navigate("/dashboard/xdata/analytics/locations/")
      setCode("")
      setBucketSearch("")
      localStorage.setItem("filter", JSON.stringify(bucketList))
  }

  const loadMore = () => {
        
        setPosPage(posPage+1)
        if(posNextPage !== ""){
          if(posNextPage === null){

          }else{
            dispatch(getAllBuckets({offset:posNextPage}))   
          }          
        }else{
          // getPosList(lastItem)
        }       
  }

  const bucketSearchOnChange = (event) => {
    setBucketSearch(event.target.value)
    const keyword = event.target.value;
    if (keyword !== '') {
    const results = buckets.data?.filter(item =>
      Object.keys(item).some(k => {
        if(typeof item[k] !== "number" && typeof item[k] !== "boolean"){
          return item[k]?.toLowerCase().includes(keyword.toLowerCase())
        }
      }));
      setBucketList(results)
    } else {
      setBucketList(buckets.data)
    }
   
  }

  useEffect(() => {
    if(buckets){
      if(bucketList.length === 0){
        setPosNextPage(buckets?.next)
        buckets.data.forEach(object => {
          if(object.code === code && filterClick === false){
            // bucketItems.push(object.code)
            object.checked = true;
            var input = [...checkedBucketList]
            input.push(object)
            setCheckedBucketList(input)
          }
          else{
            object.checked = false;
          }
        });
        setBucketList(buckets.data)
        setCheckedBucketList(buckets.data)
        localStorage.setItem("filter", JSON.stringify(buckets.data))
      }
      else if(buckets?.data.length > 0){
        setPosNextPage(buckets?.next)
        if(posNextPage !== null){
          let prev_posList = bucketList
          buckets.data.map((item,index)=>{ 
            if(item.code === code && filterClick === false){
              
              item.checked = true;
            }
            else{
              item.checked = false;
            }
           
            prev_posList.push(item)
          })
          setBucketList(prev_posList)
          setCheckedBucketList(prev_posList)
        }
      }else{
        // setBucketList([])
      }
    }
  },[buckets])

  const clearBuckets = () => {
    setBucketItems([])
    setBucketList([])
    
    let bucketPayload = {
      offset: 0
    }  
    dispatch(getAllBuckets(bucketPayload))
  }

  const bigHtmlContent = (
    <>
    <div className={classes.head}>                 
            <div className={classes.text} >{t('xdataLocation.filter')}</div>                               
            <div className={classes.button}>
                <IconButton onClick={() => handleClose()}>
                    <AiFillCloseSquare color='#C8102E' size="30px"/>
                </IconButton>
            </div>                
        </div>
        <StyledTextField
                        autoComplete={'off'}
                        variant="standard"
                        height={50}
                        name={"name"}
                        type={"text"}
                        placeholder={t('XdataAnalytics.searchBuckets')}
                        onChange={(e) => bucketSearchOnChange(e)}
                        value={bucketSearch}
                        placeholdercolor="#000000"
                        inputtextcolor="#000000"
                        inputbackgroundcolor="#FFF"
                        fullWidth={50}  
                        InputProps={{
                            disableUnderline: true,
                            startAdornment:
                              <InputAdornment position='start'>
                                <SearchIcon  className="search-icon"/>
                              </InputAdornment>
                             
                          }}           
                >
                    </StyledTextField>
    <Grid container item xs={12} >
      {
        bucketItems.length > 0 ? 
        <div style={{padding: 10, width: '100%', fontFamily: 'Roboto',border: "2px solid #EAEAEA", borderRadius: 5, marginTop: 10}}>
          <div style={{marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{fontSize: 17, fontWeight: 'bold'}}>{t('xdataLocation.selectedBuckets')}</div>
            </div> 
            <Grid container item xs={12} >
            {
              checkedBucketList.map((item, index) => {
                return(  
                  
                    item.checked === true ? 
                    <Grid item xs={4}>
                        <FormControlLabel className={classes.gridLabel}  name="poslist" value={item.code} onChange={onChangeCheck(item, index)}  control={<Checkbox checked={item.checked ? true: false} style ={{color: "#C8102E"}} />} 
                        label={item.name + " - " + item.code}/>
                  </Grid>: null
                  
                  
                )
              })
            }
            </Grid>
       </div>:
       null
      }
       
    </Grid>
    <Grid container item xs={12} >
      {
        bucketList.map((item, index) => {
          return(
            item.checked === false ? 
            <Grid item xs={4}>
                  <FormControlLabel className={classes.gridLabel}  name="poslist" value={item.code} onChange={onChangeCheck(item, index)}  control={<Checkbox checked={item.checked ? true: false} style ={{color: "#C8102E"}} />} 
                  label={item.name + " - " + item.code}/>
            </Grid>:null
          )
        })
      }
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
        {bucketLoading ?<Box sx={{ textAlign: 'center', width:'100%',marginTop:5}}><CircularProgress sx={{color:'#c8102e'}}/></Box>
        :
         null}
            
          </Grid>
          {
            bucketItems.length > 0 ? 
          <div className={classes.button1}>
              <Button          
              type="submit"
              variant="contained"   
              onClick={() => removeFilter()} 
              >
                {t('xdataLocation.clearFilter')}
              </Button>
          </div>:null
          }
          <div className={classes.button1} style={{marginLeft: 10}}>
              <Button          
              type="submit"
              variant="contained"   
              onClick={() => onFilterSubmit()} 
              >
                {t('xdataLocation.filter')}
              </Button>
          </div>
      </Grid>
    </>     
  )

  const errorBlockBtnClick = () => {
    const parsed = queryString.parse(window.location.search);
      
      if(window.location.search === "") {
        setCode("")
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset,
          search: searchText,
          bucketItems: bucketItems.toString()
        }  
        dispatch(getAllLocations(payload)) 
      }
      else{
        setCode(parsed.code)
        setBucketItems([parsed.code])
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset,
          search: searchText,
          bucketItems: parsed.code
        }  
        dispatch(getAllLocations(payload)) 
        
      }
  }
    
    

  return (
    <DashboardLayout>
        <BreadCrumb linkData={linkData}/>
        <CustomTable 
            loading={loading}
            title ={t('xdataLocation.locations')}
            thead={thead}
            tdata={locationList}
            maxRow={10}
            handleRowClick={handleRowClick}
            height={isSmallerDesktop?617:767}
            totalPageCount={locationsCount}
            currentPage={page}
            loadPagination={setPage}
            search={true}
            onChange={onSearchChange}
            value={searchText}
            searchLabel={t('xdataLocation.searchLocations')}
            headerFilter={headerFilter}
            clickableColumn={0}
            errorStatus = {error}
            errorBlockBtnClick={errorBlockBtnClick}
        />

        <AnalyticsModal 
            handleclose={handleClose} 
            open={open} 
            scrollBottom={true}
            content={bigHtmlContent}
            filterModal
        /> 

    </DashboardLayout>
  )
}
