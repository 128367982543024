import React from 'react';
import ReactEcharts from "echarts-for-react"; 
import echarts from 'echarts/lib/echarts';


export default function NestedPie({chartInfo}) {  

    return (  
        <ReactEcharts
          echarts={echarts}
          option={chartInfo}
          opts={{renderer: 'svg'}}
          style={{width: '50%', height: '350px'}}
        />
    )
}
