import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";

export const userPermissionActivationActions = (user, values) => {
  
  return dispatch => {
    dispatch({
      type: "ACTIVATION_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      // .get(api_settings.url_base + api_endpoints.activation + "?token=" + user, config)
      .post(api_settings.url_base + api_endpoints.permission_access + "?token=" + user , {
        database: values.database,
        analytics: values.analytics,
        themes: values.themes,
        graphs: values.graphs,
        reject: values.reject
      }, config)
      .then(response => {
        dispatch({
          type: "ACTIVATION_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: "ACTIVATION_FAILURE",
          payload: err.response.data
        })
      })
  }
}