import { history } from "../../../history"
import { store } from "../../storeConfig/store";


export function logoutRedirect (dispatch) {    
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    if(impersonateUserInfo?.status === "success"){
        dispatch({
            type: "REQUEST_IMPERSONATE_USER_DATA_SUCCESS",
            payload: null
        })    
    }
    else{
        dispatch({ type: "LOGOUT", payload: {} });
    }
    history.push("/logout");
    window.location.reload();
}