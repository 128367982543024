import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery } from "@mui/material";
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { useFormik } from "formik";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import styles from "./style";
import {RiErrorWarningLine} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux"
import { getAllRootAccessUsers, editUserAccess, deleteUserAccess } from "../../redux/actions/roorAccess/rootAccessActions"
import {forgotWithJWT} from "../../redux/actions/auth/forgotActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(styles)

export default function WorkspaceUsers() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName:t('rootAccessUsers.rootAccessUsers'),
        link:"/dashboard/root-access-list"
    }
  ]

  const thead = [
    {
      name:"email",
      displayName:t('rootAccessUsers.Email'),
      type:"text",
      width:40,  //percentage
    },
    {
      name:"access",//
      displayName:t('rootAccessUsers.Access'),
      type:"boolean",
      width:10,  //percentage
    },
    {
      name:"datetime_permission_requested",
      displayName:t('rootAccessUsers.PermissionRequestedAt'),
      type:"text",
      width:30,  //percentage
    },
    {
      name:"datetime_permission_requested_verified",
      displayName:t('rootAccessUsers.PermissionVerifiedAt'),
      type:"text",
      width:40,  //percentage
    },
    {
      name:"Last_login_at",
      displayName:t('rootAccessUsers.LastLoginAt'),
      type:"text",
      width:40,  //percentage
    },
    {
      name:"actions",
      displayName:t('rootAccessUsers.Actions'),
      type:"action",
      width:13
    },
  ]
  const classes = useStyles();
  const theme = useTheme();
  const [btnOpen, setbtnOpen] = useState(false);
  const [Delete,setDelete] = useState(false);
  const [checkBoxError,setCheckboxError] = useState();
  

  const [userlist, setUserlist] = useState([]);

    const deleteClose = () =>setDelete(false);

    const [page, setPage] = useState(1);
    const [deleteItem, setDeleteItem] = useState(null);
    const [allusersCount, setAllUsersCount] = useState(0);
    const [originalPermission, setOriginalPermission] = useState();

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { loading,
    rootAccessusers,
    updateLoading,
    updateSuccess,
    deleteSuccess,
    deleteLoading,
    updateError500, 
    deleteError500,
    error
  } = useSelector((state) => state.rootAccess.user);
  const { user } = useSelector((state) => state.auth.login);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  const forgot = useSelector((state) => state.auth.forgot);
  

  useEffect(() => {  
    let rootAccessusersList = []
    if(rootAccessusers?.data){
      (rootAccessusers.data).forEach(function(item){
        rootAccessusersList.push({
          email: item.email,
          access:  item.access === 'Yes'? true:false,
          datetime_permission_requested: item.user_requested_for_root_access_permission_at,
          datetime_permission_requested_verified: item.user_root_access_request_verified_by_IT_at,
          Last_login_at: item.Last_login_at,
        })
      })
      setUserlist(rootAccessusersList)
      setAllUsersCount(rootAccessusers.count)
    }
  }, [rootAccessusers])
  

  const handleRowClick = (item) => () => {
    //
  }

  const handleLockClick = (item) => () => {
    let values = {
      email: item.email,
      language: language.languageLabel === "italiano" ? "italian" : language.languageLabel === "English" ? "english" : "chinese" 
    }
    dispatch(forgotWithJWT(values)) 
    
  }

  const editFormik = useFormik({
    initialValues: {
      access:false,
  },
  onSubmit : (values, formikHelpers)=> {
    if(originalPermission.access === values.access){
      toast.error(t('rootAccessUsers.Nochangesweremade'), {
        duration: 6000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
    }
    else{
        if(values.access === false){
          setCheckboxError(t('rootAccessUsers.Permissionrequired'))
        }
        else{
          setCheckboxError()
          dispatch(editUserAccess(values)) 
        }
    }
  },
  });

  const buttonClose = () => {
    setOriginalPermission()
    editFormik.resetForm()
    setCheckboxError()
    setbtnOpen(false)
  };

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllRootAccessUsers(payload)) 
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllRootAccessUsers(payload))  

    if(updateSuccess?.status === "success"){
      setbtnOpen(false)
      Swal.fire({
        title: t('rootAccessUsers.Success'),
        text: t('rootAccessUsers.AccessUpdated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      
    }

  },[page,updateSuccess, dispatch, t]);

  

  useEffect(() => {  
  
    if(deleteSuccess){
      setDelete(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllRootAccessUsers(payload)) 
      Swal.fire({
        title: t('rootAccessUsers.Success'),
        text: t('rootAccessUsers.AccountDeleted'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }

  },[deleteSuccess, dispatch, t]);


  const handleDeleteClick = (item) => () => {
    setDelete(true)
    setDeleteItem(item)
  }

  const deleteConfirm = () => {    
    dispatch(deleteUserAccess({email: deleteItem.email}))
  }

  const handleEditClick = (item) => () => {
    setOriginalPermission({
      access: item.access === 'Yes'? true:false,
    })
    editFormik.values.email = item.email;
    if(item.access === true){
      editFormik.values.access = true;
    }
    else if(item.access === false){
      editFormik.values.access = false;
    }
    setbtnOpen(true)
  }


  useEffect(() => {  
    if(updateError500){
      toast.error(t('rootAccessUsers.userUpdateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "UPDATE_REQUEST_USERACCESS_FAILED_500",
        payload: null
      })
    }

    if(deleteError500){
      toast.error(t('rootAccessUsers.userDeleteFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "DELETE_REQUEST_USERACCESS_FAILED_500",
        payload: null
      })
    }
    
  }, [updateError500, deleteError500, dispatch])
 

  let impersonateUserInfoData = impersonateUserInfo?.data
  

  const bigHtmlContent1 = (
    <>
    <form onSubmit={editFormik.handleSubmit}>
    <div className={classes.head}>{t('rootAccessUsers.EditUserAccess')}</div>
    <Divider className={classes.divide} />
      <div className={classes.permission}>
      {t('rootAccessUsers.Permissions')} *
      </div>
      {(user.is_supervisor === true) &&
        (<FormControlLabel name="access" value="access" onChange={editFormik.handleChange} checked={editFormik.values.access} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('rootAccessUsers.revertPermission')}/>)
      } 
        <div>
        <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
       <div className={classes.button}>
                  <Button  
                  onClick={buttonClose}
                  className="appBtnCancel"                                    
                    size="large"
                  >
                     {t('rootAccessUsers.Cancel')}
                  </Button>
                  <div className={classes.update}>
                      <Button 
                       className='appBtn'                        
                        size="large"
                        type="submit"
                      >
                       {updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('rootAccessUsers.Update')}  
                      </Button>
                  </div>
          </div>
      </div>
      </form>
    </>                            
  )

  const bigHtmlContentImp1 = (
    <>
    <form onSubmit={editFormik.handleSubmit}>
    <div className={classes.head}>{t('rootAccessUsers.EditWorkspaceUser')}</div>
    <Divider className={classes.divide} />
      <div className={classes.permission}>
      {t('rootAccessUsers.Permissions')} *
      </div>
      {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_supervisor === true) &&
        (<FormControlLabel name="access" value="access" onChange={editFormik.handleChange} checked={editFormik.values.access} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('rootAccessUsers.revertPermission')}/>)
      }
      <div>
      <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
       <div className={classes.button}>
                  <Button  
                  onClick={buttonClose}
                  className="appBtnCancel"                                    
                    size="large"
                  >
                     {t('rootAccessUsers.Cancel')}
                  </Button>
                  <div className={classes.update}>
                      <Button 
                       className='appBtn'                        
                        size="large"
                        type="submit"
                      >
                       {updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('rootAccessUsers.Update')}  
                      </Button>
                  </div>
          </div>
      </div>
      </form>
    </>                            
  )

  const bigHtmlContent3 = (
    <>
      <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('rootAccessUsers.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('rootAccessUsers.Youareabouttodeleteaworkspaceuser')}
              
      </div>
      
      <div className={classes.button2}>
       
              <Button  
              className="appBtnCancel"  
                onClick={deleteClose}                         
                size="large"
              >
                 {t('rootAccessUsers.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={deleteConfirm}
                  >
                    {deleteLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('rootAccessUsers.OK')} 
                  </Button>
              </div>
           
      </div>
     
    </>     
  )
  
  if(impersonateUserInfo?.status === "success"){
    return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loading}
              totalPageCount={allusersCount}
              title ={t('rootAccessUsers.rootAccessUsers')}
              thead={thead}
              tdata={userlist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:[],
                        default:["edit","delete"]
                      }
                    }
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handleLockClick={handleLockClick}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={-1}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
        handleclose={buttonClose} 
        open={btnOpen} 
        content={bigHtmlContentImp1}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={Delete} 
        content={bigHtmlContent3}                   
      />
      </DashboardLayout>
      
    )
                  }
  else{
    return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loading}
              totalPageCount={allusersCount}
              title ={t('rootAccessUsers.rootAccessUsers')}
              thead={thead}
              tdata={userlist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:[],
                        default:["edit","delete"]
                      }
                    }
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handleLockClick={handleLockClick}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={-1}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
        handleclose={buttonClose} 
        open={btnOpen} 
        content={bigHtmlContent1}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={Delete} 
        content={bigHtmlContent3}                   
      />
      </DashboardLayout>
      
    )
  }
}
