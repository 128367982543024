const initalState = {
    loading: false,
    error:null,
    success: null,
    errorStatus: null,
    companyListLoading: false,
    companyListError:null,
    companyListSuccess: null,
    userCompanyListSuccess: null,
    countryListLoading: false,
    countryListError:null,
    countryListSuccess: null,
  }
  
  export const register = (state = initalState, action) => {
    switch (action.type) {
      case "REGISTER_REQUEST": {
        return { ...state, loading: true, error:null, success: null }
      }
      case "REGISTER_SUCCESS": {
        return { ...state, error:null, loading: false, success: action.payload }
      }
      case "REGISTER_FAILURE": {
        return { ...state, loading: false, error:action.payload, success: null }
      }
      case "REGISTER_FAILURE_STATUS": {
        return { ...state, loading: false, errorStatus:action.payload, success: null }
      }

      case "COMPANY_LIST_REQUEST": {
        return { ...state, companyListLoading: true, companyListError:null, companyListSuccess: null }
      }
      case "COMPANY_LIST_SUCCESS": {
        return { ...state, companyListError:null, companyListLoading: false, companyListSuccess: action.payload }
      }
      case "COMPANY_LIST_FAILURE": {
        return { ...state, companyListLoading: false, companyListError:action.payload, companyListSuccess: null }
      }

      case "COUNTRY_LIST_REQUEST": {
        return { ...state, countryListLoading: true, countryListError:null, countryListSuccess: null }
      }
      case "COUNTRY_LIST_SUCCESS": {
        return { ...state, countryListError:null, countryListLoading: false, countryListSuccess: action.payload }
      }
      case "COUNTRY_LIST_FAILURE": {
        return { ...state, countryListLoading: false, countryListError:action.payload, countryListSuccess: null }
      }

      case "USER_COMPANY_LIST_REQUEST": {
        return { ...state, companyListLoading: true, companyListError:null, userCompanyListSuccess: null }
      }
      case "USER_COMPANY_LIST_SUCCESS": {
        return { ...state, companyListError:null, companyListLoading: false, userCompanyListSuccess: action.payload }
      }
      case "USER_COMPANY_LIST_FAILURE": {
        return { ...state, companyListLoading: false, companyListError:action.payload, userCompanyListSuccess: null }
      }
      
      default: {
        return state
      }
    }
  }