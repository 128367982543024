import React from 'react'
import { PublicLayout } from 'layouts'
import { SignInForm } from 'components'

const SignIn = () => {
  return (
    <PublicLayout>
        <SignInForm />
    </PublicLayout>
  )
}


export default SignIn
