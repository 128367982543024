const initalState = {
    loading: false,
    legacyanalytics: null,
    error:null,
    analyticsExporterloading: false,
    analyticsExporterDetails: null,
    analyticsExporterError:null,
    analyticsExporterError500:null,
    posLoading: false,
    posSuccess: null,
    posError: null
  }
  
  export const analytics = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_ANALYTICS": {
        return { ...state, loading: true, error: null, posSuccess: null, analyticsExporterDetails: null }
      }
      case "REQUEST_ANALYTICS_SUCCESS": {
        return { ...state, legacyanalytics: action.payload, error: null, loading: false, }
      }
      case "REQUEST_ANALYTICS_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }

      case "REQUEST_ANALYTICS_EXPORTER": {
        return { ...state, analyticsExporterloading: true, analyticsExporterError: null }
      }
      case "REQUEST_ANALYTICS_EXPORTER_SUCCESS": {
        return { ...state, analyticsExporterDetails: action.payload, analyticsExporterError: null, analyticsExporterloading: false, }
      }
      case "REQUEST_ANALYTICS_EXPORTER_FAILED": {
        return { ...state, analyticsExporterloading: false, analyticsExporterError: action.payload }
      }
      case "REQUEST_ANALYTICS_EXPORTER_FAILED_500": {
        return { ...state, analyticsExporterloading: false, analyticsExporterError500: action.payload }
      }
      case "REQUEST_POS_LIST": {
        return { ...state, posLoading: true, posError: null }
      }
      case "REQUEST_POS_LIST_SUCCESS": {
        return { ...state, posLoading: false, posError: null, posSuccess: action.payload }
      }
      case "REQUEST_POS_LIST_FAILED": {
        return { ...state, posLoading: false, posError: action.payload, posSuccess: null }
      }


      default: {
        return state
      }
    }
  }