const styles = (theme) => (
    {
   
    head:{
        fontSize: 20,
        fontFamily:"Roboto",
        fontWeight:"400",  
        [theme.breakpoints.up("xl")]:{
            fontSize: 30,
         },
    },
    divide:{
        marginBottom:"20px !important"
    },
    buttons: {
        display: 'flex',
    },
    sub:{
        marginLeft: 20
    },
    warning:{
        textAlign: 'center',
        fontSize: 20,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:"600", 
        [theme.breakpoints.up('xl')]: {
            fontSize: 35,
        }
    },
    text:{
        textAlign: 'center',
        fontSize: 14,
        padding:"5%",
        fontFamily:"Roboto",
        fontWeight:"600",
        color:"#36333D",
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        }
    },
    button2:{
        display:'flex',
        justifyContent: 'center'
    },
    logo1: {
        textAlign: 'center',
        padding: 10       
    },
    ok: {
        marginLeft: 10
    },
    colar: {
        backgroundColor: "#A2AAAD !important"
    },
    rowDiv: {
        marginTop: 10
    },
    colorName:{
        fontSize: 14,
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        }
    },
    reloadHead: {
        fontSize: 20, 
        fontWeight: 'bold',
         marginTop: 20,
         [theme.breakpoints.up('xl')]: {
            fontSize: 30,
        }
    },
    reloadPara: {
        fontSize: 14, 
        color: '#36333D', 
        marginTop: 20,
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        }
    },
    colorMainDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    colorBox: {
        width: 12, 
        height: 12, 
        borderRadius: 3,
        border: "1px solid",
        [theme.breakpoints.up('xl')]: {
            width: 15, 
            height: 15, 
        }
    },
    colorCode: {
        color: '#C8102E', 
        marginLeft: 10, 
        fontSize: 11,
        [theme.breakpoints.up('xl')]: {
            fontSize: 17,
        }
    }
})

export default styles