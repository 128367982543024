const styles = (theme) => ({
    heading:{      
        marginTop:36,
        color:"#fff",
        fontSize:30, 
        fontFamily:"Roboto",
        fontWeight:"Medium",  
        [theme.breakpoints.up("xl")]:{
            fontSize:"40px !important", 
        }   
    },
    subheading:{
        color: "#fff",
        marginTop:"36px !important",
        marginBottom:"25px !important",
        fontSize:13, 
        fontFamily:"Roboto",
        fontWeight:"400",  
        opacity:0.9,
        [theme.breakpoints.up("xl")]:{
            fontSize:"20px !important", 
        } 
    
    },

    text:{
         
        color:"#fff !important"
    },
    button:{
        width:"100%",
       backgroundColor:"#000 !important",
       marginTop:6,
       fontSize:'20px !important', 
        fontFamily:"Roboto",
        fontWeight:500, 
       [theme.breakpoints.down("xl")]:{
        marginTop:1,
        fontSize:'18px !important'
       },
       [theme.breakpoints.up("xl")]:{
        minHeight: "65px!important",
        minWidth:  "160px !important",
        fontSize: "22px!important",
    }
       
    },
    bottom:{
        textAlign:"center",
        marginTop:"20px !important",
        fontSize:18, 
        fontFamily:"Roboto",
        fontWeight:"400",  
    },
    link: {
        hover:"none",
        opacity:0.8,
        fontSize: 15,
        [theme.breakpoints.up("xl")]:{
            fontSize:"20px !important", 
        }
    },
    
//     placeholder:{
//        color:"#C8102E !important"
//    },
   

    
textField: {
    "& input":{
        backgroundColor:"red"
    },
    "& input::placeholder": {
      fontSize: "20px"
    }
  },

  tag:{
    fontSize: 16,
    fontWeight:500
} ,
logo: {
    display: "flex",
    justifyContent: "center"
 },
 button1: {
    display:"flex",
    justifyContent:"center",
    textAlign: "center"
 },
 text1: {
    // display:"flex",
    justifyContent:"center",
    textAlign: "center",
    marginTop:20
 },


})
export default styles