const styles = (theme) => ({
    signupContainer:{
        display:"flex",
        flexDirection:"column",
        // alignItems:"center",
        // justifyContent:"center",
        width:"100%",
        boxShadow: "0px 2px 10px #00000029",
        backgroundColor:"#FFFFFF",
        padding:"67px 82px",
        [theme.breakpoints.down('xl')]: {
            padding:"50px 75px",
        },
        [theme.breakpoints.up('xl')]: {
            padding:"75px 110px",
        },
        
    },
    logo:{
        alignSelf:"flex-start",
        marginBottom:25,
        [theme.breakpoints.down('xl')]: {
            marginBottom:20,
            width:150,
            height:50
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom:20,
            width:200,
            height:100
        }
    },
    header:{
        fontFamily:"Roboto",
        color:"#36333D",
        fontWeight:500,
        fontSize:30,
        [theme.breakpoints.down('xl')]: {
            fontSize:30
        },
        [theme.breakpoints.up('xl')]: {
            fontSize:40
        }
    }
})

export default styles