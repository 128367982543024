const initalState = {
    userRole: "admin",
    loading: false,
    user: null,
    error:null,
    errorStatus: null,
    accessrootuser: null,
    accessrooterror:null,
    accessrooterrorStatus: null,
    accessroototpuser: null,
    accessroototperror:null,
    accessrooterrorotpStatus: null,
    checkroototpuser: null,
    checkroototperror:null,
    checkrooterrorotpStatus: null
  }
  
  export const login = (state = initalState, action) => {  
    switch (action.type) {
      case "LOGIN_WITH_EMAIL_REQUEST": {
        return { ...state, loading: true, error: null }
      }
      case "LOGIN_WITH_EMAIL_SUCCESS": {
        return { ...state, user: action.payload, error: null, loading: false, }
      }
      case "LOGIN_WITH_EMAIL_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }
      case "LOGIN_WITH_EMAIL_FAILED_STATUS": {
        return { ...state, loading: false, error: null, errorStatus: action.payload }
      }
      
      case "ACCESS_OF_ROOT_REQUEST": {
        return { ...state, loading: true, accessrooterror: null }
      }
      case "ACCESS_OF_ROOT_SUCCESS": {
        return { ...state, accessrootuser: action.payload, accessrooterror: null, loading: false, }
      }
      case "ACCESS_OF_ROOT_FAILED": {
        return { ...state, loading: false, accessrooterror: action.payload }
      }
      case "ACCESS_OF_ROOT_FAILED_STATUS": {
        return { ...state, loading: false, accessrooterror: null, accessrooterrorStatus: action.payload }
      }

      case "CHECK_OTP_FOR_ROOT_REQUEST": {
        return { ...state, loading: true, checkingroototperror: null }
      }
      case "CHECK_OTP_FOR_ROOT_SUCCESS": {
        return { ...state, checkingroototpuser: action.payload, checkingroototperror: null, loading: false, }
      }
      case "CHECK_OTP_FOR_ROOT_FAILED": {
        return { ...state, loading: false, checkingroototperror: action.payload }
      }
      case "CHECK_OTP_FOR_ROOT_FAILED_STATUS": {
        return { ...state, loading: false, checkingroototperror: null, checkingroototperrorStatus: action.payload }
      }

      case "ACCESS_OF_OTP_FOR_ROOT_REQUEST": {
        return { ...state, loading: true, accessroototperror: null }
      }
      case "ACCESS_OF_OTP_FOR_ROOT_SUCCESS": {
        return { ...state, accessroototpuser: action.payload, accessroototperror: null, loading: false, }
      }
      case "ACCESS_OF_OTP_FOR_ROOT_FAILED": {
        return { ...state, loading: false, accessroototperror: action.payload }
      }
      case "ACCESS_OF_OTP_FOR_ROOT_FAILED_STATUS": {
        return { ...state, loading: false, accessroototperror: null, accessroototperrorStatus: action.payload }
      }

      case "INITIAL_STATE": {
        return { ...state, loading: false, error: null }
      }

      case "LOGOUT": {
        return { ...state, user: null, error: null, loading: false, }
      }
      default: {
        return state
      }
    }
  }
  