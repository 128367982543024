import React from 'react'
import { WhiteLayout } from 'layouts'
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import {useTranslation} from "react-i18next";


import { ReactComponent as Logo } from "assets/images/corob-logo-red.svg";
import CompanyRegForm from 'components/CompanyReg';

const useStyles = makeStyles(styles)

export default function CompanyReg() {
  const {t} = useTranslation('common');
    const classes = useStyles()
  return (
    <WhiteLayout horizontalAlign="top">
        <div className={classes.signupContainer}>
            <Logo className={classes.logo}/>
            <div className={classes.header}>{t('companyReg.title')}</div>
            <CompanyRegForm/>
        </div>
    </WhiteLayout>
  )
}
