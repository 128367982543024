import { combineReducers } from "redux"
import auth from "./auth";
import legacy from "./legacyDatabase";
import workspace from "./workspace";
import xdata from "./xdata";
import analytics from "./analytics";
import history from "./history";
import themes from "./themes";
import impersonate from "./impersonate";
import userActivation from "./userActivation";
import companyActivation from "./companyActivation";
import userPermissionActivation from "./userPermissionActivation";
import queryresolved from "./queryresolved"
import rootAccess from "./rootAccess";
import rootAccessActivation from "./rootAccessActivation";

import language from "./language";
import userquery from "./userquery";

const rootReducer = combineReducers({
    auth: auth,
    workspace: workspace,
    xdata: xdata,
    analytics: analytics,
    legacy: legacy,
    history: history,
    themes: themes,
    impersonate: impersonate,
    userActivation: userActivation,
    companyActivation: companyActivation,
    userPermissionActivation: userPermissionActivation,
    rootAccessActivation: rootAccessActivation,
    userquery: userquery,
    queryresolved:queryresolved,
    language: language,
    rootAccess: rootAccess,
})

export default rootReducer