import React,{useState, useEffect } from 'react'
import { InputFile} from 'components';
import { Button,Typography,Grid } from "@mui/material";
import { useFormik } from "formik";
import { styled } from '@mui/system';
import { ReactComponent as Grey } from "assets/images/Grey.svg";
import { ReactComponent as Blue } from "assets/images/Blue.svg";
import { useDispatch, useSelector } from "react-redux";
import {createThemeVersion, reloadThemeVersion} from "../../redux/actions/themes/themesActions"
import {AiOutlineCheck} from "react-icons/ai";
import ColorPicker from "./ColorPicker";
import toast, { Toaster } from 'react-hot-toast';
import { Spinner } from "reactstrap"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {useTranslation} from "react-i18next";
 

const Container1 = styled('div')(({ theme,height }) => ({
    padding:20,
    "& .box-container":{
        padding:"20px 36px",
        border: "1px solid #EAEAEA", 
        borderRadius: 10,
        fontFamily:"Roboto",
    },
    '& .box-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:700,
        fontSize:14,
        [theme.breakpoints.up("xl")]:{
            fontSize: 20,
         },
         [theme.breakpoints.down("xl")]:{
          fontSize: 15,
       },
    },
    '& .theme-grid': {
        marginTop: 20,
        fontFamily:"Roboto",
        fontSize: 12,
        [theme.breakpoints.up("xl")]:{
            fontSize: 20,
         },
    },
    '& .theme-box':{
        position: 'relative', 
        display: 'flex', 
        padding: 7, 
        borderRadius: 5,
        width: "95%",
        alignItems: 'center',
        border: "1px solid"
    },
    '& .theme-code': {
        marginLeft: 5, 
        fontSize:11,
        [theme.breakpoints.up("xl")]:{
            fontSize: 14,
         },
    },
    '& .subtheme-box': {
        width: 12, 
        height: 12, 
        borderRadius: 3, 
        border: '1px solid',
        [theme.breakpoints.up("xl")]:{
            width: 15, 
            height: 15, 
         },
    },
    '& .theme-icon':{
        position: "absolute",
        right: 10,
    },
    '& .sketch-picker':{
        position: "absolute"
    },
    '& .colorpickerDiv':{
        width: "200px"
    },
    
    [theme.breakpoints.down('xl')]: {
        "& .box-container":{
            padding:"15px 31px"
        },
    }
}))


const Container = styled('div')(({ theme,height }) => ({
    position:"relative",
    width:"100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px #00000029",
    borderRadius: 5,
    opacity: 1,
    display:"flex",
    minHeight:height,
    flexDirection:"column",
    "& .box-container":{
        padding:"20px 36px"
    },
    "& .text-color": {
        fontSize: 16,
        color: '#C8102E',
        marginTop: 20
    },
    '& .box-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:700,
        fontSize: 14,
        [theme.breakpoints.up("xl")]:{
            fontSize: 20,
         },
         [theme.breakpoints.down("xl")]:{
          fontSize: 15,
       },
    },
    [theme.breakpoints.down('xl')]: {
        "& .box-container":{
            padding:"15px 31px"
        },
    }
}))

export default function Themes({item, reloadThemeVersionSuccess, page}) {
    const {t} = useTranslation('common');
    const [styleBox, setStylebox] = useState("");
    const dispatch = useDispatch();
    const { themeCreateVersionLoader, themeVersionCreateError, themeVersionCreateDetails,themeDetails } = useSelector((state) => state.themes.themes);
    
    const formik = useFormik({
        initialValues: {    
          logo: "", 
          secondary_logo: "",
          sidebar_color: "rgb(96, 103, 112)",
          text_over_sidebar_color: "rgb(255, 255, 255)",
          background_color: "rgb(162, 173, 178)",
          text_over_background_color: "rgb(73, 101, 115)",
          primary_color: "rgb(232, 232, 232)",
          text_over_primary_color: "rgb(73, 101, 115)",
          background_over_primary_color: "rgb(201, 201, 201)",
          disabled_text_over_primary_color: "rgb(167, 163, 180)",
          call_to_action_color: "rgb(171, 35, 40)",
          disabled_button_text_color: "rgb(167, 163, 180)",
          disabled_button_background_color: "rgb(218, 218, 218)",
          text_input_background_color: "rgb(255, 255, 255)",
          text_input_text_color: "rgb(115, 115, 115)",
          text_input_placeholder_color: "rgb(208, 208, 208)",
          list_highlight_color: "rgb(176, 185, 197)",
          list_highlight_border_color: "rgb(96, 103, 112)",
          menu_background_color: "rgb(171, 35, 40)",
          text_over_menu_color: "rgb(255, 255, 255)",
          success_color: "rgb(0, 255, 0)",
          warning_color: "rgb(255, 255, 0)",
          error_color: "rgb(255, 0, 0)",
          theme: item
        },
        onSubmit : (values, formikHelpers)=> {
            dispatch(createThemeVersion(values))
        }
      });

      useEffect(() => { 
        if(themeVersionCreateError?.message?.logo){
            toast.error(themeVersionCreateError.message.logo, {
                duration: 8000,
                position: 'top-right',
                style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
              });
              dispatch({
                type: "REQUEST_CREATE_THEME_VERSION_FAILED",
                payload: null
              });
        }

        if(themeVersionCreateError?.message?.non_field_errors){
            toast.error(themeVersionCreateError.message.non_field_errors[0], {
                duration: 8000,
                position: 'top-right',
                style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
              });
              dispatch({
                type: "REQUEST_CREATE_THEME_VERSION_FAILED",
                payload: null
              });
        }
        
        if(themeVersionCreateError?.message?.secondary_logo){
            toast.error(themeVersionCreateError.message.secondary_logo, {
                duration: 8000,
                position: 'top-right',
                style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
              });
              dispatch({
                type: "REQUEST_CREATE_THEME_VERSION_FAILED",
                payload: null
              });
        }
        
      },[themeVersionCreateError]);


      useEffect(() => {    
        // dispatch(getThemeDetails(item))
        if(themeVersionCreateDetails?.status === "success") {
            toast.success(t('themesDetails.themeVersionCreated'), {
                duration: 8000,
                position: 'top-right',
                style: {fontSize: 20, fontFamily: 'Roboto'}
              });
            formik.resetForm();
            setStylebox("")
            dispatch({
                type: "REQUEST_CREATE_THEME_VERSION_SUCCESS",
                payload: null
              });
        }
      }, [themeVersionCreateDetails]);

      useEffect(() => {    
        if(reloadThemeVersionSuccess) {
            formik.setFieldValue("logo", reloadThemeVersionSuccess?.data.logos.logo)
            formik.setFieldValue("secondary_logo", reloadThemeVersionSuccess?.data.logos.secondary_logo)
            formik.setFieldValue("sidebar_color", reloadThemeVersionSuccess?.data.colors.sidebar_color)
            formik.setFieldValue("text_over_sidebar_color", reloadThemeVersionSuccess?.data.colors.text_over_sidebar_color)
            formik.setFieldValue("background_color", reloadThemeVersionSuccess?.data.colors.background_color)
            formik.setFieldValue("text_over_background_color", reloadThemeVersionSuccess?.data.colors.text_over_background_color)
            formik.setFieldValue("primary_color", reloadThemeVersionSuccess?.data.colors.primary_color)
            formik.setFieldValue("text_over_primary_color", reloadThemeVersionSuccess?.data.colors.text_over_primary_color)
            formik.setFieldValue("background_over_primary_color", reloadThemeVersionSuccess?.data.colors.background_over_primary_color)
            formik.setFieldValue("disabled_text_over_primary_color", reloadThemeVersionSuccess?.data.colors.disabled_text_over_primary_color)
            formik.setFieldValue("call_to_action_color", reloadThemeVersionSuccess?.data.colors.call_to_action_color)
            formik.setFieldValue("disabled_button_text_color", reloadThemeVersionSuccess?.data.colors.disabled_button_text_color)
            formik.setFieldValue("disabled_button_background_color", reloadThemeVersionSuccess?.data.colors.disabled_button_background_color)
            formik.setFieldValue("text_input_background_color", reloadThemeVersionSuccess?.data.colors.text_input_background_color)
            formik.setFieldValue("text_input_text_color", reloadThemeVersionSuccess?.data.colors.text_input_text_color)
            formik.setFieldValue("text_input_placeholder_color", reloadThemeVersionSuccess?.data.colors.text_input_placeholder_color)
            formik.setFieldValue("list_highlight_color", reloadThemeVersionSuccess?.data.colors.list_highlight_color)
            formik.setFieldValue("list_highlight_border_color", reloadThemeVersionSuccess?.data.colors.list_highlight_border_color)
            formik.setFieldValue("menu_background_color", reloadThemeVersionSuccess?.data.colors.menu_background_color)
            formik.setFieldValue("text_over_menu_color", reloadThemeVersionSuccess?.data.colors.text_over_menu_color)
            formik.setFieldValue("success_color", reloadThemeVersionSuccess?.data.colors.success_color)
            formik.setFieldValue("warning_color", reloadThemeVersionSuccess?.data.colors.warning_color)
            formik.setFieldValue("error_color", reloadThemeVersionSuccess?.data.colors.error_color)
            setStylebox("")
            dispatch({
                type: "REQUEST_RELOAD_THEME_VERSION_SUCCESS",
                payload: null
            });
        }
      }, [reloadThemeVersionSuccess]);



    useEffect(() => {  
        if(styleBox === "grey"){
            formik.setFieldValue("sidebar_color", "rgb(96, 103, 112)")
            formik.setFieldValue("text_over_sidebar_color", "rgb(255, 255, 255)")
            formik.setFieldValue("background_color", "rgb(162, 173, 178)")
            formik.setFieldValue("text_over_background_color", "rgb(73, 101, 115)")
            formik.setFieldValue("primary_color", "rgb(232, 232, 232)")
            formik.setFieldValue("text_over_primary_color", "rgb(73, 101, 115)")
            formik.setFieldValue("background_over_primary_color", "rgb(201, 201, 201)")
            formik.setFieldValue("disabled_text_over_primary_color", "rgb(167, 163, 180)")
            formik.setFieldValue("call_to_action_color", "rgb(171, 35, 40)")
            formik.setFieldValue("disabled_button_text_color", "rgb(167, 163, 180)")
            formik.setFieldValue("disabled_button_background_color", "rgb(218, 218, 218)")
            formik.setFieldValue("text_input_background_color", "rgb(255, 255, 255)")
            formik.setFieldValue("text_input_text_color", "rgb(115, 115, 115)")
            formik.setFieldValue("text_input_placeholder_color", "rgb(208, 208, 208)")
            formik.setFieldValue("list_highlight_color", "rgb(176, 185, 197)")
            formik.setFieldValue("list_highlight_border_color", "rgb(96, 103, 112)")
            formik.setFieldValue("menu_background_color", "rgb(171, 35, 40)")
            formik.setFieldValue("text_over_menu_color", "rgb(255, 255, 255)")
            formik.setFieldValue("success_color", "rgb(0, 255, 0)")
            formik.setFieldValue("warning_color", "rgb(255, 255, 0)")
            formik.setFieldValue("error_color", "rgb(255, 0, 0)")

        }
        else if(styleBox === "blue"){
            formik.setFieldValue("sidebar_color", "rgb(25, 99, 133)")
            formik.setFieldValue("text_over_sidebar_color", "rgb(255, 255, 255)")
            formik.setFieldValue("background_color", "rgb(86, 135, 163)")
            formik.setFieldValue("text_over_background_color", "rgb(255, 255, 255)")
            formik.setFieldValue("primary_color", "rgb(187, 200, 208)")
            formik.setFieldValue("text_over_primary_color", "rgb(25, 99, 133)")
            formik.setFieldValue("background_over_primary_color", "rgb(220, 227, 232)")
            formik.setFieldValue("disabled_text_over_primary_color", "rgb(85, 98, 106)")
            formik.setFieldValue("call_to_action_color", "rgb(171, 35, 40)")
            formik.setFieldValue("disabled_button_text_color", "rgb(85, 98, 106)")
            formik.setFieldValue("disabled_button_background_color", "rgb(170, 183, 191)")
            formik.setFieldValue("text_input_background_color", "rgb(255, 255, 255)")
            formik.setFieldValue("text_input_text_color", "rgb(25, 99, 133)")
            formik.setFieldValue("text_input_placeholder_color", "rgb(187, 200, 208)")
            formik.setFieldValue("list_highlight_color", "rgb(0, 255, 255)")
            formik.setFieldValue("list_highlight_border_color", "rgb(171, 35, 40)")
            formik.setFieldValue("menu_background_color", "rgb(171, 35, 40)")
            formik.setFieldValue("text_over_menu_color", "rgb(220, 227, 232)")
            formik.setFieldValue("success_color", "rgb(0, 255, 0)")
            formik.setFieldValue("warning_color", "rgb(255, 255, 0)")
            formik.setFieldValue("error_color", "rgb(255, 0, 0)")
        }
       
    },[styleBox]);

    const resetForm = () => {
        if(themeDetails?.data.theme_version.length > 0) {
            dispatch(reloadThemeVersion(themeDetails?.data.theme_version[0].id))
        } 
        else{
            formik.resetForm()
            setStylebox("grey")
        }
    }

    useEffect(() => { 
        if(themeDetails?.data.theme_version.length > 0) {
            
          } 
          else{
           formik.resetForm()
          }     
      },[themeDetails]);
    
  return (

    <Container style={{marginTop: 20}}>
        <form onSubmit={formik.handleSubmit}>
            <Grid className='box-container' container direction="row">
                <Grid item xs={12}>
                    <h3 className='box-title'>{t('themesDetails.newTheme')}</h3> 
                </Grid>

                <Grid item sm={6}>
                    <InputFile 
                        label={t('themesDetails.uploadHeaderLogo')}
                        btnText={t('themesDetails.chooseFile')}
                        inputWarning={t('themesDetails.inputWarning1')}
                        formik={formik}
                        onBlur={formik.handleBlur} 
                        value={formik.values.logo}
                        name="logo"
                        preview={true}
                        previewHead={t('themesDetails.previewHead1')}
                        theme={true}
                    />
                </Grid>
                <Grid item sm={6}>
                    <InputFile 
                        name="secondary_logo"
                        label={t('themesDetails.uploadBottomLogo')}
                        btnText={t('themesDetails.chooseFile')}
                        inputWarning={t('themesDetails.inputWarning2')}
                        formik={formik}
                        onBlur={formik.handleBlur} 
                        value={formik.values.secondary_logo}
                        preview={true}
                        previewHead={t('themesDetails.previewHead2')}
                        theme={true}
                        
                    />
                </Grid>
            </Grid>
           
            <Container1>
                <div className='box-container'>
            <Grid  container direction="row">
                <Grid item xs={12}>
                    <h3 className='box-title'>{t('themesDetails.themeColors')}</h3> 
                </Grid>
                
                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.sidebarClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid" >
                    <div className='colorpickerDiv' >
                        <ColorPicker formik={formik} value={formik.values.sidebar_color}  id="sidebar_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.textOversidebar')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                        <ColorPicker formik={formik} value={formik.values.text_over_sidebar_color} id="text_over_sidebar_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.bgClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                        <ColorPicker formik={formik} value={formik.values.background_color} id="background_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.textOverBgClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                        <ColorPicker formik={formik} value={formik.values.text_over_background_color} id="text_over_background_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.primaryClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                        <ColorPicker formik={formik} value={formik.values.primary_color} id="primary_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.textOverPrimaryClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv'>
                        <ColorPicker formik={formik} value={formik.values.text_over_primary_color} id="text_over_primary_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.bgOverPrimaryClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv'>
                        <ColorPicker formik={formik} value={formik.values.background_over_primary_color} id="background_over_primary_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.disabledTextOverPrimaryClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                    <ColorPicker formik={formik} value={formik.values.disabled_text_over_primary_color} id="disabled_text_over_primary_color"/>
                        
                    </div>
                    
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.callToActionClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                    <ColorPicker formik={formik} value={formik.values.call_to_action_color} id="call_to_action_color"/>
                    </div>
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.listHighlightClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv'>
                    <ColorPicker formik={formik} value={formik.values.list_highlight_color} id="list_highlight_color"/>
                    </div>
                    
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.listHighlightBorderClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                    <ColorPicker formik={formik} value={formik.values.list_highlight_border_color} id="list_highlight_border_color"/>
                        
                    </div>
                    
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.menuBgClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv'>
                    <ColorPicker formik={formik} value={formik.values.menu_background_color} id="menu_background_color"/>
                       
                    </div>
                    
                </Grid>

                <Grid  md={3} className="theme-grid">
                    <p style={{marginTop: 5}}>{t('themesDetails.textOverMenuClr')}</p>
                </Grid>
                <Grid  md={3} className="theme-grid">
                    <div className='colorpickerDiv' >
                    <ColorPicker formik={formik} value={formik.values.text_over_menu_color} id="text_over_menu_color"/>
                       
                    </div>
                    
                </Grid>
                </Grid>
                <Accordion  style={{boxShadow: 'unset', marginTop: 20}} className="theme-accordian">
                    <div style={{display: 'flex',justifyContent: 'center'}}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{marginTop: 0}}
                            >
                            <div style={{background: '#c8102e', borderRadius: 5, padding: 5, color: '#fff', fontSize: 14}}>
                                {t('themesDetails.moreColors')}
                            </div>
                        </AccordionSummary>
                            
                        </div>
                        <AccordionDetails style={{padding: 0}}>
                            <Typography>
                                <Grid container direction="row">
                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.disabledBtnTextClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv' >
                                            <ColorPicker formik={formik} value={formik.values.disabled_button_text_color} id="disabled_button_text_color"/>
                                            
                                        </div>
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.disabledButtonBgClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv' >
                                        <ColorPicker formik={formik} value={formik.values.disabled_button_background_color} id="disabled_button_background_color"/>
                                        
                                        </div>
                                        
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.textInputBgClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv' >
                                            <ColorPicker formik={formik} value={formik.values.text_input_background_color} id="text_input_background_color"/>
                                        </div>
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.textInputTextClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv' >
                                        <ColorPicker formik={formik} value={formik.values.text_input_text_color} id="text_input_text_color"/>
                                            
                                        </div>
                                        
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.textInputPlaceHolder')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv'>
                                        <ColorPicker formik={formik} value={formik.values.text_input_placeholder_color} id="text_input_placeholder_color"/>
                                            
                                        </div>
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.successClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv'>
                                            <ColorPicker formik={formik} value={formik.values.success_color} id="success_color"/>
                                        </div>
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.warningClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv'>
                                        <ColorPicker formik={formik} value={formik.values.warning_color} id="warning_color"/>
                                        </div>
                                    </Grid>

                                    <Grid  md={3} className="theme-grid">
                                        <p style={{marginTop: 5}}>{t('themesDetails.errorClr')}</p>
                                    </Grid>
                                    <Grid  md={3} className="theme-grid">
                                        <div className='colorpickerDiv' >
                                        <ColorPicker formik={formik} value={formik.values.error_color} id="error_color"/>
                                        
                                        </div>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
            
            </div>
            <Toaster position="bottom-center"/>
        </Container1>


            <div>
                <Grid className='box-container'  container direction="row">
                    <Grid item xs={12}>
                        <h3 className='box-title'>{t('themesDetails.preset')}</h3>
                        <div>
                        </div> 
                    </Grid>
                    <Grid item md={3} style={{marginTop: 20}}>
                        <div  onClick={() => setStylebox("grey")}>
                        {
                            styleBox === "grey" ?
                            <div style={{width: 200, height: 150, position: 'relative'}} >
                                <Grey style={{border: "3px solid red",width: 200, height: 150}}/>
                                {
                                    styleBox === "grey" &&
                                    <div style={{width: 25, height: 25, position: 'absolute', bottom: 0, right: 0, background: 'red', display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                                        <AiOutlineCheck color={"#fff"} style={{fontSize: 22}}/>
                                    </div>
                                }
                            </div>:
                            <Grey />
                        }
                        
                            
                        </div>
                        <p style={{fontSize: 14, fontWeight: 'bold',fontFamily:"Roboto",}}>{t('themesDetails.grey')}</p>
                    </Grid>
                    <Grid item md={3} style={{marginTop: 20}} >
                        <div style={{position: 'relative'}} onClick={() => setStylebox("blue")}>
                            {
                                styleBox === "blue" ?
                                <div style={{width: 200, height: 150, position: 'relative'}}>
                                <Blue style={{border: "3px solid red",width: 200, height: 150}}/>
                                {
                                styleBox === "blue" &&
                                    <div style={{width: 25, height: 25, position: 'absolute', bottom: 0, right: 0, background: 'red',display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                                        <AiOutlineCheck color={"#fff"} style={{fontSize: 22}}/>
                                    </div>
                                }
                                </div>:
                                <Blue />
                            }
                            
                            <p style={{fontSize: 14, fontWeight: 'bold', fontFamily:"Roboto",}}>{t('themesDetails.blue')}</p>
                        </div>
                    </Grid>
                </Grid>

                {/* {
                    formik.dirty === true ?  */}
                    <div className='box-container' style={{display: 'flex'}}>
                        <Button
                            className='appBtn'
                            size="meduim"
                            type="submit"
                            >
                                {themeCreateVersionLoader ? <Spinner size="sm">{t('common.Loading')}</Spinner> :t('themesDetails.saveChanges')}
                            
                        </Button>
                        <Button
                            className='appBtnCancel'
                            variant="contained"
                            component="label"
                            style={{marginLeft: 20, background: '#A2AAAD'}}
                            onClick={()=> resetForm()}
                        >
                            {t('themesDetails.cancelChanges')}
                        </Button>
                    </div> 
                    {/* :
                    null
                } */}
            </div>
          </form>
        </Container>
           
        
  )
}
