const styles = (theme) => ({
    titleDiv:{
        position:"relative",
        width:"100%",
        display:"flex",
        alignItems: 'center'
    },
    iconDiv:{
        width: 50,
        height: 50,
        background: '#FF9FAE 0% 0% no-repeat padding-box',
        borderRadius: 10,
        margin: 'auto',
        padding: 13
    },
    icon: {
        // background: '#C8102E'
    },
    columnInfo: {
        position:"relative",
        display:"-webkit-box",
        width:"100%",
        top: 15,
        font: 'normal normal normal 13px/15px Roboto'
    },
    columnInfoChild: {
        width:"33.33%",
        textAlign: 'center'
    },
    columnInfoChildInfo: {
        marginTop: 10,
        fontSize: 13
    },
    columnInfoChildInfoSize: {
        marginTop: 10,
        fontSize: 13,
        overflowWrap: "break-word",
        [theme.breakpoints.down("xl")]:{
        fontSize:10,
        }
    },
    bottomRightDiv: {
        marginTop: 10,
        textAlign: 'right',
        width:"100%",
    },
    bottomRightDivTitle: {
        font: 'normal normal normal 12px/14px Roboto',
        position: 'absolute',
        bottom: 20,
        left: 0,
        padding: 10,
        width: '100%'
    },
    bottomRightDivDesc: {
        font: 'normal normal bold 12px/14px Roboto',
        bottom: 2,
        position: 'absolute',
        left: 0,
        padding: 10,
        width: '100%'
    },
    columnInfoChildWithRightBorder: {
        width:"33.33%",
        textAlign: 'center',
        borderRight: '1px solid #d6aea9'
    },
    columnInfo2ChildWithRightBorder:{
        width:"50%",
        textAlign: 'left',
        borderRight: '1px solid #d6aea9'
    },
    columnInfo2CenterChildWithRightBorder:{
        width:"50%",
        textAlign: 'center',
        borderRight: '1px solid #d6aea9',
        marginTop: 30
    },
    columnInfo2CenterChild: {
        width:"50%",
        textAlign: 'center',
        marginTop: 30
    },
    columnInfo2Child: {
        width:"50%",
        marginLeft: '20%'
    },
    bottomLeftDiv: {
        textAlign: 'left',
        width:"100%",
    },
    bottomLeftTitle: {
        font: 'normal normal bold 22px/24px Roboto',
        position: 'absolute',
        bottom: 20,
        left: 0,
        padding: 13,
        width: '100%'
    },
    bottomLeftDesc: {
        font: 'normal normal normal 12px/14px Roboto',
        bottom: 2,
        position: 'absolute',
        left: 0,
        padding: 13,
        width: '100%'
    },
    bottomLeftButton: {
        font: 'normal normal normal 12px/14px Roboto',
        bottom: 2,
        position: 'absolute',
        left: 0,
        padding: '6px 8px 6px 13px',
        width: '100%',
        background: '#C8102E',
        color: '#fff',
        cursor: 'pointer',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    buttonRightIcon: {
        float: 'right',
        marginTop: 2
    },
    centerInfo: {
        width: '100%',
        textAlign: 'center',
    },
    centerInfoText: {
        font: 'normal normal normal 15px/14px Roboto',
        "& b":{
            marginLeft: 35
        }
    },
    descInfoText: {
        font: 'normal normal normal 12px/12px Roboto',
        marginTop: 40,
        marginBottom: 10
    },
    descInfoText2: {
        font: 'normal normal normal 12px/12px Roboto',
        marginBottom: 10
    },
    centerInfoBtn: {
        background: '#c8102e', 
        font: 'normal normal normal 12px/35px Roboto',
        borderRadius: 5, 
        cursor: 'pointer', 
        color: '#fff',
        padding: '10px 20px 10px 20px'
    },
    spinnerStyle:{
        background: '#c8102e', 
        font: 'normal normal normal 12px/35px Roboto',
        borderRadius: 5, 
        cursor: 'pointer', 
        color: '#fff',
       height: 30
    },
    loaderbtnvisible: {
        display: 'flex',
        justifyContent: 'center'
    }

})

export default styles