import React from 'react'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { WhiteLayout } from 'layouts';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";

import Logo  from "assets/images/corob-logo-red.svg";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    Red: {
      main: '#C8102E',
      contrastText: '#fff',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:"#C8102E"},
      },
    },
  },
 });

const useStyles = makeStyles(styles)

export default function Privacypolicy() {
  const {t} = useTranslation('common');
    const classes = useStyles()
    // let navigate = useNavigate();
    // setTimeout(
    //   () => navigate("/sign-in"),
    //   2000
    // );
    // const handleLoginClick = () => {
    //     navigate("/sign-in")
    // }
  // return (
  //   <WhiteLayout>
  //       <div className={classes.container}>
  //           <Logo className={classes.logo} />
  //           <div className={classes.logoutText}>{t("common.youhavebeenloggedout")}</div>
  //           <Button onClick={handleLoginClick}>{t("signIn.login")} </Button>
  //       </div>
  //   </WhiteLayout>
  // )
  
  return (
    <ThemeProvider theme={theme}>
    <div className={classes.container}>
              <div className={classes.logo}>
                  <img src={Logo} align='right' alt='logo' /> 
                </div>
                <div>
                    <p style={{fontSize: 26}}>Privacy Policy</p>
                </div>
                <div>
                    <p className={classes.titlename} >Information notice on personal data processing </p>
                </div>
                <div>
                    <p className={classes.pclass}>COROB S.p.A., with registered office in Via dell&rsquo;Agricoltura, 103 - 41038 S. Felice s/P. {'('}MO{')'}, Italy {'('}hereinafter referred to as &ldquo;Corob&rdquo;{')'}, and which may be contacted by email at privacy@corob.com, in its capacity as data controller, hereby provides the following information notice {'('}&ldquo;Information Notice&rdquo;{')'} regarding the processing of personal data of users and/or customers {'('}collectively referred to as &ldquo;Users&rdquo;{')'} who visit and/or use the functions of the websites <a href="/" target="_blank" rel="noreferrer noopener">www.corob-cloud.com</a> {'('}Website{')'}. </p>
                </div>
                <div>
                    <ol start="1" className={classes.olist}>
                            <li>
                                <p>Types of personal data processed </p>
                            </li>
                    </ol>
                </div>
                <div>
                    <p className={classes.pclass}>In accordance with this Information Notice, Corob process the following personal data of Users: </p>
                </div>
                <div>
                    <ol className={classes.tolist}>
                        <p className={classes.pclass}>a{')'} Browsing data </p>
                    </ol>
                </div>
                
                <div>
                    <p className={classes.pclass}>The computer systems and software used to operate the Website acquire, during their normal operations, some personal data whose transmission is implicit in the use of Internet communication protocols. Such information is not collected in order to be associated with identified data subjects, but, by its very nature, it could make it possible to identify Users by means of processing and cross-referencing with data held by third parties. This category of data includes IP addresses or domain names of the computers used by the Users who connect to the Website, the URI {'('}Uniform Resource Identifier{')'} addresses of the resources requested, the time of the request, the method used to submit a request to the server, the size of the file obtained in response, the numerical code indicating the status of the response given by the server {'('}successful, error, etc.{')'}, and other parameters relating to the User&rsquo;s operating system and computer environment. </p>
                </div>
                <div>
                    <p className={classes.pclass}>These data are used for the sole purpose of obtaining anonymous statistical information on the use of the Website and to check its proper functioning and are deleted immediately after processing. </p>
                </div>
                <div>
                    <ol className={classes.tolist}>
                        <p className={classes.pclass}>b{')'} Information provided with access or permission </p>
                    </ol>
                </div>
                <div>
                    <p className={classes.pclass}>If you visit the Website, we also may collect the following information if you choose to provide us with access or permission: </p>
                </div>
                <div>
                    <ul className={classes.llist}>
                        <li>
                        <p className={classes.pclass}>Geolocation Information. We may request access or permission to track location-based information from your device, either continuously or while you are using our mobile application{'('}s{')'}, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&apos;s settings. </p>
                        </li>

                        <li>
                            <p className={classes.pclass}>Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device&apos;s camera, Bluetooth, microphone, sensors, storage, and other features. If you wish to change our access or permissions, you may do so in your device&apos;s settings. </p>
                        </li>
                        <li>
                            <p className={classes.pclass}>Mobile Device Data. We automatically collect device information {'('}such as your mobile device ID, model, and manufacturer{')'}, operating system, version information and system configuration information, device and application, identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol {'('}IP{')'} address {'('}or proxy server{')'}. If you are using our application{'('}s{')'}, we may also collect information about the phone network associated with your mobile device, your mobile device&rsquo;s operating system or platform, the type of mobile device you use, your mobile device&apos;s unique device ID, and information about the features of our application{'('}s{')'} you accessed. </p>
                        </li>
                        <li>
                            <p className={classes.pclass}>Push Notifications. We may request to send you push notifications regarding your account or certain features of the website/application{'('}s{')'}. If you wish to opt out from receiving these types of communications, you may turn them off in your device&apos;s settings. This information is primarily needed to maintain the security and operation of our application{'('}s{')'}, for troubleshooting, and for our internal analytics and reporting purpose. </p>
                        </li>
                    </ul>
                </div>
                <div>
                    <ol className={classes.tolist}>
                        <p className={classes.pclass}>c{')'} Data provided voluntarily by Users </p>
                    </ol>
                </div>
                <div>
                    <p className={classes.pclass}>At various points on the Website, you may be requested to submit personal information, including, but not limited to, your name, e-mail address, street address, phone number, employer and occupational title. Corob does not collect personal information about you unless you specifically and knowingly provide such information. </p>
                </div>
                <div>
                    <p className={classes.pclass}>Forms are used on the Website to collect information from you, which will allow us to carry out a number of tasks, including, but not limited to: </p>
                </div>
                    <div>
                      <ul className={classes.llist}>
                          <li style={{listStyleType: 'none'}}>
                              <p className={classes.pclass}>- Respond to product inquiries or technical questions;  </p>
                          </li>
                          <li style={{listStyleType: 'none'}}>
                              <p className={classes.pclass}>- Allow you to have access to your machines; </p>
                          </li>
                          <li style={{listStyleType: 'none'}}>
                              <p className={classes.pclass}>- Facilitate delivery of services to the user; </p>
                          </li>
                          <li style={{listStyleType: 'none'}}>
                              <p className={classes.pclass}>- Provide you with the requested services; </p>
                          </li>
                          <li style={{listStyleType: 'none'}}>
                              <p className={classes.pclass}>- Respond to user inquiries/offer support to users. </p>
                          </li>
                      </ul>
                      </div>
                        <div>
                          <p className={classes.pclass}>Receiving follow-up mailings at any time {'('}See Choice/Opt-Out below{')'}. </p>
                        </div>
                        <div>
                            <ol start="2" className={classes.olist}>
                                <li>
                                    <p>Purposes and legal grounds of processing </p>
                                </li>
                            </ol>
                        </div>

                    <div>
                        <p className={classes.pclass}>The above-mentioned data are processed by Corob for the following purposes: </p>
                    </div>
                    <div>
                        <ul className={classes.llist}>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>a{')'} Allow Users to browse the Website and use its features {'('}including accessing the reserved area, requesting to be contacted by Corob, having remote access to the machines etc.{')'}; </p>
                            </li>
                        
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>b{')'} For handling complaints and disputes; </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>c{')'}In order to comply with applicable national and European legislation; </p>
                            </li>
                        
                            <p className={classes.pclass}>{'('}Hereinafter jointly referred to as the &ldquo;<b>Contractual Purposes</b>&rdquo;{')'} </p>
                        
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>d{')'} To assert and defend its rights; </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>e{')'} To carry out a potential merger, transfer of assets, transfer of business or branch of business or any other corporate transformation, by transferring Users&rsquo; personal data to the third party/ies involved; </p>
                            </li>
                        
                            <p className={classes.pclass}>{'('}Hereinafter the &ldquo;<b>Legitimate Interest Purposes</b>&rdquo;{')'} </p>
                        
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>f{')'} With the prior consent of Users, for accessing the reserved area, the management of contact requests, for receiving promotional communications relating to products and services offered by Corob through traditional communication channels such as paper mail, or through automated communication tools, emails, automatic messages and other remote communication tools. </p>
                            </li>
                            <p className={classes.pclass}>{'('}Hereinafter the &ldquo;<b>Marketing Purposes</b>&rdquo;{')'}. </p>
                        </ul>
                    </div>
                <div>
                    <div>
                        <ol start="3" className={classes.olist}>
                            <li>
                                <p>Legal grounds for the processing </p>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className={classes.pclass}>The processing of Users&rsquo; personal data is necessary with reference to the Contractual Purposes, as it is essential in order to: </p>
                    </div>
                    <div>
                        <ul className={classes.inllist}>
                            <li>
                                <p className={classes.pclass}>Provide the service requested by a User through the functionalities available on the Website in the cases provided for in Section 2{'('}a{')'} and {'('}b{')'}; </p>
                            </li>
                            <li>
                                <p className={classes.pclass}>Comply with the provisions of the applicable legislation as set out in Section 2{'('}c{')'}. </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <p className={classes.pclass}>If a User does not provide the personal data necessary for the Contractual Purposes, it will not be possible for Corob to provide the services referred to on the Website. </p>
                    </div>
                    <div>
                        <p className={classes.pclass}>The processing of a User&rsquo;s personal data for the Legitimate Interest Purposes referred to in Section 2{'('}d{')'} and {'('}e{')'} is carried out in accordance with Article 6{'('}f{')'} of the European General Data Protection Regulation 2016/679 {'('}the &ldquo;Data Protection Regulation&rdquo;{')'} for the pursuit of the legitimate interest of Corob, which is equally balanced with the interest of the User, as the processing of personal data is limited to what is strictly necessary for the performance of the required financial transactions. Processing for the Legitimate Interest purposes is not mandatory and the User may object to such processing in the manners set out in this Information Notice, but if the User objects to such processing, his or her data may not be used for the Legitimate Interest purposes, except in the event that Corob proves compelling legitimate reasons or the exercise or defence of a right pursuant to Article 21 of the Data Protection Regulation. </p>
                    </div>
                    <div>
                        <p className={classes.pclass}>The processing of Personal Data for Marketing Purposes is optional and subject to the User&rsquo;s consent. Therefore, in case of opposition to marketing communications or refusal to give consent, or withdrawal of such consent, the User will not receive the marketing communications referred to in Section 2{'('}f{')'} and {'('}g{')'}. In any case, Users may withdraw their consent to the processing of Personal Data and oppose the sending of all marketing communications at any time, through the methods provided for in this information notice. </p>
                    </div>
                    <div>
                        <ol start="4" className={classes.olist}>
                            <li>
                                <p>Processing methods </p>
                            </li>
                        </ol>
                    </div>
                </div>
                <div>
                    <div>
                        <p className={classes.pclass}>In relation to the above-mentioned purposes, personal data will be processed both through computer and/or IT means and on paper and, in any case, by means of instruments suitable to guarantee their security, protection and confidentiality through the adoption of appropriate technical, physical and organizational security measures to prevent unauthorized access, loss, dissemination and theft of personal data. </p>
                    </div>
                    <div>
                        <ol start="5" className={classes.olist}>
                            <li>
                                <p>Scope of data disclosure </p>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className={classes.pclass}>For the Contractual Purposes referred to above, the personal data of Users may be disclosed to the following categories of recipients: {'('}a{')'} third party providers of assistance and consultancy services for Corob active in the technological, IT, accounting, administrative, legal, insurance sectors {'('}merely by way of example{')'}; {'('}b{')'} companies of the Corob group, {'('}c{')'} persons and authorities for whom access to Users&rsquo; personal data is expressly recognized by law, regulations or measures issued by the competent authorities. </p>
                    </div>
                    <div>
                        <p className={classes.pclass}>For the Legitimate Interest purposes mentioned above, the personal data of Users may be transferred to the following categories of recipients: {'('}a{')'} companies of the Corob group; {'('}b{')'} potential purchasers of Corob and entities resulting from the merger or any other form of transformation concerning Corob; {'('}b{')'} competent authorities. </p>
                    </div>
                    <div>
                        <p className={classes.pclass}>Such recipients, depending on the circumstances, will process the personal data in their capacity as data controllers, data processors or persons in charge of processing. A complete list of the data processors appointed by Corob is available, upon request, via the methods indicated in this Information Notice. </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ol start="6" className={classes.olist}>
                            <li>
                                <p>Are Personal Data transferred abroad? </p>
                            </li>
                        </ol>
                    </div>
                    <div>
                    </div>
                    <div>
                        <p className={classes.pclass}>Users&rsquo; Personal Data will not be transferred to countries outside the European Union. Should Corob transfer the data of the Data Subjects to countries outside the European Union, it will, in any case, ensure compliance with the appropriate guarantees, suitably for the purposes of the transfer, such as the standard contractual clauses on data protection, in accordance with the applicable legislation and in particular Articles 45 and 46 of the Data Protection Regulation. </p>
                    </div>
                    <div>
                        <ol start="7" className={classes.olist}>
                            <li>
                                <p>Timeframe of storage </p>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className={classes.pclass}>Users&rsquo; personal data will be stored for the period of time necessary to pursue the purposes for which such data have been gathered, in accordance with this information notice. In any case, the following storage terms will apply with reference to the processing of data for the purposes set out below: </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul className={classes.llist}>
                            <li>
                                <p className={classes.pclass}>For the Contractual and Legitimate Interest Purposes, the Personal Data of the Users are stored for a period equal to the provision of the service and for 10 years after its termination, resolution or withdrawal, except in cases where the storage for a longer period is required for any litigation, requests by the competent authorities, or under applicable law; </p>
                            </li>
                            <li>
                                <p className={classes.pclass}>For the Marketing Purposes referred to in section 2{'('}f{')'}, the Personal Data of Users are stored for a period equal to the provision of the services requested by the User, plus a further period of 24 months. </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                    </div>
                    <div>
                        <ol start="8" className={classes.olist}>
                            <li>
                                <p>User Rights </p>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className={classes.pclass}>Users may exercise the following rights at any time and free of charge, by sending an email to the address <a href="mailto:privacy@corob.com" target="_blank" rel="noreferrer noopener">privacy@corob.com</a>: </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul className={classes.llist}>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>a{')'} Obtain from Corob confirmation of the existence or non-existence of data concerning them and be informed about the content and source of the data, verify their accuracy and request their integration, updating or modification; </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>b{')'} Obtain the deletion, anonymization or blocking of any data processed in violation of applicable law; </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>c{')'} Object to the processing in whole or in part, for legitimate reasons, unless Corob has overriding legitimate reasons, or the need to assert or defend a right in any legal proceedings; and </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>d{')'} Withdraw at any time their consent to the processing of data {'('}in relation to processing for which such consent may be required{')'}, without this in any way affecting the lawfulness of the processing based on the consent given before the withdrawal; </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>e{')'} Ask Corob to limit the processing of their personal data in the event that: </p>
                            </li>
                            <ul className={classes.llist}>
                                <li>
                                    <p className={classes.pclass}>The accuracy of the personal data is challenged, for the period necessary for Corob to verify the accuracy of such data; </p>
                                </li>
                                <li>
                                    <p className={classes.pclass}>The processing is unlawful and one objects to the deletion of one&rsquo;s personal data, asking that their use be restricted; </p>
                                </li>
                                <li>
                                    <p className={classes.pclass}>Although Corob no longer needs the data for the purposes of processing, the data are necessary to ascertain, exercise or defend a right in court; or </p>
                                </li>
                                <li>
                                    <p className={classes.pclass}>The User has objected to the processing pursuant to Article 21{'('}1{')'} of the Data Protection Regulation, pending verification of the potential prevalence of Corob&rsquo;s legitimate compelling reasons for continuing the processing. </p>
                                </li>
                            </ul>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>f{')'} Request the deletion of Personal Data concerning them without undue delay; </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>g{')'} Obtain the portability of Personal Data concerning them; and </p>
                            </li>
                            <li style={{listStyleType: 'none'}}>
                                <p className={classes.pclass}>h{')'} Submit a complaint to the competent Supervisory Authority. </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className={classes.pclass}>Under Article 2Terdecies of Legislative Decree No. 196/2003, in the event of the User&rsquo;s death, the aforementioned rights relating to his/her personal data may be exercised by those who have an interest of their own, or act to protect the Data Subject as his/her representative, or for family reasons worthy of protection. Users may expressly forbid the exercise of any of the above rights by their successors by sending a written declaration to Corob to the e-mail address indicated above. Such declaration may be withdrawn or amended at a later stage in the same manner. </p>
                    </div>
                    <div>
                    </div>
                </div>
                <div>
                    <div>
                        <ol start="9" className={classes.olist}>
                            <li>
                                <p>Amendments and updates </p>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p className={classes.pclass}>This Information Notice may be subject to amendments and integrations, also as a result of any regulatory changes and/or additions. Changes will be notified in advance and Users may view the text of the constantly updated Information Notice on the Website. </p>
                    </div>
                </div>
          
    </div>
    </ThemeProvider>
  )
}