import React from 'react'
import { makeStyles } from '@mui/styles';
import styles from "./styles";

const useStyles = makeStyles(styles)

export default function WhiteLayout({children,horizontalAlign="center"}) {
  const classes = useStyles()
  return (
    <div className={horizontalAlign==="center"?classes.container:classes.containerTop}>
      {children}
    </div>
  )
}
