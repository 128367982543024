import React from 'react';
import ReactEcharts from "echarts-for-react"; 
import echarts from 'echarts/lib/echarts';

export default function AreaChart({chartInfo, heightValue}) {  

    return (  
        <ReactEcharts
            echarts={echarts}
            option={chartInfo}
            opts={{renderer: 'svg'}}
            style={{width: '100%', height:  heightValue ? heightValue:  400}}
        />
    )
}
