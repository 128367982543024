import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { 
  SignIn,
  ForgotPassword,
  ResetPassword,
  ConfirmPassword,
  SignUp,
  Logout,
  Admin,
  WorkspaceUsers,
  XDataAnalyticsBuckets,
  XDataBuckets,
  XDataAnalyticsLocations,
  LegacyDatabases,
  LegacyDatabasesAnalytics,
  XDataBucketDetail,
  Themes,
  ThemesDetails,
  AccessDenied,
  AnalyticDashboard,
  AnalyticsLocationDashboard,
  AnalyticsBucketLocationDashboard,
  Dashboard,
  AsignUserAction,
  Privacypolicy,
  CompanyReg,
  RootAccessList
} from "pages";
import PrivateRoutes from "./privateRoute";
import PublicRoutes from "./publicRoute";
import LegacyDatabaseDetailDetail from "pages/LegacyDatabases/LegacyDatabaseDetail";
import UserActivation from "pages/UserActivation";
import CompanyActivation from "pages/CompanyActivation";
import PermissionActivation from "pages/PermissionActivation";
import QueryResolved from "pages/QueryResolved";
import UsersQuery from "pages/UsersQuery";
import RootAccessActivation from "pages/RootAccessActivation";

const AppRouter = () => {
    return(
        <BrowserRouter>
            <Routes>
              <Route path="account/verify-email/" element={<UserActivation />} />
              <Route path="reset-password/:uidb64/:token" element={<ResetPassword />} />
              <Route path="account/verify-company/" element={<CompanyActivation />} />
              <Route path="account/verify-permission/" element={<PermissionActivation />} />
              <Route path="account/requested-query-resolved/" element={<QueryResolved />} />
              <Route path="account/verify-root-permission/" element={<RootAccessActivation />} />
              {/* Private Routes Start*/}
              <Route path="dashboard" element={<PrivateRoutes />} >
                <Route path="dashboard" element={<Dashboard/>} />             
                <Route path="admin" element={<Admin />} />
                <Route path="workspace-users" element={<WorkspaceUsers />} />
                <Route path="asign-user-action" element={<AsignUserAction />} />
                <Route path="xdata/buckets" element={<XDataBuckets />} />
                <Route path="xdata/buckets/details/:code" element={<XDataBucketDetail />} />
                <Route path="xdata/analytics" element={<XDataAnalyticsBuckets />} />
                <Route path="xdata/analytics/:code" element={<AnalyticDashboard/>} />
                <Route path="xdata/analytics-location/:code" element={<AnalyticsLocationDashboard/>} />
                <Route path="xdata/analytics/locations/:code" element={<AnalyticsBucketLocationDashboard />} />
                <Route path="xdata/analytics/locations/" element={<XDataAnalyticsLocations />} />
                <Route path="legacy/databases" element={<LegacyDatabases/>} />
                <Route path="legacy/databases/details/:code" element={<LegacyDatabaseDetailDetail/>} />
                <Route path="legacy/analytics" element={<LegacyDatabasesAnalytics/>} />
                <Route path="themes" element={<Themes />} />
                <Route path="themes/details/:code" element={<ThemesDetails />} />
                <Route path="403" element={<AccessDenied/>} />
                <Route path="complaint-registration" element={<UsersQuery />} />
                <Route path="root-access-list" element={<RootAccessList />} />
                
              </Route>
              {/* Private Routes End*/}
              <Route path="" element={<PublicRoutes />} >
                <Route path="" element={<SignIn />} />
                <Route path="confirm-reset-password" element={<ConfirmPassword />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="logout" element={<Logout />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="privacy-policy" element={<Privacypolicy />} />
                <Route path="company-reg" element={<CompanyReg />} />        
              </Route>
            </Routes>
            
        </BrowserRouter>
    )
}
export default AppRouter;