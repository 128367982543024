const initalState = {
    loading: false,
    users:null,
    error:null
    
  }
  
  export const allUsers = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_USERS": {
        return { ...state, loading: true, error: null }
      }
      case "REQUEST_USERS_SUCCESS": {
        return { ...state, users: action.payload, error: null, loading: false, }
      } 
      case "REQUEST_USERS_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }   
      default: {
        return state
      }
    }
  }
  