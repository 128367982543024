const styles = (theme) => ({
    container:{
        textAlign:"center",
        marginTop:'10%'
    },
    statusText:{
        fontFamily:"Roboto",
        fontSize:40,
        color:"#C8102E",
    },
    descriptionText:{
        fontFamily:"Roboto",
        fontSize:20,
        color:"#36333D",
        marginTop:10,
        fontWeight:"Regular",
        marginBottom:10,
        [theme.breakpoints.down('xl')]: {
            fontSize:18,
            marginTop:28,
            marginBottom:26
        }
        
    }
})

export default styles