import React,{useState, useRef, useEffect} from 'react'
import { Button} from "@mui/material";
import { styled } from '@mui/system';
import {IoMdClose} from "react-icons/io";
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";

const CustomInput = styled('div')(({ theme,height }) => ({
    
    "& .box-div":{
        backgroundColor: '#E8E8E8', 
        padding: 10,
        marginTop: 10, 
        width: "75%", 
        borderRadius: 10,
        fontFamily:"Roboto",
        position: "relative"
    },
    "& .btnStyle": {
        background: '#36333D', 
        minHeight: '0px !important', 
       
        marginTop: 'unset !important', 
        padding: 6, 
        minWidth: 'unset !important', 
        fontSize:"11px !important",
        fontFamily:"Roboto",
        [theme.breakpoints.up("xl")]:{
            fontSize: "18px !important",
            width: '130px',
        },
        [theme.breakpoints.down("xl")]:{
            width: '100px',
        },
    },
    '& .input-para':{
        color: '#66636B', 
        marginLeft:5, 
        marginTop: 5,
        fontFamily:"Roboto",
        fontSize: 12,
        width: '70%',
        wordWrap: "break-word",
        [theme.breakpoints.up("xl")]:{
            fontSize: "20px !important",
            width: '70%',
        },
    },
    '& .warning-msg': {
        fontSize: 12, 
        color: '#888888', 
        marginTop: 10, 
        width: "75%",
        fontFamily:"Roboto",
        [theme.breakpoints.up("xl")]:{
            fontSize: "16px !important",
        },
    },
    '& .previewHead': {
        fontSize: 12, 
        fontFamily: 'Roboto',
        [theme.breakpoints.up("xl")]:{
            fontSize: 18,
         },
    },
    '& .previewFooter': {
        fontSize: 12, 
        fontFamily: 'Roboto',
        marginTop: 10,
        [theme.breakpoints.up("xl")]:{
            fontSize: 18,
        },
    }
}))

function useOutsideAlerter(ref,formik) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            formik.resetForm()
        }
      } 
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

export default function InputFile({label, btnText, inputWarning, onChange,
    onBlur,
    value,
    name, formik, preview, previewHead, refresh, theme}) {
        const {t} = useTranslation('common');
    
    const [close, setClose] = useState(false);    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, formik);
    
    useEffect(() => {  
        document.getElementById("selectimage").value = ""
      },[refresh]);
      
      useEffect(() => {  
        if(close === true) {
            document.getElementById("selectimage").value = ""
        }
      },[close]);

      const onClose = () => {
        setClose(true)
        formik.setFieldValue(name, "")
      }
  return (
        <CustomInput>
            <Toaster position="bottom-center"/>
            {
                preview && value !== "" && value !== null ?
                <div style={{marginTop: 10,fontFamily:"Roboto"}}>
               
                <p className="previewHead">{previewHead}</p>
                    
                <div style={{position: 'relative',width: 250, height: 100}}>
                <img src={typeof value === "string" ? process.env.REACT_APP_API_URL.replace(/\/+$/, '') + value : value === null ? "" : URL.createObjectURL(value)}  style={{width: 250, height: 100, marginTop: 10, marginBottom: 10, display: 'block'}} alt='logo' />
                <IoMdClose onClick={() => onClose()} style={{fontSize: 25, fontWeight: 'bold', position: 'absolute', top: 0, right: 0, cursor: 'pointer'}}/>
                </div>
                </div> :
                null
            }
            <p className="previewFooter">{label}</p>
            <div className='box-div'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <Button
                    variant="contained"
                    component="label"
                    className="btnStyle"
                    >{btnText} 
                    <input
                    id="selectimage"
                        type="file"
                        hidden
                        onChange={(e) => {
                            var target = e.target || e.srcElement;
                            
                            if (target.value.length === 0) {
                                setClose(false)
                            }
                            else{
                                setClose(false)
                                if(theme) {
                                    if(e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png"){
                                        var img = new Image();
                                        img.src = window.URL.createObjectURL(e.target.files[0])
    
                                        img.onload = function () {
                                            var totalSizeKB = e.target.files[0].size / Math.pow(1024,1)
                                            if(totalSizeKB > 976){
                                                toast.error(t('inputTheme.imageIsTooLarge') + totalSizeKB + t('inputTheme.kb'), {
                                                    duration: 8000,
                                                    position: 'top-right',
                                                    style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
                                                  });
                                            }
                                            
                                            else if(img.width > 4096 || img.height > 1024){
                                                toast.error(t('inputTheme.imageLarge'), {
                                                    duration: 8000,
                                                    position: 'top-right',
                                                    style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
                                                  });
                                            }
                                            else  {
                                                formik.setFieldValue(name, e.target.files[0]) 
                                            }
                                        };
                                    }
                                    else{
                                        toast.error(t('inputTheme.validImage'), {
                                            duration: 8000,
                                            position: 'top-right',
                                            style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
                                          });
                                    }
                                }
                                else{
                                    formik.setFieldValue(name, e.target.files[0]) 
                                }
                               
                            }
                        }
                    
                        }
                        onBlur={onBlur}
                        name={name}
                        onFocus={() => formik.errors.name = ""}
                       
                    />
                    </Button>

                    <p className='input-para'>{typeof value === "string" ? value === "" ? t('XdataBucketDetail.NoFileSelected') : "": value === null ? t('XdataBucketDetail.NoFileSelected') : value === undefined ? t('XdataBucketDetail.NoFileSelected'):  value?.name}</p>
                </div>
                {formik.errors.name ? <div ref={wrapperRef} style={{color: '#e81010', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: "150px", padding: 5, borderRadius: 5, position: 'absolute', left: 300, top: 10 }}>{formik.errors.name}</div> : null}
            </div>
            
            <p className='warning-msg'>{inputWarning}</p>
        </CustomInput>  
  )
}
