import { Padding } from "@mui/icons-material"

const styles = (theme) => ({
    container:{
        margin: '50px 100px 10px',
        display: 'inline-block',
        padding: '50px 50px 50px 50px',
        boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
        fontFamily: 'Roboto',
        userSelect: 'none',
    },
    titlename:{
        fontSize: '20px',
        color: 'rgb(200, 16, 46)',
        margin: '28px 0px 10px 0px',
        padding: '0px',
    },
    olist:{
        fontSize: '20px',
        color: 'rgb(200, 16, 46)',
        margin: '10px 0px 10px 0px',
        padding: '0px 25px',
    },
    tolist:{
        fontSize: '18px',
        // color: 'rgb(200, 16, 46)',
        margin: '10px 0px 10px 0px',
        padding: '0px',
        fontWeight: 'bold',
    },
    llist:{
        margin: '0px 0px 0px 0px',
        padding: '10px 10px 5px 32px',
    },
    inllist:{
        margin: '0px 0px 0px 0px',
        padding: '10px 10px 5px 32px',
    },
    pclass:{
        fontSize: '14px',
        marginBottom: '5px',
        textAlign:'justify',  

    },
    logo:{
        width:283,
        height:92,
        float: 'right',
        [theme.breakpoints.down('xl')]: {
            width:150,
            height:50
        }
    },
    // logoutText:{
    //     fontFamily:"Roboto",
    //     fontSize:20,
    //     color:"#36333D",
    //     marginTop:33,
    //     fontWeight:"Regular",
    //     marginBottom:31,
    //     [theme.breakpoints.down('xl')]: {
    //         fontSize:18,
    //         marginTop:28,
    //         marginBottom:26
    //     }
        
    // }
})

export default styles