import React from 'react'
import { PublicLayout } from 'layouts'

export default function ConfirmPassword() {
  return (
    <PublicLayout>
      Confirm password
    </PublicLayout>
  )
}
