import {Modal,Box} from "@mui/material";
import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from "./style";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 680,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    borderRadius:2,
    boxShadow: 24,
    p: 4,
  };

const useStyles = makeStyles(styles)
export default function Modals({open,handleclose,content}) {
 
  const classes = useStyles()
     
  return (
    <Modal
        open={open}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
          <div className={classes.modal}>
            {content}
          </div>                     
        </Box>
    </Modal>
  )
}