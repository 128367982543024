const initalState = {
    impersonateLoading: false,
    impersonateError:null,
    impersonateUserInfo:null
  }
  
  export const impersonate = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_IMPERSONATE_USER_DATA": {
        return { ...state, impersonateLoading: true, impersonateError: null }
      }
      case "REQUEST_IMPERSONATE_USER_DATA_SUCCESS": {
        return { ...state, impersonateLoading: false, impersonateError: null, impersonateUserInfo: action.payload }
      }
      case "REQUEST_IMPERSONATE_USER_DATA_FAILED": {
        return { ...state, impersonateLoading: false, impersonateError: action.payload }
      }
      case "REQUEST_RESET_IMPERSONATE": {
        return { ...state, impersonateLoading: false, impersonateError: null, impersonateUserInfo: null }
      }
      default: {
        return state
      }
    }
  }
  