import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";

// export const registerWithJWT = (user, companyid) => {
export const registerWithJWT = (user, countryCode) => {  
  return dispatch => {
    dispatch({
      type: "REGISTER_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.register, {
        first_name: user.FirstName,
        last_name: user.LastName,
        email: user.email,
        password: user.password,
        password2: user.confirm ,
        // company: companyid,
        company: user.company,
        country: countryCode,
      }, config)
      .then(response => {
        dispatch({
          type: "REGISTER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        try {
          if (err.response.status === 400) {
            dispatch({
              type: "REGISTER_FAILURE",
              payload: err.response.data.error[0]
            })
          } else {
            dispatch({
              type: "REGISTER_FAILURE",
            })
          }
          if(err.response.status === 0 || err.response.status === 500){
            dispatch({
              type: "REGISTER_FAILURE_STATUS",
              payload: err.response.status
            })
          } 
        } catch (err) {
          dispatch({
            type: "REGISTER_FAILURE",
          })
        }
      })
  }
}

export const getCompanyList = () => {
  
  return dispatch => {
    dispatch({
      type: "COMPANY_LIST_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.companyList, config)
      .then(response => {
        dispatch({
          type: "COMPANY_LIST_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        try {
          if (err.response.status === 400) {
            dispatch({
              type: "COMPANY_LIST_FAILURE",
              payload: err.response.data.error[0]
            })
          } else {
            dispatch({
              type: "COMPANY_LIST_FAILURE",
            })
          }
          if(err.response.status === 0 || err.response.status === 500){
            dispatch({
              type: "COMPANY_LIST_STATUS",
              payload: err.response.status
            })
          } 
        } catch (err) {
          dispatch({
            type: "COMPANY_LIST_FAILURE",
          })
        }
      })
  }
}

// Single company of user
export const getUserCompanyList = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    // let companyId = null;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }
      // companyId = impersonateUserInfoData.company     
    }
    else{
      // companyId = user.company
    }
    dispatch({
      type: "USER_COMPANY_LIST_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
    .get(api_settings.url_base + api_endpoints.usercompanyList + payload.companyid, config)
    .then(response => {
      dispatch({
        type: "USER_COMPANY_LIST_SUCCESS",
        payload: response.data
      });
    })
    .catch(err => {
      try {
        if (err.response.status === 400) {
          dispatch({
            type: "USER_COMPANY_LIST_FAILURE",
            payload: err.response.data.error[0]
          })
        } else {
          dispatch({
            type: "USER_COMPANY_LIST_FAILURE",
          })
        }
        if(err.response.status === 0 || err.response.status === 500){
          dispatch({
            type: "USER_COMPANY_LIST_STATUS",
            payload: err.response.status
          })
        } 
      } catch (err) {
        dispatch({
          type: "USER_COMPANY_LIST_FAILURE",
        })
      }
    })
  }
}


export const getCountryList = () => {
  
  return dispatch => {
    dispatch({
      type: "COUNTRY_LIST_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.countryList, config)
      .then(response => {
        dispatch({
          type: "COUNTRY_LIST_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        try {
          if (err.response.status === 400) {
            dispatch({
              type: "COUNTRY_LIST_FAILURE",
              payload: err.response.data.error[0]
            })
          } else {
            dispatch({
              type: "COUNTRY_LIST_FAILURE",
            })
          }
          if(err.response.status === 0 || err.response.status === 500){
            dispatch({
              type: "COUNTRY_LIST_STATUS",
              payload: err.response.status
            })
          } 
        } catch (err) {
          dispatch({
            type: "COUNTRY_LIST_FAILURE",
          })
        }
      })
  }
}


// export const registerWithJWT = (user, companyid) => {
export const registerCompanyWithJWT = (user, countryCode) => {  
  return dispatch => {
    dispatch({
      type: "REGISTER_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.comp_register, {
        city: user.city,
        address: user.address,
        zipcode:user.zipcode,
        phone:user.phone,
        vat:user.vat,
        email: user.email,
        company: user.company,
        country: user.country,
        // countrycode: countryCode,
      }, config)
      .then(response => {
        dispatch({
          type: "REGISTER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        try {
          if (err.response.status === 400) {
            dispatch({
              type: "REGISTER_FAILURE",
              payload: err.response.data.error[0]
            })
          } else {
            dispatch({
              type: "REGISTER_FAILURE",
            })
          }
          if(err.response.status === 0 || err.response.status === 500){
            dispatch({
              type: "REGISTER_FAILURE_STATUS",
              payload: err.response.status
            })
          } 
        } catch (err) {
          dispatch({
            type: "REGISTER_FAILURE",
          })
        }
      })
  }
}
