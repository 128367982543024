import { ThemeProvider, } from '@mui/material/styles';
import AppRouter from "router";
import theme from "theme";
import "styles/base.css";
import i18next from "i18next";
import common_it from "./locales/it.json";
import common_en from "./locales/en.json";
import common_cn from "./locales/zh-cn.json";
import { useSelector } from "react-redux";
import {I18nextProvider} from "react-i18next";


function App() {
  const { language } = useSelector((state) => state.language);
  i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: language.languageCode,      // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        it: {
            common: common_it
        },
        cn: {
          common: common_cn
      },
    },
});
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
          <AppRouter/>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
