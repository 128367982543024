import React, { useState, useEffect, useRef } from 'react';
import { DashboardLayout } from 'layouts';
import { BreadCrumb, CustomTable } from 'components';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import styles from "./style";
import DashboardCard from 'components/DashboardCard';
import ErrorBlock from 'components/ErrorBlock';
import BarChart from 'components/eCharts/BarChart';
import { ReactComponent as CheckIcon } from "assets/icons/tick.svg";
import Box from '@mui/material/Box';
import ReactTooltip from "react-tooltip";
import MapChart from './mapchart';
import { useDispatch, useSelector } from "react-redux";
import { 
  getCCCountriesNoOfMachines, 
  getCCCustomersList, 
  getCCTopTenCountries, 
  getCCTopTenCustomers,
  getCCUserMachineStat,
  getCCDataUsage,
  CCResetDataUsage
} from "redux/actions/analytics/adminDashboardAction";
import {Modal,IconButton} from "@mui/material";
import {AiFillCloseSquare} from "react-icons/ai";
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import UseIntersection from '../../elements/UseIntersection'
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import {AiOutlineReload} from 'react-icons/ai';
import Tooltip from '@mui/material/Tooltip';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  borderRadius:2,
  boxShadow: 24,
  p: 4,
  maxHeight: '95%',
  overflowY:'scroll'
};

const Refresh = styled('div')(({ theme }) => ({
  background: '#c8102e',
  borderRadius: 5, 
  flex: 'none', 
  fontFamily: 'Roboto', 
  cursor: 'pointer', 
  fontSize: 12,
  width: 'fit-content',
  color: '#fff',
  "&:hover":{
    background: "#eb314f",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
  },
  [theme.breakpoints.up("xl")]:{
    fontSize:14,
  }
}))

const useStyles = makeStyles(styles)

const WhiteCard = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 620,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 10px #00000029',
  borderRadius: 5,
  padding: '2% 3% 2% 3%',
  marginBottom: '2%'
}))



const FirstSection = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 'auto',
  display:"-webkit-box",
  marginTop: 20
}))

const RootContainer = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"49%",
  display:"-webkit-box",
  minHeight:height,
  marginRight: '2%'
}))

const MachineStatistics = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"49%",
  display:"-webkit-box",
  minHeight:height,
}))

export default function Dashboard() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName:t('AdminDashboard.Admin'),
        link:"/x-data"
    },
    {
        displayName:t('AdminDashboard.Dashboard'),
        link:"/xdata/analytics"
    },
  ]
  const thead = [
    {
      name:"name",
      displayName:""+t('AdminDashboard.CustomerName')+"",
      type:"text",
      width:20
    },
    {
      name:"email",
      displayName:""+t('AdminDashboard.EmailID')+"",
      type:"text",
      width:20
    },
    {
      name:"company",
      displayName:""+t('AllUsers.Company')+"",
      type:"text",
      width:20,  //percentage
    },
    {
      name:"user_accounts",
      displayName:""+t('AdminDashboard.UserAccounts')+"",
      type:"text",
      width:10
    },
    {
      name:"notification_status",
      displayName:""+t('AdminDashboard.NotificationStatus')+"",
      type:"status",
      width:20
    },
    {
      name:"data_usage_percentage",
      displayName:""+t('AdminDashboard.DataUsage')+"",
      type:"progressbar",
      width:20
    }
  ]
  const classes = useStyles();
  const [content, setContent] = useState();
  const dispatch = useDispatch();
  const [topTenCountriesXaxis, setTopTenCountriesXaxis] = useState([]);
  const [topTenCountriesData, setTopTenCountriesData] = useState([]);
  const [dataUsageXaxis, setDataUsageXaxis] = useState([]);
  const [topTenCustomerXaxis, setTopTenCustomerXaxis] = useState([]);
  const [topTenCustomerData, setTopTenCustomerData] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [dataUsageEmpty, setDataUsageEmpty] = useState(false);
  const [allcustomersCount, setAllcustomersCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [modalInfo, setModalInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [online, setOnline] = useState(0);
  const [offline, setOffline] = useState(0);
  const [nonOperational, setNonOperational] = useState(0);
  const [machineStatusTotal, setMachineStatusTotal] = useState(0);
  const [machineDeployedYearTotal, setMachineDeployedYearTotal] = useState(0);

  // const [trueColor, setTrueColor] = useState(0)
  // const [easyTint, setEasyTint] = useState(0);
  // const [touchTint, setTouchTint] = useState(0);
  const [posTotal, setPosTotal] = useState(0);

  const [dataUsage, setDataUsage] = useState(null);
  const [dataUsageChart, setDataUsageChart] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalMachine, setTotalMachine] = useState(0);
  const [currentCustomer, setCurrentCustomer] = useState();
  const staticColors = [
    '#A2AAAD', '#C8102E', '#36333D', '#FFB9C5', '#FF2C4F', '#C70021',  '#36333D', '#E30025',  '#FF637D',   '#76737D'
  ];
  const [staticData, setStaticData] = useState([]);


  const { 
    ccAdminContriesNoOfMachinesSuccess, 
    ccAdminTopTenCountriesSuccess,
    ccAdminTopTenCustomersSuccess,
    ccAdminCustomersListSuccess,
    ccAdminCustomersListLoading,
    ccAdminUserMachineStatSuccess,
    ccAdminUserDataUsageSuccess,
    ccAdminTopTenCountriesLoading,
    ccAdminContriesNoOfMachinesLoading,
    ccAdminTopTenCustomersLoading,
    ccAdminUserMachineStatLoading,
    ccAdminUserDataUsageLoading,
    ccAdminResetUserDataUsageSuccess,
    ccAdminResetUserDataUsageLoading,
    ccAdminResetUserDataUsageError,
    ccAdminContriesNoOfMachinesError,
    ccAdminTopTenCountriesError,
    ccAdminTopTenCustomersError,
    ccAdminUserDataUsageError,
    ccAdminUserMachineStatError,
    ccAdminCustomersListError
  } = useSelector((state) => state.analytics.admin);

  // useEffect(() => {  
    

  //   dispatch(getCCTopTenCustomers())
  // }, [dispatch])


  const coutriesNoOfMachineref = useRef();
  const countriesNoOfMachineViewport = UseIntersection(coutriesNoOfMachineref, '0px'); // Trigger as soon as the element becomes visible
  const [isLoadCountriesNoOfMachine, setIsLoadCountriesNoOfMachine] = useState(false);
  if (countriesNoOfMachineViewport) {
    if(!isLoadCountriesNoOfMachine){
      dispatch(getCCCountriesNoOfMachines()) 
      setIsLoadCountriesNoOfMachine(true)
    }
  }

  const topTenCountriesref = useRef();
  const topTenCountriesViewport = UseIntersection(topTenCountriesref, '0px'); // Trigger as soon as the element becomes visible
  const [isLoadtopTenCountries, setIsLoadtopTenCountries] = useState(false);
  if (topTenCountriesViewport) {
    if(!isLoadtopTenCountries){
      dispatch(getCCTopTenCountries())
      setIsLoadtopTenCountries(true)
    }
  }

  const topTenCustomersref = useRef();
  const topTenCustomersViewport = UseIntersection(topTenCustomersref, '0px'); // Trigger as soon as the element becomes visible
  const [isLoadtopTenCustomers, setIsLoadtopTenCustomers] = useState(false);
  if (topTenCustomersViewport) {
    if(!isLoadtopTenCustomers){
      dispatch(getCCTopTenCustomers())
      setIsLoadtopTenCustomers(true)
    }
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
      search: searchText
    }
    dispatch(getCCCustomersList(payload))
  }, [page, searchText, dispatch])

  useEffect(() => {  
    if(ccAdminContriesNoOfMachinesSuccess?.status === 'success'){
      setTotalMachine(ccAdminContriesNoOfMachinesSuccess.data.total_machines_deployed)
      setTotalCustomers(ccAdminContriesNoOfMachinesSuccess.data.total_customers)
  }
  }, [ccAdminContriesNoOfMachinesSuccess])
  

  useEffect(() => {  
    if(ccAdminTopTenCountriesSuccess?.status === 'success'){
        let topCountriesXaxis_ary = []
        let topCountriesData_ary = []
        ccAdminTopTenCountriesSuccess.data.result.forEach((data, key) => {
          topCountriesXaxis_ary.push(data?.country)
          topCountriesData_ary.push(data?.count_of_machinaries)
        })
        setTopTenCountriesXaxis(topCountriesXaxis_ary)
        setTopTenCountriesData(topCountriesData_ary)
    }
  }, [ccAdminTopTenCountriesSuccess])

  useEffect(() => {  
    if(ccAdminTopTenCustomersSuccess?.status === 'success'){
        let topCustomersXaxis_ary = []
        let topCustomersData_ary = []
        ccAdminTopTenCustomersSuccess.data.result.forEach((data, key) => {
          topCustomersXaxis_ary.push(data?.company)
          topCustomersData_ary.push(data?.no_of_machines)
        })
        setTopTenCustomerXaxis(topCustomersXaxis_ary)
        setTopTenCustomerData(topCustomersData_ary)
    }
  }, [ccAdminTopTenCustomersSuccess])

  useEffect(() => {  
    if(ccAdminCustomersListSuccess?.status === 'success'){
        setCustomersList(ccAdminCustomersListSuccess.data)
        setAllcustomersCount(ccAdminCustomersListSuccess.count)
    }
  }, [ccAdminCustomersListSuccess])

  useEffect(() => {  
    if(ccAdminUserMachineStatSuccess?.status === 'success'){
      setOnline(ccAdminUserMachineStatSuccess?.data?.machine_stat?.operation_statistics?.online)
      setOffline(ccAdminUserMachineStatSuccess?.data?.machine_stat?.operation_statistics?.offline)
      setNonOperational(ccAdminUserMachineStatSuccess?.data?.machine_stat?.operation_statistics?.non_operational)
      setMachineStatusTotal(ccAdminUserMachineStatSuccess?.data?.machine_stat?.total)
      setMachineDeployedYearTotal(ccAdminUserMachineStatSuccess?.data?.machine_stat?.total_no_machines_deployed_this_year)
      // var postot = ccAdminUserMachineStatSuccess?.data?.machine_stat?.type_statistics?.TRUEcolor + ccAdminUserMachineStatSuccess?.data?.machine_stat?.type_statistics?.EASYTINT+ ccAdminUserMachineStatSuccess?.data?.machine_stat?.type_statistics?.TOUCH_AND_TINT
      // setPosTotal(postot)
      // setTrueColor(ccAdminUserMachineStatSuccess?.data?.machine_stat?.type_statistics?.TRUEcolor)
      // setEasyTint(ccAdminUserMachineStatSuccess?.data?.machine_stat?.type_statistics?.EASYTINT)
      // setTouchTint(ccAdminUserMachineStatSuccess?.data?.machine_stat?.type_statistics?.TOUCH_AND_TINT)
      var static_data = []
      var sum = 0
      ccAdminUserMachineStatSuccess.data.machine_stat.type_statistics.forEach((data, index) => {
         static_data.push({value: data.count, name: data.pos_type,itemStyle: {color: ""}})
         sum += data.count
      })
      setPosTotal(sum)
      static_data.forEach((data, index) => {
         staticColors.forEach((color, colorIndex) => {
          if(index === colorIndex){
             static_data[index].itemStyle.color = color
          }
         })
      })
      setStaticData(static_data)
    }
  }, [ccAdminUserMachineStatSuccess])

  useEffect(() => {  
    if(ccAdminUserDataUsageSuccess?.status === 'success'){
    setDataUsage(ccAdminUserDataUsageSuccess?.data)    
    let chart_info = []
    let chart_xaxis = []
    if(ccAdminUserDataUsageSuccess.data.bucket_details?.length > 0){
      ccAdminUserDataUsageSuccess.data.bucket_details.forEach((item, key) => {
      
      if(item.current_usage !== 0){
            chart_info.push(item.current_usage.toFixed(4))
            chart_xaxis.push(item.name)
        }
      })
      const isAllZero = ccAdminUserDataUsageSuccess.data.bucket_details.every(item => item.current_usage === 0)
      
      setDataUsageEmpty(isAllZero)
     
    }
    if(ccAdminUserDataUsageSuccess.data.number_of_buckets === 0){
      setDataUsageEmpty(true)
    }
      setDataUsageChart(chart_info)
      setDataUsageXaxis(chart_xaxis)
    }
  }, [ccAdminUserDataUsageSuccess])

  const handleRowClick = (item) => () => {
    setCurrentCustomer(item.id)
    let payload = {
      customer_id: item.id
    }
    dispatch(getCCUserMachineStat(payload))
    dispatch(getCCDataUsage(payload))
    
    setModalInfo(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOnline(0)
    setOffline(0)
    setNonOperational(0)
    setMachineStatusTotal(0)
    setMachineDeployedYearTotal(0)
    setPosTotal(0)
    // setTrueColor(0)
    // setEasyTint(0)
    // setTouchTint(0)
    setModalInfo(null)
    setDataUsageChart([])
    setDataUsage(null)
  };

  const onSearchChange = (e) => {
    setPage(1)
    setSearchText(e.target.value)
  }

  const btnClick = () => {
   dispatch(CCResetDataUsage(currentCustomer))
  }

  useEffect(() => {  
    if(ccAdminResetUserDataUsageSuccess?.status === 'success'){
      toast.success(t('AdminDashboard.Datausageresetsuccess'), {
        duration: 8000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
      let payload1 = {
        customer_id: currentCustomer
      }
      dispatch(getCCUserMachineStat(payload1))
      dispatch(getCCDataUsage(payload1))
      dispatch({
        type: "REQUEST_RESET__USER_DATA_USAGE_SUCCESS",
        payload: null
      });
    }
  }, [ccAdminResetUserDataUsageSuccess,dispatch, currentCustomer])

  useEffect(() => {  
    if(ccAdminResetUserDataUsageError?.status === 'failure'){
      toast.error(ccAdminResetUserDataUsageError.message, {
        duration: 8000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
      // setOpen(false)
      dispatch({
        type: "REQUEST_RESET__USER_DATA_USAGE_FAILED",
        payload: null
      });
    }
  }, [ccAdminResetUserDataUsageError, dispatch])

  const tryAgain = () => {
    dispatch(getCCCountriesNoOfMachines()) 
  }

  const dataUsageTryAgain = () => {
    let payload = {
      customer_id: currentCustomer
    }
    dispatch(getCCDataUsage(payload))
  }

  const machineStatTryAgain = () => {
    let payload = {
      customer_id: currentCustomer
    }
    dispatch(getCCUserMachineStat(payload))
  }

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
      search: searchText
    }
    dispatch(getCCCustomersList(payload))
  }
 
  return (
    <DashboardLayout>

      <BreadCrumb linkData={linkData}/>

      <WhiteCard ref={coutriesNoOfMachineref}>
        <div className={classes.datechartHeader}>
            <h2 className={classes.chartTitle}>{t('AdminDashboard.Locations')}</h2>
            <Tooltip title={t('xdataLocationDetails.reload')}> 
            <Refresh style={{padding: 7}} onClick={tryAgain}>
            <AiOutlineReload className={classes.DownloadIcon}/>
              </Refresh>
              </Tooltip>
        </div>  
        {
          ccAdminContriesNoOfMachinesLoading ? 
          <div className={classes.loaderDiv}>
                <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
          </div>:
          ccAdminContriesNoOfMachinesError === 0 ? 
          <ErrorBlock btnClick={tryAgain} />
            :
          <>
        <MapChart className="worldmap" setTooltipContent={setContent} />
        {
          content ? 
          <ReactTooltip>
            <div style={{padding: 10}}>
            <p style={{fontFamily: 'Roboto'}}>{content.name}</p>
            </div>
            <div style={{display: 'flex', fontFamily: 'Roboto'}}>
              <div style={{padding: 10, borderRight: '1px solid #655E5E'}}>
                <p>{t('AdminDashboard.Customers')}</p>
                <p>{content.noofcustomers}</p>
              </div>
              <div style={{padding: 10}}>
                <p>{t('AdminDashboard.MachinesDeployed')}</p>
                <p>{content.noofmachine}</p>
              </div>
            </div>
            </ReactTooltip> : 
          null
        }
        <div style={{position: 'absolute', bottom: 20, right: 20}}>
          <div style={{display: 'flex'}}> 
            <p style={{border: "1px solid #36333D", padding: 5, width: 200, fontFamily: 'Roboto', fontSize: 15, color:'#36333D'}}>{t('AdminDashboard.TotalCustomers')}</p>
            <p style={{border: "1px solid #36333D", padding: 5,fontFamily: 'Roboto', width: 60,  fontSize: 15, color:'#C8102E', fontWeight: 'bold'}}>{totalCustomers}</p>
          </div>
          <div style={{display: 'flex'}}> 
            <p style={{border: "1px solid #36333D", padding: 5,  width: 200, fontFamily: 'Roboto', fontSize: 15, color:'#36333D'}}>{t('AdminDashboard.TotalMachinesDeployed')}</p>
            <p style={{border: "1px solid #36333D", padding: 5,fontFamily: 'Roboto', width: 60, fontSize: 15, color:'#C8102E', fontWeight: 'bold'}}>{totalMachine}</p>
          </div>
        </div>
        </>
      }
      </WhiteCard>

      <div className={classes.row}>
        <div className={classes.colleft50} ref={topTenCustomersref}>
            <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('AdminDashboard.Top10CustomerswithHighestNumberofMachinesDeployed')}</h2>
              <Tooltip title={t('xdataLocationDetails.reload')}> 
              <Refresh style={{padding: 7}} onClick={() => dispatch(getCCTopTenCustomers())}>
              <AiOutlineReload className={classes.DownloadIcon}/>
              </Refresh>
              </Tooltip>
            </div>
            {
          ccAdminTopTenCustomersLoading ? 
          <div className={classes.loaderDiv}>
            <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
          </div>:
          ccAdminTopTenCustomersError === 0 ? 
          <ErrorBlock btnClick={dispatch(getCCTopTenCustomers())} />
            :
          topTenCustomerXaxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('AdminDashboard.Nodatafound')}</p>
          </div>
          :
            <BarChart chartInfo={{
                tooltip: {
                  trigger: 'axis',
                  position: function (pt) {
                    return [pt[0], '10%'];
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '6%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  data: topTenCustomerXaxis,
                  axisLabel: {
                    interval: 0,
                    rotate: 30 //If the label names are too long you can manage this by rotating the label.
                  },
                  axisTick: {
                    alignWithLabel: true
                  }
                },
                yAxis: {
                  type: 'value'
                },
                textStyle: {
                  fontFamily: 'Roboto',
               },
                series: [                
                  {                  
                    data: topTenCustomerData,
                    type: 'bar',
                    itemStyle: {color: '#C8102E', borderRadius: 5},
                    barWidth:13
                  }
                ]
            }}/>
          }
        </div>
        <div className={classes.colright50} ref={topTenCountriesref}>
          <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('AdminDashboard.Top10CountrieswithHighestNumberofMachinesDeployed')}</h2>
              <Tooltip title={t('xdataLocationDetails.reload')}> 
              <Refresh style={{padding: 7}} onClick={() => dispatch(getCCTopTenCountries())}>
                <AiOutlineReload className={classes.DownloadIcon}/>
              </Refresh>  
              </Tooltip>
          </div>
          {
          ccAdminTopTenCountriesLoading ? 
          <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccAdminTopTenCountriesError === 0 ? 
                <ErrorBlock btnClick={dispatch(getCCTopTenCountries())} />
                  :
          topTenCountriesXaxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('AdminDashboard.Nodatafound')}</p>
          </div>
          :
          <BarChart chartInfo={{
              tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0], '10%'];
                }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '6%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: topTenCountriesXaxis,
                axisLabel: {
                  interval: 0,
                  rotate: 30 //If the label names are too long you can manage this by rotating the label.
                },
                axisTick: {
                  alignWithLabel: true
                }
              },
              yAxis: {
                type: 'value'
              },
              textStyle: {
                fontFamily: 'Roboto',
             },
              series: [                
                {                  
                  data: topTenCountriesData,
                  type: 'bar',
                  itemStyle: {color: '#C8102E', borderRadius: 5},
                  barWidth:13
                }
              ]
          }}/>
        }
        </div>
      </div>

      <CustomTable 
        loading={ccAdminCustomersListLoading}
        title = {t('AdminDashboard.ListofCustomers')}
        thead={thead}
        tdata={customersList}
        maxRow={10}
        handleRowClick={handleRowClick}
        totalPageCount={allcustomersCount}
        loadPagination={setPage}
        currentPage={page}
        search={true}
        onChange={onSearchChange}
        value={searchText}
        searchLabel={t('AdminDashboard.SearchCustomers')}
        clickableColumn={0}
        errorStatus={ccAdminCustomersListError}
        errorBlockBtnClick={errorBlockBtnClick}
        errorTopheight={"translateY(0%)"}
      />

        <Modal
          open={open}
          onClose={()=> handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className={classes.modal}>            
                <div className={classes.main}>                 
                  <div className={classes.text3} >{modalInfo?.name}</div>                               
                  <div className={classes.button3}>
                    <IconButton onClick={()=> handleClose()}>
                      <AiFillCloseSquare color='#C8102E' size="30px"/>
                    </IconButton> 
                  </div>                
                </div>
                <Divider className={classes.divide} /> 
                <div className={classes.headInfo}>
                  <div className={classes.headInfoChildLeft}>
                    <div className={classes.headInfoChild}>
                    {t('AdminDashboard.CustomerId')} - #{modalInfo?.id}
                    </div>
                    <div className={classes.headInfoChild}>
                    {t('AdminDashboard.EmailID')} - {modalInfo?.email}
                    </div>
                    <div>
                    {t('AdminDashboard.UserAccounts')} - {modalInfo?.user_accounts}
                    </div>
                  </div>
                  <div className={classes.headInfoRightChild}>
                  {t('AdminDashboard.Notifications')} : <span className={classes.notificationSpan}><CheckIcon className={classes.notificationCheckIcon}/> <span className={classes.notificationStatusText}>
                    {modalInfo?.notification_status ? t('AdminDashboard.Sent') : t('AdminDashboard.Notsent') }
                      </span></span>
                  </div>
                </div> 

                <FirstSection>
                  <RootContainer>
                    <DashboardCard
                    errorStatus = {ccAdminUserDataUsageError}
                    tryClick={dataUsageTryAgain}
                    errorTopheight={"translateY(80%)"}
                    btnLoader={ccAdminResetUserDataUsageLoading}
                    btnClick={btnClick}
                      title={t('AdminDashboard.DataUsage')}
                      width="100%"
                      height={650}
                      loading={ccAdminUserDataUsageLoading}
                      
                      chart={ dataUsageEmpty ? "No Data Usage" :
                        {
                          barChart: {
                            
                            tooltip: {
                              trigger: 'axis',
                              position: function (pt) {
                                return [pt[0], '10%'];
                              },
                              formatter: function(d) {
                                return d[0].name + " : " + d[0].data + " MB";
                              }
                            },
                            grid: {
                              left: '3%',
                              right: '4%',
                              bottom: '6%',
                              containLabel: true
                            },
                            xAxis: {
                              type: 'category',
                              data: dataUsageXaxis,
                              axisTick: {
                                alignWithLabel: true
                              },
                              // axisLabel: {
                              //   interval: 0,
                              //   rotate: dataUsageChart.length > 20 ? 0: 30 //If the label names are too long you can manage this by rotating the label.
                              // },
                            },
                            yAxis: {
                              type: 'value',
                              axisLine: {
                                lineStyle: {
                                  width: 1,
                                }
                              }
                              
                            },
                            textStyle: {
                              fontFamily: 'Roboto',
                           },
                            series: [                
                              {                  
                                data: dataUsageChart,
                                type: 'bar',
                                itemStyle: {color: '#C8102E', borderRadius: 5},
                                barWidth: dataUsageChart.length > 50 ? 3 : 13
                              }
                            ]
                        }
                        }
                      }
                      centerInfo={
                        {
                          info: t('AdminDashboard.NumberofBuckets'),
                          infoBold: dataUsage?.number_of_buckets,
                          columnInfo2: {
                            column1Title: (dataUsage?.current_usage/1024) < 1 ? 
                                  ((dataUsage?.current_usage-Math.floor(dataUsage?.current_usage))!== 0 ?dataUsage?.current_usage.toFixed(4):dataUsage?.current_usage)+' MB' :
                                  (dataUsage?.current_usage/1024)?.toFixed(2) + ' GB' ,
                            column1Desc: t('AdminDashboard.CurrentUsage') ,
                            // column2Title: (dataUsage?.data_limit/1024) < 100 ? dataUsage?.data_limit?.toFixed(4) +' MB': (dataUsage?.data_limit/1024)?.toFixed(2) + ' GB',
                            column2Title: 
                                  (dataUsage?.data_limit/1024) < 1 ? 
                                  ((dataUsage?.data_limit-Math.floor(dataUsage?.data_limit))!== 0 ?dataUsage?.data_limit.toFixed(4):dataUsage?.data_limit)+' MB' :
                                  (dataUsage?.data_limit/1024)?.toFixed(2) + ' GB',
                            column2Desc: t('AdminDashboard.UsageLimit'),
                          },
                          desc1: t('AdminDashboard.LastReset')+ " : ",
                          desc1Bold: dataUsage?.last_reset,
                          desc2: t('AdminDashboard.Expireson')+' : ',
                          desc2Bold: dataUsage?.expires_on,
                          buttonText: t('AdminDashboard.Reset')
                        }
                      }                      
                    />  

                  </RootContainer>

                  <MachineStatistics>
                      <DashboardCard
                        errorStatus = {ccAdminUserMachineStatError}
                        tryClick={machineStatTryAgain}
                        errorTopheight={"translateY(80%)"}
                        total={machineStatusTotal}
                        loading={ccAdminUserMachineStatLoading}
                        title={t('AdminDashboard.MachineDeploymentStatistics')}
                        width="100%"
                        height={650}
                        chart={
                          {
                            DoughnutChart: {
                              title: {
                                text: t('AdminDashboard.MachineStatus'),
                                textStyle: {
                                  fontStyle : 'normal',
                                  fontSize : 12
                                },
                                left: 'center',
                                top:30
                              },
                              tooltip: {
                                trigger: 'item'
                              },
                              legend: {
                                bottom: 0,
                                align: 'left'
                              },
                              textStyle: {
                                fontFamily: 'Roboto',
                             },
                              series: [
                                  {
                                    stillShowZeroSum: false,
                                      name: '',
                                      type: 'pie',
                                      radius: ['45%', '70%'],
                                      avoidLabelOverlap: false,
                                      label: {
                                        show: false,
                                        position: 'center'
                                      },
                            
                                      emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '18',
                                            fontWeight: 'bold',
                                            formatter: () => {
                                              return t('AdminDashboard.Total') + " : "+machineStatusTotal ; // Use sum variable here
                                            }
                                        }
                                      },
                                      labelLine: {
                                        show: false
                                      },
                                      data: [
                                          { 
                                              value: offline, 
                                              name: t('AdminDashboard.Offline'), 
                                              itemStyle: { color: '#C8102E' } 
                                          },
                                          { 
                                            value: online, 
                                            name: t('AdminDashboard.Online'), 
                                            itemStyle:{ color: '#36333d' } 
                                        },
                                          { 
                                              value: nonOperational, 
                                              name: t('AdminDashboard.NonOperational'), 
                                              itemStyle:{ color: '#a2aaad' } 
                                          },
                                      ]
                                    }
                              ]
                            },
                            NestedPie: {
                              title: {
                                text: t('AdminDashboard.POSTypeMachines'),
                                textStyle: {
                                  fontStyle : 'normal',
                                  fontSize : 12
                                },
                                left: 'center',
                                top:30
                              },
                              tooltip: {
                                trigger: 'item'
                              },
                              legend: {
                                bottom: -7,
                                align: 'left'
                              },
                              textStyle: {
                                fontFamily: 'Roboto',
                             },
                              series: [                                
                                {
                                  stillShowZeroSum: false,
                                  name: '',
                                  type: 'pie',
                                  radius: ['45%', '70%'],
                                  labelLine: {
                                    length: 30
                                  },
                                  label: {
                                    show: false,
                                    position: 'center'
                                  },
                                  emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '18',
                                        fontWeight: 'bold',
                                        formatter: () => {
                                          return ""+t('AdminDashboard.Total')+": "+ posTotal ; // Use sum variable here
                                        },
                                    }
                                  },
                                  data: staticData
                                }
                              ]
                            }
                          }
                        }
                        footerLeftContent = {
                          {
                            title: machineDeployedYearTotal,
                            desc: t('AdminDashboard.NumberofNewMachinesDeployedthisYear')
                          }
                        }
                      />
                  </MachineStatistics>
                </FirstSection>
                           
              </div>  
            </Box>
          </Modal>
          <Toaster position="bottom-center"/>
    </DashboardLayout>
    
  )
}
