import React,{useState,useEffect} from 'react'
import { CustomTextField } from 'elements';
import { Button,Link,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from "./style";
import Modals from 'components/Modal/Modal';
import ResetLogo from "assets/images/reset.svg";
import { useFormik } from "formik";
import {  string } from "yup";
import * as Yup from "yup";
import { useNavigate,useParams } from "react-router-dom";
import {resetWithJWT} from "../../redux/actions/auth/resetActions"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(styles)

export default function Resetform() {
  const {t} = useTranslation('common');
  const classes = useStyles()
  const [open, setOpen] = useState(false);

  let { uidb64, token } = useParams();
  
  const handleClose = () => setOpen(false);
  const { success, loading, error } = useSelector((state) => state.auth.reset);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  

  const bigHtmlContent = (
    <>
      <div className={classes.logo}>
         <img src={ResetLogo} alt='logo' /> 
      </div>
      <div className={classes.text1}>      
            <div>
                <Typography variant="subtitle1" gutterBottom component="div">
                   {t('resetPassword.successMsg')}
                </Typography>
            </div>           
      </div>
      <div className={classes.button1}>
                  <Button                 
                  variant="contained" 
                  onClick={() => {navigate("/sign-in");}}
                  size="medium"
                  >
                    {t('resetPassword.login')}
                  </Button>
      </div> 
    </>
  );

  const formik = useFormik({
    initialValues: {   
        password: "",
        confirm: "", 
        resettoken: token,
        resetuidb64: uidb64,    
  },
  validationSchema: Yup.object({
    password: string().required(t('resetPassword.enterPassword')),
    confirm: string().oneOf([Yup.ref('password'), null], t('resetPassword.passwordMatch')).required(t('resetPassword.confirmPasswordRequired')),        
  }),
  onSubmit : (values, formikHelpers)=> {
    dispatch(resetWithJWT(values))    
 }
});

useEffect(() => {
  if(success) {
    setOpen(true);
    dispatch({
      type: "RESET_REQUEST_RESET",
      payload: null
    })
    formik.resetForm();
  }
  if(error){
    Swal.fire({
      title: t('signIn.error'),
      text: ''+error+'',
      icon: 'error',
      iconColor: '#C8102E',
      confirmButtonColor: '#C8102E',
      confirmButtonText: t('companyAdd.close'),
    })
    dispatch({
      type: "RESET_REQUEST_RESET",
      payload: null
    })
  }
},[error, success]);

  return (
   <React.Fragment>
     <div className={classes.heading}>{t('resetPassword.resetPassword?')}</div>
     <span className={classes.subheading}>{t('resetPassword.chooseNewPassword')}</span>
     <form id="signup"  onSubmit={formik.handleSubmit}>
     <div  className="public-textbox">
        <CustomTextField 
              label={t('resetPassword.password')}
              name="password"
              type="password" 
              placeholder={t('resetPassword.password')}
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.password} 
              error = {formik.touched.password && Boolean(formik.errors.password)} 
              helperText = {formik.touched.password && formik.errors.password}
              login={true}
          />          
        <CustomTextField 
              label={t('resetPassword.confirmPassword')}
              name="confirm"
              type="password" 
              placeholder={t('resetPassword.confirmPassword')}
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.confirm} 
              error = {formik.touched.confirm && Boolean(formik.errors.confirm)} 
              helperText = {formik.touched.confirm && formik.errors.confirm}
              login={true}
          />
          </div>
        <Button className={classes.button} type="submit">
          {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('resetPassword.resetPassword')} 
        </Button>
     </form>
     <Modals handleclose={handleClose} open={open} content={bigHtmlContent}/>
     <div className={classes.bottom}>
        <Link className={classes.link}  href="/sign-in" color="#fff" >{t('resetPassword.backToLogin')}</Link>
     </div>
     
   </React.Fragment>
  )
}