const styles = (theme) => ({
    heading:{      
        marginTop:33,
        color:"#fff",
        fontSize:30, 
        fontFamily:"Roboto",
        fontWeight:"Medium", 
        [theme.breakpoints.up("xl")]:{
            fontSize:"40px !important", 
        }     
    },
    subheading:{
        color: "#fff",
        marginTop:"18px !important",
        marginBottom:"25px !important",
        fontSize:15, 
        fontFamily:"Roboto",
        fontWeight:"Medium",  
        [theme.breakpoints.up("xl")]:{
            fontSize:"20px !important", 
        } 
    },
    text:{
         
        color:"#fff !important",
        fontSize:20, 
        fontFamily:"Roboto",
        fontWeight:"100 !important",  
    },
    button:{
        backgroundColor:"#000 !important",
        marginTop:6,
        width: '100%',
        fontSize:'20px !important', 
         fontFamily:"Roboto",
         fontWeight:500, 
        [theme.breakpoints.down("xl")]:{
         marginTop:1,
         fontSize:'18px !important'
        },
        [theme.breakpoints.up("xl")]:{
            minHeight: "65px!important",
            minWidth:  "160px !important",
            fontSize: "22px!important",
        }
       
    },
    bottom:{
        textAlign:"center",
        marginTop:"20px !important",
        fontSize:18, 
        fontFamily:"Roboto",
        fontWeight:"Medium",  
    },
    link: {
        hover:"none",
        opacity:0.8,
        fontSize: 15,  
        [theme.breakpoints.up("xl")]:{
            fontSize:"20px !important", 
        }
    },
    tag:{
        fontSize: 16,
        fontWeight:500
    },
    logo: {
        display: "flex",
        justifyContent: "center"
     },
     button1: {
        display:"flex",
        justifyContent:"center",
        textAlign: "center"
     },
     text1: {
        // display:"flex",
        justifyContent:"center",
        textAlign: "center",
        marginTop:20
     },
})

export default styles