const styles = (theme) => ({
    gridLabel : {
        '& .MuiFormControlLabel-label':{
            [theme.breakpoints.down('xl')]: {
                fontSize: 13,
            }
        }
    },
 
    head: {
           display:"flex",
           justifyContent:"space-between",
           textAlign:"end",
            alignItems: 'center'
        },
    button: {
        
        justifyContent:"end",
        textAlign: "end"
    },
    text:{
        fontSize: 20,
        fontFamily:"Roboto",
        fontWeight:"400" ,
        [theme.breakpoints.up("xl")]:{
            fontSize: 30,
         },
    },
    inputLabel: {
        color:"#36333D !important",
        fontWeight: "550 !important", 
        fontSize: 12,
        [theme.breakpoints.up("xl")]:{
            fontSize: "18px  !important",
         },
    },
    button1: {
        marginTop:6
    },
    grid:{
        marginTop:"3px !important"
    },
    divide:{
        padding:10
    },
    heading: {
        color: "#36333D",
        fontWeight: 550,
        marginBottom: "10px !important"
    },
    field: {
        marginTop: 10
    },
    button2: {
        marginTop:30,
        
    },
    select:{
        fontSize: 16,
        fontFamily:"Roboto",
        fontWeight:"500" ,
        color: "#C8102E !important",
        textTransform: "unset !important"
    }
    
    })
    
       
       export default styles
       