const initalState = {
    activationLoading: false,
    activationError:null,
    activationSuccess: null,
  }
  
  export const activation = (state = initalState, action) => {
    switch (action.type) {
      case "ACTIVATION_REQUEST": {
        return { ...state, activationLoading: true,activationError:null,activationSuccess: null }
      }
      case "ACTIVATION_SUCCESS": {
        return { ...state, activationError:null,activationLoading: false,activationSuccess: action.payload}
      }
      case "ACTIVATION_FAILURE": {
        return { ...state, activationLoading: false,activationError:action.payload, activationSuccess: null}
      }
      default: {
        return state
      }
    }
  }