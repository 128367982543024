import React from 'react'
import { PublicLayout } from 'layouts'
import Resetform from 'components/Reset/Resetform'

export default function ResetPassword() {
  return (
   <PublicLayout>
     <Resetform/>
   </PublicLayout>
  )
}
