const styles = (theme) => ({
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    },
    logo:{
        width:283,
        height:92,
        [theme.breakpoints.down('xl')]: {
            width:150,
            height:50
        }
    },
    logoutText:{
        fontFamily:"Roboto",
        fontSize:20,
        color:"#36333D",
        fontWeight:"Regular",
        
        [theme.breakpoints.down('xl')]: {
            fontSize:18,
          
        },
        [theme.breakpoints.up('xl')]: {
            fontSize:23,
          
        }
        
    },
    errorText: {
        fontFamily:"Roboto",
        fontSize:20,
        color:"#c8102e",
        fontWeight:"Regular",
        
        [theme.breakpoints.down('xl')]: {
            fontSize:18,
          
        },
        [theme.breakpoints.up('xl')]: {
            fontSize:23,
          
        }
    },
    contentdiv: {
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
        alignItems: 'center'
     },
     icon: {
        width: "35px",
        marginRight: 5
     },
})

export default styles