import React from 'react';
import { DashboardLayout } from 'layouts';
import { makeStyles } from '@mui/styles';
import styles from "./styles";

const useStyles = makeStyles(styles)

export default function PermissionDenied() {
  const classes = useStyles();
  return (
    <DashboardLayout >
      <div className={classes.container}>
        <h1 className={classes.statusText}>403</h1>
        <div className={classes.descriptionText}>ACCESS DENIED</div>
        <div className={classes.descriptionText}>Sorry, You dont't have permission to access this page.</div>
      </div>
    </DashboardLayout>
    
  )
}
