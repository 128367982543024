import { createTheme } from '@mui/material/styles';

const theme= createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `none`,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `none`,
            },
          }
        },
      }
    },
    MuiButton:{ 
      styleOverrides: {
        root: {
          backgroundColor:"#C8102E",
          borderRadius:5,
          minHeight:49,
          minWidth:188,
          color:"#FFFFFF",
          fontFamily:"Roboto",
          fontWeight:"Regular",
          fontSize:16,
          marginTop:15,
          textTransform:"none",
          "&:hover":{
            backgroundColor:"#eb314f"
          }
        },
      }
    },
    MuiTextField:{ 
      styleOverrides: {
        root: {
          backgroundColor:"#FFFFFF",
          borderRadius:5,
          minHeight:49,
          minWidth:188,
          color:"#FFFFFF",
          fontFamily:"Roboto",
          fontWeight:"Regular",
          fontSize:16,
          
          textTransform:"none",
          
        },
      }
    },
    MuiAppBar:{
      styleOverrides: {
      colorPrimary: {
        width:"100%",
        backgroundColor:"#FFFFFF",
        boxShadow:"none",
        padding:0
      }
    }
    },
    MuiDrawer:{
      styleOverrides: {
        root: {
            
          }
        }
      },
      zIndex: {
        
      },
      breakpoints: {
        values: {
          
        },
      },
      MuiDivider:{
        styleOverrides: {
          root: {
              margin:"unset"
            }
          }
      }
  }
});

export default theme;