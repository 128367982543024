import React from 'react'
import {IoIosColorPalette} from "react-icons/io";
import { SketchPicker } from 'react-color';
import Popover from '@mui/material/Popover';


export default function Themes({formik, value, id}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor, darkColor, id) =>  {
        var stringRemove;
        if(typeof bgColor === "string"){
            stringRemove = bgColor.replace(/rgb/g, '')
        }
        else{
            stringRemove = bgColor[0].replace(/rgb/g, '')
        }
        var parenthesisRemove = stringRemove.slice(1,-1)
        var stringToArray = JSON.parse("[" + parenthesisRemove + "]");
        var uicolors = [stringToArray[0] / 255, stringToArray[1] / 255, stringToArray[2] / 255];
        var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        return (L > 0.279) ? darkColor : lightColor;
        
      }

    const open = Boolean(anchorEl);
    const id1 = open ? 'simple-popover' : undefined;
  
  return (

    <>
    <div className='theme-box' style={{background: value}} aria-describedby={id1}  onClick={handleClick}>
        {/* <div className='subtheme-box' style={{background: value}}>
        </div> */}
        <p className='theme-code' style={{color: pickTextColorBasedOnBgColorAdvanced(value, '#FFFFFF', '#000000',id)}}>{value}</p>
        <IoIosColorPalette style={{color: pickTextColorBasedOnBgColorAdvanced(value, '#FFFFFF', '#000000',id)}} className='theme-icon'/>
    </div>
    <Popover
                id={id1}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <SketchPicker
                 width='220px'
                        id="sidebar_color"
                        color={typeof value === "object" ? value[0]: value}
                        onChangeComplete={color => {
                            const RGB = 'rgb(' + color.rgb.r + "," +  color.rgb.g + "," + color.rgb.b + ')'
                            formik.setFieldValue(id, RGB)
                        }}
                
                    />
        </Popover>
    </>

        
        
  )
}
