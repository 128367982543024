import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";

export const rootAccessActivationAction = (user) => {
  
  return dispatch => {
    dispatch({
      type: "ACTIVATION_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.root_access_activation + "?token=" + user, config)
      .then(response => {
        dispatch({
          type: "ACTIVATION_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: "ACTIVATION_FAILURE",
          payload: err.response.data
        })
      })
  }
}