import React from 'react';
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import styles from "./styles";
import DoughnutChart from '../eCharts/DoughnutChart';
import BarChart from '../eCharts/BarChart';
import PieChart from '../eCharts/PieChart';
import NestedPie from '../eCharts/NestedPie';
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as FillIcon } from "assets/icons/fill.svg";
import { ReactComponent as ShopIcon } from "assets/icons/shop.svg";
import { ReactComponent as LocationIcon } from "assets/icons/marker.svg";
import { ReactComponent as CaretRightIcon } from "assets/icons/caret-right-white.svg";
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from "react-i18next";
import ErrorBlock from 'components/ErrorBlock';

const useStyles = makeStyles(styles)


const Container = styled('div')(({ theme, width, height, adjust}) => ({
    position:"relative",
    height: height,
    width: width,
    background: "transparent linear-gradient(147deg, #FFDFE5 50%, #ffd8e0 50%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 10px #00000029",
    borderRadius: 5,
    opacity: 1,
    display:"flex",
    flexDirection:"column",
    marginRight: adjust ? 'unset' : "5%",
    marginBottom: adjust ? 15 : 30,
    "& .box-container":{
        padding:"20px 20px"
    },
    "& .text-color": {
        fontSize: 12,
        color: '#C8102E',
        marginTop: 20
    },
    '& .box-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:'bold',
        fontSize:14
    },
    [theme.breakpoints.down('xl')]: {
        "& .box-container":{
            padding:"15px 25px"
        },
    }
}))

export default function Card({title, width, height, icon, columnInfo3, columnInfo3Data, columnInfo2, columnInfo2Data, footerRightContent, footerLeftContent, chart, footerClick, centerInfo, loading, observerref, total, btnClick, btnLoader, errorStatus, errorTopheight, tryClick, adjust, adjustText}) {  
    const classes = useStyles();
    const {t} = useTranslation('common');

    return (        
        <Container width={width} height={height} ref={observerref} adjust={adjust}>
            {
                loading ? 
                <div style={{display: 'flex', justifyContent: 'center',transform: 'translateY(200%)'}}>
                    <CircularProgress color="secondary" sx={{color:'#C8102E'}}/>
                </div> :
                errorStatus === 0 ? 
                <ErrorBlock height={errorTopheight} btnClick={tryClick}/> :
                total === 0 ? 
                <div  style={{display: 'flex', justifyContent: 'center',transform: 'translateY(700%)'}}>
                    <p style={{font: 'normal normal normal 20px Roboto'}}>{t('common.Nodatafound')}</p>
                </div>:
                <>
            
            <Grid className='box-container' style={{justifyContent: 'center'}} container direction="row">
            
                <div className={classes.titleDiv}>
                    <h3 className='box-title'>{title}</h3> 
                    {icon && <span className={classes.iconDiv}>
                        {icon === "document" && <DocumentIcon className={classes.icon}/>}
                        {icon === "fill" && <FillIcon className={classes.icon}/>}
                        {icon === "shop" && <ShopIcon className={classes.icon}/>}
                        {icon === "location" && <LocationIcon className={classes.icon}/>}
                    </span>}
                </div> 
                {
                chart === "No Data Usage" && <div style={{font: 'normal normal normal 20px Roboto', marginBottom: 100, marginTop: 100}}>{t('common.NoDataUsage')}</div>}
                {
                chart?.barChart && <BarChart chartInfo={chart.barChart}/>}
                {
                chart?.DoughnutChart && <DoughnutChart chartInfo={chart.DoughnutChart}/>}
                {chart?.NestedPie && <NestedPie chartInfo={chart.NestedPie}/>}
                {chart?.PieChart && <PieChart chartInfo={chart.PieChart}/>}
                {columnInfo3 && <div className={classes.columnInfo}>
                    <div className={classes.columnInfoChildWithRightBorder} style={{overflowWrap: "break-word"}}>
                        {columnInfo3Data.column1Title}
                        <div className={classes.columnInfoChildInfo}>
                            <b>{columnInfo3Data.column1Desc}</b>
                        </div>                        
                    </div>
                    <div className={classes.columnInfoChildWithRightBorder} style={{width: "42%", overflowWrap: "break-word"}}>
                        {columnInfo3Data.column2Title}
                        <div className={columnInfo3Data.column2Desc?.length > 100 && adjustText ? classes.columnInfoChildInfoSize : classes.columnInfoChildInfo}>
                            <b>{columnInfo3Data.column2Desc}</b>
                        </div> 
                    </div>
                    <div className={classes.columnInfoChild}>
                        {columnInfo3Data.column3Title}
                        <div className={classes.columnInfoChildInfo}>
                            <b>{columnInfo3Data.column3Desc === "undefined.undefined" ? "" : columnInfo3Data.column3Desc}</b>
                        </div> 
                    </div>
                </div>}  
                {columnInfo2 &&<div className={classes.columnInfo}>
                    <div className={classes.columnInfo2ChildWithRightBorder}>
                        {columnInfo2Data.column1Title}
                        <div className={classes.columnInfoChildInfo}>
                            <b>{columnInfo2Data.column1Desc}</b>
                        </div>                        
                    </div>
                    <div className={classes.columnInfo2Child}>
                        {columnInfo2Data.column2Title}
                        <div className={classes.columnInfoChildInfo}>
                            <b>{columnInfo2Data.column2Desc}</b>
                        </div> 
                    </div>
                </div>}
                {footerRightContent && <div className={classes.bottomRightDiv}>
                    <div className={classes.bottomRightDivTitle}>
                        {footerRightContent.title}
                    </div>
                    <div className={classes.bottomRightDivDesc}>
                        {footerRightContent.desc}
                    </div>
                </div>}      
                {footerLeftContent && <div onClick={footerClick} className={classes.bottomLeftDiv}>
                    <div className={classes.bottomLeftTitle}>
                        {footerLeftContent.title}
                    </div>
                    <div className={classes.bottomLeftDesc}>
                        {footerLeftContent.desc}
                    </div>
                    {footerLeftContent.button && <div className={classes.bottomLeftButton}>
                        {footerLeftContent.button.text}
                        <CaretRightIcon className={classes.buttonRightIcon}/>
                    </div>}
                </div>}   
                {centerInfo && <div className={classes.centerInfo}>
                    <span className={classes.centerInfoText}>{centerInfo.info} <b>{centerInfo.infoBold}</b></span>
                    {centerInfo.columnInfo2 &&<div className={classes.columnInfo}>
                    <div className={classes.columnInfo2CenterChildWithRightBorder}>
                        <h1>{centerInfo.columnInfo2.column1Title}</h1>
                        <div className={classes.columnInfoChildInfo}>
                            <b>{centerInfo.columnInfo2.column1Desc}</b>
                        </div>                        
                    </div>
                    <div className={classes.columnInfo2CenterChild}>
                        <h1>{centerInfo.columnInfo2.column2Title}</h1>
                        <div className={classes.columnInfoChildInfo}>
                            <b>{centerInfo.columnInfo2.column2Desc}</b>
                        </div> 
                    </div>
                </div>}
                <div className={classes.descInfoText}>{centerInfo.desc1} <b>{centerInfo.desc1Bold}</b></div>
                <div className={classes.descInfoText2}>{centerInfo.desc2} <b>{centerInfo.desc2Bold}</b></div>
                {centerInfo.buttonText && <span className={classes.centerInfoBtn} onClick={btnClick}>{btnLoader ? t('common.Loading') : centerInfo.buttonText}</span>}
                </div>}
            </Grid>
            </>  
                }
        </Container>
    )
}
