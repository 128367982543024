const initalState = {
    loading: false,
    location: null,
    error:null,
    ccLocationInformationLoading: false,
    ccLocationInformationSuccess: null,
    ccLocationInformationError:null,
    ccLocationVolumeOrderLoading: false,
    ccLocationVolumeOrderSuccess: null,
    ccLocationVolumeOrderError: null,
    ccLocationTotalSalesLoading: false,
    ccLocationTotalSalesSuccess: null,
    ccLocationTotalSalesError: null,
    ccLocationCustomFormulaLoading: false,
    ccLocationCustomFormulaSuccess: null,
    ccLocationCustomFormulaError: null,
    ccLocationTopTenColorsLoading: false,
    ccLocationTopTenColorsSuccess: null,
    ccLocationTopTenColorsError: null,
    ccLocationTopProductsLoading: false,
    ccLocationTopProductsSuccess: null,
    ccLocationTopProductsError: null, 
    ccLocationTopSubProductsLoading:false,
    ccLocationTopSubProductsSuccess: null,
    ccLocationTopSubProductsError: null, 
    ccLocationTopBasesLoading:false,
    ccLocationTopBasesSuccess: null,
    ccLocationTopBasesError: null, 
    ccLocationColorantConsumptionLoading:false,
    ccLocationColorantConsumptionSuccess: null,
    ccLocationColorantConsumptionError: null, 
    ccLocationAvgLocationFootfallLoading:false,
    ccLocationAvgLocationFootfallSuccess: null,
    ccLocationAvgLocationFootfallError: null, 
    ccLocationMachineStatLoading: false,
    ccLocationMachineStatSuccess: null,
    ccLocationMachineStatError: null,
  }
  
  export const locations = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_ALL_LOCATIONS": {
        return { ...state, loading: true, error: null, createError:null}
      }
      case "REQUEST_ALL_LOCATIONS_SUCCESS": {
        return { ...state, location: action.payload, error: null, loading: false, }
      }
      case "REQUEST_ALL_LOCATIONS_FAILED": {
        return { ...state, loading: false, error: action.payload, location: null }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_INFORMATION": {
        return { ...state, 
          ccLocationInformationLoading: true,
          ccLocationInformationSuccess: null,
          ccLocationInformationError: null, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_INFORMATION_SUCCESS": {
        return { ...state, 
          ccLocationInformationLoading:false,
          ccLocationInformationSuccess: action.payload,
          ccLocationInformationError: null, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_INFORMATION_FAILED": {
        return { ...state, 
          ccLocationInformationLoading:false,
          ccLocationInformationSuccess: null,
          ccLocationInformationError: action.payload, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_VOLUME_ORDER": {
        return { ...state, 
          ccLocationVolumeOrderLoading:true,
          ccLocationVolumeOrderSuccess: null,
          ccLocationVolumeOrderError: null, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_VOLUME_ORDER_SUCCESS": {
        return { ...state, 
          ccLocationVolumeOrderLoading:false,
          ccLocationVolumeOrderSuccess: action.payload,
          ccLocationVolumeOrderError: null, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_VOLUME_ORDER_FAILED": {
        return { ...state, 
          ccLocationVolumeOrderLoading:false,
          ccLocationVolumeOrderSuccess: null,
          ccLocationVolumeOrderError: action.payload, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOTAL_SALES": {
        return { ...state, 
          ccLocationTotalSalesLoading:true,
          ccLocationTotalSalesSuccess: null,
          ccLocationTotalSalesError: null, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOTAL_SALES_SUCCESS": {
        return { ...state, 
          ccLocationTotalSalesLoading:false,
          ccLocationTotalSalesSuccess: action.payload,
          ccLocationTotalSalesError: null, }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOTAL_SALES_FAILED": {
        return { ...state, 
          ccLocationTotalSalesLoading:false,
          ccLocationTotalSalesSuccess: null,
          ccLocationTotalSalesError: action.payload, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_CUSTOM_FORMULA": {
        return { ...state, 
          ccLocationCustomFormulaLoading:true,
          ccLocationCustomFormulaSuccess: null,
          ccLocationCustomFormulaError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_CUSTOM_FORMULA_SUCCESS": {
        return { ...state, 
          ccLocationCustomFormulaLoading:false,
          ccLocationCustomFormulaSuccess: action.payload,
          ccLocationCustomFormulaError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_CUSTOM_FORMULA_FAILED": {
        return { ...state, 
          ccLocationCustomFormulaLoading:false,
          ccLocationCustomFormulaSuccess: null,
          ccLocationCustomFormulaError: action.payload, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_TEN_COLORS": {
        return { ...state, 
          ccLocationTopTenColorsLoading:true,
          ccLocationTopTenColorsSuccess: null,
          ccLocationTopTenColorsError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_TEN_COLORS_SUCCESS": {
        return { ...state, 
          ccLocationTopTenColorsLoading:false,
          ccLocationTopTenColorsSuccess: action.payload,
          ccLocationTopTenColorsError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_TEN_COLORS_FAILED": {
        return { ...state, 
          ccLocationTopTenColorsLoading:false,
          ccLocationTopTenColorsSuccess: null,
          ccLocationTopTenColorsError: action.payload, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_PRODUCTS": {
        return { ...state, 
          ccLocationTopProductsLoading:true,
          ccLocationTopProductsSuccess: null,
          ccLocationTopProductsError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_PRODUCTS_SUCCESS": {
        return { ...state, 
          ccLocationTopProductsLoading:false,
          ccLocationTopProductsSuccess: action.payload,
          ccLocationTopProductsError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_PRODUCTS_FAILED": {
        return { ...state, 
          ccLocationTopProductsLoading:false,
          ccLocationTopProductsSuccess: null,
          ccLocationTopProductsError: action.payload, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_SUB_PRODUCTS": {
        return { ...state, 
          ccLocationTopSubProductsLoading:true,
          ccLocationTopSubProductsSuccess: null,
          ccLocationTopSubProductsError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_SUB_PRODUCTS_SUCCESS": {
        return { ...state, 
          ccLocationTopSubProductsLoading:false,
          ccLocationTopSubProductsSuccess: action.payload,
          ccLocationTopSubProductsError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_SUB_PRODUCTS_FAILED": {
        return { ...state, 
          ccLocationTopSubProductsLoading:false,
          ccLocationTopSubProductsSuccess: null,
          ccLocationTopSubProductsError: action.payload, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_BASES": {
        return { ...state, 
          ccLocationTopBasesLoading:true,
          ccLocationTopBasesSuccess: null,
          ccLocationTopBasesError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_BASES_SUCCESS": {
        return { ...state, 
          ccLocationTopBasesLoading:false,
          ccLocationTopBasesSuccess: action.payload,
          ccLocationTopBasesError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_TOP_BASES_FAILED": {
        return { ...state, 
          ccLocationTopBasesLoading:false,
          ccLocationTopBasesSuccess: null,
          ccLocationTopBasesError: action.payload, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_COLORANT_CONSUMPTION": {
        return { ...state, 
          ccLocationColorantConsumptionLoading:true,
          ccLocationColorantConsumptionSuccess: null,
          ccLocationColorantConsumptionError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_COLORANT_CONSUMPTION_SUCCESS": {
        return { ...state, 
          ccLocationColorantConsumptionLoading:false,
          ccLocationColorantConsumptionSuccess: action.payload,
          ccLocationColorantConsumptionError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_COLORANT_CONSUMPTION_FAILED": {
        return { ...state, 
          ccLocationColorantConsumptionLoading:false,
          ccLocationColorantConsumptionSuccess: null,
          ccLocationColorantConsumptionError: action.payload, 
        }
      }


      case "REQUEST_CC_LOCATION_ANALYTICS_AVG_LOCATION_FOOTFALL": {
        return { ...state, 
          ccLocationAvgLocationFootfallLoading:true,
          ccLocationAvgLocationFootfallSuccess: null,
          ccLocationAvgLocationFootfallError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_AVG_LOCATION_FOOTFALL_SUCCESS": {
        return { ...state, 
          ccLocationAvgLocationFootfallLoading:false,
          ccLocationAvgLocationFootfallSuccess: action.payload,
          ccLocationAvgLocationFootfallError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_AVG_LOCATION_FOOTFALL_FAILED": {
        return { ...state, 
          ccLocationAvgLocationFootfallLoading:false,
          ccLocationAvgLocationFootfallSuccess: null,
          ccLocationAvgLocationFootfallError: action.payload, 
        }
      }
      
      case "REQUEST_CC_LOCATION_ANALYTICS_MACHINE_STAT": {
        return { ...state, 
          ccLocationMachineStatLoading:true,
          ccLocationMachineStatSuccess: null,
          ccLocationMachineStatError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_MACHINE_STAT_SUCCESS": {
        return { ...state, 
          ccLocationMachineStatLoading:false,
          ccLocationMachineStatSuccess: action.payload,
          ccLocationMachineStatError: null, 
        }
      }

      case "REQUEST_CC_LOCATION_ANALYTICS_MACHINE_STAT_FAILED": {
        return { ...state, 
          ccLocationMachineStatLoading:false,
          ccLocationMachineStatSuccess: null,
          ccLocationMachineStatError: action.payload, 
        }
      }
      
      default: {
        return state
      }
    }
  }
  