export const search = (array, string) => {
    const keyword = string;
    if (keyword !== '') {
    // const results = array.filter((user) => {
    //     return user.name.toLowerCase().includes(keyword.toLowerCase());
    //     // Use the toLowerCase() method to make it case-insensitive
    // });
    const results = array.filter(item =>
        Object.keys(item).some(k => {
            if(typeof item[k] !== "number" && typeof item[k] !== "boolean"){
                return item[k]?.toLowerCase().includes(keyword.toLowerCase())
            }
        }
            ));
        return results
    } else {
        return array
    // If the text field is empty, show all users
    }
 };