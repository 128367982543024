import { combineReducers } from "redux"
import { login } from './loginReducer'
import {register} from './registerReducer'
import {forgot} from './forgotReducer'
import {reset} from './resetReducer'
import {allUsers} from './allUserListReducer'
import {github} from './githubReducer'

const authReducers = combineReducers({
  login,
  forgot,
  register, 
  reset,
  allUsers,
  github
})

export default authReducers