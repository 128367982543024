import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { WhiteLayout } from 'layouts';
import Button from '@mui/material/Button';
import ResetLogo from "assets/images/reset.svg";
import { useDispatch, useSelector } from "react-redux";
import {rootAccessActivationAction} from "../../redux/actions/rootAccessActivation/rootAccessActivationActions"
import queryString from 'query-string';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';

import { ReactComponent as Logo } from "assets/images/corob-logo-red.svg";

const useStyles = makeStyles(styles)

export default function RootAccessActivation(props) {
    const classes = useStyles()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { activationError } = useSelector((state) => state.rootAccessActivation.activation);
    const parsed = queryString.parse(window.location.search);
    // dispatch(rootAccessActivationAction(parsed.token))

    useEffect(() => { 
        dispatch(rootAccessActivationAction(parsed.token))
    },[]);

    // const parsed = queryString.parse(window.location.search);

    const handleLoginClick = () => {
        navigate("/sign-in")
    }
    
  return (
    <WhiteLayout>
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <div className={classes.contentdiv}>
                {
                    activationError ?
                    <>
                    <ErrorOutlineSharpIcon  style={{ color: "#C8102E" }} />
                    <div className={classes.errorText}>&nbsp;&nbsp;{activationError.message}</div>
                    </>
                    :
                    <>
                    <img src={ResetLogo} alt='success' className={classes.icon} /> 
                    <div className={classes.logoutText}>Root Access Granted Successfully!</div>
                    </>
                }
            </div>
            <Button onClick={handleLoginClick} style={{marginTop: 30}}>Login </Button>
        </div>
    </WhiteLayout>
  )
}