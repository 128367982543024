import React from 'react'
import { PublicLayout } from 'layouts'
import ForgotForm from 'components/Forgot'

export default function ForgotPassword() {
  return (
    <PublicLayout>
        <ForgotForm/>
    </PublicLayout>
  )
}
