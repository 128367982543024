import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable } from 'components';
import AnalyticsModal from 'components/Modal/AnalyticsModal';
import { useFormik } from "formik";
import ExporterModal from 'components/Datepicker/Exportermodal';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux"
import {  string } from "yup";
import * as Yup from "yup";
import { getAllAnalytics, analyticsExporter, getAllPOS, getPaginationPOS } from "../../redux/actions/history/analyticsActions"
import {useTranslation} from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';


export default function LegacyDatabasesAnalytics() {
  const {t} = useTranslation('common');

  const [analyticslist, setAnalyticslist] = useState([]);

  const [open, setOpen] = useState(false);
  const [btnOpen, setbtnOpen] = useState(false);
   
  const handleOpen = () => setOpen(true);
 
  
  const buttonOpen = () => setbtnOpen(true);

  const theme = useTheme();

  const [lastItem, setLastItem] = useState(null);
  const [page, setPage] = useState(1)
  const [allusersCount, setAllUsersCount] = useState(0);
  const [posList, setPosList] = useState([]);
  const [posSelectAll, setPosSelectAll] = useState(false);
  const [posNextPage, setPosNextPage] = useState("");
  const [posPage, setPosPage] = useState(1)
  const [checkedPosList, setCheckedPosList] = useState([]);
  const [posSearch, setPosSearch] = useState([]);

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));

  const dispatch = useDispatch();
  const { loading,
    legacyanalytics, 
    analyticsExporterDetails, 
    posSuccess, 
    posLoading, 
    analyticsExporterloading,
    analyticsExporterError500,
    error } = useSelector((state) => state.history.analytics);
  
  const linkData = [ 
    {
        displayName:t('legacyDatabaseAnalytics.legacydb'),
        link:"/legacy-databases"
    },
    {
        displayName:t('legacyDatabaseAnalytics.analytics'),
        link:"/legacy-databases/analytics"
    }
]

const thead = [
    {
      name:"name",
      displayName:t('legacyDatabaseAnalytics.name'),
      type:"text",
      width:50,  //percentage
    },
    {
      name:"id",
      displayName:t('legacyDatabaseAnalytics.code'),
      type:"number",
      width:60
    },
    {
        name:"records",
        displayName:t('legacyDatabaseAnalytics.records'),
        type:"number",
        width:10
      },
]


  const formik = useFormik({
    initialValues: {
      startdate: "",
      enddate: "",
      posname: "",
      id: "",
      poslist: [] 
    },
    validationSchema: Yup.object({
      startdate: string().required(t('legacyDatabaseAnalytics.startdate')),
      enddate: string().required(t('legacyDatabaseAnalytics.enddate')),                                                                
    }),
    onSubmit : (values, formikHelpers)=> {
      dispatch(analyticsExporter(values))      
    }
  });

  const getPosList = (itemcode) => {    
    let payload = {
      analyticsCode: itemcode,
      search: formik.values.posname
    }  
    dispatch(getAllPOS(payload))      
}

const handleClose = () => {
  formik.resetForm()
  setOpen(false)
};

  const onChangeCheck = (item, key) => () => {
    let formik_poslist = formik.values.poslist
    let already_in = false
      formik_poslist.map((itemv,keyi)=>{ 
        if(itemv===item.pos_name){
          already_in = true;
          formik_poslist.splice(keyi, 1);
        }
      })
      if(item.checked === true){
        formik.setFieldValue('poslist', formik_poslist)
        formik.setFieldValue('posname', formik_poslist.join(','))
        var input = [...posList]
        input[key].checked = false
        setPosList(input)
      }
      else{
        formik_poslist.push(item.pos_name)
        formik.setFieldValue('poslist', formik_poslist)
        formik.setFieldValue('posname', formik_poslist.join(','))
        var input = [...posList]
        input[key].checked = true
        setPosList(input)
      }
  }

  const handlePOSSearch = () => {
    setPosNextPage("")
    setPosList([])
    getPosList(lastItem)
  }

  const checkSelectAll = () => {
    let formik_poslist = formik.values.poslist
    setPosSelectAll(!posSelectAll)
    let prev_posList = [...posList]
    prev_posList.map((item, index) => {
      if(posSelectAll){
        formik.setFieldValue('poslist', [])
        formik.setFieldValue('posname', "")
        item.checked = false
      }
      else{
        formik_poslist.push(item.pos_name)
        formik.setFieldValue('poslist', formik_poslist)
        formik.setFieldValue('posname', formik_poslist.join(','))
        item.checked = true
       }
    })
    setPosList(prev_posList)
    setCheckedPosList(prev_posList)
  }

  const scrollBottom = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if(bottom){
      if(posSelectAll){}else{
        setPosPage(posPage+1)
        if(posNextPage !== ""){
          if(posNextPage === "" || posNextPage === null || posNextPage === undefined){

          }else{
            dispatch(getPaginationPOS({page:posNextPage}))   
          }          
        }else{
          getPosList(lastItem)
        }   
      }     
    }      
  }

  const loadMore = () => {
    if(posSelectAll){}else{
      setPosPage(posPage+1)
      if(posNextPage !== ""){
        if(posNextPage === "" || posNextPage === null || posNextPage === undefined){

        }else{
          dispatch(getPaginationPOS({page:posNextPage}))   
        }          
      }else{
        getPosList(lastItem)
      }   
    }  
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset
    } 
    dispatch(getAllAnalytics(payload))     

  },[page]);

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset
    } 
    dispatch(getAllAnalytics(payload))
  }

  const posSearchOnChange = (event) => {
    setPosSearch(event.target.value)
    const keyword = event.target.value;
    if (keyword !== '') {
    const results = posSuccess.data?.filter(item =>
      Object.keys(item).some(k => {
        if(typeof item[k] !== "number" && typeof item[k] !== "boolean"){
          return item[k]?.toLowerCase().includes(keyword.toLowerCase())
        }
      }));
      setPosList(results)
    } else {
      setPosList(posSuccess.data)
    }
}

  useEffect(() => {  
    if(posSuccess?.data){
      setPosNextPage(posSuccess?.next)
      if(posSuccess.data.length > 0){
        let prev_posList = posList
        posSuccess.data.map((item,index)=>{ 
          item.checked = false
          prev_posList.push(item)
        })
        setPosList(prev_posList)
        setCheckedPosList(prev_posList)
      }else{
        setPosList([])
        setCheckedPosList([])
      }
      if(posPage === 1){
        formik.setFieldValue('poslist', [])
        formik.setFieldValue('posname', "")
      }
    }  
         
  },[posSuccess]);

  useEffect(() => {  
    if(legacyanalytics?.results){
      setAnalyticslist(legacyanalytics.results)
      setAllUsersCount(legacyanalytics.count)
    }
  }, [legacyanalytics])

  const handleRowClick = (item) => () => {
    if(posSelectAll){
      setPosSelectAll(!posSelectAll)
    }
    setPosNextPage("")
    formik.setFieldValue('poslist', [])
    formik.setFieldValue('posname', "")    
    formik.setFieldValue("id", item.id)
    setLastItem(item.id)
    setPosList([])      
    
    handleOpen()      
    getPosList(item.id)
  }

  
   
  const formatDate = (d) => {
    var yyyy = d.getFullYear().toString();                                    
    var mm = (d.getMonth()+1).toString(); // getMonth() is zero-based         
    var dd  = d.getDate().toString();             
    return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]);
  }

  useEffect(() => {  
    if(analyticsExporterDetails?.status === 200){
      // handleClose()
      var base64replace1 = analyticsExporterDetails.data.data.replace("b'", "");
      var base64replace2 = base64replace1.replace("'", "");        
      var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      var blob1 = b64toBlob(base64replace2, contentType);
      var blobUrl1 = URL.createObjectURL(blob1);
      dispatch({
        type: "DB_ANALYTICS_DOWNLOAD_RESET",
        payload: null
      })
     
      dispatch({
        type: "REQUEST_ANALYTICS_EXPORTER_SUCCESS",
        payload: null
      });
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = blobUrl1;
      a.download = "history-export-" + lastItem + "-" + formik.values.startdate + " 00_00_00-" + formik.values.enddate + " 23_59_59";
      a.click();       
    }
  },[analyticsExporterDetails]);

  const b64toBlob =(b64Data, contentType, sliceSize) =>  {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;    
    var byteCharacters = atob(b64Data);
    var byteArrays = [];    
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);      
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }    
      var byteArray = new Uint8Array(byteNumbers);      
      byteArrays.push(byteArray);
    }    
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  useEffect(() => {  
    if(analyticsExporterError500){
      toast.error(t('legacyDatabaseAnalytics.exportFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_ANALYTICS_EXPORTER_FAILED_500",
        payload: null
      })
    }
    
  }, [analyticsExporterError500])
  
  return (
    <DashboardLayout pageLoading={analyticsExporterloading}>
      <Toaster/>
        <BreadCrumb linkData={linkData}/>
        <CustomTable 
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('legacyDatabaseAnalytics.analytics')}
            thead={thead}
            tdata={analyticslist}
            maxRow={10}
            handleRowClick={handleRowClick}
            handleDeleteClick={buttonOpen}
            height={isSmallerDesktop?617:767}
            loadPagination={setPage}
            clickableColumn={0}
            errorStatus={error}
            errorBlockBtnClick={errorBlockBtnClick}
        />        
       
        <AnalyticsModal 
            handleclose={handleClose} 
            open={open} 
            scrollBottom={scrollBottom}
            content={<ExporterModal onChange={(value) => {                                                       
                      formik.setFieldValue('startdate', formatDate(value.$d));
                      }}
            onChange1={(value) => {
                      formik.setFieldValue('enddate', formatDate(value.$d));
                      }}
            onChange2={posSearchOnChange}
            value={formik.values.startdate} 
            value1={formik.values.enddate}
            value2={formik.values.posname}
            date_type_analytics={formik.values.date_type_analytics}
            onSubmit={formik.handleSubmit}
            click={handleClose}
            checkSelectAll={checkSelectAll}
            posList={posList}
            onChangeCheck={onChangeCheck}
            posSelectAll={posSelectAll}
            posLoading={posLoading}
            handlePOSSearch={handlePOSSearch}
            posNextPageUrl={posNextPage}
            loadMore={loadMore}
            error = {formik.touched.startdate && Boolean(formik.errors.startdate)}
            errorenddate = {formik.touched.enddate && Boolean(formik.errors.enddate)}
                        
            helperText = {formik.touched.startdate && formik.errors.startdate}
            helperTextenddate = {formik.touched.enddate && formik.errors.enddate}
            checkedPosList={checkedPosList}
            posSearch={posSearch}
            legacyExporter={true}
            
        />}
      />         
      
       
    </DashboardLayout>
  )
}
