const initalState = {
    ccAdminContriesNoOfMachinesLoading: false,
    ccAdminContriesNoOfMachinesSuccess: null,
    ccAdminContriesNoOfMachinesError: null,
    ccAdminTopTenCountriesLoading: false,
    ccAdminTopTenCountriesSuccess: null,
    ccAdminTopTenCountriesError: null,
    ccAdminTopTenCustomersLoading: false,
    ccAdminTopTenCustomersSuccess: null,
    ccAdminTopTenCustomersError: null,
    ccAdminCustomersListLoading: false,
    ccAdminCustomersListSuccess: null,
    ccAdminCustomersListError: null,
    ccAdminUserMachineStatLoading:false,
    ccAdminUserMachineStatSuccess: null,
    ccAdminUserMachineStatError: null,
    ccAdminUserDataUsageLoading:false,
    ccAdminUserDataUsageSuccess: null,
    ccAdminUserDataUsageError: null,
    ccAdminResetUserDataUsageLoading: false,
    ccAdminResetUserDataUsageSuccess: null,
    ccAdminResetUserDataUsageError: null
  }
  
  export const admin = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_CC_COUNTRIES_NO_OF_MACHINES": {
        return { ...state, 
          ccAdminContriesNoOfMachinesLoading: true,
          ccAdminContriesNoOfMachinesSuccess: null,
          ccAdminContriesNoOfMachinesError: null, }
      }

      case "REQUEST_CC_COUNTRIES_NO_OF_MACHINES_SUCCESS": {
        return { ...state, 
            ccAdminContriesNoOfMachinesLoading:false,
            ccAdminContriesNoOfMachinesSuccess: action.payload,
            ccAdminContriesNoOfMachinesError: null, }
      }

      case "REQUEST_CC_COUNTRIES_NO_OF_MACHINES_FAILED": {
        return { ...state, 
            ccAdminContriesNoOfMachinesLoading:false,
            ccAdminContriesNoOfMachinesSuccess: null,
            ccAdminContriesNoOfMachinesError: action.payload, }
      }

      case "REQUEST_CC_TOP_TEN_COUNTRIES": {
        return { ...state, 
          ccAdminTopTenCountriesLoading: true,
          ccAdminTopTenCountriesSuccess: null,
          ccAdminTopTenCountriesError: null, }
      }

      case "REQUEST_CC_TOP_TEN_COUNTRIES_SUCCESS": {
        return { ...state, 
            ccAdminTopTenCountriesLoading:false,
            ccAdminTopTenCountriesSuccess: action.payload,
            ccAdminTopTenCountriesError: null, }
      }

      case "REQUEST_CC_TOP_TEN_COUNTRIES_FAILED": {
        return { ...state, 
            ccAdminTopTenCountriesLoading:false,
            ccAdminTopTenCountriesSuccess: null,
            ccAdminTopTenCountriesError: action.payload, }
      }


      case "REQUEST_CC_TOP_TEN_CUSTOMERS": {
        return { ...state, 
          ccAdminTopTenCustomersLoading: true,
          ccAdminTopTenCustomersSuccess: null,
          ccAdminTopTenCustomersError: null, }
      }

      case "REQUEST_CC_TOP_TEN_CUSTOMERS_SUCCESS": {
        return { ...state, 
          ccAdminTopTenCustomersLoading:false,
          ccAdminTopTenCustomersSuccess: action.payload,
          ccAdminTopTenCustomersError: null, }
      }

      case "REQUEST_CC_TOP_TEN_CUSTOMERS_FAILED": {
        return { ...state, 
            ccAdminTopTenCustomersLoading:false,
            ccAdminTopTenCustomersSuccess: null,
            ccAdminTopTenCustomersError: action.payload, }
      }

      case "REQUEST_CUSTOMERS_LIST": {
        return { ...state, 
          ccAdminCustomersListLoading: true,
          ccAdminCustomersListSuccess: null,
          ccAdminCustomersListError: null, }
      }

      case "REQUEST_CC_CUSTOMERS_LIST_SUCCESS": {
        return { ...state, 
          ccAdminCustomersListLoading:false,
          ccAdminCustomersListSuccess: action.payload,
          ccAdminCustomersListError: null, }
      }

      case "REQUEST_CC_CUSTOMERS_LIST_FAILED": {
        return { ...state, 
          ccAdminCustomersListLoading:false,
          ccAdminCustomersListSuccess: null,
          ccAdminCustomersListError: action.payload, }
      }

      case "REQUEST_USER_MACHINE_STAT": {
        return { ...state, 
          ccAdminUserMachineStatLoading: true,
          ccAdminUserMachineStatSuccess: null,
          ccAdminUserMachineStatError: null, }
      }

      case "REQUEST_USER_MACHINE_STAT_SUCCESS": {
        return { ...state, 
          ccAdminUserMachineStatLoading:false,
          ccAdminUserMachineStatSuccess: action.payload,
          ccAdminUserMachineStatError: null, }
      }

      case "REQUEST_USER_MACHINE_STAT_FAILED": {
        return { ...state, 
          ccAdminUserMachineStatLoading:false,
          ccAdminUserMachineStatSuccess: null,
          ccAdminUserMachineStatError: action.payload, }
      }

      case "REQUEST_USER_DATA_USAGE": {
        return { ...state, 
          ccAdminUserDataUsageLoading: true,
          ccAdminUserDataUsageSuccess: null,
          ccAdminUserDataUsageError: null, }
      }

      case "REQUEST_USER_DATA_USAGE_SUCCESS": {
        return { ...state, 
          ccAdminUserDataUsageLoading:false,
          ccAdminUserDataUsageSuccess: action.payload,
          ccAdminUserDataUsageError: null, }
      }

      case "REQUEST_USER_DATA_USAGE_FAILED": {
        return { ...state, 
          ccAdminUserDataUsageLoading:false,
          ccAdminUserDataUsageSuccess: null,
          ccAdminUserDataUsageError: action.payload, }
      }

      case "REQUEST_RESET_USER_DATA_USAGE": {
        return { ...state, 
          ccAdminResetUserDataUsageLoading: true,
          ccAdminResetUserDataUsageSuccess: null,
          ccAdminResetUserDataUsageError: null, }
      }

      case "REQUEST_RESET__USER_DATA_USAGE_SUCCESS": {
        return { ...state, 
          ccAdminResetUserDataUsageLoading:false,
          ccAdminResetUserDataUsageSuccess: action.payload,
          ccAdminResetUserDataUsageError: null, }
      }

      case "REQUEST_RESET__USER_DATA_USAGE_FAILED": {
        return { ...state, 
          ccAdminResetUserDataUsageLoading:false,
          ccAdminResetUserDataUsageSuccess: null,
          ccAdminResetUserDataUsageError: action.payload, }
      }
      
      default: {
        return state
      }
    }
  }
  