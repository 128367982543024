import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery } from "@mui/material";
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { CustomTextArea, CustomTextField } from 'elements';
import {Grid} from "@mui/material";
import { useFormik } from "formik";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {  string} from "yup";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import {RiErrorWarningLine} from "react-icons/ri";
import {AiFillCloseSquare} from "react-icons/ai";
import { IconButton } from '@mui/material';
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { useDispatch, useSelector } from "react-redux"
import { getAllWorkspaceUsersquery, CreateQuery, deleteWorkspaceUserQuery, resetWorkspaceUserOtp, reopenWorkspaceUserOtp } from "../../redux/actions/userquery/userqueryActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import InputFile from 'components/InputFile';

const useStyles = makeStyles(styles)

export default function WorkspaceUsers() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName:t('WorkspaceUsersQuery.WorkspaceUsersQuery'),
        link:"/dashboard/workspace-users"
    }
  ]

  const thead = [
    {
      name:"query",
      displayName:t('WorkspaceUsersQuery.Query'),
      type:"text",
      width:30,  //percentage
    },
    {
      name:"description",
      displayName:t('WorkspaceUsersQuery.Description'),
      type:"text",
      width:30,
    },
    {
      name:"status",
      displayName:t('WorkspaceUsersQuery.Status'),
      type:"text",
      width:20,
    },
    {
      name:"queryOwner",
      displayName:t('WorkspaceUsersQuery.queryOwner'),
      type:"queryownicon",
      width:10,
    },

    {
      name:"otp_send",
      displayName:t('WorkspaceUsersQuery.OTPSend'),
      type:"boolean",
      width:9,
    },
    {
      name:"actions",
      displayName:t('WorkspaceUsersQuery.Actions'),
      type:"action",
      width:9
    },
  ]
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [Delete,setDelete] = useState(false);
  const [Reset,setReset] = useState(false);
  const [Reopen,setReopen] = useState(false);
  

  const [userquerylist, setUserQuerylist] = useState([]);

    const deleteClose = () =>setDelete(false);
    const resetClose = () =>setReset(false);
    const reopenClose = () => setReopen(false);

    const [page, setPage] = useState(1);
    const [deleteItem, setDeleteItem] = useState(null);
    const [allusersCount, setAllUsersQueryCount] = useState(0);
    const [originalPermission, setOriginalPermission] = useState();

    const [resetItem, setResetItem] = useState(null);
    const [reopenItem, setReopenItem] = useState(null);

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { loading,
    workspaceusersquery,
    createLoading,
    createError,
    createSuccess,
    updateLoading,
    updateSuccess,
    deleteSuccess,
    deleteLoading,
    createError500,
    updateError500, 
    deleteError500,
    reopenLoading, 
    reopenError500,  
    reopenSuccess,
    error
  } = useSelector((state) => state.userquery.query);
  const { user } = useSelector((state) => state.auth.login);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  const forgot = useSelector((state) => state.auth.forgot);
  const { sendSuccess } = useSelector((state) => state.userActivation.activation);
  let impersonateUserInfoData = impersonateUserInfo?.data
  
  const handleOpen = () => {
    setOpen(true)
  };
  

  useEffect(() => {  
    let workspaceusersqueryList = []
    if(workspaceusersquery?.data){
      (workspaceusersquery.data).forEach(function(item){
        workspaceusersqueryList.push({
          id: item.id,
          query: item.query.length > 30 ? item.query.substring(0, 30)+"...": item.query,
          description: item.description.length > 30 ? item.description.substring(0, 30)+"...": item.description ,
          fullquery: item.query,
          fulldescription: item.description ,
          filedata:item.filedata,
          otp_send:item.otp_send,
          status:item.status,
          email:item.email,
          queryOwner: item.email === user.email ? true : false,
        })
      })
      setUserQuerylist(workspaceusersqueryList)
      setAllUsersQueryCount(workspaceusersquery.count)
    }
  }, [workspaceusersquery])
  

  const handleRowClick = (item) => () => {
    Swal.fire({
      title: t('WorkspaceUsersQuery.readQuery'),
      text: item.query,
      html: `<div style ='text-align: justify;'><p> <b>Query: </b>${item.fullquery}</p> <br> <p><b>Discription:</b>${item.fulldescription}</p> </div>`,
      confirmButtonColor: '#C8102E'
    })
  }

  useEffect(() => {  
    if(forgot.success){      
      setReopen(true)
      dispatch({
        type: "RESET_FORGOT_REQUEST",
        payload: null
      })
    }
  }, [forgot.success, dispatch])
     

  const formik = useFormik({
    initialValues: {
      query:"",
      description: "",
      filedata: "",
      email:user.email,
      accept: false
  },
  validationSchema: Yup.object({
    query: string().required(t('WorkspaceUsersQuery.queryValidation')),
    description: string().required(t('WorkspaceUsersQuery.descriptionValidation')),                                                                             
  }),
  onSubmit : (values, formikHelpers)=> {
  dispatch(CreateQuery(values))  
  }
  });


  const buttonClose = () => {
    setOriginalPermission()
  };

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  };

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllWorkspaceUsersquery(payload)) 
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllWorkspaceUsersquery(payload))
    if(createSuccess?.status === "Success"){
      setOpen(false)
      Swal.fire({
        title: t('WorkspaceUsersQuery.Success'),
        text: t('WorkspaceUsersQuery.QueryCreated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      formik.resetForm()
    }

  },[page, createSuccess,sendSuccess, dispatch, t]);

  useEffect(() => {  
  
    if(deleteSuccess){
      setDelete(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllWorkspaceUsersquery(payload)) 
      Swal.fire({
        title: t('WorkspaceUsersQuery.Success'),
        text: t('WorkspaceUsersQuery.QueryDeleted'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }

  },[deleteSuccess, dispatch, t]);

  useEffect(() => {  
  
    if(updateSuccess){
      setReset(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllWorkspaceUsersquery(payload)) 
      if(updateSuccess.message === "User Query OTP refreshed Successfully"){
      Swal.fire({
        title: t('WorkspaceUsersQuery.Success'),
        text: t('WorkspaceUsersQuery.QueryReset'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })}
      else if(updateSuccess.message === "The OTP does not refresh for Query"){
        Swal.fire({
          title: t('WorkspaceUsersQuery.oops'),
          text: t('WorkspaceUsersQuery.otpNotSend'),
          icon: 'error',
          iconColor: '#C8102E',
          confirmButtonColor: '#C8102E'
        })
      }
      else if(updateSuccess.message === "The Query already resolved"){
        Swal.fire({
          title: t('WorkspaceUsersQuery.oops'),
          text: t('WorkspaceUsersQuery.queryAlreadyResolved'),
          icon: 'error',
          iconColor: '#C8102E',
          confirmButtonColor: '#C8102E'
        })
      }
    }
    resetClose()

  },[updateSuccess, dispatch, t]);


  useEffect(() => {  
  
    if(reopenSuccess){
      setReset(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllWorkspaceUsersquery(payload)) 
      if(reopenSuccess.message === "User Query reopen Successfully"){
      Swal.fire({
        title: t('WorkspaceUsersQuery.Success'),
        text: t('WorkspaceUsersQuery.QueryReopen'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })}
      else if(reopenSuccess.message === "Failed to reopen may be requested query in pending state"){
        Swal.fire({
          title: t('WorkspaceUsersQuery.oops'),
          text: t('WorkspaceUsersQuery.QueryNotReopen'),
          icon: 'error',
          iconColor: '#C8102E',
          confirmButtonColor: '#C8102E'
        })
      }
      reopenClose()
    }

  },[reopenSuccess, dispatch, t]);

  useEffect(() => {  
    if(createError){
      Swal.fire({
        title: 'Error',
        text: t('WorkspaceUsersQuery.createError'),
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',
        
      })
      dispatch({
        type: "CREATE_REQUEST_USERQUERY_FAILED",
        payload: null
      })
    }

  },[createError, dispatch, t]);


  const handleDeleteClick = (item) => () => {
    if(impersonateUserInfoData?.impersonate !== true){
      if(item['email'] === user.email){
        setDelete(true)
        setDeleteItem(item)
        }
        else{
          setDelete(false)
        }
    }
    else{
      setDelete(false)
    }

  }

  const deleteConfirm = () => {    
    dispatch(deleteWorkspaceUserQuery({id: deleteItem.id}))
  }

  const handleResetClick = (item) => () => {
    if(impersonateUserInfoData?.impersonate !== true){
      if(item['status'] === "Pending" && item['email'] === user.email){
        setReset(true)
        setResetItem(item)
      }
      else{
        setReset(false)
      }
    }
  else{
    setReset(false)
  }
  }

  const resetotpConfirm = () => {    
    dispatch(resetWorkspaceUserOtp({id: resetItem.id}))
  }

  const handleReopenClick = (item) => () => {
    if(impersonateUserInfoData?.impersonate !== true){
        if(item['status'] === "Done" && item['email'] === user.email){
        setReopen(true)
        setReopenItem(item)
        }
        else{
          setReopen(false)
        }
      }
    else{
      setReopen(false)
    }
    }


  const reopenQueryConfirm = () => {    
    dispatch(reopenWorkspaceUserOtp({id: reopenItem.id}))
  }



  useEffect(() => {  
    if(createError500){
      toast.error(t('WorkspaceUsersQuery.queryCreateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "CREATE_REQUEST_USERQUERY_FAILED_500",
        payload: null
      })
    }

    if(updateError500){
      toast.error(t('WorkspaceUsersQuery.queryUpdateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "UPDATE_REQUEST_USERQUERY_FAILED_500",
        payload: null
      })
    }

    if(reopenError500){
      toast.error(t('WorkspaceUsersQuery.queryUpdateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REOPEN_REQUEST_USERQUERY_FAILED_500",
        payload: null
      })
    }


    if(deleteError500){
      toast.error(t('WorkspaceUsersQuery.queryDeleteFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "DELETE_REQUEST_USERQUERY_FAILED_500",
        payload: null
      })
    }
    
  }, [createError500, updateError500, deleteError500, reopenError500, dispatch])


  
  const headerActions = (
    <>
    <Button onClick={handleOpen} style={{marginLeft:30}}><PlusIcon /> {t('WorkspaceUsersQuery.CreateNew')}</Button>
    </>
  )
  

  
  const bigHtmlContent = (
    <>
      <form onSubmit={formik.handleSubmit}>
      <div className={classes.main}>                 
            <div className={classes.text3} >{t('WorkspaceUsersQuery.WorkspaceUsersQuery')}</div>                               
            <div className={classes.button3}>
                <IconButton onClick={()=> handleClose()}>
                    <AiFillCloseSquare color='#C8102E' size="30px"/>
                </IconButton>
            </div>                
        </div>
      <Divider className={classes.divide} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
      <CustomTextField
            label={t('WorkspaceUsersQuery.Query')}
            name="query"
            type="name" 
            placeholder="Query"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.query} 
            error = {formik.touched.query && Boolean(formik.errors.query)} 
            helperText = {formik.touched.query && formik.errors.query}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={true} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextArea
            label={t('WorkspaceUsersQuery.Description')}
            name="description"
            type="name" 
            placeholder="Description"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.description} 
            error = {formik.touched.description && Boolean(formik.errors.description)} 
            helperText = {formik.touched.description && formik.errors.description}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            multiline
            minRows={5}
            required={true} />
            
        </Grid>

        <Grid item sm={8}>
            <InputFile 
                btnText={t('WorkspaceUsersQuery.ChooseFile')}
                formik={formik}
                onBlur={formik.handleBlur} 
                value={formik.values.filedata}
                name="filedata"
                label ={t('WorkspaceUsersQuery.uploadFile')}
                accept="image/*"
              />
        </Grid>
        <Grid item sm={4}></Grid>
      </Grid>
                <div className={classes.permission}>
                {t('WorkspaceUsersQuery.Permissions')} *
                </div> 
                <FormControlLabel  name="accept" value={formik.values.accept} onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsersQuery.AccessAccount')}/>
              <div>                   
                <Button  
                className='appBtn'
                  size="meduim"
                  type="submit"
                >
                  {createLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsersQuery.Create')}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )

  const bigHtmlContent1 = (
    <>
 <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('WorkspaceUsersQuery.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('WorkspaceUsersQuery.Wehavesentaresetotp')}
              
      </div>
      
      <div className={classes.button2}>
       
              <Button  
              className="appBtnCancel"  
                onClick={resetClose}                         
                size="large"
              >
                 {t('WorkspaceUsersQuery.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={resetotpConfirm}
                  >
                    {updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsersQuery.OK')} 
                  </Button>
              </div>
           
      </div>
    </>                            
  )

  const bigHtmlContent2 = (
    <>
      <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('WorkspaceUsersQuery.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('WorkspaceUsersQuery.ReopenQuery')}
              
      </div>
      
      <div className={classes.button2}>
              <Button  
              className="appBtnCancel"  
                onClick={reopenClose}                         
                size="large"
              >
                 {t('WorkspaceUsersQuery.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={reopenQueryConfirm}
                  >
                    {reopenLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsersQuery.OK')} 
                  </Button>
              </div>
      </div>
    </> 
  )

  const bigHtmlContent3 = (
    <>
      <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('WorkspaceUsersQuery.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('WorkspaceUsersQuery.Youareabouttodeleteaworkspaceuserquery')}
              
      </div>
      
      <div className={classes.button2}>
       
              <Button  
              className="appBtnCancel"  
                onClick={deleteClose}                         
                size="large"
              >
                 {t('WorkspaceUsersQuery.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={deleteConfirm}
                  >
                    {deleteLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsersQuery.OK')} 
                  </Button>
              </div>
           
      </div>
     
    </>     
  )
  

  return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loading}
              totalPageCount={allusersCount}
              title ={t('WorkspaceUsersQuery.WorkspaceUsersQuery')}
              thead={thead}
              tdata={userquerylist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:["refresh","reopen","delete"],
                        default:["refresh","reopen","delete"]
                      }
                    }
              handleResetClick={handleResetClick}
              handleDeleteClick={handleDeleteClick}
              handleReopenClick={handleReopenClick}
              headerActions={headerActions}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={0}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
          handleclose={handleClose} 
          open={open} 
          content={bigHtmlContent}                   
      />
      <Modals 
        handleclose={buttonClose} 
        open={Reset} 
        content={bigHtmlContent1}                   
      />
      <Modals 
        handleclose={reopenClose} 
        open={Reopen} 
        content={bigHtmlContent2}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={Delete} 
        content={bigHtmlContent3}                   
      />
      </DashboardLayout>
      
    )
  }
// }
