import React, {useEffect, useState} from 'react'
import { styled } from '@mui/system';
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

const FooterRoot = styled('div')(({ theme }) => ({
  // position:"absolute",
  // bottom:0,
  width: '100%',
  height: 60,
  backgroundColor: "#FFFFFF",
  boxShadow: "3px 0px 6px #00000029",
  opacity: 1,
  padding:"22px 59px 20px 57px",
  fontFamily:"Roboto",
  fontSize:14,
  fontWeight:300,
  color:"#36333D",
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  [theme.breakpoints.down('xl')]: {
    padding:"17px 20px 15px 270px",
    fontSize:12,
  },
  [theme.breakpoints.up('xl')]: {
    padding:"17px 20px 15px 350px",
    fontSize:16,
  }
}));

function compareVersions(version1, version2) {
  const parts1 = version1?.split('.').map(Number);
  const parts2 = version2?.split('.').map(Number);

  for (let index = 0; index < parts1?.length; index++) {
    if (parts1[index] > parts2[index]) {
      return version1;
    } else if (parts1[index] < parts2[index]) {
      return version2;
    }
  }

  return version1;
}

export default function AppFooter() {
  const {t} = useTranslation('common');
  const currentDate = new Date();
  const { language } = useSelector((state) => state.language);
  const { 
    frontEndsuccess, backEndsuccess
  } = useSelector((state) => state.auth.github);

    // Get the current year using the getFullYear() method
  const currentYear = currentDate.getFullYear();
  const [version, setVersion] = useState('');

  useEffect(() => {
    if(frontEndsuccess && backEndsuccess){
      const greaterVersion = compareVersions(frontEndsuccess[0]?.tag_name, backEndsuccess[0]?.tag_name);
      setVersion(greaterVersion)
    }
    else if(!frontEndsuccess){
      setVersion(backEndsuccess[0]?.tag_name)
    }
    else if(!backEndsuccess){
      setVersion(frontEndsuccess[0]?.tag_name)
    }
    if(!frontEndsuccess && !backEndsuccess){
      setVersion('2.0.0')
    }
  }, [frontEndsuccess, backEndsuccess]);

  return (
    <FooterRoot>
        <span>{language.languageCode === 'cn' ? `${t('common.copyright')} ${currentYear} 版权所有` : `${t('common.copyright')} ${currentYear}`}</span>
        <span>{t('common.corobVersion')} {version} | {t('common.databaseVersion')}</span>
    </FooterRoot>
  )
}
