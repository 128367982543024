const initalState = {
    activationPermissionLoading: false,
    activationPermissionError:null,
    activationPermissionSuccess: null,
  }
  
  export const activation = (state = initalState, action) => {
    switch (action.type) {
      case "ACTIVATION_REQUEST": {
        return { ...state, activationPermissionLoading: true,activationPermissionError:null,activationPermissionSuccess: null }
      }
      case "ACTIVATION_SUCCESS": {
        return { ...state, activationPermissionError:null,activationPermissionLoading: false,activationPermissionSuccess: action.payload}
      }
      case "ACTIVATION_FAILURE": {
        return { ...state, activationPermissionLoading: false,activationPermissionError:action.payload, activationPermissionSuccess: null}
      }
      default: {
        return state
      }
    }
  }