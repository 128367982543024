import React from 'react'
import { styled } from '@mui/system';
import {Pagination} from '@mui/material';


const Container = styled('div')(({ theme }) => ({
    position:"absolute",
    bottom:0,
    display:"flex",
    alignSelf:"flex-end",
    alignItems:"center",
    justifyContent:"flex-end",
    padding:10,
    height:82,
    // alignSelf:"flex-end",
    "& >nav>ul>li>button":{
      width:"30px !important",
      height:"30px !important",
      fontSize: "12px !important"
    },
    '& .Mui-selected':{
      backgroundColor:"#C8102E !important",
      width: "30px !important",
      height: "30px !important",
      color: '#fff'
    },
    [theme.breakpoints.up('xl')]: {
      "& >nav>ul>li>button":{
        width:"40px !important",
        height:"40px !important",
        fontSize: "20px !important"
      },
      '& .Mui-selected':{
        backgroundColor:"#C8102E !important",
        width: "40px !important",
        height: "40px !important"
      },
    }
}))

export default function PaginationController({totalCount,setPage, loadPagination, page}) {
  const handleChange = (event, value) => {
    loadPagination(value)
    // setPage(value)
  };
  return (
    <Container>
      <Pagination page={page} count={totalCount} shape="rounded" onChange={handleChange} />
    </Container>
  )
}
