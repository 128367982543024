const initalState = {
    loading: false,
    analytics: null,
    error:null,
    createError:null,
    createSuccess: null,
    exportLoading: false,
    posLoading: false,
    posSuccess: null,
    posError: null,
    ccAnnualSalesTrendLoading: false,
    ccAnnualSalesTrendSuccess: null, 
    ccAnnualSalesTrendError: null,
    ccAnnualSalesVolumeLoading:false,
    ccAnnualSalesVolumeSuccess: null,
    ccAnnualSalesVolumeError: null,
    ccTopTenColorsLoading:false,
    ccTopTenColorsSuccess: null,
    ccTopTenColorsError: null,
    ccTopTenProductsLoading:false,
    ccTopTenProductsSuccess: null,
    ccTopTenProductsError: null,
    ccTopCanSalesLoading:false,
    ccTopCanSalesSuccess: null,
    ccTopCanSalesError: null,
    ccCustomFormulaSalesLoading:false,
    ccCustomFormulaSalesSuccess: null,
    ccCustomFormulaSalesError: null,
    ccMachineStatLoading:false,
    ccMachineStatSuccess: null,
    ccMachineStatError: null,
    ccBucketAnalyticsLoading:false,
    ccBucketAnalyticsSuccess: null,
    ccBucketAnalyticsError: null,
    ccBucketAnalyticsNewShopLoading:false,
    ccBucketAnalyticsNewShopSuccess: null,
    ccBucketAnalyticsNewShopError: null,
    ccBucketAnalyticsVolumeTintedLoading:false,
    ccBucketAnalyticsVolumeTintedSuccess: null,
    ccBucketAnalyticsVolumeTintedError: null,
    ccBucketAnalyticsLocationLoading:false,
    ccBucketAnalyticsLocationSuccess: null,
    ccBucketAnalyticsLocationError: null,
    ccBucketAnalyticsLocationBasedSalesLoading: false,
    ccBucketAnalyticsLocationBasedSalesSuccess: null,
    ccBucketAnalyticsLocationBasedSalesError: null,
    ccBucketAnalyticsColorantConsumptionLoading:false,
    ccBucketAnalyticsColorantConsumptionSuccess: null,
    ccBucketAnalyticsColorantConsumptionError: null,
    ccBucketAnalyticsScheduledReportDeliveryLoading:false,
    ccBucketAnalyticsScheduledReportDeliverySuccess: null,
    ccBucketAnalyticsScheduledReportDeliveryError: null,
    sendRequestLoading: false,
    sendRequestError:null,
    sendRequestSuccess: null,
  }
  
  export const analytics = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_ALL_ANALYTICS": {
        return { ...state, loading: true, error: null, createSuccess:null, posSuccess: null }
      }
      case "REQUEST_ALL_ANALYTICS_SUCCESS": {
        return { ...state, analytics: action.payload, error: null, loading: false, }
      }
      case "REQUEST_ALL_ANALYTICS_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }
      case "REQUEST_CREATE_ANALYTICS": {
        return { ...state, exportLoading: true, createError: null }
      }
      case "REQUEST_CREATE_ANALYTICS_SUCCESS": {
        return { ...state, exportLoading: false, createError: null, createSuccess: action.payload }
      }
      case "REQUEST_CREATE_ANALYTICS_FAILED": {
        return { ...state, exportLoading: false, createError: action.payload, createSuccess: null }
      }
      case "REQUEST_POS_LIST": {
        return { ...state, posLoading: true, posError: null }
      }
      case "REQUEST_POS_LIST_SUCCESS": {
        return { ...state, posLoading: false, posError: null, posSuccess: action.payload }
      }
      case "REQUEST_POS_LIST_FAILED": {
        return { ...state, posLoading: false, posError: action.payload, posSuccess: null }
      }
      case "DOWNLOAD_RESET": {
        return { ...state, 
          createError:null,
          createSuccess: null,
          exportLoading: false, }
      }
      case "REQUEST_CC_ANNUALSALESTREND": {
        return { ...state, 
          ccAnnualSalesTrendLoading:true,
          ccAnnualSalesTrendSuccess: null,
          ccAnnualSalesTrendError: null, }
      }
      case "REQUEST_CC_ANNUALSALESTREND_SUCCESS": {
        return { ...state, 
          ccAnnualSalesTrendLoading:false,
          ccAnnualSalesTrendSuccess: action.payload,
          ccAnnualSalesTrendError: null, }
      }
      case "REQUEST_CC_ANNUALSALESTREND_FAILED": {
        return { ...state, 
          ccAnnualSalesTrendLoading:false,
          ccAnnualSalesTrendSuccess: null,
          ccAnnualSalesTrendError: action.payload, }
      }
      case "REQUEST_CC_ANNUALSALESVOLUME": {
        return { ...state, 
          ccAnnualSalesVolumeLoading:true,
          ccAnnualSalesVolumeSuccess: null,
          ccAnnualSalesVolumeError: null, }
      }
      case "REQUEST_CC_ANNUALSALESVOLUME_SUCCESS": {
        return { ...state, 
          ccAnnualSalesVolumeLoading:false,
          ccAnnualSalesVolumeSuccess: action.payload,
          ccAnnualSalesVolumeError: null, }
      }
      case "REQUEST_CC_ANNUALSALESVOLUME_FAILED": {
        return { ...state, 
          ccAnnualSalesVolumeLoading:false,
          ccAnnualSalesVolumeSuccess: null,
          ccAnnualSalesVolumeError: action.payload, }
      }
      case "REQUEST_CC_TOPTENCOLORS": {
        return { ...state, 
          ccTopTenColorsLoading:true,
          ccTopTenColorsSuccess: null,
          ccTopTenColorsError: null, }
      }
      case "REQUEST_CC_TOPTENCOLORS_SUCCESS": {
        return { ...state, 
          ccTopTenColorsLoading:false,
          ccTopTenColorsSuccess: action.payload,
          ccTopTenColorsError: null, }
      }
      case "REQUEST_CC_TOPTENCOLORS_FAILED": {
        return { ...state, 
          ccTopTenColorsLoading:false,
          ccTopTenColorsSuccess: null,
          ccTopTenColorsError: action.payload, }
      }
      case "REQUEST_CC_TOPTENPRODUCTS": {
        return { ...state, 
          ccTopTenProductsLoading:true,
          ccTopTenProductsSuccess: null,
          ccTopTenProductsError: null, }
      }
      case "REQUEST_CC_TOPTENPRODUCTS_SUCCESS": {
        return { ...state, 
          ccTopTenProductsLoading:false,
          ccTopTenProductsSuccess: action.payload,
          ccTopTenProductsError: null, }
      }
      case "REQUEST_CC_TOPTENPRODUCTS_FAILED": {
        return { ...state, 
          ccTopTenProductsLoading:false,
          ccTopTenProductsSuccess: null,
          ccTopTenProductsError: action.payload, }
      }
      case "REQUEST_CC_TOPCANSALES": {
        return { ...state, 
          ccTopCanSalesLoading:true,
          ccTopCanSalesSuccess: null,
          ccTopCanSalesError: null, }
      }
      case "REQUEST_CC_TOPCANSALES_SUCCESS": {
        return { ...state, 
          ccTopCanSalesLoading:false,
          ccTopCanSalesSuccess: action.payload,
          ccTopCanSalesError: null, }
      }
      case "REQUEST_CC_TOPCANSALES_FAILED": {
        return { ...state, 
          ccTopCanSalesLoading:false,
          ccTopCanSalesSuccess: null,
          ccTopCanSalesError: action.payload, }
      }
      case "REQUEST_CC_CUSTOM_FORMULA_SALES": {
        return { ...state, 
          ccCustomFormulaSalesLoading:true,
          ccCustomFormulaSalesSuccess: null,
          ccCustomFormulaSalesError: null, }
      }
      case "REQUEST_CC_CUSTOM_FORMULA_SALES_SUCCESS": {
        return { ...state, 
          ccCustomFormulaSalesLoading:false,
          ccCustomFormulaSalesSuccess: action.payload,
          ccCustomFormulaSalesError: null, }
      }
      case "REQUEST_CC_CUSTOM_FORMULA_SALES_FAILED": {
        return { ...state, 
          ccCustomFormulaSalesLoading:false,
          ccCustomFormulaSalesSuccess: null,
          ccCustomFormulaSalesError: action.payload, }
      }
      case "REQUEST_CC_MACHINE_STAT": {
        return { ...state, 
          ccMachineStatLoading:true,
          ccMachineStatSuccess: null,
          ccMachineStatError: null, }
      }
      case "REQUEST_CC_MACHINE_STAT_SUCCESS": {
        return { ...state, 
          ccMachineStatLoading:false,
          ccMachineStatSuccess: action.payload,
          ccMachineStatError: null, }
      }
      case "REQUEST_CC_MACHINE_STAT_FAILED": {
        return { ...state, 
          ccMachineStatLoading:false,
          ccMachineStatSuccess: null,
          ccMachineStatError: action.payload, }
      }

      case "REQUEST_CC_BUCKET_ANALYTICS": {
        return { ...state, 
          ccBucketAnalyticsLoading:true,
          ccBucketAnalyticsSuccess: null,
          ccBucketAnalyticsError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsLoading:false,
          ccBucketAnalyticsSuccess: action.payload,
          ccBucketAnalyticsError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_FAILED": {
        return { ...state, 
          ccBucketAnalyticsLoading:false,
          ccBucketAnalyticsSuccess: null,
          ccBucketAnalyticsError: action.payload, }
      }

      case "REQUEST_CC_BUCKET_ANALYTICS_NEW_SHOP": {
        return { ...state, 
          ccBucketAnalyticsNewShopLoading:true,
          ccBucketAnalyticsNewShopSuccess: null,
          ccBucketAnalyticsNewShopError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_NEW_SHOP_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsNewShopLoading:false,
          ccBucketAnalyticsNewShopSuccess: action.payload,
          ccBucketAnalyticsNewShopError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_NEW_SHOP_FAILED": {
        return { ...state, 
          ccBucketAnalyticsNewShopLoading:false,
          ccBucketAnalyticsNewShopSuccess: null,
          ccBucketAnalyticsNewShopError: action.payload, }
      }

      case "REQUEST_CC_BUCKET_ANALYTICS_VOLUME_TINTED": {
        return { ...state, 
          ccBucketAnalyticsVolumeTintedLoading:true,
          ccBucketAnalyticsVolumeTintedSuccess: null,
          ccBucketAnalyticsVolumeTintedError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_VOLUME_TINTED_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsVolumeTintedLoading:false,
          ccBucketAnalyticsVolumeTintedSuccess: action.payload,
          ccBucketAnalyticsVolumeTintedError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_VOLUME_TINTED_FAILED": {
        return { ...state, 
          ccBucketAnalyticsVolumeTintedLoading:false,
          ccBucketAnalyticsVolumeTintedSuccess: null,
          ccBucketAnalyticsVolumeTintedError: action.payload, }
      }

      case "REQUEST_CC_BUCKET_ANALYTICS_LOCATION": {
        return { ...state, 
          ccBucketAnalyticsLocationLoading:true,
          ccBucketAnalyticsLocationSuccess: null,
          ccBucketAnalyticsLocationError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsLocationLoading:false,
          ccBucketAnalyticsLocationSuccess: action.payload,
          ccBucketAnalyticsLocationError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_FAILED": {
        return { ...state, 
          ccBucketAnalyticsLocationLoading:false,
          ccBucketAnalyticsLocationSuccess: null,
          ccBucketAnalyticsLocationError: action.payload, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_BASED_SALES": {
        return { ...state, 
          ccBucketAnalyticsLocationBasedSalesLoading:true,
          ccBucketAnalyticsLocationBasedSalesSuccess: null,
          ccBucketAnalyticsLocationBasedSalesError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_BASED_SALES_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsLocationBasedSalesLoading:false,
          ccBucketAnalyticsLocationBasedSalesSuccess: action.payload,
          ccBucketAnalyticsLocationBasedSalesError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_LOCATION_BASED_SALES_FAILED": {
        return { ...state, 
          ccBucketAnalyticsLocationBasedSalesLoading:false,
          ccBucketAnalyticsLocationBasedSalesSuccess: null,
          ccBucketAnalyticsLocationBasedSalesError: action.payload, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION": {
        return { ...state, 
          ccBucketAnalyticsColorantConsumptionLoading:true,
          ccBucketAnalyticsColorantConsumptionSuccess: null,
          ccBucketAnalyticsColorantConsumptionError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsColorantConsumptionLoading:false,
          ccBucketAnalyticsColorantConsumptionSuccess: action.payload,
          ccBucketAnalyticsColorantConsumptionError: null, }
      }
      case "REQUEST_CC_BUCKET_ANALYTICS_COLORANT_CONSUMPTION_FAILED": {
        return { ...state, 
          ccBucketAnalyticsColorantConsumptionLoading:false,
          ccBucketAnalyticsColorantConsumptionSuccess: null,
          ccBucketAnalyticsColorantConsumptionError: action.payload, }
      }
      case "REQUEST_SCHEDULED_REPORT_DELIVERY": {
        return { ...state, 
          ccBucketAnalyticsScheduledReportDeliveryLoading:true,
          ccBucketAnalyticsScheduledReportDeliverySuccess: null,
          ccBucketAnalyticsScheduledReportDeliveryError: null, }
      }
      case "REQUEST_SCHEDULED_REPORT_DELIVERY_SUCCESS": {
        return { ...state, 
          ccBucketAnalyticsScheduledReportDeliveryLoading:false,
          ccBucketAnalyticsScheduledReportDeliverySuccess: action.payload,
          ccBucketAnalyticsScheduledReportDeliveryError: null, }
      }
      case "REQUEST_SCHEDULED_REPORT_DELIVERY_FAILED": {
        return { ...state, 
          ccBucketAnalyticsScheduledReportDeliveryLoading:false,
          ccBucketAnalyticsScheduledReportDeliverySuccess: null,
          ccBucketAnalyticsScheduledReportDeliveryError: action.payload, }
      }
      case "SEND_REQUEST": {
        return { ...state, sendRequestLoading: true,sendRequestError:null,sendRequestSuccess: null }
      }
      case "SEND_SUCCESS": {
        return { ...state, sendRequestError:null,sendRequestLoading: false,sendRequestSuccess: action.payload}
      }
      case "SEND_FAILURE": {
        return { ...state, sendRequestLoading: false,sendRequestError:action.payload, sendRequestSuccess: null}
      }
      default: {
        return state
      }
    }
  }
  