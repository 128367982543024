import React from 'react'
import { AppHeader,SideBar,AppFooter } from 'components'
import { styled } from '@mui/system';

const DashboardRoot = styled('div')(({ theme }) => ({
  backgroundColor:"#F3F3F4",
  width:"100%",
  height:"100%",
  "& .main-content":{
    paddingLeft:350,
  },
  '& .children-container':{
    minHeight:"calc(100vh - 179px)",      //119+60
    padding:"100px 30px 60px 10px",
  },
  [theme.breakpoints.down('xl')]: {
    "& .main-content":{
      paddingLeft:250,
    },
    '& .children-container':{
      padding:"100px 20px 50px 30px",
      minHeight:"calc(100vh - 160px)" ,
    }
  },
  [theme.breakpoints.up('xl')]: {
    "& .main-content":{
      paddingLeft:350,
    },
    '& .children-container':{
      padding:"120px 20px 50px 30px",
      minHeight:"calc(100vh - 160px)" ,
    }
  }
}));

export default function DashboardLayout({children, pageLoading}) {
  return (
    <DashboardRoot >
      <AppHeader pageLoading={pageLoading}/>
      <div className='main-content'>
        <SideBar />
        <div className='children-container'>
          {children}
        </div>
       
      </div>
      <AppFooter />
    </DashboardRoot>
  )
}
