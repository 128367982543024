import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";

export const queryResolvedAction = (user, pk, hmac) => {
  
  return dispatch => {
    dispatch({
      type: "QUERY_RESOLVE_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.resolveduserQuery + "?token=" + user + "&hmac=" + hmac + "&pk=" + pk, config)
      .then(response => {
        dispatch({
          type: "QUERY_RESOLVE_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: "QUERY_RESOLVE_FAILURE",
          payload: err.response.data
        })
      })
  }
}