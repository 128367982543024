const initalState = {
    loading: false,
    error:null,
    success: false
  }
  
  export const reset = (state = initalState, action) => {
    switch (action.type) {
      case "RESET_REQUEST": {
        return { ...state, loading: true,error:null,success: false }
      }
      case "RESET_SUCCESS": {
        return { ...state, error:null,loading: false,success: true }
      }
      case "RESET_FAILURE": {
        return { ...state, loading: false,error:action.payload, success: false}
      }
      case "RESET_REQUEST_RESET": {
        return { ...state, loading: false,error:null, success: false}
      }
      default: {
        return state
      }
    }
  }