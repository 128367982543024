import { history } from "../../../history"
import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";

 export const loginWithJWT = values => {

  return dispatch => {
    dispatch({
      type: "LOGIN_WITH_EMAIL_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.login, {
        email: values.email,
        password: values.password,
        your_email: values.yourEmail,
        otp : values.otp
      }, config)
      .then(response => {
        dispatch({
          type: "LOGIN_WITH_EMAIL_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        if(err.response.status === 0 || err.response.status === 500){
          dispatch({
            type: "LOGIN_WITH_EMAIL_FAILED_STATUS",
            payload: err.response.status
          })
        } 
        dispatch({
          type: "LOGIN_WITH_EMAIL_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const accessRoot = values => {
  return dispatch => {
    dispatch({
      type: "ACCESS_OF_ROOT_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.verifyRoot, {
        email: values.email,
      }, config)
      .then(response => {
        dispatch({
          type: "ACCESS_OF_ROOT_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        console.log(err)
        if(err.response.status === 0 || err.response.status === 500|| err.response.status === 400){
          dispatch({
            type: "ACCESS_OF_ROOT_FAILED_STATUS",
            payload: err.response.status
          })
        } 
        dispatch({
          type: "ACCESS_OF_ROOT_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const verifyaccessRootOTP = values => {
  // console.log(payload.userEmail)
  // console.log(values)
  return dispatch => {
    dispatch({
      type: "CHECK_OTP_FOR_ROOT_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.otpToAccessRoot, {
        email: values.email,
        password: values.password,
        useremail: values.yourEmail,
      }, config)
      .then(response => {
        dispatch({
          type: "CHECK_OTP_FOR_ROOT_SUCCESS", 
          payload: response.data
        });
      })
      .catch(err => {
        if(err.response.status === 0 || err.response.status === 500|| err.response.status === 400){
          dispatch({
            type: "CHECK_OTP_FOR_ROOT_FAILED_STATUS",
            payload: err.response.status
          })
        } 
        dispatch({
          type: "CHECK_OTP_FOR_ROOT_FAILED",
          payload: err.response.data.error
        })
        console.log(err)
      })
  }
}


export const accessRootOTP = values => {
  return dispatch => {
    dispatch({
      type: "ACCESS_OF_OTP_FOR_ROOT_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.otpToAccessRoot, {
        email: values.email,
        password: values.password,
        useremail: values.yourEmail,
      }, config)
      .then(response => {
        dispatch({
          type: "ACCESS_OF_OTP_FOR_ROOT_SUCCESS", 
          payload: response.data
        });
      })
      .catch(err => {
        if(err.response.status === 0 || err.response.status === 500|| err.response.status === 400){
          dispatch({
            type: "ACCESS_OF_OTP_FOR_ROOT_FAILED_STATUS",
            payload: err.response.status
          })
        } 
        dispatch({
          type: "ACCESS_OF_OTP_FOR_ROOT_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT", payload: {} });
    history.push("/logout");
    window.location.reload();
  }
}

