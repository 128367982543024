import React from 'react'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { WhiteLayout } from 'layouts';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";

import { ReactComponent as Logo } from "assets/images/corob-logo-red.svg";

const useStyles = makeStyles(styles)

export default function Logout() {
  const {t} = useTranslation('common');
    const classes = useStyles()
    let navigate = useNavigate();
    setTimeout(
      () => navigate("/sign-in"),
      2000
    );
    const handleLoginClick = () => {
        navigate("/sign-in")
    }
  return (
    <WhiteLayout>
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <div className={classes.logoutText}>{t("common.youhavebeenloggedout")}</div>
            <Button onClick={handleLoginClick}>{t("signIn.login")} </Button>
        </div>
    </WhiteLayout>
  )
}
