const initalState = {
    loading: false,
    themes: null,
    error:null,
    createLoader: false,
    createThemesdata: null,
    createError: null,
    createError500: null,
    detailsLoader: false,
    detailsError: null,
    themeDetails: null,
    themeCreateVersionLoader: false,
    themeVersionCreateDetails: null,
    themeVersionCreateError: null,
    themeVersionCreateError500: null,
    deleteLoader: false,
    deleteError: null,
    deleteError500: null,
    deleteSuccess: null,
    createData: null,
    deleteThemeVersionSuccess: null,
    deleteThemeVersionError: null,
    deleteThemeVersionError500: null,
    deleteThemeVersionLoader: false,
    reloadThemeVersionLoader: false,
    reloadThemeVersionError: null,
    reloadThemeVersionError500: null,
    reloadThemeVersionSuccess: null,
    downloadThemeVersionSuccess: null,
    downloadThemeVersionLoader: false,
    downloadThemeVersionError: null,
    downloadThemeVersionError500: null,
  }
  
  export const themes = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_ALL_THEMES": {
        return { ...state, loading: true, error: null }
      }
      case "REQUEST_ALL_THEMES_SUCCESS": {
        return { ...state, themes: action.listpayload, error: null, loading: false, }
      }
      case "REQUEST_ALL_THEMES_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }

      case "REQUEST_CREATE_THEMES": {
        return { ...state, createLoader: true, createError: null }
      }
      case "REQUEST_CREATE_THEMES_SUCCESS": {
        return { ...state, createData:action.payload,  createError: null, createLoader: false, }
      }
      case "REQUEST_CREATE_THEMES_FAILED": {
        return { ...state, createLoader: false, createError: action.payload }
      }
      case "REQUEST_CREATE_THEMES_FAILED_500": {
        return { ...state, createLoader: false, createError500: action.payload }
      }


      case "REQUEST_DELETE_THEME": {
        return { ...state, deleteLoader: true, deleteError: null }
      }
      case "REQUEST_DELETE_THEME_SUCCESS": {
        return { ...state, deleteSuccess: action.payload,  deleteError: null, deleteLoader: false, }
      }
      case "REQUEST_DELETE_THEME_FAILED": {
        return { ...state, deleteLoader: false, deleteError: action.payload }
      }
      case "REQUEST_DELETE_THEME_FAILED_500": {
        return { ...state, deleteLoader: false, deleteError500: action.payload }
      }


      case "REQUEST_THEME_DETAILS": {
        return { ...state, detailsLoader: true, detailsError: null }
      }
      case "REQUEST_THEME_DETAILS_SUCCESS": {
        return { ...state, themeDetails: action.payload, detailsError: null, detailsLoader: false, }
      }
      case "REQUEST_THEME_DETAILS_FAILED": {
        return { ...state, detailsLoader: false, detailsError: action.payload }
      }


      case "REQUEST_CREATE_THEME_VERSION": {
        return { ...state, themeCreateVersionLoader: true, themeVersionCreateError: null }
      }
      case "REQUEST_CREATE_THEME_VERSION_SUCCESS": {
        return { ...state, themeVersionCreateDetails: action.payload, themeVersionCreateError: null, themeCreateVersionLoader: false, }
      }
      case "REQUEST_CREATE_THEME_VERSION_FAILED": {
        return { ...state, themeCreateVersionLoader: false, themeVersionCreateError: action.payload }
      }
      case "REQUEST_CREATE_THEME_VERSION_FAILED_500": {
        return { ...state, themeCreateVersionLoader: false, themeVersionCreateError500: action.payload }
      }

      case "REQUEST_DELETE_THEME_VERSION": {
        return { ...state, deleteThemeVersionLoader: true, deleteThemeVersionError: null }
      }
      case "REQUEST_DELETE_THEME_VERSION_SUCCESS": {
        return { ...state, deleteThemeVersionSuccess: action.payload,  deleteThemeVersionError: null, deleteThemeVersionLoader: false, }
      }
      case "REQUEST_DELETE_THEME_VERSION_FAILED": {
        return { ...state, deleteThemeVersionLoader: false, deleteThemeVersionError: action.payload }
      }
      case "REQUEST_DELETE_THEME_VERSION_FAILED_500": {
        return { ...state, deleteThemeVersionLoader: false, deleteThemeVersionError500: action.payload }
      }

      case "REQUEST_RELOAD_THEME_VERSION": {
        return { ...state, reloadThemeVersionLoader: true, reloadThemeVersionError: null }
      }
      case "REQUEST_RELOAD_THEME_VERSION_SUCCESS": {
        return { ...state, reloadThemeVersionSuccess: action.payload,  reloadThemeVersionError: null, reloadThemeVersionLoader: false, }
      }
      case "REQUEST_RELOAD_THEME_VERSION_FAILED": {
        return { ...state, reloadThemeVersionLoader: false, reloadThemeVersionError: action.payload }
      }
      case "REQUEST_RELOAD_THEME_VERSION_FAILED_500": {
        return { ...state, reloadThemeVersionLoader: false, reloadThemeVersionError500: action.payload }
      }

      case "REQUEST_DOWNLOAD_THEME_VERSION": {
        return { ...state, downloadThemeVersionLoader: true, downloadThemeVersionError: null }
      }
      case "REQUEST_DOWNLOAD_THEME_VERSION_SUCCESS": {
        return { ...state, downloadThemeVersionSuccess: action.payload,  downloadThemeVersionError: null, downloadThemeVersionLoader: false, }
      }
      case "REQUEST_DOWNLOAD_THEME_VERSION_FAILED": {
        return { ...state, downloadThemeVersionLoader: false, downloadThemeVersionError: action.payload }
      }
      case "REQUEST_DOWNLOAD_THEME_VERSION_FAILED_500": {
        return { ...state, downloadThemeVersionLoader: false, downloadThemeVersionError500: action.payload }
      }

      default: {
        return state
      }
    }
  }
  