import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery } from "@mui/material";
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { CustomTextField } from 'elements';
import {Grid} from "@mui/material";
import { useFormik } from "formik";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MessageLogo from "assets/images/message.svg";
import {  string} from "yup";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import {RiErrorWarningLine} from "react-icons/ri";
import {AiFillCloseSquare} from "react-icons/ai";
import { IconButton } from '@mui/material';
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { useDispatch, useSelector } from "react-redux"
import { getAlluserWorkspaceUsers, createWorkspaceUser, editAllWorkspaceUser, deleteWorkspaceUser } from "../../redux/actions/workspace/userActions"
import {forgotWithJWT} from "../../redux/actions/auth/forgotActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import { getAllUsers } from "../../redux/actions/auth/allUserslistActions";

const useStyles = makeStyles(styles)

export default function AsignUserAction() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName:t('AsignUserAction.AsignUserAction'),
        link:"/dashboard/asign-user-action"
    }
  ]

  const thead = [
    {
      name:"email",
      displayName:t('AsignUserAction.Email'),
      type:"text",
      width:30,  //percentage
    },
    {
      name:"company_name",
      displayName:t('AsignUserAction.Company'),
      type:"text",
      width:20
    },
    {
      name:"workspaceAdmin",
      displayName:t('AsignUserAction.WorkspaceAdmin'),
      type:"boolean",
      width:9
    },
    {
      name:"databases",
      displayName:t('AsignUserAction.Databases'),
      type:"boolean",
      width:9
    },
    {
      name:"analytics",
      displayName:t('AsignUserAction.Analytics'),
      type:"boolean",
      width:9
    },
    {
      name:"themes",
      displayName:t('AsignUserAction.Themes'),
      type:"boolean",
      width:9
    },
    {
      name:"graphs",
      displayName:t('AsignUserAction.Graphs'),
      type:"boolean",
      width:9
    },
    {
      name:"actions",
      displayName:t('AsignUserAction.Actions'),
      type:"action",
      width:13
    },
  ]
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [btnOpen, setbtnOpen] = useState(false);
  const [Delete,setDelete] = useState(false);
  const [Key,setKey] = useState(false);
  const [checkBoxError,setCheckboxError] = useState();
  const [searchText, setSearchText] = useState("");

  const [userlist, setUserlist] = useState([]);

    const deleteClose = () =>setDelete(false);
    const keyClose = () => setKey(false);

    const [page, setPage] = useState(1);
    const [deleteItem, setDeleteItem] = useState(null);
    const [allusersCount, setAllUsersCount] = useState(0);
    const [originalPermission, setOriginalPermission] = useState();

    const [companyId, setCompanyId] = useState();

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { loading,
    workspaceusers,
    createLoading,
    createError,
    createSuccess,
    updateLoading,
    updateSuccess,
    deleteSuccess,
    deleteLoading,
    createError500,
    updateError500, 
    deleteError500,
    error
  } = useSelector((state) => state.workspace.user);
  const { user } = useSelector((state) => state.auth.login);
   
  const forgot = useSelector((state) => state.auth.forgot);

  
  const handleOpen = () => {
    if(user){
      formik.setFieldValue("company", user.company_name)
      setCompanyId(user.company)
    }
    setOpen(true)
  };
  

  useEffect(() => {  
    let workspaceusersList = []
    if(workspaceusers?.data){
      (workspaceusers.data).forEach(function(item){
        if(item.groups.admin === true){
          workspaceusersList.push({
            id: item.id,
            email: item.email,
            company_name: item.company_name,
            workspaceAdmin: item.groups.admin,
            databases: item.groups.database,
            analytics: item.groups.analytics,
            themes: item.groups.themes,
            graphs: item.groups.graph,
            role: item.groups.admin?'admin':'user'
          })
        }
      })
      setUserlist(workspaceusersList)
      setAllUsersCount(workspaceusers.count)
    }
  }, [workspaceusers])



  const handleRowClick = (item) => () => {
    //
  }

  const handleLockClick = (item) => () => {
    let values = {
      email: item.email,
      language: language.languageLabel === "italiano" ? "italian" : language.languageLabel === "English" ? "english" : "chinese" 
    }
    dispatch(forgotWithJWT(values)) 
    
  }

  useEffect(() => {  
    if(forgot.success){      
      setKey(true)
      dispatch({
        type: "RESET_FORGOT_REQUEST",
        payload: null
      })
    }
  }, [forgot.success, dispatch])
     
  const formik = useFormik({
    initialValues: {
    email: "",
    company: "",
    password: "",
    confirm: "",
    database: false,
    analytics: false,
    themes: false   
  },
  validationSchema: Yup.object({    
    email: string().required(t('AsignUserAction.Pleaseenteremail')).email(t('AsignUserAction.Invalidemail')),
    company: string().required(t('AsignUserAction.Pleaseentercompanyname')),
    password: string().required(t('AsignUserAction.Pleaseenterpassword')),
    confirm: string().oneOf([Yup.ref('password'), null], t('AsignUserAction.Passwordmustmatch')).required(t('AsignUserAction.Confirmpasswordisrequired')),                                                        
  }),
  onSubmit : (values, formikHelpers)=> {
    if(values.database === false && values.analytics === false && values.themes === false){   
      setCheckboxError(t('AsignUserAction.Permissionrequired'))
    }
    else{
      setCheckboxError()
      dispatch(createWorkspaceUser(values, companyId)) 
    }
    
 }
  });

  const editFormik = useFormik({
    initialValues: {
      editdatabase: false,
      editanalytics: false,
      editthemes: false,  
      editgraphs: false
  },
  onSubmit : (values, formikHelpers)=> {
    if(originalPermission.themes === values.editthemes && originalPermission.analytics === values.editanalytics && originalPermission.databases === values.editdatabase && originalPermission.graphs === values.editgraphs){
      toast.error(t('AsignUserAction.Nochangesweremade'), {
        duration: 6000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
    }
    else{
        if(values.editdatabase === false && values.editanalytics === false && values.editthemes === false){
          setCheckboxError(t('AsignUserAction.Permissionrequired'))
        }
        else{
          setCheckboxError()
          dispatch(editAllWorkspaceUser(values)) 
        }
    }
    
  },
  
  });

  const buttonClose = () => {
    setOriginalPermission()
    editFormik.resetForm()
    setCheckboxError()
    setbtnOpen(false)
  };

  const handleClose = () => {
    formik.resetForm()
    setCheckboxError()
    setOpen(false)
  };

  const onSearchChange = (e) => {
    setPage(1)
    setSearchText(e.target.value)
  }

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
      search: searchText
    }  
    dispatch(getAlluserWorkspaceUsers(payload)) 
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
      search: searchText
    } 
    
    dispatch(getAlluserWorkspaceUsers(payload))  
    if(createSuccess?.status === "Success"){
      setOpen(false)
      Swal.fire({
        title: t('AsignUserAction.Success'),
        text: t('AsignUserAction.AccountCreated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      formik.resetForm()
    }

    if(updateSuccess?.status === "success"){
      setbtnOpen(false)
      Swal.fire({
        title: t('AsignUserAction.Success'),
        text: t('AsignUserAction.AccountUpdated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      
    }

  },[page, searchText, createSuccess,updateSuccess,dispatch, t]);


  useEffect(() => {  
  
    if(deleteSuccess){
      setDelete(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAlluserWorkspaceUsers(payload)) 
      Swal.fire({
        title: t('AsignUserAction.Success'),
        text: t('AsignUserAction.AccountDeleted'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }

  },[deleteSuccess, dispatch, t]);

  useEffect(() => {  
    if(createError){
      Swal.fire({
        title: 'Error',
        text: createError.message.email[0] === "user with this email address already exists."? t('AsignUserAction.userwiththisemailaddressalreadyexists'): null,
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',
        
      })
      dispatch({
        type: "CREATE_REQUEST_WORKSPACEUSER_FAILED",
        payload: null
      })
    }

  },[createError, dispatch, t]);


  const handleDeleteClick = (item) => () => {
    setDelete(true)
    setDeleteItem(item)
  }

  const deleteConfirm = () => {    
    dispatch(deleteWorkspaceUser({id: deleteItem.id}))
  }

  const handleEditClick = (item) => () => {
    setOriginalPermission({
      themes: item.themes,
      databases: item.databases,
      analytics: item.analytics,
      graphs: item.graphs
    })
    editFormik.values.id = item.id;
    if(item.databases){
      editFormik.values.editdatabase = true;
    }
    else{
      editFormik.values.editdatabase = false;
    }
    if(item.analytics){
      editFormik.values.editanalytics = true;
    }
    else{
      editFormik.values.editanalytics = false;
    }
    if(item.themes){
      editFormik.values.editthemes = true;
    }
    else{
      editFormik.values.editthemes = false;
    }
    if(item.graphs){
      editFormik.values.editgraphs = true;
    }
    else{
      editFormik.values.editgraphs = false;
    }
    setbtnOpen(true)
  }

  useEffect(() => {  
    if(createError500){
      toast.error(t('AsignUserAction.userCreateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "CREATE_REQUEST_WORKSPACEUSER_FAILED_500",
        payload: null
      })
    }

    if(updateError500){
      toast.error(t('AsignUserAction.userUpdateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "UPDATE_REQUEST_WORKSPACEUSER_FAILED_500",
        payload: null
      })
    }

    if(deleteError500){
      toast.error(t('AsignUserAction.userDeleteFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "DELETE_REQUEST_WORKSPACEUSER_FAILED_500",
        payload: null
      })
    }
    
  }, [createError500, updateError500, deleteError500, dispatch])
 

  const headerActions = (
    <Button onClick={handleOpen}><PlusIcon /> {t('AsignUserAction.CreateNew')}</Button>
  )
  

  const bigHtmlContent = (
    <>
    <form onSubmit={formik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('AsignUserAction.AsignUserAction')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <CustomTextField
          label={t('AsignUserAction.Email')}
          name="email"
          type="name"
          placeholder="Email"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.email} 
          error = {formik.touched.email && Boolean(formik.errors.email)} 
          helperText = {formik.touched.email && formik.errors.email}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          handleDeleteClick={handleDeleteClick}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          disableField
          label={t('AsignUserAction.Company')}
          name="company"
          type="name"
          placeholder="Company"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.company} 
          error = {formik.touched.company && Boolean(formik.errors.company)} 
          helperText = {formik.touched.company && formik.errors.company}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('AsignUserAction.Password')}
          name="password"
          type="password"
          placeholder="Password"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.password} 
          error = {formik.touched.password && Boolean(formik.errors.password)} 
          helperText = {formik.touched.password && formik.errors.password}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('AsignUserAction.ConfirmPassword')}
          name="confirm"
          type="password"
          placeholder="Confirm Password"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.confirm} 
          error = {formik.touched.confirm && Boolean(formik.errors.confirm)} 
          helperText = {formik.touched.confirm && formik.errors.confirm}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
    </Grid>
    
                <div className={classes.permission}>
                {t('AsignUserAction.Permissions')} *
                </div> 
                 
                {((user.is_colordb_editor) === true) &&
                (<FormControlLabel  name="database" value="database" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('AsignUserAction.Databases')}/> )
                }
                {((user.is_historians) === true) &&
                (<FormControlLabel  name="analytics" value="analytics" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('AsignUserAction.Analytics')}/>)
                }
                {((user.is_theme_editor) === true) &&
                (<FormControlLabel  name="themes" value="themes" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('AsignUserAction.Themes')}/>)
              }
                  <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
              <div>                   
                <Button  
                className='appBtn'
                  size="meduim"
                  type="submit"
                >
                  {createLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('AsignUserAction.Create')}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )

  

  const bigHtmlContent1 = (
    <>
    <form onSubmit={editFormik.handleSubmit}>
    <div className={classes.head}>{t('AsignUserAction.EditWorkspaceUser')}</div>
    <Divider className={classes.divide} />
      <div className={classes.permission}>
      {t('AsignUserAction.Permissions')} *
      </div><FormControlLabel name="editdatabase" value="database" onChange={editFormik.handleChange} checked={editFormik.values.editdatabase} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('AsignUserAction.Databases')} /><FormControlLabel name="editanalytics" value="analytics" onChange={editFormik.handleChange} checked={editFormik.values.editanalytics} control={<Checkbox style={{ color: "#C8102E" }}  />}
          label={t('AsignUserAction.Analytics')} /><FormControlLabel name="editthemes" value="themes" onChange={editFormik.handleChange} checked={editFormik.values.editthemes} control={<Checkbox style={{ color: "#C8102E" }} />}
            label={t('AsignUserAction.Themes')} /><FormControlLabel name="editgraphs" value="graphs" onChange={editFormik.handleChange} checked={editFormik.values.editgraphs} control={<Checkbox style={{ color: "#C8102E" }} />}
            label={t('AsignUserAction.Graphs')} /><div>
        <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
       <div className={classes.button}>
                  <Button  
                  onClick={buttonClose}
                  className="appBtnCancel"                                    
                    size="large"
                  >
                     {t('AsignUserAction.Cancel')}
                  </Button>
                  <div className={classes.update}>
                      <Button 
                       className='appBtn'                        
                        size="large"
                        type="submit"
                      >
                       {updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('AsignUserAction.Update')}  
                      </Button>
                  </div>
          </div>
      </div>
      </form>
    </>                            
  )

  const bigHtmlContent2 = (
    
   <>
        <div className={classes.logo}>
           <img src={MessageLogo} alt='logo' /> 
        </div>
        <div className={classes.text1}>                                    
           {t('AsignUserAction.Wehavesentapasswordresetlink')}                     
        </div>
        <div className={classes.button1}>
                    <Button
                    className='appBtn'  
                     onClick={keyClose}
                     size="medium"
                    >
                        {t('AsignUserAction.OK')}   
                    </Button>
        </div> 
  
      </>
  )

  const bigHtmlContent3 = (
    <>
      <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('AsignUserAction.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('AsignUserAction.Youareabouttodeleteaworkspaceuser')}
              
      </div>
      
      <div className={classes.button2}>
       
              <Button  
              className="appBtnCancel"  
                onClick={deleteClose}                         
                size="large"
              >
                 {t('AsignUserAction.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={deleteConfirm}
                  >
                    {deleteLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('AsignUserAction.OK')} 
                  </Button>
              </div>
           
      </div>
     
    </>     
  )         
  
  return (
    <DashboardLayout pageLoading={forgot.loading}>
      <Toaster />
        <BreadCrumb linkData={linkData}/>
        <CustomTable
            search={true} 
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('AsignUserAction.AsignUserAction')}
            thead={thead}
            tdata={userlist}
            maxRow={10}
            handleRowClick={handleRowClick}
            height={isSmallerDesktop?683:813}
            action={
                    {
                      admin:["edit"],
                      default:["impersonate"]
                    }
                  }
            onChange={onSearchChange}
                  value={searchText}
                  searchLabel={t('AllUsers.SearchUsers')}
            handleEditClick={handleEditClick}
            loadPagination={setPage}
            currentPage={page}
            clickableColumn={-1}
            errorStatus={error}
            errorBlockBtnClick={errorBlockBtnClick}
        />
    <Modals 
      handleclose={buttonClose} 
      open={btnOpen} 
      content={bigHtmlContent1}                   
    />
    </DashboardLayout>
    
  )
}
