const styles = (theme) => ({
    helperText:{
        backgroundColor:"transparent",
        color:props => `${props.helpertextcolor} !important`
    },
    inputAdornment:{
        backgroundColor:"transparent",
        width:"100%",
        height:"100%",
        flex:1
    },
    iconColor:{
        color:props => `${props.inputtextcolor} !important`,
        fill:props => `${props.inputtextcolor} !important`,
    }
    
})
export default styles