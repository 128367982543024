
const styles = (theme) => ({
    container:{
        width:"100%",
        height:"100%",
        display:"flex",
    },
    firstContainer:{
        backgroundColor:"#C8102E",
        padding: "10px 100px 10px 100px",
        [theme.breakpoints.down("xl")]:{
            padding: "10px 70px 10px 70px",
        },
        [theme.breakpoints.between("xl")]:{
            padding: "10px 110px 10px 110px",
        }
    },
    secondContainer:{
        flex:1,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    image:{
        maxWidth:739,
        maxHeight:587,
        [theme.breakpoints.down("xl")]:{
            maxWidth:500,
            maxHeight:400
        }
    },
    formContainer:{
        display:"flex",
        flexDirection:"column",
        width:"100%"
    },
    corobLogo:{
        marginTop: 10,
        [theme.breakpoints.down("xl")]:{
            width:150,
            height:50
        },
        [theme.breakpoints.up("xl")]:{
            width:220,
            height:120
        },
    }
})

export default styles;