import axios from "axios";

 export const getFrontEndRelease = user => {
  return dispatch => {
    dispatch({
      type: "GITHUB_FRONTEND_RELEASE_REQUEST"
    })
    const config = {
      headers: {        
        Authorization: `Token ${process.env.REACT_APP_GITHUB_VERSION_RELEASE_TOKEN}`
      },
    }
    axios
      .get("https://api.github.com/repos/corobsoftware/corob-cloud-frontend/releases", config)
      .then(response => {
        dispatch({
          type: "GITHUB_FRONTEND_RELEASE_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: "GITHUB_FRONTEND_RELEASE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getBackendRelease = user => {
    return dispatch => {
      dispatch({
        type: "GITHUB_BACKEND_RELEASE_REQUEST"
      })
      const config = {
        headers: {        
            Authorization: `Token ${process.env.REACT_APP_GITHUB_VERSION_RELEASE_TOKEN}`
        },
      }
      axios
        .get("https://api.github.com/repos/corobsoftware/corob-cloud-backend/releases", config)
        .then(response => {
          dispatch({
            type: "GITHUB_BACKEND_RELEASE_SUCCESS",
            payload: response.data
          });
        })
        .catch(err => { 
          dispatch({
            type: "GITHUB_BACKEND_RELEASE_FAILED",
            payload: err.response.data.error
          })
        })
    }
  }