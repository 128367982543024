const initalState = {
    frontEndloading: false,
    frontEndsuccess: false,
    frontEnderror:null,
    backEndloading: false,
    backEndsuccess: false,
    backEnderror:null,
  }
  
  export const github = (state = initalState, action) => {
    switch (action.type) {
        case "GITHUB_FRONTEND_RELEASE_REQUEST": {
            return { ...state, frontEndloading: true,frontEnderror:null,frontEndsuccess: false }
          }
          case "GITHUB_FRONTEND_RELEASE_SUCCESS": {
            return { ...state, frontEnderror:null,frontEndloading: false,frontEndsuccess: action.payload }
          }
          case "GITHUB_FRONTEND_RELEASE_FAILED": {
            return { ...state, frontEndloading: false,frontEnderror:action.payload, frontEndsuccess: false}
          }

          case "GITHUB_BACKEND_RELEASE_REQUEST": {
            return { ...state, backEndloading: true,backEnderror:null,backEndsuccess: false }
          }
          case "GITHUB_BACKEND_RELEASE_SUCCESS": {
            return { ...state, backEnderror:null,backEndloading: false,backEndsuccess: action.payload }
          }
          case "GITHUB_BACKEND_RELEASE_FAILED": {
            return { ...state, backEndloading: false,backEnderror:action.payload, backEndsuccess: false}
          }
      default: {
        return state
      }
    }
  }
  