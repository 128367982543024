import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

 export const getAllDatabases = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DATABASE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        'content-type': 'multipart/form-data',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.legacyDatabase + '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_DATABASE_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_DATABASE_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const createDatabase = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CREATE_DATABASE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.legacyDatabase,{
        name: payload.database,
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CREATE_DATABASE_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500 || err.response.status === 0){
          dispatch({
            type: "REQUEST_CREATE_DATABASE_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_CREATE_DATABASE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const deleteDatabase = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DELETE_DATABASE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.legacyDatabase + payload.id + "/", config)
      .then(response => {
        dispatch({
          type: "REQUEST_DELETE_DATABASE_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DELETE_DATABASE_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DELETE_DATABASE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getDatabaseDetails = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DATABASE_DETAILS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.legacyDbDetails + payload.bucket_code + "/" + '?limit=' + payload.limit + '&offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_DATABASE_DETAILS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_DATABASE_DETAILS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const uploadDb = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_UPLOAD_DATABASE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Content-Type":'multipart/form-data',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    var bodyFormData = new FormData();
    bodyFormData.append('blob', payload.blob);
    bodyFormData.append('colordb_id', payload.colordb_id);
    axios
      .post(api_settings.url_base + api_endpoints.legacyDbDetails + payload.colordb_id + "/",bodyFormData, config)
      .then(response => {
        dispatch({
          type: "REQUEST_UPLOAD_DATABASE_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_UPLOAD_DATABASE_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_UPLOAD_DATABASE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}


export const deleteDbVersion = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DATABASE_VERSION_DELETE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.legacyDbDetails + payload.id, config)
      .then(response => {
        dispatch({
          type: "REQUEST_DATABASE_VERSION_DELETE_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DATABASE_VERSION_DELETE_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DATABASE_VERSION_DELETE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const downloadDatabase = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DOWNLOAD_DB_VERSION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.legacyDbDownload  + payload + "/", config)
      .then(response => {
        dispatch({
          type: "REQUEST_DOWNLOAD_DB_VERSION_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DOWNLOAD_DB_VERSION_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DOWNLOAD_DB_VERSION_FAILED",
          payload: err.response.data
        })
      })
  }
}

export const downloadLog = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DOWNLOAD_LOG"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.legacyDownloadLog, config)
      .then(response => {
        dispatch({
          type: "REQUEST_DOWNLOAD_LOG_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_DOWNLOAD_LOG_FAILED",
          payload: err.response.data.error
        })
      })
  }
}





