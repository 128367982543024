const styles = (theme) => ({
   appBar:{
    
    padding: "15px 30px 15px 350px !important",
    boxShadow: "0px 2px 10px #00000029 !important",
    [theme.breakpoints.down("xl")]:{
        padding: "15px 30px 15px 250px !important",
        boxShadow: "0px 2px 10px #00000029 !important"
    },
   },
   toolBar:{
    width: '100%',
    display: 'flex',
    flex: 1,
    //justifyContent: 'space-between',
    alignItems: 'center',
   },
   leftContainer:{
      alignSelf:"flex-start",
      height:"100%",
      width:333,
      backgroundColor:"#C8102E",
      borderBottom: "1px solid #E40025",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      [theme.breakpoints.down("xl")]:{
         width:250,
      }
   },
   centerContainer:{
      
      width:"100%",
      maxWidth:512,
      height:"100%",
      [theme.breakpoints.down("xl")]:{
         maxWidth:350,
         marginLeft:30
      }

   },
   rightContainer:{
      display:"flex",
      flex:1,
      alignItems:"center",
      justifyContent:"flex-end"
   },
   logo:{
      width:200,
      height:60,
      [theme.breakpoints.down("xl")]:{
         width:170,
         height:50
      }
   },
   notificationicon:{
      width:"30px !imporatnt",
      height: "30px !imporatnt"
    },
   chipRoot:{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      backgroundColor:"#FFD8D3",
      borderRadius: 10,
      opacity: 1,
      marginLeft:36,
      color:"#C8102E",
      [theme.breakpoints.down("xl")]:{
         marginLeft:25,
         width:40,
      height:40,
      },
      [theme.breakpoints.up("xl")]:{
         width:50,
         height:50,
      },
   },
   userCard:{
      display:"flex",
      alignItems: 'center',
      marginLeft:25,
      [theme.breakpoints.down("xl")]:{
         marginLeft:40
      }
   },
   userInfo:{
      flex:1,
      display:"flex",
      flexDirection:"column"
   },
   icon:{
      alignSelf:"flex-start",
      color:"#36333D",
      marginLeft:8,
      [theme.breakpoints.down("xl")]:{
         marginLeft:3
      }
   },
   email:{
      fontFamily:"Roboto",
      fontSize:16,
      fontWeight:300,
      color:"#36333D",
      [theme.breakpoints.down("xl")]:{
         fontSize:14
      },
      [theme.breakpoints.up("xl")]:{
         fontSize:20
      }
   },
   name:{
      fontFamily:"Roboto",
      fontSize:16,
      fontWeight:500,
      color:"#36333D",
      marginBottom:5,
      [theme.breakpoints.down("xl")]:{
         fontSize:14
      },
      [theme.breakpoints.up("xl")]:{
         fontSize:20
      }
   },
})

export default styles