const styles = (theme) => ({
    head:{
        fontSize: 30,
        marginBottom: 20,
        fontFamily:"Roboto",
        fontWeight:"400",  
    },
    divide:{
        marginBottom:"20px !important"
    },
    buttons: {
        display: 'flex',
        
    },
    sub:{
        marginLeft: 20
    },
    warning:{
        textAlign: 'center',
        fontSize: 25,
        color:"#36333D",
        padding: "2%",
        fontFamily:"Roboto",
        fontWeight:"600",
        [theme.breakpoints.up('xl')]: {
            fontSize: 35,
        }
    },
    text:{
        textAlign: 'center',
        fontSize: 13,
        padding: "2%",
        fontFamily:"Roboto",
        fontWeight:"600",
        color:"#36333D",
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        }
    },
    span: {
        color: "#C8102E"
    },
    button2:{
        display:'flex',
        justifyContent: 'center'
    },
    logo1: {
        textAlign: 'center',
        padding: 10       
    },
    ok: {
        marginLeft: 10
    },
    colar: {
        backgroundColor: "#A2AAAD !important"
    }

})

export default styles