const initalState = {
    languageCode: "en",
    languageLabel: "English"
  }
  
  export const language = (state = initalState, action) => {
    switch (action.type) {
        case "LANGUAGE_CHANGE": {
            return { ...state,        
              languageCode:  action.payload.code,
              languageLabel:  action.payload.label }
        }

      default: {
        return state
      }
    }
  }
  