import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'


export const getAllRootAccessUsers = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_USER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.rootAccessUsers +  '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_USER_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        else{
          dispatch({
            type: "REQUEST_ALL_USER_FAILED",
            payload: err.response.status
          })
        }
        
      })
  }
}

export const editUserAccess = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "UPDATE_REQUEST_USERACCESS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.actionrootAccessUsers + payload.email + "/update/", {       
        "access": payload.access,
      }, config)
      .then(response => {
        dispatch({
          type: "UPDATE_REQUEST_USERACCESS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 500){
          dispatch({
            type: "UPDATE_REQUEST_USERACCESS_FAILED_500",
            payload: true
          })
        }
          dispatch({
            type: "UPDATE_REQUEST_USERACCESS_FAILED",
            payload: err.response.data.error
          })
        
      })
  }
}

export const deleteUserAccess = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "DELETE_REQUEST_USERACCESS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.actionrootAccessUsers + payload.email + "/delete/", config)
      .then(response => {
        dispatch({
          type: "DELETE_REQUEST_USERACCESS_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "DELETE_REQUEST_USERACCESS_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "DELETE_REQUEST_USERACCESS_FAILED",
          payload: err.response.data.error
        })
      })
  }
}


