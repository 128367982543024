
const styles = (theme) => ({
    container:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        backgroundColor:"#F3F3F4",
        width:"100%",
        height:"100%"
    },
    containerTop:{
        display:"flex",
        alignItems:"flex-start",
        justifyContent:"center",
        backgroundColor:"#F3F3F4",
        width:"100%",
        minHeight:"100%",
        padding: "50px 250px 50px 250px",
        [theme.breakpoints.up('xl')]: {
            padding:"50px 350px 50px 350px",
        }
    }
})

export default styles