const styles = (theme) => ({    
 
   text: {
      display:"flex",
      justifyContent:"center",
      textAlign: "center",
      marginTop:20
   },
   button: {
      display:"flex",
      justifyContent:"center",
      textAlign: "center"
   },
   

})

export default styles
