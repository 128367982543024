import React from 'react';
import { styled } from '@mui/system';
import {useTranslation} from "react-i18next";

const TryAagin = styled('div')(({ theme }) => ({
    background: '#c8102e',
    borderRadius: 5, 
    flex: 'none', 
    fontFamily: 'Roboto', 
    cursor: 'pointer', 
    marginLeft: 10,
    fontSize: 12,
    width: 'fit-content',
    color: '#fff',
    margin: 'auto',
    marginTop: 20,
    "&:hover":{
      background: "#eb314f",
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
    },
    [theme.breakpoints.up("xl")]:{
      fontSize:14,
    }
  })) 

export default function ErrorBlock({btnClick, height}) { 
  const {t} = useTranslation('common');
  return (
    <div style={{display: 'flex', justifyContent: 'center',transform:  height ? height :  'translateY(200%)', fontFamily: 'Roboto'}}>
        <div>
        {t('errorBlock.somethingWentWrong')}
            <TryAagin>
                <div onClick={btnClick}  style={{padding: 10}}>
                {t('errorBlock.tryagain')}
                </div>
            </TryAagin>
        </div>
    </div>
  )
}
