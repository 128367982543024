import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

 export const getAllAnalytics = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ANALYTICS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.legacyAnalytics + '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ANALYTICS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_ANALYTICS_FAILED",
          payload: err.response.data.error
        })
      })
  }
}


export const analyticsExporter = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ANALYTICS_EXPORTER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.legacyAnalytics + payload.id + "/",{
        start_date: payload.startdate,
        end_date: payload.enddate,
        pos_names: payload.poslist
      } , config)
      .then(response => {
        dispatch({
          type: "REQUEST_ANALYTICS_EXPORTER_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_ANALYTICS_EXPORTER_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_ANALYTICS_EXPORTER_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getAllPOS = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_POS_LIST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    let searchParam = ''
    if(payload.search !== " "){
      searchParam += '?search='+payload.search
    }
    axios
      .get(api_settings.url_base + api_endpoints.legacyAnalytics + payload.analyticsCode +'/' + searchParam, config)
      .then(response => {
        dispatch({
          type: "REQUEST_POS_LIST_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_POS_LIST_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getPaginationPOS = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_POS_LIST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }    
    axios
      .get(payload.page, config)
      .then(response => {
        dispatch({
          type: "REQUEST_POS_LIST_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_POS_LIST_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

