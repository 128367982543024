import React,{useEffect} from 'react'
import { Menu,MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as CaretDownIcon } from "assets/icons/caret-down.svg";
import {useTranslation} from "react-i18next";

const CustomButton = styled('span')(({ theme }) => ({    
    background: '#c8102e', 
    padding: 10, 
    borderRadius: 5, 
    fontFamily: 'Roboto', 
    cursor: 'pointer',
    color: '#fff',
    fontSize: 12,
    marginLeft: '2%',
    "&:hover":{
        background: "#eb314f",
        boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
    },
}))

const CustomMenu = styled(Menu)(({theme}) => ({
    "& .MuiMenu-paper":{
        width:121,
        marginTop:2,
        backgroundColor: "#FFFFFF !important",
        border: "1px solid #36333D !important",
        borderRadius: 5,
    },
    "& .MuiMenu-list":{
        color:"#36333D !important",
        fontFamily:"Roboto",
        fontSize:16,
        fontWeight:400,
    },
    [theme.breakpoints.down("xl")]:{
        "& .MuiMenu-paper":{
            width:111,
        },
        "& .MuiMenu-list":{
            fontSize:14
        },
     }
}))

export default function ButtonDropdown({label,options,annualSalesTrendSelectOption,selectedOption,selectedOptionClose, closeDropdownBtn, mouseOverEvent}) {
    const [anchorEl, setAnchorEl] = React.useState(null);    
    const {t} = useTranslation('common');
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {  
        if(open){
            setAnchorEl(null);
        }    
      }, [closeDropdownBtn, selectedOption, t])

    // const handleSelectOption = (option) => () => {
    //     handleClose();
    // };

  return (
    <React.Fragment>
        <CustomButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            onMouseOver={mouseOverEvent}
            endIcon={<KeyboardArrowDownIcon fontSize='large'/>}
        >
            {selectedOption?selectedOption:label} <CaretDownIcon style={{width:9}}/>
        </CustomButton>
        <CustomMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {options.map((item, keyval) => {
                return(
                    <MenuItem onClick={annualSalesTrendSelectOption(item)} key={keyval}>{item}</MenuItem>
                )
            })}
        </CustomMenu>
    </React.Fragment>
  )
}
