import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

export const getAllLocations = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_LOCATIONS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    let searchParam = ''
    if(payload.bucketItems !== " "){
      searchParam += ''+payload.bucketItems
    }
    axios
      .get(api_settings.url_base + api_endpoints.locations + searchParam + "?search=" + payload.search  + '&offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_LOCATIONS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_ALL_LOCATIONS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getAllBucketLocations = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_LOCATIONS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    let searchParam = ''
    if(payload.bucketItems !== " "){
      searchParam += ''+payload.bucketItems
    }
    axios
      .get(api_settings.url_base + api_endpoints.locations + searchParam + "?search=" + payload.search  + '&offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_LOCATIONS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_ALL_LOCATIONS_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getCCLocationAnalyticsInformation = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_INFORMATION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_locationInformation, {
        dealer_code: payload.dealer_code,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country:  payload.pos_country,
        bucket_id: payload.bucket_id
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_INFORMATION_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_INFORMATION_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsVolumeOrder = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_VOLUME_ORDER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_volumeOrder, {
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_VOLUME_ORDER_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_VOLUME_ORDER_FAILED",
          payload: err.response.status
          
        })
      })
  }
}


export const getCCLocationAnalyticTotalSales = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_TOTAL_SALES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_totalSales, {
        start_date: payload.start_date,
        end_date: payload.end_date,
        type: payload.type,
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOTAL_SALES_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOTAL_SALES_FAILED",
          payload: err.response.status
        })
      })
  }
}


export const getCCLocationAnalyticCustomFormula = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_CUSTOM_FORMULA"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_customFormula, {
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_CUSTOM_FORMULA_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_CUSTOM_FORMULA_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsTopTenColors = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_TEN_COLORS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_TopTenColors, {
        start_date: payload.start_date,
        end_date: payload.end_date,
        type: payload.type,
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_TEN_COLORS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_TEN_COLORS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsTopProducts = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_PRODUCTS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_TopProducts, {
        start_date: payload.start_date,
        end_date: payload.end_date,
        type: payload.type,
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_PRODUCTS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_PRODUCTS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsTopSubProducts = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_SUB_PRODUCTS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_TopSubProducts, {
        start_date: payload.start_date,
        end_date: payload.end_date,
        type: payload.type,
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_SUB_PRODUCTS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_SUB_PRODUCTS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsTopBases = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_BASES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_TopBases, {
        start_date: payload.start_date,
        end_date: payload.end_date,
        type: payload.type,
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_BASES_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_TOP_BASES_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsColorantConsumption = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_COLORANT_CONSUMPTION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_ColorantConsumption, {
        start_date: payload.start_date,
        end_date: payload.end_date,
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_COLORANT_CONSUMPTION_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_COLORANT_CONSUMPTION_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsAvgLocationFootfall = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_AVG_LOCATION_FOOTFALL"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_AvgLocationFootfall, {
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_AVG_LOCATION_FOOTFALL_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_AVG_LOCATION_FOOTFALL_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const getCCLocationAnalyticsMachineStat = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CC_LOCATION_ANALYTICS_MACHINE_STAT"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.cc_locationAnalytics_MachineStat, {
        bucket_id: payload.bucket_id,
        pos_name: payload.pos_name,
        pos_address: payload.pos_address,
        pos_country: payload.pos_country
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_MACHINE_STAT_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
       
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_CC_LOCATION_ANALYTICS_MACHINE_STAT_FAILED",
          payload: err.response.status
        })
      })
  }
}