import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery } from "@mui/material";
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { CustomTextField } from 'elements';
import {Grid} from "@mui/material";
import { useFormik } from "formik";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MessageLogo from "assets/images/message.svg";
import {  string} from "yup";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import {RiErrorWarningLine} from "react-icons/ri";
import {AiFillCloseSquare} from "react-icons/ai";
import { IconButton } from '@mui/material';
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { useDispatch, useSelector } from "react-redux"
import { getAllWorkspaceUsers, createWorkspaceUser, editWorkspaceUser, deleteWorkspaceUser } from "../../redux/actions/workspace/userActions"
import {userRequestPermissionAction} from "../../redux/actions/userActivation/userActivationActions"
import {forgotWithJWT} from "../../redux/actions/auth/forgotActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles(styles)

export default function WorkspaceUsers() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName:t('WorkspaceUsers.WorkspaceUsers'),
        link:"/dashboard/workspace-users"
    }
  ]

  const thead = [
    {
      name:"email",
      displayName:t('WorkspaceUsers.Email'),
      type:"text",
      width:30,  //percentage
    },
    {
      name:"company_name",
      displayName:t('WorkspaceUsers.Company'),
      type:"text",
      width:20
    },
    {
      name:"workspaceAdmin",
      displayName:t('WorkspaceUsers.WorkspaceAdmin'),
      type:"boolean",
      width:9
    },
    {
      name:"databases",
      displayName:t('WorkspaceUsers.Databases'),
      type:"boolean",
      width:9
    },
    {
      name:"analytics",
      displayName:t('WorkspaceUsers.Analytics'),
      type:"boolean",
      width:9
    },
    {
      name:"themes",
      displayName:t('WorkspaceUsers.Themes'),
      type:"boolean",
      width:9
    },
    {
      name:"graphs",
      displayName:t('AsignUserAction.Graphs'),
      type:"boolean",
      width:9
    },
    {
      name:"actions",
      displayName:t('WorkspaceUsers.Actions'),
      type:"action",
      width:13
    },
  ]
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [opennew, setnewOpen] = useState(false);
  const [btnOpen, setbtnOpen] = useState(false);
  const [Delete,setDelete] = useState(false);
  const [Key,setKey] = useState(false);
  const [checkBoxError,setCheckboxError] = useState();
  

  const [userlist, setUserlist] = useState([]);

    const deleteClose = () =>setDelete(false);
    const keyClose = () => setKey(false);

    const [page, setPage] = useState(1);
    const [deleteItem, setDeleteItem] = useState(null);
    const [allusersCount, setAllUsersCount] = useState(0);
    const [originalPermission, setOriginalPermission] = useState();

    const [companyId, setCompanyId] = useState();

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { loading,
    workspaceusers,
    createLoading,
    createError,
    createSuccess,
    updateLoading,
    updateSuccess,
    deleteSuccess,
    deleteLoading,
    createError500,
    updateError500, 
    deleteError500,
    error,
    sendNewError, 
    sendNewSuccess, 
    sendNewLoading
  } = useSelector((state) => state.workspace.user);
  const { user } = useSelector((state) => state.auth.login);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  const forgot = useSelector((state) => state.auth.forgot);
  
  const handleOpen = () => {
    if(user){
      formik.setFieldValue("company", user.company_name)
      setCompanyId(user.company)
    }
    setOpen(true)
  };

  const handlenewOpen = () => {
    setnewOpen(true)
  };
  

  useEffect(() => {  
    let workspaceusersList = []
    if(workspaceusers?.data){
      (workspaceusers.data).forEach(function(item){
        workspaceusersList.push({
          id: item.id,
          email: item.email,
          company_name: item.company_name,
          workspaceAdmin: item.groups.admin,
          databases: item.groups.database,
          analytics: item.groups.analytics,
          themes: item.groups.themes,
          graphs: item.groups.graph,
          role: item.groups.admin?'admin':'user'
        })
      })
      setUserlist(workspaceusersList)
      setAllUsersCount(workspaceusers.count)
    }
  }, [workspaceusers])
  

  const handleRowClick = (item) => () => {
    //
  }

  const handleLockClick = (item) => () => {
    let values = {
      email: item.email,
      language: language.languageLabel === "italiano" ? "italian" : language.languageLabel === "English" ? "english" : "chinese" 
    }
    dispatch(forgotWithJWT(values)) 
    
  }

  useEffect(() => {  
    if(forgot.success){      
      setKey(true)
      dispatch({
        type: "RESET_FORGOT_REQUEST",
        payload: null
      })
    }
  }, [forgot.success, dispatch])
     
  const formik = useFormik({
    initialValues: {
    firstName:"",
    lastName:"",
    email: "",
    company: "",
    password: "",
    confirm: "",
    database: false,
    analytics: false,
    themes: false,
    graphs: false  
  },
  validationSchema: Yup.object({
    firstName: string().required(t('WorkspaceUsers.firstNameValidation')),
    lastName: string().required(t('WorkspaceUsers.lastNameValidation')),
    email: string().required(t('WorkspaceUsers.Pleaseenteremail')).email(t('WorkspaceUsers.Invalidemail')),
    company: string().required(t('WorkspaceUsers.Pleaseentercompanyname')),
    password: string().required(t('WorkspaceUsers.Pleaseenterpassword')),
    confirm: string().oneOf([Yup.ref('password'), null], t('WorkspaceUsers.Passwordmustmatch')).required(t('WorkspaceUsers.Confirmpasswordisrequired')),                                                        
  }),
  onSubmit : (values, formikHelpers)=> {
    if(values.database === false && values.analytics === false && values.themes === false && values.graphs === false){   
      setCheckboxError(t('WorkspaceUsers.Permissionrequired'))
    }
    else{
      setCheckboxError()
      dispatch(createWorkspaceUser(values, companyId)) 
    }
    
 }
  });

  const permissionformik = useFormik({
    initialValues: {
    permissionDatabase: false,
    permissionAnalytics: false,
    permissionThemes: false,
    permissionGraphs: false, 
  },
  onSubmit : (values, formikHelpers)=> {
    if(values.permissionDatabase === false && values.permissionAnalytics === false && values.permissionThemes === false && values.permissionGraphs === false){   
      setCheckboxError(t('WorkspaceUsers.Permissionrequired'))
    }
    else if(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_historians === false && values.permissionAnalytics === false && values.permissionGraphs === true){
        setCheckboxError(t('WorkspaceUsers.Permissionrequired2'))
      }
    else if(user.is_historians === false && values.permissionAnalytics === false && values.permissionGraphs === true){
        setCheckboxError(t('WorkspaceUsers.Permissionrequired2'))
      }    
    else{
      setCheckboxError()
      if(impersonateUserInfo?.status === "success"){
        let permissionEmail = impersonateUserInfo.data.email
        dispatch(userRequestPermissionAction(values, permissionEmail))
      }
      else{
        let permissionEmail = user.email
        dispatch(userRequestPermissionAction(values, permissionEmail))
      }
    }
  }
  });

  const editFormik = useFormik({
    initialValues: {
      editdatabase: false,
      editanalytics: false,
      editthemes: false,
      editgraphs: false  
  },
  onSubmit : (values, formikHelpers)=> {
    if(originalPermission.themes === values.editthemes && originalPermission.analytics === values.editanalytics && originalPermission.databases === values.editdatabase && originalPermission.graphs === values.editgraphs){
      toast.error(t('WorkspaceUsers.Nochangesweremade'), {
        duration: 6000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
    }
    else{
        if(values.editdatabase === false && values.editanalytics === false && values.editthemes === false){
          setCheckboxError(t('WorkspaceUsers.Permissionrequired'))
        }
        else{
          setCheckboxError()
          dispatch(editWorkspaceUser(values)) 
        }
    }
    
  },
  
  });

  const buttonClose = () => {
    setOriginalPermission()
    editFormik.resetForm()
    setCheckboxError()
    setbtnOpen(false)
  };

  const handleClose = () => {
    formik.resetForm()
    setCheckboxError()
    setOpen(false)
  };

  const handlenewClose = () => {
    permissionformik.resetForm()
    setCheckboxError()
    setnewOpen(false)
  };

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllWorkspaceUsers(payload)) 
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllWorkspaceUsers(payload))  
    if(createSuccess?.status === "Success"){
      setOpen(false)
      Swal.fire({
        title: t('WorkspaceUsers.Success'),
        text: t('WorkspaceUsers.AccountCreated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      formik.resetForm()
    }

    if(updateSuccess?.status === "success"){
      setbtnOpen(false)
      Swal.fire({
        title: t('WorkspaceUsers.Success'),
        text: t('WorkspaceUsers.AccountUpdated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      
    }

    if(sendNewSuccess?.status === "success" && opennew === true){
      setnewOpen(false)
      Swal.fire({
        title: t('WorkspaceUsers.Success'),
        text: t('WorkspaceUsers.SendSucess'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      permissionformik.resetForm()
    }

  },[page, createSuccess,updateSuccess,sendNewSuccess, dispatch, t]);

  

  useEffect(() => {  
  
    if(deleteSuccess){
      setDelete(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllWorkspaceUsers(payload)) 
      Swal.fire({
        title: t('WorkspaceUsers.Success'),
        text: t('WorkspaceUsers.AccountDeleted'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }

  },[deleteSuccess, dispatch, t]);

  useEffect(() => {  
    if(createError){
      Swal.fire({
        title: 'Error',
        text: createError.message.email[0] === "user with this email address already exists."? t('WorkspaceUsers.userwiththisemailaddressalreadyexists'): null,
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',
        
      })
      dispatch({
        type: "CREATE_REQUEST_WORKSPACEUSER_FAILED",
        payload: null
      })
    }

  },[createError, dispatch, t]);


  const handleDeleteClick = (item) => () => {
    setDelete(true)
    setDeleteItem(item)
  }

  const deleteConfirm = () => {    
    dispatch(deleteWorkspaceUser({id: deleteItem.id}))
  }

  const handleEditClick = (item) => () => {
    setOriginalPermission({
      themes: item.themes,
      databases: item.databases,
      analytics: item.analytics,
      graphs: item.graphs
    })
    editFormik.values.id = item.id;
    if(item.databases){
      editFormik.values.editdatabase = true;
    }
    else{
      editFormik.values.editdatabase = false;
    }
    if(item.analytics){
      editFormik.values.editanalytics = true;
    }
    else{
      editFormik.values.editanalytics = false;
    }
    if(item.themes){
      editFormik.values.editthemes = true;
    }
    else{
      editFormik.values.editthemes = false;
    }
    if(item.graphs){
      editFormik.values.editgraphs = true;
    }
    else{
      editFormik.values.editgraphs = false;
    }
    setbtnOpen(true)
  }


  useEffect(() => {  
    if(createError500){
      toast.error(t('WorkspaceUsers.userCreateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "CREATE_REQUEST_WORKSPACEUSER_FAILED_500",
        payload: null
      })
    }

    if(updateError500){
      toast.error(t('WorkspaceUsers.userUpdateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "UPDATE_REQUEST_WORKSPACEUSER_FAILED_500",
        payload: null
      })
    }

    if(deleteError500){
      toast.error(t('WorkspaceUsers.userDeleteFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "DELETE_REQUEST_WORKSPACEUSER_FAILED_500",
        payload: null
      })
    }

    if(sendNewError && opennew === true){
      let sendNewerror = sendNewError['error'][0]
      if(sendNewerror === "email : Please attempt again in 24 hours!"){
        setnewOpen(false)
        Swal.fire({
          title: t('WorkspaceUsers.permissionError'),
          text: t('WorkspaceUsers.waitTosend'),
          icon: 'error',
          iconColor: '#C8102E',
          confirmButtonColor: '#C8102E'
        })
        permissionformik.resetForm()
      }
      else{
          toast.error(t('WorkspaceUsers.userCreateFailed') , {
          duration: 8000,
          position: 'top-right',
          style: {fontSize: 20, fontFamily: 'Roboto'}
        });
      }
      dispatch({
        type: "SEND_FAILURE",
        payload: null
      })
    }
    
  }, [createError500, updateError500, deleteError500, sendNewError, dispatch])
 

  let impersonateUserInfoData = impersonateUserInfo?.data
    const headerActions = (
      <>
      {impersonateUserInfo?.status === "success" && (impersonateUserInfoData.is_colordb_editor === false || 
        impersonateUserInfoData.is_historians === false || impersonateUserInfoData.is_theme_editor === false || 
        impersonateUserInfoData.is_workspace_graph === false) ?
        <Button onClick={handlenewOpen} variant="contained" startIcon={<SendIcon />}>{t('WorkspaceUsers.requestPermissions')}</Button>: null }
      {user.is_colordb_editor === false || user.is_historians === false || user.is_theme_editor === false || user.is_workspace_graph === false ?
        <Button onClick={handlenewOpen} variant="contained" startIcon={<SendIcon />}> {t('WorkspaceUsers.requestPermissions')}</Button>: null }
      <Button onClick={handleOpen} style={{marginLeft:30}}><PlusIcon /> {t('WorkspaceUsers.CreateNew')}</Button>
      </>
    )
    
  

  const bigHtmlContent = (
    <>
    <form onSubmit={formik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('WorkspaceUsers.WorkspaceUsers')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
    <Grid item xs={6}>
        <CustomTextField
          label={t('WorkspaceUsers.firstName')}
          name="firstName"
          type="name"
          placeholder="First Name"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.firstName} 
          error = {formik.touched.firstName && Boolean(formik.errors.firstName)} 
          helperText = {formik.touched.firstName && formik.errors.firstName}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('WorkspaceUsers.lastName')}
          name="lastName"
          type="name"
          placeholder="Last Name"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.lastName} 
          error = {formik.touched.lastName && Boolean(formik.errors.lastName)} 
          helperText = {formik.touched.lastName && formik.errors.lastName}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label={t('WorkspaceUsers.Email')}
          name="email"
          type="name"
          placeholder="Email"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.email} 
          error = {formik.touched.email && Boolean(formik.errors.email)} 
          helperText = {formik.touched.email && formik.errors.email}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          handleDeleteClick={handleDeleteClick}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          disableField
          label={t('WorkspaceUsers.Company')}
          name="company"
          type="name"
          placeholder="Company"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.company} 
          error = {formik.touched.company && Boolean(formik.errors.company)} 
          helperText = {formik.touched.company && formik.errors.company}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('WorkspaceUsers.Password')}
          name="password"
          type="password"
          placeholder="Password"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.password} 
          error = {formik.touched.password && Boolean(formik.errors.password)} 
          helperText = {formik.touched.password && formik.errors.password}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('WorkspaceUsers.ConfirmPassword')}
          name="confirm"
          type="password"
          placeholder="Confirm Password"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.confirm} 
          error = {formik.touched.confirm && Boolean(formik.errors.confirm)} 
          helperText = {formik.touched.confirm && formik.errors.confirm}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
    </Grid>
    
                <div className={classes.permission}>
                {t('WorkspaceUsers.Permissions')} *
                </div> 
                 
                {(user.is_colordb_editor === true) &&
                (<FormControlLabel  name="database" value="database" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Databases')}/> )
                }
                {(user.is_historians === true) &&
                (<FormControlLabel  name="analytics" value="analytics" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Analytics')}/>)
                }
                {( user.is_theme_editor === true) &&
                (<FormControlLabel  name="themes" value="themes" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Themes')}/>)
              }
              {(user.is_workspace_graph === true) &&
                (<FormControlLabel  name="graphs" value="graphs" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Graphs')}/>)
              }
                  <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
              <div>                   
                <Button  
                className='appBtn'
                  size="meduim"
                  type="submit"
                >
                  {createLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsers.Create')}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )
  
  const bigHtmlContentImp = (
    <>
    <form onSubmit={formik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('WorkspaceUsers.WorkspaceUsers')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <CustomTextField
          label={t('WorkspaceUsers.Email')}
          name="email"
          type="name"
          placeholder="Email"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.email} 
          error = {formik.touched.email && Boolean(formik.errors.email)} 
          helperText = {formik.touched.email && formik.errors.email}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          handleDeleteClick={handleDeleteClick}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          disableField
          label={t('WorkspaceUsers.Company')}
          name="company"
          type="name"
          placeholder="Company"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.company} 
          error = {formik.touched.company && Boolean(formik.errors.company)} 
          helperText = {formik.touched.company && formik.errors.company}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('WorkspaceUsers.Password')}
          name="password"
          type="password"
          placeholder="Password"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.password} 
          error = {formik.touched.password && Boolean(formik.errors.password)} 
          helperText = {formik.touched.password && formik.errors.password}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label={t('WorkspaceUsers.ConfirmPassword')}
          name="confirm"
          type="password"
          placeholder="Confirm Password"
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.confirm} 
          error = {formik.touched.confirm && Boolean(formik.errors.confirm)} 
          helperText = {formik.touched.confirm && formik.errors.confirm}
          height={45}
          labelColor="#000"
          labelTextSize={16}
          inputbackgroundcolor="#E8E8E8"
          inputtextcolor="#000"
          placeholdercolor="#A5A5A5"
          helpertextcolor="#C8102E" />
      </Grid>
    </Grid>
    
                <div className={classes.permission}>
                {t('WorkspaceUsers.Permissions')} *
                </div> 
                 
                {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_colordb_editor === true) &&
                (<FormControlLabel  name="database" value="database" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Databases')}/> )
                }
                {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_historians === true) &&
                (<FormControlLabel  name="analytics" value="analytics" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Analytics')}/>)
                }
                {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_theme_editor === true) &&
                (<FormControlLabel  name="themes" value="themes" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Themes')}/>)
              }
              {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_workspace_graph === true) &&
                (<FormControlLabel  name="graphs" value="graphs" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Graphs')}/>)
              }
                  <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
              <div>                   
                <Button  
                className='appBtn'
                  size="meduim"
                  type="submit"
                >
                  {createLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsers.Create')}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )
  

  const bigHtmlContent1 = (
    <>
    <form onSubmit={editFormik.handleSubmit}>
    <div className={classes.head}>{t('WorkspaceUsers.EditWorkspaceUser')}</div>
    <Divider className={classes.divide} />
      <div className={classes.permission}>
      {t('WorkspaceUsers.Permissions')} *
      </div>
      {(user.is_colordb_editor === true) &&
        (<FormControlLabel name="editdatabase" value="database" onChange={editFormik.handleChange} checked={editFormik.values.editdatabase} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('WorkspaceUsers.Databases')} />)
      }
      {(user.is_historians === true) &&
      (<FormControlLabel name="editanalytics" value="analytics" onChange={editFormik.handleChange} checked={editFormik.values.editanalytics} control={<Checkbox style={{ color: "#C8102E" }}  />}
          label={t('WorkspaceUsers.Analytics')} />)
        }
        {(user.is_theme_editor === true) &&
        (<FormControlLabel name="editthemes" value="themes" onChange={editFormik.handleChange} checked={editFormik.values.editthemes} control={<Checkbox style={{ color: "#C8102E" }} />}
            label={t('WorkspaceUsers.Themes')} />)
          }

        {(user.is_workspace_graph === true) &&
        (<FormControlLabel name="editgraphs" value="graphs" onChange={editFormik.handleChange} checked={editFormik.values.editgraphs} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('WorkspaceUsers.Graphs')} />)
          }
          <div>
        <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
       <div className={classes.button}>
                  <Button  
                  onClick={buttonClose}
                  className="appBtnCancel"                                    
                    size="large"
                  >
                     {t('WorkspaceUsers.Cancel')}
                  </Button>
                  <div className={classes.update}>
                      <Button 
                       className='appBtn'                        
                        size="large"
                        type="submit"
                      >
                       {updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsers.Update')}  
                      </Button>
                  </div>
          </div>
      </div>
      </form>
    </>                            
  )

  const bigHtmlContentImp1 = (
    <>
    <form onSubmit={editFormik.handleSubmit}>
    <div className={classes.head}>{t('WorkspaceUsers.EditWorkspaceUser')}</div>
    <Divider className={classes.divide} />
      <div className={classes.permission}>
      {t('WorkspaceUsers.Permissions')} *
      </div>
      {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_colordb_editor === true) &&
        (<FormControlLabel name="editdatabase" value="database" onChange={editFormik.handleChange} checked={editFormik.values.editdatabase} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('WorkspaceUsers.Databases')} />)
      }
      {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_historians === true) &&
      (<FormControlLabel name="editanalytics" value="analytics" onChange={editFormik.handleChange} checked={editFormik.values.editanalytics} control={<Checkbox style={{ color: "#C8102E" }}  />}
          label={t('WorkspaceUsers.Analytics')} />)
        }
        {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_theme_editor === true) &&
        (<FormControlLabel name="editthemes" value="themes" onChange={editFormik.handleChange} checked={editFormik.values.editthemes} control={<Checkbox style={{ color: "#C8102E" }} />}
            label={t('WorkspaceUsers.Themes')} />)
          }

        {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_workspace_graph === true) &&
        (<FormControlLabel name="editgraphs" value="graphs" onChange={editFormik.handleChange} checked={editFormik.values.editgraphs} control={<Checkbox style={{ color: "#C8102E" }} />}
        label={t('WorkspaceUsers.Graphs')} />)
          }
          <div>
        <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
       <div className={classes.button}>
                  <Button  
                  onClick={buttonClose}
                  className="appBtnCancel"                                    
                    size="large"
                  >
                     {t('WorkspaceUsers.Cancel')}
                  </Button>
                  <div className={classes.update}>
                      <Button 
                       className='appBtn'                        
                        size="large"
                        type="submit"
                      >
                       {updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsers.Update')}  
                      </Button>
                  </div>
          </div>
      </div>
      </form>
    </>                            
  )

  const bigHtmlContent2 = (
    
   <>
        <div className={classes.logo}>
           <img src={MessageLogo} alt='logo' /> 
        </div>
        <div className={classes.text1}>                                    
           {t('WorkspaceUsers.Wehavesentapasswordresetlink')}                     
        </div>
        <div className={classes.button1}>
                    <Button
                    className='appBtn'  
                     onClick={keyClose}
                     size="medium"
                    >
                        {t('WorkspaceUsers.OK')}   
                    </Button>
        </div> 
  
      </>
  )

  const bigHtmlContent3 = (
    <>
      <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('WorkspaceUsers.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('WorkspaceUsers.Youareabouttodeleteaworkspaceuser')}
              
      </div>
      
      <div className={classes.button2}>
       
              <Button  
              className="appBtnCancel"  
                onClick={deleteClose}                         
                size="large"
              >
                 {t('WorkspaceUsers.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={deleteConfirm}
                  >
                    {deleteLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('WorkspaceUsers.OK')} 
                  </Button>
              </div>
           
      </div>
     
    </>     
  )
  
  const bigHtmlContent4 = (
    <>
    <form onSubmit={permissionformik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('WorkspaceUsers.WorkspaceUsers')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handlenewClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    
                <div className={classes.permission}>
                {t('WorkspaceUsers.Permissions')} *
                </div> 
                 
                {(user.is_colordb_editor === false) &&
                (<FormControlLabel  name="permissionDatabase" value="database" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Databases')}/> )
                }
                {(user.is_historians === false) &&
                (<FormControlLabel  name="permissionAnalytics" value="analytics" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Analytics')}/>)
                }
                {( user.is_theme_editor === false) &&
                (<FormControlLabel  name="permissionThemes" value="themes" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Themes')}/>)
              }
              {(user.is_workspace_graph === false) &&
                (<FormControlLabel  name="permissionGraphs" value="graphs" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Graphs')}/>)
              }
                  <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
              <div>                   
                <Button  
                className='appBtn'
                  size="meduim"
                  type="submit"
                >
                  {sendNewLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : "Send"}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )
  const bigHtmlContentImp4 = (
    <>
    <form onSubmit={permissionformik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('WorkspaceUsers.WorkspaceUsers')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handlenewClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    
                <div className={classes.permission}>
                {t('WorkspaceUsers.Permissions')} *
                </div> 
                 
                {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_colordb_editor === false) &&
                (<FormControlLabel  name="permissionDatabase" value="database" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Databases')}/> )
                }
                {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_historians === false) &&
                (<FormControlLabel  name="permissionAnalytics" value="analytics" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Analytics')}/>)
                }
                {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_theme_editor === false) &&
                (<FormControlLabel  name="permissionThemes" value="themes" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Themes')}/>)
              }
              {(impersonateUserInfo?.status === "success" && impersonateUserInfoData.is_workspace_graph === false) &&
                (<FormControlLabel  name="permissionGraphs" value="graphs" onChange={permissionformik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                  label={t('WorkspaceUsers.Graphs')}/>)
              }
                  <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 12}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
              <div>                   
                <Button  
                className='appBtn'
                  size="meduim"
                  type="submit"
                >
                  {sendNewLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : "Send"}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )
  
  if(impersonateUserInfo?.status === "success"){
    return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loading}
              totalPageCount={allusersCount}
              title ={t('WorkspaceUsers.WorkspaceUsers')}
              thead={thead}
              tdata={userlist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:[],
                        default:["edit","lock","delete"]
                      }
                    }
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handleLockClick={handleLockClick}
              headerActions={headerActions}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={-1}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
          handleclose={handleClose} 
          open={open} 
          content={bigHtmlContentImp}                   
      />
      <Modals 
        handleclose={buttonClose} 
        open={btnOpen} 
        content={bigHtmlContentImp1}                   
      />
      <Modals 
        handleclose={keyClose} 
        open={Key} 
        content={bigHtmlContent2}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={Delete} 
        content={bigHtmlContent3}                   
      />
      <Modals 
        handleclose={handlenewClose} 
        open={opennew} 
        content={bigHtmlContentImp4}                   
      />
      </DashboardLayout>
      
    )
                  }
  else{
    return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loading}
              totalPageCount={allusersCount}
              title ={t('WorkspaceUsers.WorkspaceUsers')}
              thead={thead}
              tdata={userlist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:[],
                        default:["edit","lock","delete"]
                      }
                    }
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handleLockClick={handleLockClick}
              headerActions={headerActions}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={-1}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
          handleclose={handleClose} 
          open={open} 
          content={bigHtmlContent}                   
      />
      <Modals 
        handleclose={buttonClose} 
        open={btnOpen} 
        content={bigHtmlContent1}                   
      />
      <Modals 
        handleclose={keyClose} 
        open={Key} 
        content={bigHtmlContent2}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={Delete} 
        content={bigHtmlContent3}                   
      />
      <Modals 
        handleclose={handlenewClose} 
        open={opennew} 
        content={bigHtmlContent4}                   
      />
      </DashboardLayout>
      
    )
  }
}
