import React,{useState, useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable, DetailsHead } from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery,Grid } from "@mui/material";
import Modals from 'components/Modal';
import { useFormik } from "formik";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import ThemeColors from "./ThemeColors";
import {IoMdClose} from "react-icons/io";
import {FiAlertCircle} from "react-icons/fi";
import { useParams } from "react-router-dom";
import {getThemeDetails, deleteThemeVersion, reloadThemeVersion, downloadThemeVersion} from "../../redux/actions/themes/themesActions"
import { useDispatch, useSelector } from "react-redux";
import {RiErrorWarningLine} from "react-icons/ri";
import toast, { Toaster } from 'react-hot-toast';
import AnalyticsModal from 'components/Modal/AnalyticsModal';
import {useTranslation} from "react-i18next";





const useStyles = makeStyles(styles)

export default function Themes() {
  const theme = useTheme();
  const {t} = useTranslation('common');
  const [colorsModalOpen, setColorsModalOpen] = useState(false);
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  
  const [colorTheme, setColorTheme] = useState();
  const [logodata, setLogodata] = useState();
  const [page, setPage] = useState(1);
  const [version, setVersion] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [currentDownloadSlug, setCurrentDownloadSlug] = useState();
  const [historyCount, setAllHistoryCount] = useState();
  
  
  

  const {code} = useParams();
  
  const dispatch = useDispatch();
  const { detailsLoader,
     themeDetails,
     deleteThemeVersionSuccess,    
     reloadThemeVersionSuccess,
     themeVersionCreateDetails,
     downloadThemeVersionSuccess,
     downloadThemeVersionLoader,
     downloadThemeVersionError500,
     reloadThemeVersionError500,
     themeVersionCreateError500,
     deleteThemeVersionError500,
     detailsError
     } = useSelector((state) => state.themes.themes);
  const classes = useStyles();

  const [historyList, setHistorylist] = useState([]);


  const formik = useFormik({
    initialValues: {    
      id: "",   
    },
    onSubmit : (values, formikHelpers)=> {
        dispatch(reloadThemeVersion(values.id))
        setEditModalOpen(false)
    }
  });

  const linkData = [ 
    {
        displayName:t('themesDetails.themes'),
        link:"/dashboard/themes",
        linkClick:true
    },
    {
        displayName: themeDetails?.data.theme_info?.theme_name,
        link:"/dashboard/themes/details"
    }
  ]

  const thead = [
    {
      name:"version",
      displayName:"#",
      type:"slno",
      latestTag: true,
      width:30,  //percentage
    },
    {
      name:"created",
      displayName:t('themesDetails.creationDate'),
      type:"date",
      width:50
    },
    {
      name:"actions",
      displayName:t('themesDetails.actions'),
      type:"action",
      width:20
    },
]

  

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const handleRowClick = (item) => () => {
    // 
  }

  const handleEditClick = (item) => () => {
    formik.setFieldValue("id", item.id)
    setVersion(item.version)
    setEditModalOpen(!editModalOpen)
  }

  const handleLockClick = (e) => {
    e.stopPropagation()
  }

  const handleDeleteClick = (item) => () => {
    setDeleteModal(!deleteModal)
    setSelectedVersion(item)
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if(selectedVersion !== ""){
          dispatch(deleteThemeVersion(selectedVersion))
        }
        
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [selectedVersion]);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if(formik.values.id !== "") {
          dispatch(reloadThemeVersion(formik.values.id))
          setEditModalOpen(!editModalOpen)
        }
        
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [version]);

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      code: code,
      offset: paginationOffset
    }
      dispatch(getThemeDetails(payload))
      
  },[page, themeVersionCreateDetails]);


  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      code: code,
      offset: paginationOffset
    }
      dispatch(getThemeDetails(payload))
  }





useEffect(() => {
    
  return () => 
  dispatch({
    type: "REQUEST_THEME_DETAILS_SUCCESS",
    payload: null
});
}, []);

  

  useEffect(() => {  
    
    if(themeDetails) {
      if(themeDetails?.data.theme_version.length > 0 && detailsLoader === false) {
        setHistorylist(themeDetails?.data.theme_version)
        setAllHistoryCount(themeDetails?.count)
        dispatch(reloadThemeVersion(themeDetails?.data.theme_version[0].id))
      } 
      else{
        setHistorylist([])
      }     
    }
  },[themeDetails]);

  
  useEffect(() => {  
    if(deleteThemeVersionSuccess?.status === 204) {
      setDeleteModal(!deleteModal)
      toast.success(t('themesDetails.themeVersions') + '"' + selectedVersion.version + '"' + t('themesDetails.deleted') , {
        duration: 8000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
      let payload = {
        offset: 0,
        code: code
      }  
      setPage(1)
      dispatch(getThemeDetails(payload))
      dispatch({
        type: "REQUEST_DELETE_THEME_VERSION_SUCCESS",
        payload: null
      })
  }
  },[deleteThemeVersionSuccess]);

  useEffect(() => {  
    if(reloadThemeVersionSuccess && version !== "") {
      setVersion("")
      formik.resetForm()
      toast.success(t('themesDetails.settingsApplied'), {
        duration: 8000,
        position: 'top-right',
        style: {maxWidth: 'unset', fontSize: 20, fontFamily: 'Robot'}
      });
  }
  },[reloadThemeVersionSuccess]);

  useEffect(() => {  
      if(downloadThemeVersionSuccess?.status === 200){
        var base64replace1 = downloadThemeVersionSuccess.data.data.replace("b'", "");
        var base64replace2 = base64replace1.replace("'", "");   

      const blob = b64toBlob(base64replace2, "application/zip");
      const blobUrl = URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = blobUrl;
      a.download = "theme-" + currentDownloadSlug.slug;
      a.click();
       dispatch({
        type: "REQUEST_DOWNLOAD_THEME_VERSION_SUCCESS",
        payload: null
      });
      }
  },[downloadThemeVersionSuccess]);

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  
  const deleteContent = (
    <>
    <div className={classes.logo1}>
        <RiErrorWarningLine color="#C8102E" size={"100px"} />
    </div>
    <div className={classes.warning}>
        {t('themesDetails.areYouSure')}
    </div>
    <div className={classes.text} >
        {t('themesDetails.deleteConfirmation')}
    </div>
    <div className={classes.button2}>
            <Button  
            onClick={()=> {
              setSelectedVersion()
              setDeleteModal(!deleteModal)}}  
              className="appBtnCancel"                                    
              size="large"
            >
               {t('themesDetails.cancel')}
            </Button>
            <div className={classes.ok}>
                <Button
                className="appBtn"                         
                  size="large"
                  onClick={() =>   dispatch(deleteThemeVersion(selectedVersion))}
                >
                    {t('themesDetails.ook')}
                </Button>
            </div>
    </div>
  </>     
  )

  const colorsContent = (
    <div style={{fontFamily:"Roboto"}}>

    <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className={classes.head}></div>
        <Button
                onClick={()=> setColorsModalOpen(!colorsModalOpen)}
                variant="contained"
                component="label"
                className="closeBtn"
                ><IoMdClose  style={{color: '#fff', fontSize: 25, fontWeight: 'bold'}}/>
        </Button>
    </div>                        
    <Grid container direction="row" style={{width: 500}}>
          <Grid  xs={8}  className={classes.rowDiv}>
              <p className={classes.colorName}>{t('themesDetails.sidebarClr')}</p>
          </Grid>
          <Grid  xs={4} className={classes.rowDiv}>
            <div className={classes.colorMainDiv}>
                <div className={classes.colorBox} style={{background: colorTheme?.sidebar_color}}>
                </div>
                <p className={classes.colorCode} >{colorTheme?.sidebar_color}</p>
            </div>
          </Grid>
          <Grid  xs={8}  className={classes.rowDiv}>
              <p className={classes.colorName}>{t('themesDetails.textOversidebar')}</p>
          </Grid>
          <Grid  xs={4} className={classes.rowDiv}>
            <div className={classes.colorMainDiv}>
                <div className={classes.colorBox} style={{background: colorTheme?.text_over_sidebar_color}}>
                </div>
                <p className={classes.colorCode}>{colorTheme?.text_over_sidebar_color}</p>
            </div>
          </Grid>

          <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.bgClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.background_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.background_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
          <p className={classes.colorName}>{t('themesDetails.textOverBgClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.text_over_background_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.text_over_background_color}</p>
            </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
          <p className={classes.colorName}>{t('themesDetails.primaryClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.primary_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.primary_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.textOverPrimaryClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.text_over_primary_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.text_over_primary_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.bgOverPrimaryClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.background_over_primary_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.background_over_primary_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.disabledTextOverPrimaryClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.disabled_text_over_primary_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.disabled_text_over_primary_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.callToActionClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.call_to_action_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.call_to_action_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.listHighlightClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.list_highlight_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.list_highlight_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.listHighlightBorderClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.list_highlight_border_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.list_highlight_border_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>

        <p className={classes.colorName}>{t('themesDetails.menuBgClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.menu_background_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.menu_background_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.textOverMenuClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.text_over_menu_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.text_over_menu_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.disabledButtonBgClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.disabled_button_background_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.disabled_button_background_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.disabledBtnTextClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.disabled_button_text_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.disabled_button_text_color}</p>
        </div>
        
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.textInputTextClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.text_input_text_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.text_input_text_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.textInputBgClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.text_input_background_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.text_input_background_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.successClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.success_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.success_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.textInputPlaceHolder')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.text_input_placeholder_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.text_input_placeholder_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.errorClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.error_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.error_color}</p>
        </div>
        </Grid>
        <Grid  xs={8}  className={classes.rowDiv}>
        <p className={classes.colorName}>{t('themesDetails.warningClr')}</p>
        </Grid>
        <Grid  xs={4}  className={classes.rowDiv}>
        <div className={classes.colorMainDiv}>
            <div className={classes.colorBox} style={{background: colorTheme?.warning_color}}>
            </div>
            <p className={classes.colorCode}>{colorTheme?.warning_color}</p>
        </div>
        </Grid> 
      </Grid>
    </div>
  )

  const handleThemeClick = (item) => () => {
    setColorTheme(item.colors)
    setColorsModalOpen(!colorsModalOpen)
  }

  const handleDownloadclick = (item) => () => {
    setCurrentDownloadSlug(item)
    dispatch(downloadThemeVersion(item))
  }

  const handlePictureClick = (item) => () => {
    setLogodata(item.logos)
    setLogoModalOpen(!logoModalOpen)
  }

  
  

  const logoContent =(
    <div style={{minWidth: 300,fontFamily:"Roboto"}}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className={classes.head}>{t('themesDetails.logosUsed')}</div>
        <Button
                onClick={()=> setLogoModalOpen(!logoModalOpen)}
                variant="contained"
                component="label"
                className="closeBtn"
                ><IoMdClose  style={{color: '#fff', fontSize: 25, fontWeight: 'bold'}}/>
                    
        </Button>
    </div>
        
        <div style={{marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p className={classes.colorName}>{t('themesDetails.headerLogo')}</p>
            {
               logodata?.logo === null ?
               t('themesDetails.noHeaderLogo') :
               <img src={process.env.REACT_APP_API_URL.replace(/\/+$/, '') + logodata?.logo} style={{width: 200, height: 70}} alt="Header Logo"/>
            }
            
        </div>
        <div style={{marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p className={classes.colorName}>{t('themesDetails.bottomLogo')}</p>
            {
              logodata?.secondary_logo === null ? t('themesDetails.noBottomLogo') : 
              <img src={process.env.REACT_APP_API_URL.replace(/\/+$/, '') + logodata?.secondary_logo} style={{width: 200, height: 70}} alt="Footer Logo"/>
            }
            
        </div>
    </div>
  )

  const editModalContent = (
     <>
        <div style={{textAlign: 'center', fontFamily: 'Roboto'}}>
            <FiAlertCircle style={{fontSize: 80, color: '#C8102E'}}/>
            <p className={classes.reloadHead}>{t('themesDetails.reloadImage')}</p>
            <p className={classes.reloadPara}>{t('themesDetails.sourceVersion')}{version}</p>
            <form onSubmit={formik.handleSubmit}>
              <div  style={{display: 'flex', marginTop: 20, justifyContent: 'center'}}>
                    
                      <Button
                          onClick={() => setEditModalOpen(!editModalOpen)}
                          variant="contained"
                          component="label"
                          className='appBtnCancel'
                      >
                          {t('themesDetails.cancel')}
                      </Button>
                      <Button
                      className='appBtn'
                          type="submit"
                          size="meduim"
                          style={{marginLeft: 20}}
                          >{t('themesDetails.ook')}
                      </Button>
              </div>
            </form>
        </div>
     </>
  )

  useEffect(() => {  
    if(themeVersionCreateError500){
      toast.error(t('themesDetails.themeVersionCreationFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_CREATE_THEME_VERSION_FAILED_500",
        payload: null
      })
    }

    if(reloadThemeVersionError500){
      toast.error(t('themesDetails.themeVersionReloadFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_RELOAD_THEME_VERSION_FAILED_500",
        payload: null
      })
    }

    
    if(downloadThemeVersionError500){
      toast.error(t('themesDetails.themeVersionDownloadFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DOWNLOAD_THEME_VERSION_FAILED_500",
        payload: null
      })
    }
    if(deleteThemeVersionError500){
      toast.error(t('themesDetails.themeVersionDeleteFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DELETE_THEME_VERSION_FAILED_500",
        payload: null
      })
    }
    
  }, [themeVersionCreateError500, reloadThemeVersionError500, downloadThemeVersionError500, deleteThemeVersionError500])
  
  return (
    <DashboardLayout >
      <Toaster position="bottom-center"/>
      <BreadCrumb linkData={linkData}/>
        <DetailsHead 
            column1={{label: t('themesDetails.themeName'), value: themeDetails?.data.theme_info?.theme_name}}
            column2={{label: t('themesDetails.themeCode'), value: themeDetails?.data.theme_info?.theme_code}}
        />
 
        <ThemeColors
          item={code}
          page={page}
          reloadThemeVersionSuccess={reloadThemeVersionSuccess}
        />

        <div style={{marginTop: 20}}>
            <CustomTable 
                title ={t('themesDetails.history')}
                thead={thead}
                tdata={historyList}
                maxRow={10}
                handleRowClick={handleRowClick}
                height={isSmallerDesktop?150:413}
                action={
                        {
                        admin:["delete", "lock"],
                        default:["delete", "download", "themeView", "picture", "reloadVersion"]
                        }
                    }
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleDownloadclick={handleDownloadclick}
                handleLockClick={handleLockClick}
                handleThemeClick={handleThemeClick}
                handlePictureClick={handlePictureClick}
                slno={true}
                downloadLoader={downloadThemeVersionLoader} 
                currentItem ={currentDownloadSlug}
                totalPageCount={historyCount}
                currentPage={page}
                loadPagination={setPage}
                clickableColumn={-1}
                errorStatus={detailsError}
                errorBlockBtnClick={errorBlockBtnClick}
                errorTopheight={"translateY(50%)"}
            />
        </div>
        <AnalyticsModal 
            handleclose={handleThemeClick} 
            open={colorsModalOpen} 
            content={colorsContent}                   
        />

        <Modals 
            handleclose={handlePictureClick} 
            open={logoModalOpen} 
            content={logoContent}                   
        />

        <Modals 
            handleclose={() => setEditModalOpen(!editModalOpen)} 
            open={editModalOpen} 
            content={editModalContent}                   
        />

      <Modals 
            handleclose={() => setDeleteModal(!deleteModal)} 
            open={deleteModal} 
            content={deleteContent}                   
        />
    </DashboardLayout>
    
  )
}
