const initalState = {
    loading: false,
    success: false,
    error:null,
    ForgotError500: null
  }
  
  export const forgot = (state = initalState, action) => {
    switch (action.type) {
        case "FORGOT_REQUEST": {
            return { ...state, loading: true,error:null,success: false }
          }
          case "FORGOT_SUCCESS": {
            return { ...state, error:null,loading: false,success: true }
          }
          case "FORGOT_FAILURE": {
            return { ...state, loading: false,error:action.payload, success: false}
          }
          case "RESET_FORGOT_REQUEST": {
            return { ...state, loading: false,error:null,success: false }
          }
          case "FORGOT_FAILED_STATUS_500": {
            return { ...state, loading: false, ForgotError500: action.payload }
          }
      default: {
        return state
      }
    }
  }
  