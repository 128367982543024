import React,{useState,useEffect} from 'react'
import { CustomTextField } from 'elements';
import { Button,Link,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from "./style";
import Modals from 'components/Modal/Modal';
import MessageLogo from "assets/images/message.svg";
import { useFormik } from "formik";
import {  string } from "yup";
import * as Yup from "yup";
import {forgotWithJWT} from "../../redux/actions/auth/forgotActions"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";



const useStyles = makeStyles(styles)

export default function ForgotForm() {
  const {t} = useTranslation('common');
  const classes = useStyles()
  

  const [open, setOpen] = useState(false);

   
  const handleClose = () => setOpen(false);
  const { loading, error, success, ForgotError500 } = useSelector((state) => state.auth.forgot);
  const { language } = useSelector((state) => state.language);
   
  const dispatch = useDispatch();


    const bigHtmlContent = (
      <>
        <div className={classes.logo}>
           <img src={MessageLogo} alt='logo' /> 
        </div>
        <div className={classes.text1}>      
              <div style={{padding:"5%"}}>
                  <Typography  variant="subtitle1" gutterBottom component="div">
                  {t('signIn.Wehavesentapasswordresetlinktotheemailaddressyouhaveprovided')}
                  </Typography>
              </div>           
        </div>
        <div className={classes.button1}>
                    <Button
                    variant="contained" 
                    size="medium"
                    onClick={handleClose}
                    >
                       {t('signIn.OK')}
                    </Button>
        </div> 
  
      </>
  );

    const formik = useFormik({
      initialValues: {   
      email: "",
      language: language.languageLabel === "italiano" ? "italian" : language.languageLabel === "English" ? "english" : "chinese" 
    },
    validationSchema: Yup.object({
      email: string().required(t('signIn.Pleaseenteremail')).email(t('signIn.Invalidemail')),       
    }),
    onSubmit : (values, formikHelpers)=> {
      dispatch(forgotWithJWT(values))      
   }
  });

  useEffect(() => {
    if(success) {
      setOpen(true);
      dispatch({
        type: "RESET_FORGOT_REQUEST",
        payload: null
      })
      formik.resetForm();
    }
    if(error){
      Swal.fire({
        title: t('signIn.error'),
        text: t('signIn.Invalidemail'),
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonText: t('companyAdd.close'),
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "RESET_FORGOT_REQUEST",
        payload: null
      })
    }
    if(ForgotError500){
      Swal.fire({
        title: t('signIn.error'),
        text: t('signIn.failed'),
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonText: t('companyAdd.close'),
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "FORGOT_FAILED_STATUS_500",
        payload: null
      })
    }
  },[error, success, ForgotError500]);

    return(
       <React.Fragment>  
           
         <div className={classes.heading}>{t('signIn.ForgotPassword')}</div>
         <span className={classes.subheading}>{t('signIn.Enteryouremailaddresstoreceivepasswordresetlink')}</span>
         <form id="signup"  onSubmit={formik.handleSubmit}>
         <div  className="public-textbox">
            <CustomTextField 
              label={t('signIn.email')}
              name="email"
              type="email" 
              placeholder={t('signIn.email')}
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.email} 
              error = {formik.touched.email && Boolean(formik.errors.email)} 
              helperText = {formik.touched.email && formik.errors.email}
              login={true}
            />
            </div>
            <Button className={classes.button} type="submit">              
              {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('signIn.Send')}
            </Button>
          </form>
        <Modals handleclose={handleClose} open={open}  content={ bigHtmlContent} />
        <div className={classes.bottom}>
            <Link className={classes.link}  href="/sign-in" color="#fff" >{t('signIn.BackToLogin')}</Link>
        </div>
       </React.Fragment> 
    )
}