

const styles = (theme) => ({
down:{
   display: "flex",
   flexDirection:"row",
   marginTop:10
},
registerBtn: {
    width:270,
    textTransform:'none !important',
    fontWeight:"5",
    [theme.breakpoints.up('xl')]: {
        textTransform:'none !important',
        width: 300,
        height: 50,
        fontSize: "20px !important"
    }
},
country:{
    width:"100% !important"
},
label:{
   fontSize: "13px !important",
   margin: "0 0 0 0 !important",
   [theme.breakpoints.up('xl')]: {
        fontSize:"18px !important"
    }
},
type:{
    fontSize: "26px !important"
},
main:{
    marginTop:"0.1px !important"
},
tag: {
    color: "#000",
    fontSize: 16,
    fontWeight:500,
    [theme.breakpoints.down('xl')]: {
        fontSize:14
    }
},
logo: {
    display: "flex",
    justifyContent: "center"
},
text: {
    // display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "2%",
    fontFamily: 'Roboto'
},
button: {
     textAlign:"center"
}


})


export default styles