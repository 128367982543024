import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { WhiteLayout } from 'layouts';
import Button from '@mui/material/Button';
import ResetLogo from "assets/images/reset.svg";
import { useDispatch, useSelector } from "react-redux";
import {userActivationAction} from "../../redux/actions/userActivation/userActivationActions"
import queryString from 'query-string';
import {useTranslation} from "react-i18next";
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from "formik";

import { ReactComponent as Logo } from "assets/images/corob-logo-red.svg";
import { Spinner } from "reactstrap"
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import LoginIcon from '@mui/icons-material/Login';
import ApprovalIcon from '@mui/icons-material/Approval';

const useStyles = makeStyles(styles)

export default function UserActivation(props) {
    const classes = useStyles()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { activationError, activationSuccess, activationLoading } = useSelector((state) => state.userActivation.activation);
    const {t} = useTranslation('common');
    const [checkBoxError,setCheckboxError] = useState();

    const parsed = queryString.parse(window.location.search);
    const handleLoginClick = () => {
        navigate("/sign-in")
    }

    const formik = useFormik({
        initialValues: {
            database: false,
            analytics: false,
            themes: false,
            graphs: false,
            reject: false
      },
        onSubmit : (values, formikHelpers)=> {
        if(values.database === false && values.analytics === false && values.themes === false && values.graphs === false && values.reject === false){
          setCheckboxError(t('signUp.Permissionrequired'))
        }
        else if(values.database === false && values.analytics === false && values.themes === false && values.graphs === true && values.reject === false){
            setCheckboxError(t('signUp.graphPermissionError'))
        }
        else if(values.database === false && values.analytics === true && values.themes === false && values.graphs === true && values.reject === false){
            setCheckboxError(t('signUp.graphPermissionError'))
        }
        else if(values.database === true && values.analytics === false && values.themes === false && values.graphs === true && values.reject === false){
            setCheckboxError(t('signUp.analyticsPermissionError'))
        }
        else if(values.database === true && values.analytics === false && values.themes === true && values.graphs === true && values.reject === false){
            setCheckboxError(t('signUp.analyticsPermissionError'))
        }
        else if(values.database === false && values.analytics === true && values.themes === true && values.graphs === true && values.reject === false){
            setCheckboxError(t('signUp.databasePermissionError'))
        }
        else if(values.database === false && values.analytics === true && values.themes === true && values.graphs === false && values.reject === false){
            setCheckboxError(t('signUp.databasePermissionError'))
        }
        else if(values.database === false && values.analytics === true && values.themes === false && values.graphs === false && values.reject === false){
            setCheckboxError(t('signUp.databasePermissionError'))
        }
        else if(values.database === false && values.analytics === false && values.themes === false && values.graphs === false && values.reject === true){ 
            setCheckboxError()
            dispatch(userActivationAction(parsed.token, values))
        }
        else{
          setCheckboxError()
            dispatch(userActivationAction(parsed.token, values))
        }
        
        }
    });

    function sendrejectReq() {
        let new_values = {
            database: false,
            analytics: false,
            themes: false,
            graphs: false,
            reject: true, 
        }
        if(new_values.reject === true){
            setCheckboxError()
            dispatch(userActivationAction(parsed.token, new_values))
        }
      }

  return (
    <WhiteLayout>
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <div className={classes.contentdiv}>
                
                {
                    
                    activationError ?
                    <>
                    <ErrorOutlineSharpIcon  style={{ color: "#C8102E" }} />
                    <div className={classes.errorText}>&nbsp;&nbsp;{activationError.message}</div>
                    </>
                    :
                    activationSuccess !== null ? 
                       
                            <>
                             <p>{activationSuccess}</p>
                            <img src={ResetLogo} alt='success' className={classes.icon} /> 
                            <div className={classes.logoutText}>Activation Completed Successfully!</div>
                            </>
                            :
                            <>
                             <p>{activationSuccess}</p>
                             <LockOpenOutlinedIcon color="success"/>
                            <div className={classes.logoutText}>&nbsp;&nbsp;Apply following permissions to activate user</div>
                            </>
                    
                }
                
            </div>
            {
                    activationError ?
                    <>
                        <Button startIcon={<LoginIcon/>} onClick={handleLoginClick} style={{marginTop: 40}}>Login </Button>
                    </>
                    :
                    activationSuccess !== null ?
                    <>
                        <Button startIcon={<LoginIcon/>} onClick={handleLoginClick} style={{marginTop: 40}}>Login </Button>
                    </>
                    :
                    <>
                        <form onSubmit={formik.handleSubmit}>
                                    <div style={{boxShadow: "3px 3px 3px 3px #9E9E9E", marginTop: 10}}>
                                            
                                            <div className={classes.contentdiv} >
                                            
                                                <Grid className={classes.main} container rowSpacing={1} justifyContent="center" alignItems="center" style={{marginBottom: 10, marginLeft: 30}}>
                                                
                                                <Grid item xs={6}>
                                                    <FormControlLabel  name="database" value="database" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                                                    label={t('signUp.databaseFlag')}/>
                                                </Grid>
                                                <Grid item xs={6}>   
                                                    <FormControlLabel  name="analytics" value="analytics" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                                                    label={t('signUp.analyticsFlag')}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel  name="themes" value="themes" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                                                    label={t('signUp.themeFlag')}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel  name="graphs" value="graphs" onChange={formik.handleChange} control={<Checkbox style ={{color: "#C8102E"}} />} 
                                                    label={t('signUp.graphFlag')}/>
                                                </Grid>
                                                </Grid>
                                            
                                                <div>                   
                                                    <Button  
                                                    className='appBtn'
                                                    size="meduim"
                                                    type="submit"
                                                    style={{marginRight: 30}}
                                                    >
                                                        {activationLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : "Apply"}
                                                    </Button>
                                                                                                            
                                                </div> 
                                            </div>
                                    </div> 
                                    <div className={classes.container} style={{marginTop: 20}} >
                                        <p style={{color: '#c8102e', fontFamily: 'Roboto', fontSize: 15}}>{checkBoxError !== "" ? checkBoxError : ""}</p> 
                                    </div>                           
                        </form>
                        <div>
                        <Button startIcon={<DoNotDisturbIcon/>} onClick={sendrejectReq} style={{marginTop: 40, marginRight:30}}>Reject Request </Button>
                        <Button startIcon={<LoginIcon/>} onClick={handleLoginClick} style={{marginTop: 40}}>Login </Button>
                        </div>
                    </>
            }
            </div>
    </WhiteLayout>
  )
}
