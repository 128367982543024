import React, { memo, useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { countries } from './countryCordinates';
import { useSelector } from "react-redux";
import { geoCylindricalStereographic } from "d3-geo-projection"
import geoUrl1 from './features.json'
// const geoUrl =
//   "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

const MapChart = ({ setTooltipContent }) => {
  const [markersArray, setMarkersArray] = useState([]);
  
  const { ccAdminContriesNoOfMachinesSuccess
} = useSelector((state) => state.analytics.admin);


  useEffect(() => {  
    if(ccAdminContriesNoOfMachinesSuccess?.status == 'success'){
    var exArray = [...markersArray]
    var result = [];

    ccAdminContriesNoOfMachinesSuccess.data.machines_and_customers_in_country.forEach(function (a) {
        if (!this[a.country]) {
            this[a.country] = { country: a.country, machines: 0, customers: 0 };
            result.push(this[a.country]);
        }
        this[a.country].machines += a.machines;
        this[a.country].customers += a.customers;
    }, Object.create(null));
    
    for(var i= 0; i<countries.length; i++){
      for(var j=0; j<result.length; j++){
         if(countries[i].name.toLocaleLowerCase() == result[j].country.toLocaleLowerCase().replace(/^\s+|\s+$/g,'')){
            exArray.push({
              markerOffset: 10,
              name: result[j].country,
              coordinates: [countries[i].longitude, countries[i].latitude],
              noofmachine: result[j].machines,
              noofcustomers: result[j].customers,
            })
         }
      }
    }
    setMarkersArray(exArray)
  }
  }, [ccAdminContriesNoOfMachinesSuccess])

  const width = 800
  const height = 500

  const projection =  geoCylindricalStereographic()
  .translate([width / 2, height / 2])
  .fitExtent([[0.5, 0.5], [width - 0.5, height - 0.5]], {type: "Sphere"})
  .precision(0.1)
  

  return (
    <div data-tip="" style={{display: 'flex', justifyContent: 'center'}}>
      <ComposableMap projection={projection}>
        
          <Geographies geography={geoUrl1} stroke="#FFFFFF"
        strokeWidth={1}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent();
                  }}
                  onMouseLeave={() => {
                    setTooltipContent();
                  }}
                  style={{
                    default: {
                      fill: "#D6D6DA",
                      outline: "none"
                    },
                    hover: {
                      fill: "#D6D6DA",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#D6D6DA",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
        {markersArray.map(({ name, coordinates, markerOffset, noofmachine, noofcustomers }) => (
        <Marker key={name} coordinates={coordinates} onMouseEnter={() => {
          setTooltipContent({name : name, noofmachine: noofmachine, noofcustomers: noofcustomers});
        }} 
        >
          <g
            fill="none"
            stroke="#000000"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            onMouseLeave={() => {
              setTooltipContent();
            }}
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "Roboto", fill: "#5D5A6D", fontSize:10, fontWeight: 'bold', cursor: 'pointer' }}
          >
            {name}
          </text>
        </Marker>
      ))}
      </ComposableMap>
    </div>
  );
};

export default memo(MapChart);
