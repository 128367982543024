import React from 'react'
import moment from "moment-timezone";
import { makeStyles } from '@mui/styles';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import styles from "./styles";

const useStyles = makeStyles(styles)

export default function DatePicker() {

    const classes = useStyles()
    const today= new Date()
    const currentDate= moment(today).format('MMMM DD , Y')

    const [displayDate,setDisplayDate]= React.useState(currentDate)

    React.useEffect(()=>{
        let timeInterval = setInterval(() => {
            const today= new Date()
            const currentDate= moment(today).format('MMMM DD, Y')
            setDisplayDate(currentDate)
            
        }, 1000)
        
        //Clean up can be done like this
        return () => {
            clearInterval(timeInterval);
        }
    },[])
  return (
    <div className={classes.container}>
      <DateRangeOutlinedIcon />
      <span className={classes.date}>{displayDate}</span>
    </div>
  )
}
