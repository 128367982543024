import React,{useState,useEffect} from 'react'
import { CustomTextField} from 'elements';
import { Button,Link,Grid,Typography,InputLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { countries } from 'Utils/countries';
import styles from "./style";
import Modals from 'components/Modal/Modal';
import ResetLogo from "assets/images/reset.svg";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from "formik";
import {  string} from "yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {registerCompanyWithJWT, getCompanyList, getCountryList} from "../../redux/actions/auth/registerActions"
import { useDispatch, useSelector } from "react-redux"
import {useTranslation} from "react-i18next";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2';


const theme = createTheme({
    palette: {
      Red: {
        main: '#C8102E',
        contrastText: '#fff',
      },
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {color:"#C8102E"},
        },
      },
    },
   });

const useStyles = makeStyles(styles)

const SelectContainer = styled('div')(({ theme }) => ({
    '& .label':{
        color:'#000',
        fontFamily:"Roboto",
        fontSize:16,
        fontWeight:500,
        marginBottom:17,
        lineHeight:1,
    },
    [theme.breakpoints.down("xl")]:{
        '& .label':{
        fontSize:`${16-2}px`,
        fontWeight:500,
        marginBottom:12,
        }
    },
    [theme.breakpoints.up("xl")]:{
        '& .label':{
        fontSize:`${20}px`,
        }
    }

}))

const SelectTextField = styled(TextField)(({ theme }) => ({
    backgroundColor:"transparent",
    borderRadius:10,
    minHeight:`${45+23}px`,
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        color:"#C8102E"
      },
      "& .MuiInputBase-root":{
        backgroundColor:'#E8E8E8',
        borderRadius:10,
      },
      "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before":{
        borderBottom : 'none !important'
      },
      "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root.Mui-error:after":{
        borderBottom : 'none !important'
      },
      "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after":{
        borderBottom : 'none !important'
      },
    input:{
        backgroundColor:'#E8E8E8',
        fontFamily:"Roboto",
        fontSize:18,
        fontWeight:300,
        height:32,
        padding:"0px 42px !important",
        borderRadius:10,
        color:'#000',
        "&::placeholder":{
            color:'#000'
        }, 
    },
    [theme.breakpoints.down("xl")]:{
        minHeight:`${45+18}px`,
        input:{
            fontSize:16,
            padding:"0px 37px",
        },
    },
    [theme.breakpoints.up("xl")]:{
        minHeight:`${45+25}px`,
        input:{
            height:`${45+4}px`,
            fontSize:18,
            padding:"0px 37px",
        },
    }

}))

export default function CompanyRegForm() {
  const classes = useStyles()
  const { loading, error, success, errorStatus, countryListSuccess, companyListSuccess} = useSelector((state) => state.auth.register);
  const {t} = useTranslation('common');

  const [open, setOpen] = useState(false);
  const [emailSent, setEmailSent ] = useState(false);

  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  
   
  const handleClose = () => setOpen(false);
   
  
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const bigHtmlContent = (
    <>
      <div className={classes.logo}>
         <img src={ResetLogo} alt='logo' /> 
      </div>
      <div className={classes.text}>      
            <div>
                <Typography className={classes.type} id="modal-modal-title" variant="h6" component="h2">
                {t('companyReg.successMessage1')}
                </Typography>
            </div>
            <div  style={{ padding: "3%" }}>
                <Typography variant="subtitle1" gutterBottom component="div">
                {t('companyReg.successMessage2')}
                </Typography>
            </div>
            <div >
                <Typography id="modal-modal-subtitle" sx={{  color: "#7D7D7D"}}>
                {t('companyReg.successMessageNB')}
                </Typography>
            </div>
      </div>
      <div className={classes.button}>
                  <Button
                  
                  variant="contained" 
                  onClick={() => {navigate("/sign-in");}}
                  size="medium"
                  >
                      {t('companyReg.goBack')}
                  </Button>
      </div> 

    </>
);

  const formik = useFormik({
    initialValues: {
        city: "",
        address: "",
        zipcode:"",
        phone:"",
        vat:"",
        email: "",
        company: "",
        country: "",
        countrycode: countryCode,
        accept: false
  },
  validationSchema: Yup.object({
    company: string().required(t('companyReg.companyValidation')),
    country: string().required(t('companyReg.countryValidation')),
    city: string(),
    address: string(),
    zipcode: string(),
    phone: string(),
    vat: string(),
    email: string().required(t('companyReg.emailValidation1')).email(t('companyReg.emailValidation2')),                                                                                      
  }),
  onSubmit : (values, formikHelpers)=> {
    dispatch(registerCompanyWithJWT(values, countryCode))
 }
});



useEffect(() => {    
    if(success) {
        setOpen(true);
        dispatch({
            type: "REGISTER_SUCCESS",
            payload: null
          });
          formik.resetForm();
    }

    if(error) {
      if(error === "email : user with this email address already exists."){
        Swal.fire({
            title: t('signIn.error'),
            text: ''+ error === "email : user with this email address already exists." ? t('companyReg.userwiththisemailaddressalreadyexists') : 
            error.substring(error.indexOf(":") + 1)+'',
            icon: 'error',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E',
            confirmButtonText: t('companyAdd.close'),
          })
        }
      else if(error === "email : Please do not enter public domain!"){
          Swal.fire({
            title: t('signIn.error'),
            text: ''+ error === "email : Please do not enter public domain!" ? t('companyReg.emailValidation3') : error.substring(error.indexOf(":") + 1)+'' ,
            icon: 'error',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E',
            confirmButtonText: t('companyAdd.close'),
          })
        }
      else if(error === "email : Please attempt again in 24 hours!"){
          Swal.fire({
            title: t('signIn.error'),
            text: ''+ error === "error : Please attempt again in 24 hours!" ? t('companyReg.waitTosend') : error.substring(error.indexOf(":") + 1)+'' ,
            icon: 'error',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E',
            confirmButtonText: t('companyAdd.close'),
          })
        }
      else if(error === "company : The company is already registered on CorobCloud for the selected country."){
          Swal.fire({
            title: t('signIn.error'),
            text: ''+ error === "email : The company is already registered on CorobCloud for the selected country." ? "The company is already registered on CorobCloud for the selected country." : error.substring(error.indexOf(":") + 1)+'' ,
            icon: 'error',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E',
            confirmButtonText: t('companyAdd.close'),
          })
          viewMoreClick()
        }
        dispatch({
            type: "REGISTER_FAILURE",
            payload: null
          });
    }
  },[loading, error, success]);

const handleChange = (e) => {
    formik.setFieldValue("accept", e.target.checked)
}

useEffect(() => {
    dispatch(getCompanyList())
  }, []);


  useEffect(() => {    
    if(companyListSuccess) {
        setCompanyList(companyListSuccess.data)
    }
  },[companyListSuccess]);


  useEffect(() => {
    dispatch(getCountryList())
  }, []);

  
  useEffect(() => {    
    if(countryListSuccess) {
        setCountryList(countryListSuccess.data)
    }
  },[countryListSuccess]);



  useEffect(() => {  
    if(errorStatus === 0|| errorStatus === 500){
      Swal.fire({
        title: t('signIn.failed'),
        text: t('signIn.registrationFailed'),
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "REGISTER_FAILURE_STATUS",
        payload: null
      })
    }
    
  }, [errorStatus]);

  const viewMoreClick = () => {
    setEmailSent(true)
  }

  const emailSentClose = () => {
    setEmailSent(false)
  }


  return(
    <React.Fragment>
        <ThemeProvider theme={theme}>
        <form id="companyReg"  onSubmit={formik.handleSubmit}>
            <Grid className={classes.main} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.company')}
                            name="company"
                            type="name" 
                            placeholder={t('companyReg.company')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.company} 
                            error = {formik.touched.company && Boolean(formik.errors.company)} 
                            helperText = {formik.touched.company && formik.errors.company}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"

                        />   
                    </Grid>
                    <Grid item xs={6}>                       
                        <Autocomplete
                     
                        // noOptionsText={
                        //   <>
                        //       <p>{t("companyAdd.doesnotExist")} <a style={{color: "#C8102E", cursor: 'pointer'}} onClick={() => viewMoreClick()}>{t("companyAdd.viewMore")}</a></p>
                        // </>}
                        
                            sx={{ width: '100%' }}
                            options={countryList}
                            autoHighlight
                            getOptionLabel={(option) => option.country_name}                   
                            renderOption={(props, option) => (
                                <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                
                                {option.country_name}
                                </Box>
                            )}
                            onChange={(event, newValue) => {
                              setCountryCode(JSON.stringify(newValue.country_code))
                              }}
                            renderInput={(params) => (
                                
                               
                                <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                                    <InputLabel className='label' required={true}>{t('companyReg.country')}</InputLabel>
                                    <SelectTextField
                                    variant="standard"
                                    type="text"                                
                                    name="country"
                                    id="as"
                                    {...params}
                                    placeholder={t('companyReg.country')}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={() =>formik.setFieldValue("country", params.inputProps.value)}
                                    // onFocus={() =>formik.setFieldValue("country", params.inputProps.value)}
                                    error = {formik.touched.country && Boolean(formik.errors.country)}
                                    helperText = {formik.touched.country && formik.errors.country}
                                    inputProps={{
                                        
                                        ...params.inputProps,
                                        autoComplete: 'new-password'
                                    }}
                                    />
                                </SelectContainer>
                            )}
                            />
                           
                    </Grid>
                    <Grid item xs={6} >
                        <CustomTextField 
                            label={t('companyReg.city')}
                            name="city"
                            type="name" 
                            placeholder={t('companyReg.city')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.city} 
                            error = {formik.touched.city && Boolean(formik.errors.city)} 
                            helperText = {formik.touched.city && formik.errors.city}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                            required={false}
                        />   
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.address')}
                            name="address"
                            type="name" 
                            placeholder={t('companyReg.address')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.address} 
                            error = {formik.touched.address && Boolean(formik.errors.address)} 
                            helperText = {formik.touched.address && formik.errors.address}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                            required={false}
                        />   
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.zipcode')}
                            name="zipcode"
                            type="name" 
                            placeholder={t('companyReg.zipcode')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.zipcode} 
                            error = {formik.touched.zipcode && Boolean(formik.errors.zipcode)} 
                            helperText = {formik.touched.zipcode && formik.errors.zipcode}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                            required={false}
                        />   
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.phone')}
                            name="phone"
                            type="name" 
                            placeholder={t('companyReg.phone')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.phone} 
                            error = {formik.touched.phone && Boolean(formik.errors.phone)} 
                            helperText = {formik.touched.phone && formik.errors.phone}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                            required={false}
                        />   
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.vat')}
                            name="vat"
                            type="name" 
                            placeholder={t('companyReg.vat')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.Vat} 
                            error = {formik.touched.Vat && Boolean(formik.errors.Vat)} 
                            helperText = {formik.touched.Vat && formik.errors.Vat}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                            required={false}
                        />   
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.email')}
                            name="email"
                            type="name" 
                            placeholder={t('companyReg.email')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.email} 
                            error = {formik.touched.email && Boolean(formik.errors.email)} 
                            helperText = {formik.touched.email && formik.errors.email}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                        />   
                    </Grid>
                    
                    {/* <Grid item xs={6}>                       
                        <Autocomplete
                     
                        noOptionsText={
                          <>
                              <p>{t("companyAdd.doesnotExist")} <a style={{color: "#C8102E", cursor: 'pointer'}} onClick={() => viewMoreClick()}>{t("companyAdd.viewMore")}</a></p>
                        </>}
                        
                            sx={{ width: '100%' }}
                            options={companyList}
                            autoHighlight
                            getOptionLabel={(option) => option.company_name}                   
                            renderOption={(props, option) => (
                                <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                
                                {option.company_name}
                                </Box>
                            )}
                            onChange={(event, newValue) => {
                                setCompanyId(JSON.stringify(newValue.id))
                              }}
                            renderInput={(params) => (
                                
                               
                                <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                                    <InputLabel className='label' required={true}>Company</InputLabel>
                                    <SelectTextField
                                    variant="standard"
                                    type="text"                                
                                    name="company"
                                    id="as"
                                    {...params}
                                    placeholder={t('companyReg.company')}
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    onBlur={() =>formik.setFieldValue("company", params.inputProps.value)}
                                    // onFocus={() =>formik.setFieldValue("country", params.inputProps.value)}
                                    error = {formik.touched.company && Boolean(formik.errors.company)}
                                    helperText = {formik.touched.company && formik.errors.company}
                                    inputProps={{
                                        
                                        ...params.inputProps,
                                        autoComplete: 'new-password'
                                    }}
                                    />
                                </SelectContainer>
                            )}
                            />
                           
                    </Grid> */}
                    
                    {/* <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.password')}
                            name="password"
                            type="password" 
                            placeholder={t('companyReg.password')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.password} 
                            error = {formik.touched.password && Boolean(formik.errors.password)} 
                            helperText = {formik.touched.password && formik.errors.password}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                        />   
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField 
                            label={t('companyReg.confirmPassword')}
                            name="confirm"
                            type="password" 
                            placeholder={t('companyReg.confirmPassword')}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.confirm} 
                            error = {formik.touched.confirm && Boolean(formik.errors.confirm)} 
                            helperText = {formik.touched.confirm && formik.errors.confirm}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                        />   
                        
                    </Grid> */}
                    <Grid item xs={6}></Grid>
            </Grid>
            <div className={classes.down}>
                <div>
                    <FormControlLabel  control={<Checkbox style ={{color: "#C8102E"}} value={formik.values.accept} onChange={handleChange}/>} 
                        label={<Typography className={classes.label} variant="subtitle1" gutterBottom component="div">
                                {t('companyReg.privacyPolicy1')} <Link sx={{ "&:hover": { color: "#C8102E" }} } color={"#C8102E"} underline="none"  target={"_blank"} href='/privacy-policy'>{t('companyReg.privacyPolicy2')}</Link>
                                </Typography>} 
                        />              
                    <div className={classes.down}>
                        <Button  
                        disabled={formik.values.accept === false ? true: false}
                        className={classes.registerBtn}                 
                        type="submit" 
                        variant="contained" 
                        color="Red" 
                        size="meduim"
                        >
                            {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('companyReg.register')}
                            
                        </Button>
                    </div>                             
                </div>
            </div>
        </form>
        
        </ThemeProvider>
        <Modals handleclose={handleClose} open={open} content={bigHtmlContent} /> 
        {/* <Modals handleclose={emailSentClose} open={emailSent} content={emaailSentContent} />                                */}
    </React.Fragment>
  )
}