import React, { useState } from 'react'
import { styled } from '@mui/system';
import {InputLabel,TextField,InputAdornment,IconButton} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const useStyles = makeStyles(styles)

const Container = styled('div')(({ theme,labelColor,labelTextSize }) => ({
    '& .label':{
        color:labelColor,
        fontFamily:"Roboto",
        fontSize:labelTextSize,
        fontWeight:500,
        marginBottom:17,
        lineHeight:1,
    },
    [theme.breakpoints.down("xl")]:{
        '& .label':{
        fontSize:`${labelTextSize-3}px`,
        fontWeight:500,
        marginBottom:12,
        }
    },
    [theme.breakpoints.up("xl")]:{
        '& .label':{
        fontSize:`${labelTextSize+4}px`,
        }
    }

}))

const StyledTextAreaField = styled(TextField)(({ theme,height,helpertextcolor,placeholdercolor ,inputtextcolor,inputbackgroundcolor}) => ({
    backgroundColor:"transparent",
    borderRadius:10,
    minHeight:`${height+23}px`,
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
        color:helpertextcolor
      },
      "& .MuiInputBase-root":{
        backgroundColor:inputbackgroundcolor,
        borderRadius:10,
      },
    input:{
        backgroundColor:inputbackgroundcolor,
        fontFamily:"Roboto",
        fontSize:14,
        fontWeight:300,
        height:`${height-2}px`,
        padding:"0px 42px !important",
        borderRadius:10,
        color:inputtextcolor,
        "&::placeholder":{
            color:placeholdercolor?placeholdercolor:inputtextcolor
        }, 
        [theme.breakpoints.up("xl")]:{
            fontSize:"18px !important",
            
        }
    },
    [theme.breakpoints.down("xl")]:{
        minHeight:`${height+18}px`,
        input:{
            fontSize:13,
            padding:"0px 37px",
            height:`${height-8}px`,
            borderRadius:8,
        },
    },
    [theme.breakpoints.up("xl")]:{
        minHeight:`${height+25}px`,
        input:{
            fontSize:13,
            padding:"0px 37px",
            height:`${height+4}px`,
            borderRadius:12,
        },
    },
    p: {
        [theme.breakpoints.up("xl")]:{
            fontSize: 16   
        }
    },
    

}))

export default function CustomTextArea({
    label,
    name,
    type,
    placeholder,
    onChange,
    onBlur,
    value,
    error,
    helperText,
    fullWidth=true,
    // height=91,
    required,
    placeholdercolor,
    inputbackgroundcolor="#FFFFFF",
    inputtextcolor="#C8102E",
    helpertextcolor,
    labelColor="#FFFFFF",
    labelTextSize=16,
    inputProps,
    login,
    disableField,
    multiline,
    minRows,
    maxRows
}) {
    const classes = useStyles({helpertextcolor,inputtextcolor})
    const [showPassword,setShowPassword] = useState(false)

    const handlePasswordShow = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

  return (
    <Container labelColor={labelColor} labelTextSize={labelTextSize}>
        <InputLabel className='label' required={required === false ? false : true}>{label}</InputLabel>
        <StyledTextAreaField
        disabled={disableField}
            autoComplete={type==="password"?'new-password':'off'}
            variant="standard"
            // height={height}
            name={name}
            minRows={minRows}
            maxRows={maxRows}
            multiline={multiline?true:false}
            type={type==="password"?(showPassword?"text":"password"):type}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error}
            helperText={helperText}  
            helpertextcolor={login ? "#FFFFFF" : "#1F1F1F"}
            placeholdercolor={placeholdercolor}
            inputtextcolor={inputtextcolor}
            inputbackgroundcolor={inputbackgroundcolor}
            fullWidth={fullWidth}
            
            
            FormHelperTextProps={{
                classes:{
                    root:classes.helperText,
                    error:{}
                }
            }} 
            inputProps={inputProps}
            InputProps={{
                disableUnderline: true,
                endAdornment: (type==="password" ?
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handlePasswordShow}
                      onMouseDown={handleMouseDownPassword}
                      >
                       {showPassword ? <VisibilityOff className={classes.iconColor} /> : <Visibility className={classes.iconColor} />}
                    </IconButton>
                  </InputAdornment>
                  :
                  undefined
                ),
              }} 
        />
    </Container>
  )
}
