import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

export const getAllThemes = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_THEMES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.themes + '?offset=' + payload.offset , config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_THEMES_SUCCESS",
          listpayload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_ALL_THEMES_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const createThemes = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
    type: "REQUEST_CREATE_THEMES"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.themes,{
        name: payload.name,
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CREATE_THEMES_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_CREATE_THEMES_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_CREATE_THEMES_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const deleteTheme = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DELETE_THEME"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.themes + payload.id + "/", config)
      .then(response => {
        dispatch({
          type: "REQUEST_DELETE_THEME_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DELETE_THEME_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DELETE_THEME_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getThemeDetails = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_THEME_DETAILS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.themesDetails + "?limit=10" + "&offset=" + payload.offset + "&theme_id=" +  payload.code, config)
      .then(response => {
        dispatch({
          type: "REQUEST_THEME_DETAILS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_THEME_DETAILS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const createThemeVersion = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CREATE_THEME_VERSION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Content-Type":'multipart/form-data',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    var bodyFormData = new FormData();
    bodyFormData.append('logo', typeof payload.logo === "string" ? "": payload.logo === null ? "" :  payload.logo);
    bodyFormData.append('secondary_logo', typeof payload.secondary_logo === "string" ? "" : payload.secondary_logo === null ? "" : payload.secondary_logo);
    bodyFormData.append('sidebar_color', payload.sidebar_color);
    bodyFormData.append('text_over_sidebar_color', payload.text_over_sidebar_color);
    bodyFormData.append('background_color', payload.background_color);
    bodyFormData.append('text_over_background_color', payload.text_over_background_color);
    bodyFormData.append('primary_color', payload.primary_color);
    bodyFormData.append('text_over_primary_color', payload.text_over_primary_color);
    bodyFormData.append('background_over_primary_color', payload.background_over_primary_color);
    bodyFormData.append('disabled_button_background_color', payload.disabled_button_background_color);
    bodyFormData.append('disabled_text_over_primary_color', payload.disabled_text_over_primary_color);
    bodyFormData.append('call_to_action_color', payload.call_to_action_color);
    bodyFormData.append('disabled_button_text_color', payload.disabled_button_text_color);
    bodyFormData.append('text_input_background_color', payload.text_input_background_color);
    bodyFormData.append('text_input_text_color', payload.text_input_text_color);
    bodyFormData.append('text_input_placeholder_color', payload.text_input_placeholder_color);
    bodyFormData.append('list_highlight_color', payload.list_highlight_color);
    bodyFormData.append('list_highlight_border_color', payload.list_highlight_border_color);
    bodyFormData.append('menu_background_color', payload.menu_background_color);
    bodyFormData.append('text_over_menu_color', payload.text_over_menu_color);
    bodyFormData.append('success_color', payload.success_color);
    bodyFormData.append('warning_color', payload.warning_color);
    bodyFormData.append('error_color', payload.error_color);
    bodyFormData.append('theme', payload.theme);
    // for (var pair of bodyFormData.entries()) {
      
    // }
    axios
      .post(api_settings.url_base + api_endpoints.createTheme,  bodyFormData, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CREATE_THEME_VERSION_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_CREATE_THEME_VERSION_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_CREATE_THEME_VERSION_FAILED",
          payload: err.response.data
        })
      })
  }
}


export const deleteThemeVersion = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DELETE_THEME_VERSION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.createTheme + payload.id + '/', config)
      .then(response => {
        dispatch({
          type: "REQUEST_DELETE_THEME_VERSION_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500 || err.response.status === 0){
          dispatch({
            type: "REQUEST_DELETE_THEME_VERSION_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DELETE_THEME_VERSION_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const reloadThemeVersion = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_RELOAD_THEME_VERSION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.createTheme + payload, config)
      .then(response => {
        dispatch({
          type: "REQUEST_RELOAD_THEME_VERSION_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_RELOAD_THEME_VERSION_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_RELOAD_THEME_VERSION_FAILED",
          payload: err.response.data.error
        })
      })
  }
}


export const downloadThemeVersion = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DOWNLOAD_THEME_VERSION"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.downloadTheme + payload.id + "/" + payload.slug, config)
      .then(response => {
        dispatch({
          type: "REQUEST_DOWNLOAD_THEME_VERSION_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DOWNLOAD_THEME_VERSION_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DOWNLOAD_THEME_VERSION_FAILED",
          payload: err.response.data.error
        })
      })
  }
}


  

