const styles = (theme) => ({
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    },
    logo:{
        width:283,
        height:92,
        [theme.breakpoints.down('xl')]: {
            width:150,
            height:50
        }
    },
    logoutText:{
        fontFamily:"Roboto",
        fontSize:20,
        color:"#36333D",
        marginTop:33,
        fontWeight:"Regular",
        marginBottom:31,
        [theme.breakpoints.down('xl')]: {
            fontSize:18,
            marginTop:28,
            marginBottom:26
        }
        
    }
})

export default styles