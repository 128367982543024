import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable } from 'components';
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { string } from "yup";
import * as Yup from "yup";
import { CustomTextField } from 'elements';
import { Button} from '@mui/material';
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import {RiErrorWarningLine} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux"
import {getAllDatabases,createDatabase,deleteDatabase} from "../../redux/actions/legacyDatabase/databaseAction"
import Swal from 'sweetalert2';
import {useTranslation} from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';


const useStyles = makeStyles(styles)

export default function LegacyDatabases() {
  const {t} = useTranslation('common');
    let navigate = useNavigate();
 
    const classes = useStyles();

    const [dblist, setDBlist] = useState([]);

    const [open, setOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentDb, setCurrentDb] = useState();
   
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    }

    const theme = useTheme();
 
    const [page, setPage] = useState(1)
    const [allusersCount, setAllUsersCount] = useState(0);

    const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));

    const dispatch = useDispatch();
    const { loading,
      legacydb,
      createdata,
      deleteSuccess,
      downloadLogDetails, 
      downloadLogLoader,
      createError500,
      deleteError500,
      error } = useSelector((state) => state.legacy.database);
  
    const linkData = [ 
      {
          displayName:t('legacyDatabase.legacyDatabase'),
          link:"/legacy-databases"
      },
      {
          displayName:t('legacyDatabase.database'),
          link:"/legacy-databases/databases"
      }
  ]
  
  const thead = [
      {
        name:"name",
        displayName:t('legacyDatabase.name'),
        type:"text",
        width:30,  //percentage
      },
      {
        name:"id",
        displayName:t('legacyDatabase.code'),
        type:"number",
        width:30
      },
      {
          name:"filename",
          displayName:t('legacyDatabase.fileName'),
          type:"text",
          width:30
      },
      {
          name:"actions",
          displayName:t('legacyDatabase.actions'),
          type:"action",
          width:10
      },
  ]

    const formik = useFormik({
      initialValues: {    
        database: "",   
      },
      validationSchema: Yup.object({        
        database: string().required(t('legacyDatabase.enterDatabase')).max(255,t('legacyDatabase.DatabaseTooLong')),
        // company: string().required("please enter company name").max(25,"Companyname too long"),                                                    
      }),
      onSubmit : (values, formikHelpers)=> {
          dispatch(createDatabase(values))
          let payload = {
            page: page
          }
          dispatch(getAllDatabases(payload))
      }
    });

    useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
                    
          dispatch(deleteDatabase(currentDb))
          event.preventDefault();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }, [currentDb]);

    const formik1 = useFormik({
      initialValues: {    
        currentDb: currentDb,   
      },
      onSubmit : (values, formikHelpers)=> {
          dispatch(deleteDatabase(values.currentDb))
      }
    });

    const handleRowClick = (item) => () => {
      navigate("/dashboard/legacy/databases/details/"+item.id+"/")
    }


    useEffect(() => {  
      
      let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset
      }  
      dispatch(getAllDatabases(payload))
        
    },[page, createdata]);

    useEffect(() => {  
      if(legacydb?.data){
        setDBlist(legacydb.data)
        setAllUsersCount(legacydb.count)
      }
    }, [legacydb])

    useEffect(() => {  
      if(createdata?.status === "success"){
        setOpen(false)
        Swal.fire({
          title: t('legacyDatabase.success'),
          text: t('legacyDatabase.databaseCreated'),
          icon: 'success',
          iconColor: '#C8102E',
          confirmButtonColor: '#C8102E'
        })
          dispatch({
            type: "REQUEST_CREATE_DATABASE_SUCCESS",
            payload: null
          });
          formik.resetForm();
      }
    }, [createdata])

useEffect(() => {  
   if(downloadLogDetails?.status === 200){
     var base64replace1 = downloadLogDetails.data.data.replace("b'", "");
     var base64replace2 = base64replace1.replace("'", "");     
    //  download1("version.zip", base64replace2)
    const blob = b64toBlob(base64replace2, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      const blobUrl = URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
        a.href = blobUrl;
        a.download = "downloads-log";
        a.click();
      dispatch({
        type: "REQUEST_DOWNLOAD_LOG_SUCCESS",
        payload: null
      });
   }
},[downloadLogDetails]);

    const b64toBlob =(b64Data, contentType, sliceSize) =>  {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
      
        var byteArray = new Uint8Array(byteNumbers);
        
        byteArrays.push(byteArray);
      }
      
      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

    const handleDeleteClick = (item) => (e) =>{
      e.stopPropagation();
      setCurrentDb(item)
      setDeleteModal(!deleteModal)
    }

    useEffect(() => {     
        if(deleteSuccess?.status === 204) {
          setDeleteModal(!deleteModal)
            setCurrentDb()
            Swal.fire({
              title: t('legacyDatabase.success'),
              text: t('legacyDatabase.databaseDeleted'),
              icon: 'success',
              iconColor: '#C8102E',
              confirmButtonColor: '#C8102E'
            })
            let payload = {
              offset: 0
            } 
            setPage(1)
            dispatch(getAllDatabases(payload))
            dispatch({
              type: "REQUEST_DELETE_DATABASE_SUCCESS",
              payload: null
            });
        }
    }, [deleteSuccess]);

    const deleteContent = (
      <>
      <div className={classes.logo1}>
          <RiErrorWarningLine color="#C8102E" size={"100px"} />
      </div>
      <div className={classes.warning}>
          {t('legacyDatabase.areYouSure')}
      </div>
      <div className={classes.text} >
              
          {t('legacyDatabase.deleteConfirmation')} <span style={{fontWeight: 'bold'}}>{t('legacyDatabase.allVersion')}</span>{t('legacyDatabase.notRecoverable')}
              
      </div>
      <form >
        <div className={classes.button2}>
                <Button  
                onClick={()=> {
                  setCurrentDb()
                  setDeleteModal(!deleteModal)}}  
                  className='appBtnCancel'                                  
                  size="large"
                >
                  {t('legacyDatabase.cancel')}
                </Button>
                <div className={classes.ok}>
                    <Button 
                    className='appBtn'                        
                      size="large"

                      onClick ={() => dispatch(deleteDatabase(currentDb))}
                    >
                        {t('legacyDatabase.ok')}
                    </Button>
                </div>
        </div>
      </form>
    </>     
    )

    const errorBlockBtnClick = () => {
      let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset
      }  
      dispatch(getAllDatabases(payload))
    }

    const headerActions = (
          <>
              <Button onClick={handleOpen}><PlusIcon /> {t('legacyDatabase.createNew')}</Button>
              {/* <Button style={{marginLeft:30}} onClick={() => dispatch(downloadLog())}>Downloads Log</Button> */}
          </>
    )

    const bigHtmlContent = (
          <>
              <div className={classes.head}>{t('legacyDatabase.createDatabase')}</div>
              <Divider  className={classes.divide}/>
              <form onSubmit={formik.handleSubmit}>
              <CustomTextField
                
                  label={t('legacyDatabase.databaseName')}
                  name="database"
                  type="name"
                  placeholder={t('legacyDatabase.databaseName')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.database}
                  error={formik.touched.database && Boolean(formik.errors.database)}
                  helperText={formik.touched.database && formik.errors.database} 
                  labelColor="#000"
                  labelTextSize={16}
                  inputbackgroundcolor="#E8E8E8"
                  inputtextcolor="#000"
                  placeholderColor="#A5A5A5"
                  helpertextcolor="#C8102E"  
                  height={56}            
              />
              
            
              <div className={classes.buttons} >                   
                  <Button         
                      size="small"
                      onClick={handleClose}
                      className='appBtnCancel'
                  >
                      {t('legacyDatabase.cancel')}
                  </Button>                                          
                  <div className={classes.sub}>
                      <Button
                          className='appBtn'                  
                          type="submit"
                          size="meduim"
                      >
                          {t('legacyDatabase.ok')}
                      </Button>
                  </div> 
                                                      
              </div>   
              </form>    
          </>
    )   
    
    useEffect(() => {  
      if(createError500){
        toast.error(t('legacyDatabase.creationFailed') , {
          duration: 8000,
          position: 'top-right',
          style: {fontSize: 20, fontFamily: 'Roboto'}
        });
        dispatch({
          type: "REQUEST_CREATE_DATABASE_FAILED_500",
          payload: null
        })
      }

      if(deleteError500){
        toast.error(t('legacyDatabase.deleteFailed') , {
          duration: 8000,
          position: 'top-right',
          style: {fontSize: 20, fontFamily: 'Roboto'}
        });
        dispatch({
          type: "REQUEST_DELETE_DATABASE_FAILED_500",
          payload: null
        })
      }
      
    }, [createError500, deleteError500])

  return (
    <DashboardLayout pageLoading={downloadLogLoader} >
      <Toaster />
        <BreadCrumb linkData={linkData}/>
        <CustomTable 
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('legacyDatabase.database')}
            thead={thead}
            tdata={dblist}
            maxRow={10}
            handleRowClick={handleRowClick}
            handleDeleteClick={handleDeleteClick}
            headerActions={headerActions}
            height={isSmallerDesktop?617:767}
            loadPagination={setPage}
            clickableColumn={0}
            action={
               { 
                default: ["delete"],              
               }
              }
            currentPage={page}
            errorStatus={error} 
            errorBlockBtnClick={errorBlockBtnClick}
        />
        <Modals 
            handleclose={handleClose} 
            open={open} 
            content={bigHtmlContent}                   
        />

        <Modals 
            handleclose={() => setDeleteModal(!deleteModal)} 
            open={deleteModal} 
            content={deleteContent}                   
        />
        
       
    </DashboardLayout>
  )
}
