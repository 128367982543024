import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";

export const resetWithJWT = (user) => {
  
  return dispatch => {
    dispatch({
      type: "RESET_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .patch(api_settings.url_base + api_endpoints.reset, {  
        token: user.resettoken,
        uidb64: user.resetuidb64,  
        password: user.password,
        password2: user.confirm        
      }, config)
      .then(response => {
        dispatch({
          type: "RESET_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: "RESET_FAILURE",
          payload: err.response.data.error
        })
      })
  }
}