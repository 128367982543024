import React,{useState, useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable } from 'components';
import { useDispatch, useSelector } from "react-redux"
import { Grid,Button,IconButton} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AnalyticsModal from 'components/Modal/AnalyticsModal';
import styles from "./style";
import { makeStyles } from '@mui/styles';
import {AiFillCloseSquare} from 'react-icons/ai';
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { getAllBucketLocations } from 'redux/actions/analytics/locationAnalyticsAction';
import { getAllBuckets } from 'redux/actions/xdata/bucketActions';

  const linkData = [ 
      {
          displayName:"XData",
          link:"/x-data"
      },
      {
          displayName:"Analytics",
          link:"/xdata/analytics"
      },
      {
          displayName:"Locations",
          link:"/xdata/analytics/locations"
      }
  ]


/* name is key which is taking from BE */

  const thead = [
      {
        name:"dealer_code",
        displayName:"Dealer ID",
        type:"text",
        width:20,  //percentage
      },
      {
        name:"pos_name",
        displayName:"Location Name",
        type:"text",
        width:30
      },
      {
        name:"pos_address",
        displayName:"Location Address",
        type:"text",
        width:30
      },
      {
          name:"pos_country",
          displayName:"Location Country",
          type:"text",
          width:30
        },
        {
          name:"bucket_id",
          displayName:"bucket",
          type:"text",
          width:10
        },
  ]

  const useStyles = makeStyles(styles)



export default function AnalyticBucketLocationDashboard() {

    let { code } = useParams();
    const [open, setOpen] = useState(false);

    const {t} = useTranslation('common');
  const [page, setPage] = useState(1)
  const [locationList, setLocationlist] = useState([])
  const [bucketList, setBucketList] = useState([])
  let navigate = useNavigate();
  const [posPage, setPosPage] = useState(1)
  // const [lastItem, setLastItem] = useState(null);
  const [posNextPage, setPosNextPage] = useState("");
  
  
  const [locationsCount, setLocationsCount] = useState(0);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [bucketItems, setBucketItems] = useState([]);
  const classes = useStyles() 

    const theme = useTheme();
    const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
    const { loading, location} = useSelector((state) => state.analytics.locations);
    const { loading:bucketLoading, buckets} = useSelector((state) => state.xdata.buckets);

    const handleRowClick = (item) => () => {
        navigate("/dashboard/xdata/analytics-location/"+item.bucket_id+"/",{
          state:item
        }) 
    }

    const handleClose = () =>{
      setBucketList([])
      setBucketItems([])
      setOpen(false);
      let bucketPayload = {
        offset: 0,
        code: code
      }  
      dispatch(getAllBucketLocations(bucketPayload))
    } 

    useEffect(() => { 
      let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset,
        search: searchText,
        bucketItems: code
      }  
      dispatch(getAllBucketLocations(payload)) 
      
    },[page, searchText]);

   

    useEffect(() => {  
      
      if(buckets?.data){
          setBucketList(buckets.data)
      }
    }, [buckets])

    useEffect(() => {  
      if(location?.data){
          setLocationlist(location.data)
          setLocationsCount(location.count)
      }
    }, [location])

    const onSearchChange = (e) => {
      setPage(1)
      setSearchText(e.target.value)
    }

    const headerFilter = (
      <>
          <Button style={{marginLeft:30}} onClick={() => bucketItems.length > 0 ? clearFilter()  : filterOpen()}>{bucketItems.length > 0 ?  t('xdataLocation.clearFilter') : t('xdataLocation.filter')}</Button>
      </>
  )

  const clearFilter = () => {
    setBucketItems([])
    let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset,
        search: searchText,
        bucketItems: "",
        code: code
      }  
      dispatch(getAllBucketLocations(payload))

      let bucketPayload = {
        offset: 0,
        code: code
      }  
      dispatch(getAllBucketLocations(bucketPayload)) 
      
  }

  const onChangeCheck = (item) => () => {
    var exArray = [...bucketItems]
      let already_in = false
      exArray.map((itemv,keyi)=>{ 
        if(itemv===item.code){
          already_in = true;
          exArray.splice(keyi, 1);
        }
      })
      if(already_in === false){
        exArray.push(item.code)
      }
      
      setBucketItems(exArray)
  }

  const onFilterSubmit = () => {
    
    let paginationPage = page;
      let paginationOffset = 0;
      if(page > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset,
        search: searchText,
        bucketItems: code,
      }  
      dispatch(getAllBucketLocations(payload)) 
      setOpen(!open)
  }

  const loadMore = () => {
        
    setPosPage(posPage+1)
    if(posNextPage !== ""){
      if(posNextPage === null){

      }else{
        dispatch(getAllBuckets({offset:posNextPage}))   
      }          
    }else{
      // getPosList(lastItem)
    }       
}

  useEffect(() => {  
    if(buckets){
      setPosNextPage(buckets?.next)
      if(buckets.data.length > 0){
        let prev_posList = bucketList
        buckets.data.map((item,index)=>{ 
          prev_posList.push(item)
        })
        setBucketList(prev_posList)
      }else{
        setBucketList([])
      }
    }        
  },[buckets]);

  const filterOpen = () => {
    setBucketList([])
    let bucketPayload = {
      offset: 0
    }  
    dispatch(getAllBuckets(bucketPayload)) 
    setOpen(true)
  }

  useEffect(() => {  
    let paginationPage = posPage;
      let paginationOffset = 0;
      if(posPage > 1){
        paginationPage = paginationPage - 1;
        paginationPage = paginationPage * 10;
        paginationOffset = paginationPage;
      }
      let payload = {
        offset: paginationOffset,
        code:code
      }  
      dispatch(getAllBucketLocations(payload)) 
  },[]);

  const bigHtmlContent = (
    <>
    <div className={classes.head}>                 
            <div className={classes.text} >{t('xdataLocation.filter')}</div>                               
            <div className={classes.button}>
                <IconButton onClick={() => handleClose()}>
                    <AiFillCloseSquare color='#C8102E' size="30px"/>
                </IconButton>
            </div>                
        </div>
    <Grid container item xs={12} >
      {
        bucketList.map((item, index) => {
          return(
            <Grid item xs={6}>
                  <FormControlLabel  name="poslist" value={item.code} onChange={onChangeCheck(item)}  control={<Checkbox checked={item.code === code ? true : false} style ={{color: "#C8102E"}} />} 
                  label={item.name + " - " + item.code}/>
            </Grid>
          )
        })
      }
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
        {bucketLoading ?<Box sx={{ textAlign: 'center', width:'100%',marginTop:5}}><CircularProgress sx={{color:'#c8102e'}}/></Box>
        :
        posNextPage !== null ?
        <Button  
        variant="contained"        
        style={{background: '#000'}}
        onClick={() => loadMore()}
        >
          {t('xdataLocation.loadmore')}
      </Button>: null}
            
          </Grid>
          <div className={classes.button1}>
              <Button          
              type="submit"
              variant="contained"   
              onClick={() => onFilterSubmit()} 
              >
                {t('xdataLocation.filter')}
              </Button>
          </div>
      </Grid>
    </>     
  )
    
    

  return (
    <DashboardLayout>
        <BreadCrumb linkData={linkData}/>
        <CustomTable 
        loading={loading}
            title ="Locations"
            thead={thead}
            tdata={locationList}
            maxRow={10}
            handleRowClick={handleRowClick}
            height={isSmallerDesktop?617:767}
            totalPageCount={locationsCount}
            currentPage={page}
            loadPagination={setPage}
            search={true}
            onChange={onSearchChange}
            value={searchText}
            searchLabel="Search Locations..."
            headerFilter={headerFilter}
            clickableColumn={1}
        />

        <AnalyticsModal 
            handleclose={handleClose} 
            open={open} 
            scrollBottom={true}
            content={bigHtmlContent}
        /> 

    </DashboardLayout>
  )
}
