const styles = (theme) => ({
    cardDiv:{
        width:'100%'
    },
    carretDownIcon: {
        width: 9,
        float: 'right',
        marginTop: 3
    },
    chartHeader: {
        display: 'flex',
        width: '100%'
    },
    datechartHeader:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    chartTitle: {
        font: 'normal normal bold 15px/15px Roboto',
        width: "80%"
    },
    chartLeftBtns: {
        width: '50%',
        display: 'flex',
    },
    chartTitleFullWidth: {
        width: '100%',
        font: 'normal normal bold 15px/15px Roboto'
    },
    chartRightDiv: {
        width: '50%',
        textAlign: 'right'
    },
    datechartRightDiv:{
        display: 'flex',
        alignItems: 'center'
    },
    datepickerstyle : {
        display: "table-cell !important",
        flex: "1 1 auto",
        minWidth: 185,
        marginBottom: "10px"
    },
    chartRightChild: {
        marginRight: '5px',
        font: 'normal normal normal 13px/15px Roboto'
    },
    CalendarIcon: {
        position: 'relative',
        top: 5,
        width: 15,
        marginRight: 3
    },
    DownloadIcon: {
        position: 'relative',
        top: 2,
        width: 15
    },
    viewGraphBtn: {
        width: 9
    },
    row: {
        width: '100%',
        position:"relative",
    },
    col60: {
        width:"63%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'right',
        marginBottom: '3%'
    },
    colleft50: {
        width:"48%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'left',
        marginBottom: '3%'
    },

    colright50: {
        width:"48%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'right',
        marginBottom: '3%'
    },
    colLeft30: {
        width:"35%",
        height: 450,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 2px 10px #00000029',
        borderRadius: 5,
        padding: '2% 3% 2% 3%',
        float: 'right',
        marginBottom: '3%'
    },
    DoubleWhiteCardChild: {
        position:"relative",
        width:"100%",
        height: 500,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #DFDFDF',
        borderRadius: 10,
        padding: '2% 3% 2% 3%',
        marginBottom: '1%',
        marginTop: '2%'
    },

    text3:{
        fontSize: 20,
        fontFamily:"Roboto",
        fontWeight:"400",
        [theme.breakpoints.up("xl")]:{
           fontSize: 30,
        },
     },
     button3: {     
        justifyContent:"end",
        textAlign: "end"
     },
     main: {
        display:"flex",
        justifyContent:"space-between",
        alignItems: "center",
        textAlign:"end"     
     },
     divide:{
        marginTop: "10px !important",
        marginBottom:"30px !important"
    },
    headInfo: {
        background: '#FFC0CD',
        borderRadius: 10,
        height: 60,        
        padding: '0 2% 0 2%',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Roboto'
    },
    headInfoChild: {
        marginRight: '2%',
        borderRight: '1px solid #797979',
        paddingRight: '2%'
    },
    headInfoRightChild: {
        width:"50%",
        display: 'flex',
        justifyContent: 'end'
    },
    headInfoChildLeft: {
        width:"50%",
        display: 'flex',
        alignItems: 'center',
    },
    chartDiv: {
        display: 'flex',
        marginTop: '2%'
    },
    chartDivChild1: {
        width:"49%",
        marginRight: '2%',
    },
    chartDivChild2: {
        width:"49%",
    }, 
    notificationSpan: {
        background: '#FF899D',
        padding: '4px 10px 4px 2px',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 8,
    },
    notificationStatusText: {
        color: '#C8102E'
    },
    notificationCheckIcon: {
        color: '#C8102E'
    },
    loaderDiv: {
        display: 'flex', 
        justifyContent: 'center', 
        transform: 'translateY(460%)'
    },
    nodataText: {
        fontSize: 20, font: 'normal normal normal 30px Roboto'
    }
})

export default styles