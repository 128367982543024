import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Grid,Button,InputAdornment,InputBase, Divider, TextField} from '@mui/material';
import styles from "./style";
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import {AiFillCloseSquare} from 'react-icons/ai';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import { RadioGroup } from '@mui/material';
import Radio from '@mui/material/Radio';

const theme= createTheme({
  ccomponents: {
    MuiButton:{ 
        varient: "text"
    }
  }

})



const useStyles = makeStyles(styles)

const SearchBar = styled(InputBase)(({ theme }) => ({
  fontFamily:"Montserrat-Light",
  fontSize:15,
  fontWeight:300,
  width: "100%",
  height: "100%",
  maxHeight:59,
  borderRadius: 15,
  backgroundColor:"#F3F3F4",
  padding:"20px",
  color:"#36333D",
  "& .search-icon":{
      color:"#696969",
      width:20,
      height:20
  },
  [theme.breakpoints.down("xl")]:{
      padding:15,
      // width:264,
      fontSize:13,
      fontWeight:300,
      maxHeight:45,
  },
  // [theme.breakpoints.down("md")]:{
  //     width:"100%",
  //     marginTop:10
  // }
}));

const StyledFormControll = styled(FormControlLabel)(({ theme }) => ({
  maxWidth: '100%',
  alignItems: 'normal',
  marginTop: 10,
  '& .MuiFormControlLabel-label': {
    maxWidth: '100%',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '80%!important',
    }
  },
  '& .MuiCheckbox-root': {
    paddingTop: 'unset'
  }
}));


   
export default function ExporterModal({value,onChange,onSubmit,onChange1,value1,value2,date_type_analytics,onChange2,onChangeCheck,error,errorenddate,helperText,helperTextenddate,click,posList,checkSelectAll,posSelectAll,posLoading,handlePOSSearch,posNextPageUrl,loadMore, checkedPosList, posSearch, formik, legacyExporter}) {

  const classes = useStyles() 
  const {t} = useTranslation('common');


  return(
    <>
    <form onSubmit={onSubmit}>
      <div className={classes.head}>                 
          <div className={classes.text} >{t('analyticsExporterModal.analyticsExporter')}</div>                               
          <div className={classes.button}>
              <IconButton onClick={click}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
      <Divider />
      <Grid className={classes.grid} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        {
          formik && 
          <>
            <Grid item xs={12} >
              <InputLabel className={classes.inputLabel} >{t('analyticsExporter.datetype')}</InputLabel>
            </Grid>
              <Grid item xs={12} >
                <RadioGroup name='date_type_analytics' defaultValue={"lastyear"} style={{display: 'inline-block'}}>
                  <FormControlLabel onChange={formik.handleChange} style={{marginTop: 3}}  name="date_type_analytics" value="lastyear"  control={<Radio  style ={{color: "#C8102E"}} />} 
                  label={t('XdataAnalyticsDetail.lastyear')}/>
                  <FormControlLabel onChange={formik.handleChange} style={{marginTop: 3}}  name="date_type_analytics" value="thisyear"  control={<Radio  style ={{color: "#C8102E"}} />} 
                  label={t('XdataAnalyticsDetail.thisyear')}/>
                  <FormControlLabel onChange={formik.handleChange} style={{marginTop: 3}}  name="date_type_analytics" value="lastmonth"  control={<Radio  style ={{color: "#C8102E"}} />} 
                  label={t('XdataAnalyticsDetail.lastmonth')}/>
                  <FormControlLabel onChange={formik.handleChange}  style={{marginTop: 3}}  name="date_type_analytics" value="thismonth"  control={<Radio  style ={{color: "#C8102E"}} />} 
                  label={t('XdataAnalyticsDetail.thismonth')}/>
                  <FormControlLabel onChange={formik.handleChange}  name="date_type_analytics" value="custom"  control={<Radio  style ={{color: "#C8102E"}} />} 
                      label={t('XdataAnalyticsDetail.customDate')}/>
                </RadioGroup>
            </Grid>
          </>
        }
        
        {
          date_type_analytics === "custom" || legacyExporter === true ?
          <>
            <Grid item xs={6} >
              <InputLabel className={classes.inputLabel} >{t('analyticsExporterModal.startdate')}</InputLabel>
              <div className={classes.field}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={onChange}              
                  InputProps={{ disableUnderline: true}}
                  renderInput={(params) => 
                  <TextField
                    fullWidth
                    variant="filled"
                    error={error}
                    helperText={helperText}
                    placeholder={t('analyticsExporterModal.SelectDate')}
                    {...params}
                    />
                  } 
                />
              </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel} >{t('analyticsExporterModal.enddate')}</InputLabel>
              <div className={classes.field}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker          
                value={value1}
                onChange={onChange1}
                InputProps={{ disableUnderline: true}}
                renderInput={(params) => 
                <TextField
                  fullWidth
                  variant="filled"
                  error={errorenddate}
                    helperText={helperTextenddate}
                  placeholder={t('analyticsExporterModal.SelectDate')}
                  {...params}
                  />
                } 
              />
              </LocalizationProvider>
              </div>
            </Grid>
          </> : null
        }
        
        <Grid item xs={6} >
        <InputLabel className={classes.inputLabel} >{t('analyticsExporterModal.posname')}</InputLabel>
          <SearchBar
              name="posname"
              placeholder={t('analyticsExporterModal.searchpos')}
              // value={value2}
              onChange={onChange2}
            //   endAdornment = {
            //     <InputAdornment position="end" onClick={handlePOSSearch} sx={{cursor:'pointer'}}>
            //         <SearchIcon className="search-icon"/>
            //     </InputAdornment>
            // }
          />
        </Grid>
        {posList.length > 0 &&<Grid item xs={6} >
        <ThemeProvider theme={theme}> 
          <div className={classes.button2}>
            <FormControlLabel  onChange={checkSelectAll} control={<Checkbox checked={posSelectAll ? true : false} style ={{color: "#C8102E"}} />} 
              label={t('analyticsExporterModal.selectAll')}/>          
          </div>
        </ThemeProvider>
        </Grid>} 
        <Grid container item xs={12} >
          {value2.length > 0 ?

          
        <div style={{maxHeight: 200, overflowY: 'auto',padding: 10, width: '100%', fontFamily: 'Roboto',border: "2px solid #EAEAEA", borderRadius: 5}}>
          <div style={{marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{fontSize: 17, fontWeight: 'bold'}}>{t('analyticsExporterModal.selectedPos')}</div>
            </div> 
            <Grid container item xs={12} >
            {
              checkedPosList.map((item, index) => {
                return(
                    item.checked === true ? 
                    <Grid item xs={4}>
                        <StyledFormControll className={classes.gridLabel}  name="poslist" value={item.pos_name} onChange={onChangeCheck(item, index)}  control={<Checkbox checked={item.checked ? true: false} style ={{color: "#C8102E"}} />} 
                        label={item.pos_name}/>
                     </Grid> : null
                  
                )
              })
            }
            </Grid>
       </div>   
       
       :null}
       </Grid>  
       <div style={{maxHeight: 200, overflowY: 'auto', paddingLeft: 24, marginTop: 20, marginBottom: 20, width: '100%'}}>
        <Grid container>
          {posList.map((item,index) => {
            return(
              item.checked === false ? 
              <Grid item xs={4}>
                <StyledFormControll   name="poslist" value={item.pos_name} onChange={onChangeCheck(item, index)} control={<Checkbox checked={item.checked ? true: false} style ={{color: "#C8102E"}} />} 
                label={item.pos_name}/>
              </Grid>:null
            )
          })}
          </Grid>
          </div> 
          {posLoading&&<Box sx={{ textAlign: 'center', width:'100%',marginTop:5}}><CircularProgress sx={{color:'#c8102e'}}/></Box>}
      </Grid>
      {!posLoading && posNextPageUrl !== "" && posNextPageUrl !== null && posNextPageUrl !== undefined && <>
      {posSelectAll ?
          <Grid item xs={12} style={{backgroundColor: "#fff", opacity:0.3, pointerEvents:'none', display: 'flex', justifyContent: 'center'}}>
            <Button   
              variant="contained"        
              style={{background: '#000'}}
              onClick={loadMore}
              >
                {t('analyticsExporterModal.loadmore')}
            </Button>
          </Grid>
          :
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <Button   
              variant="contained"        
              style={{background: '#000'}}
              onClick={loadMore}
              >
                {t('analyticsExporterModal.loadmore')}
            </Button>
          </Grid>
          }
        </>}
      <div className={classes.button1}>
          <Button          
          type="submit"
          variant="contained" 
          disabled={value2.length > 0 ? false : true}   
          >
            {t('analyticsExporterModal.export')}
          </Button>
      </div>
      </form>                
      
    </>
  )
}