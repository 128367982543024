import React,{useMemo} from 'react'
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import {ReactComponent as RightArrow} from "assets/icons/caret-right.svg"

const Root = styled('div')(({ theme }) => ({
    display:"flex",
    alignItems:"center",
    marginBottom:25,
    "& .arrow":{
        marginLeft:15,
    },
    "& .arrow-active":{
        marginLeft:15,
        "& >path":{
            fill:"#36333D"
        }
    },
    "& .text":{
        fontFamily:"Roboto",
        fontWeight:400,
        fontSize:12,
        marginLeft:16,
        color:"#626669",
    },
    "& .text-active":{
        fontFamily:"Roboto",
        fontWeight:400,
        fontSize:12,
        marginLeft:16,
        color:"#36333D",
        // cursor:"pointer"
    },
    [theme.breakpoints.down('xl')]: {
        marginBottom:20,
        "& .arrow":{
            marginLeft:10,
        },
        "& .arrow-active":{
            marginLeft:10
        },
        "& .text":{
            fontSize:12,
            marginLeft:11,
        },
        "& .text-active":{
            fontSize:12,
            marginLeft:11,
        },
    },
    [theme.breakpoints.up('xl')]: {
        "& .text":{
            fontSize:19,
            marginLeft:11,
        },
        "& .text-active":{
            fontSize:19,
            marginLeft:11,
        },
    }
}));

export default function BreadCrumb({linkData}) {
    let navigate = useNavigate();
    const lastIndex = useMemo(()=>{
        if(linkData?.length){
            return (linkData?.length)-1
        }
        return -1
    },[linkData])
  return (
    <Root>
      {/* <HomeIcon /> */}
      {linkData.map((item,index) => {
        return(
            <React.Fragment key={index}>
                {index!==0&&<RightArrow className={index===lastIndex ?'arrow-active':'arrow'}/>}
                 <span style={item.linkClick ? {cursor: 'pointer'} : null} className={index===lastIndex ?'text-active':'text'} onClick={() => item.linkClick ? 
                    navigate(item.link) :
                    null}
                    >{item.displayName}</span>
            </React.Fragment>
        )
      })}
    </Root>
  )
}
