import React from 'react'
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { Box,Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DataView  from "assets/images/data-view.svg";
import { ReactComponent as Logo } from "assets/images/corob-logo.svg";
import { useSelector } from "react-redux"

const useStyles = makeStyles(styles)

const PublicLayout = ({children}) => {
  const classes = useStyles()
  const { loading } = useSelector((state) => state.auth.forgot);
  return (
    <div className={classes.container}>
      {loading&&<div style={{width:'100%', height:'100%', backgroundColor:'#000',opacity:'0.6',position:'fixed',zIndex:1200}}>
            <Box sx={{ textAlign: 'center',marginTop: '20%' }}>
                <CircularProgress sx={{color:'#fff'}}/>
            </Box>
        </div>}  
        <Grid  container direction="row">
            <Grid item sm={6} className={classes.firstContainer}>
              <div className={classes.formContainer}>
                  <Logo  className={classes.corobLogo}/>
                  {children}
              </div>
            </Grid>
            <Grid item sm={6} className={classes.secondContainer}>
              <img src={DataView} alt="data view" className={classes.image}/>
            </Grid>
      </Grid>
    </div>
  )
}

export default PublicLayout;
