import React, {useEffect} from 'react';
import { store } from "../redux/storeConfig/store";
import {Navigate, Outlet} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { 
  getBackendRelease,
  getFrontEndRelease
} from "../redux/actions/auth/githubActions";

const useAuth=()=>{

  const { user } = store.getState().auth.login;
  if(user){
    return true
  } else {
  return false
  }

}

const  PrivateRoutes=(props) =>{
  const dispatch = useDispatch()

  const auth = useAuth()
  useEffect(() => {
    dispatch(getBackendRelease())
    dispatch(getFrontEndRelease())
  }, [])
  

  return auth?<Outlet/>: <Navigate to="/sign-in?login-required"/>
}

export default PrivateRoutes;