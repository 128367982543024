import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { WhiteLayout } from 'layouts';
import Button from '@mui/material/Button';
import ResetLogo from "assets/images/reset.svg";
import { useDispatch, useSelector } from "react-redux";
import {queryResolvedAction} from "../../redux/actions/queryresolved/queryResolvedActions"
import queryString from 'query-string';


import { ReactComponent as Logo } from "assets/images/corob-logo-red.svg";

const useStyles = makeStyles(styles)

export default function QueryResolved(props) {
    const classes = useStyles()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { queryResolvedError, queryResolvedSuccess } = useSelector((state) => state.queryresolved.queryresolved);

    useEffect(() => { 
        const parsed = queryString.parse(window.location.search);
        dispatch(queryResolvedAction(parsed.token, parsed.pk, parsed.hmac))
    },[]);

    const handleLoginClick = () => {
        navigate("/sign-in")
    }
    
  return (
    <WhiteLayout>
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <div className={classes.contentdiv}>
                {
                    queryResolvedError ?
                        <>
                            <div className={classes.errorText}>{queryResolvedError.message}</div>
                        </>
                    :
                        <>
                            <img src={ResetLogo} alt='success' className={classes.icon} /> 
                            <div className={classes.resolveText}>User Query resolved Successfully!</div>
                        </>
                }
            </div>
            {/* <Button onClick={() =>   dispatch(companyActivationAction(parsed.token))} style={{marginTop: 30}}>register  </Button> */}
            <Button onClick={handleLoginClick} style={{marginTop: 30}}>Login </Button>
        </div>
    </WhiteLayout>
  )
}