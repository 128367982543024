import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable } from 'components';
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from "formik";
import {  string } from "yup";
import * as Yup from "yup";
import { CustomTextField } from 'elements';
import { Button, InputLabel} from '@mui/material';
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import {RiErrorWarningLine} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux"
import { getAllBuckets, createBucket, downloadLogs, deleteBucket, updateBucket } from "../../redux/actions/xdata/bucketActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import {getCompanyList} from "../../redux/actions/auth/registerActions";
import {getUserCompanyList} from "../../redux/actions/auth/registerActions";
import { search } from 'components/Search/Search';
import { api_settings } from "../../redux/services/api";

const useStyles = makeStyles(styles)

const SelectContainer = styled('div')(({ theme }) => ({
  '& .label':{
      color:'#000',
      fontFamily:"Roboto",
      fontSize:16,
      fontWeight:500,
      marginBottom:17,
      lineHeight:1,
  },
  [theme.breakpoints.down("xl")]:{
      '& .label':{
      fontSize:`${16-2}px`,
      fontWeight:500,
      marginBottom:12,
      }
  },
  [theme.breakpoints.up("xl")]:{
      '& .label':{
      fontSize:`${20}px`,
      }
  }

}))

const SelectTextField = styled(TextField)(({ theme }) => ({
  backgroundColor:"transparent",
  borderRadius:10,
  minHeight:`${45+23}px`,
  "&  .MuiFormHelperText-root.Mui-error": { //<--- here
      color:"#C8102E"
    },
    "& .MuiInputBase-root":{
      backgroundColor:'#E8E8E8',
      borderRadius:10,
    },
    "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before":{
      borderBottom : 'none !important'
    },
    "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root.Mui-error:after":{
      borderBottom : 'none !important'
    },
    "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after":{
      borderBottom : 'none !important'
    },
  input:{
      backgroundColor:'#E8E8E8',
      fontFamily:"Roboto",
      fontSize:18,
      fontWeight:300,
      height:44,
      padding:"0px 42px !important",
      borderRadius:10,
      color:'#000',
      "&::placeholder":{
          color:'#000'
      }, 
  },
  [theme.breakpoints.down("xl")]:{
      minHeight:`${45+18}px`,
      input:{
          fontSize:13,
          padding:"0px 37px",
      },
  },
  [theme.breakpoints.up("xl")]:{
      minHeight:`${45+25}px`,
      input:{
          height:`${45+4}px`,
          fontSize:18,
          padding:"0px 37px",
      },
  }

}))

export default function XDataBuckets() {
  const {t} = useTranslation('common');
  const { companyListSuccess, userCompanyListSuccess } = useSelector((state) => state.auth.register);
  const [companyList, setCompanyList] = useState([]);
  const [newcompanyList, setNewCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [defaultCompany, setDefaultCompany] = useState("");
  const [bucketCode, setBucketCode] = useState("");
  const [bucketName, setBucketName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userIdentity, setUserIdentity] = useState("");
  
  const [emailSent, setEmailSent ] = useState(false);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  
  const linkData = [ 
    {
        displayName:t('XdataBuckets.XData'),
        link:"/x-data"
    },
    {
        displayName:t('XdataBuckets.Buckets'),
        link:"/xdata/buckets"
    }
  ]

  const thead = [
    {
      name:"code",
      displayName:t('XdataBuckets.Code'),
      type:"text",
      width:20
    },
    {
      name:"name",
      displayName:t('XdataBuckets.Name'),
      type:"text",
      width:35,  //percentage
    },
    {
      name: "company_name",
      displayName: t('XdataBuckets.Company'),
      type:"text",
      width:35
    },
    {
        name:"actions",
        displayName:t('XdataBuckets.Actions'),
        type:"action",
        width:10
    },
  ]

    let navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [btnOpen, setbtnOpen] = useState(false);
    const [bucketlist, setBucketlist] = useState([]);
    const [deleteItem, setDeleteItem] = useState(null);
    const [workspaceAdmin, setWorkspaceAdmin] = useState(null);

    const dispatch = useDispatch();
    const { loading, 
      buckets, 
      createSuccess, 
      downloadLogsLoading, 
      downloadLogsSuccess, 
      deleteBucketLoading, 
      deleteBucketSuccess,
      create500Error,
      deleteBucketError500,
      downloadLogs500Error,
      updateLoading,
      updateSuccess,
      updateError,
      error} = useSelector((state) => state.xdata.buckets);
    const [page, setPage] = useState(1)
    const [allusersCount, setAllUsersCount] = useState(0);
    const { user } = useSelector((state) => state.auth.login);
   
  
    const handleOpen = () => {
      setDefaultCompany("")
      setCompanyId(user.company)
      formik.setFieldValue("company", user.company_name)
      setOpen(true)
    };

    // added new if condition to reset only bucket name of form only for new users 
    const handleClose = () => {
      if(userIdentity === 'no'){
        formik.setFieldValue("bucket", "")
        setBucketCode("")
        setBucketName("")
        setDefaultCompany("")
        setOpen(false);
      }
      else{
        formik.resetForm();
        setBucketCode("")
        setBucketName("")
        setCompanyId("")
        setDefaultCompany("")
        setOpen(false);
      }
    }


    // identify @corob.com user and other domain users.
    useEffect(() => {  
      let name = null
        if(impersonateUserInfo?.status === "success"){
          let impersonateUserInfoData = impersonateUserInfo?.data
          name = impersonateUserInfoData.email;
        }
        else {
          name = user.email;
        }
        const domname = ["corob"];
        const data = name.split('@')[1].split(".")[0].toLowerCase();
        if (domname.includes(data)) {
              setUserIdentity("yes")
        } 
      else {
        setUserIdentity("no")
        }
    }, [])

    const buttonClose = () => setbtnOpen(false);

    const handleDeleteClick = (item) => (e) => {
      e.stopPropagation()
      setDeleteItem(item)
      setbtnOpen(true)
    }

    const handleDeleteConfirm = () => {          
      dispatch(deleteBucket(deleteItem)) 
    } 

    

    const formik = useFormik({
        initialValues: {
          bucket: "",
          company: "", 
        },
        validationSchema: Yup.object({        
          company: string().required(t("XdataBuckets.SelectCompanyname")), 
          bucket: string().required(t('XdataBuckets.Pleaseenterbucket')).max(255,t('XdataBuckets.Bucketnametoolong')),                    
        }),
        onSubmit : (values, formikHelpers)=> {
          if(bucketCode === ""){
            dispatch(createBucket(values, companyId)) 
          }
          else{
            if(companyId === defaultCompany && bucketName === values.bucket){
              toast.error(t("XdataBuckets.noChanges") , {
                duration: 8000,
                position: 'top-right',
                style: {fontSize: 20, fontFamily: 'Roboto'}
              })
            }
            else{
              dispatch(updateBucket(values, companyId, bucketCode)) 
            }
            
          }
        }
    });

    const theme = useTheme();
    const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));

    const handleRowClick = (item) => () => {
      navigate("/dashboard/xdata/buckets/details/"+item.code+"/")
    }  

    const handleEditClick = (item) => () => {
        formik.setFieldValue("bucket", item.name)
        formik.setFieldValue("company", item.company_name ? item.company_name : "")
        setDefaultCompany(item.company)
        setCompanyId(item.company)
        setBucketCode(item.code)
        setBucketName(item.name)
        setOpen(true)
    }
    
    const handleDownloadLogs = () => {            
      dispatch(downloadLogs()) 
    }  

    const headerActions = (
        <>
            <Button onClick={handleOpen}><PlusIcon /> {t('XdataBuckets.CreateNew')}</Button>
            <Button onClick={handleDownloadLogs} style={{marginLeft:30}}>
              {downloadLogsLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('XdataBuckets.DownloadsLog')}</Button>
        </>
      )

    const bigHtmlContent = (
        <>
            <div className={classes.head}>{t('XdataBuckets.CreateBucket')}</div>
            <Divider  className={classes.divide}/>
            <form onSubmit={formik.handleSubmit}>
              <CustomTextField              
                  label={t('XdataBuckets.Bucketname')}
                  name="bucket"
                  type="name"
                  placeholder={t('XdataBuckets.Bucketname')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bucket}
                  error={formik.touched.bucket && Boolean(formik.errors.bucket)}
                  helperText={formik.touched.bucket && formik.errors.bucket} 
                  labelColor="#000"
                  labelTextSize={16}
                  inputbackgroundcolor="#E8E8E8"
                  inputtextcolor="#000"
                  placeholdercolor="#A5A5A5"
                  helpertextcolor="#C8102E"  
                  height={56}            
              />
              <Autocomplete
                  noOptionsText={
                    <>
                        <p>{t("companyAdd.doesnotExist")} <a style={{color: "#C8102E", cursor: 'pointer'}} onClick={() => viewMoreClick()}>{t("companyAdd.viewMore")}</a></p>
                  </>}
                    sx={{ width: '100%' }}
                    options={companyList}
                    autoHighlight
                    isOptionEqualToValue={(option, value) => option.company_name === value.company_name}
                    getOptionLabel={(option) => option.company_name}                   
                    renderOption={(props, option) => (
                        <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        
                        {option.company_name}
                        </Box>
                    )}
                    onChange={(event, newValue) => {
                        setCompanyId(JSON.stringify(newValue.id))
                      }}
                      defaultValue={bucketCode === "" ? {company_name: user.company_name}:{ company_name: formik.values.company ? formik.values.company : ""}}
                    renderInput={(params) => (
                        
                        
                        <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                            <InputLabel className='label' required={true}>{t('XdataBuckets.Company')}</InputLabel>
                            <SelectTextField
                            variant="standard"
                            type="text"                                
                            name="company"
                            id="as"
                            {...params}
                            placeholder={t('signUp.company')}
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            onBlur={() =>formik.setFieldValue("company", params.inputProps.value)}
                            // onFocus={() =>formik.setFieldValue("country", params.inputProps.value)}
                            error = {formik.touched.company && Boolean(formik.errors.company)}
                            helperText = {formik.touched.company && formik.errors.company}
                            inputProps={{
                                
                                ...params.inputProps,
                                autoComplete: 'new-password'
                            }}
                            />
                        </SelectContainer>
                    )}
                    />             
              
              <div className={classes.buttons} >                   
                  <Button         
                      size="small"
                      className='appBtnCancel'
                      onClick={handleClose}
                  >
                      {t('XdataBuckets.Cancel')}
                  </Button>                                          
                  <div className={classes.sub}>
                      <Button                  
                        type="submit"
                        size="meduim"
                        className='appBtn'
                      >
                         {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : bucketCode === "" ? t('XdataBuckets.Create') : t('WorkspaceUsers.Update')}
                      </Button>
                  </div>                                         
              </div>   
            </form> 
        </>
      )

      //  added Html for non corob users to create bucket
      const bigHtmlContentnew = (
        <>
            <div className={classes.head}>{t('XdataBuckets.CreateBucket')}</div>
            <Divider  className={classes.divide}/>
            <form onSubmit={formik.handleSubmit}>
              <CustomTextField              
                  label={t('XdataBuckets.Bucketname')}
                  name="bucket"
                  type="name"
                  placeholder={t('XdataBuckets.Bucketname')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bucket}
                  error={formik.touched.bucket && Boolean(formik.errors.bucket)}
                  helperText={formik.touched.bucket && formik.errors.bucket} 
                  labelColor="#000"
                  labelTextSize={16}
                  inputbackgroundcolor="#E8E8E8"
                  inputtextcolor="#000"
                  placeholdercolor="#A5A5A5"
                  helpertextcolor="#C8102E"  
                  height={56}            
              />

                <CustomTextField 
                            label={t('signUp.company')}
                            name="company"
                            type="name" 
                            placeholder={t('signUp.company')}
                            onChange={formik.handleChange} 
                            onBlur={() =>formik.setFieldValue("company", companyName)}
                            value={companyName} 
                            error = {formik.touched.company && Boolean(formik.errors.company)} 
                            helperText = {formik.touched.company && formik.errors.company}
                            height={45}
                            labelColor="#000"
                            labelTextSize={16}
                            inputbackgroundcolor="#E8E8E8"
                            inputtextcolor="#000"
                            placeholdercolor="#A5A5A5"
                            helpertextcolor="#C8102E"
                            // required={false}
                            disabled={true}
                        />
              
              <div className={classes.buttons} >                   
                  <Button         
                      size="small"
                      className='appBtnCancel'
                      onClick={handleClose}
                  >
                      {t('XdataBuckets.Cancel')}
                  </Button>                                          
                  <div className={classes.sub}>
                      <Button                  
                        type="submit"
                        size="meduim"
                        className='appBtn'
                      >
                         {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : updateLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : bucketCode === "" ? t('XdataBuckets.Create') : t('WorkspaceUsers.Update')}
                      </Button>
                  </div>                                         
              </div>   
            </form> 
        </>
      )

      const bigHtmlContent1 = (
        <>
          <div className={classes.logo1}>
              <RiErrorWarningLine color="#C8102E" size={"100px"} />
          </div>
          <div className={classes.warning}>
              {t('XdataBuckets.AreYouSure')}
          </div>
          <div className={classes.text} >                
          {t('XdataBuckets.Youareabouttodeletetheentirebucket')}              
          </div>
          <div className={classes.button2}>
            <Button      
              onClick={buttonClose}                                  
              size="large"
              className='appBtnCancel' 
            >
              {t('XdataBuckets.Cancel')}
            </Button>
            <div className={classes.ok}>
              <Button 
              className='appBtn'                        
                size="large"
                onClick={handleDeleteConfirm}      
              > {deleteBucketLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('XdataBuckets.OK')}</Button>
            </div>
          </div>
        </>     
      )

      useEffect(() => {  
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset
        }  
        dispatch(getAllBuckets(payload)) 
        dispatch(getCompanyList())
        
        if(createSuccess){
          
          handleClose()
          Swal.fire({
            title: t('XdataBuckets.Success'),
            text: t('XdataBuckets.BucketCreated'),
            icon: 'success',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E'
          })
        }      
        if(deleteBucketSuccess){
          setbtnOpen(false)
          let payload = {
            offset: 0
          }  
          setPage(1)
          dispatch(getAllBuckets(payload))
          Swal.fire({
            title: t('XdataBuckets.Success'),
            text: t('XdataBuckets.BucketDeleted'),
            icon: 'success',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E'
          })
        }  
    
      },[createSuccess, deleteBucketSuccess]);

      useEffect(() => { 
        if(updateSuccess){
          handleClose()
          let payload = {
            offset: 0
          }  
          setPage(1)
          dispatch(getAllBuckets(payload))
          Swal.fire({
            title: t('XdataBuckets.Success'),
            text: t('XdataBuckets.BucketUpdated'),
            icon: 'success',
            iconColor: '#C8102E',
            confirmButtonColor: '#C8102E'
          })
          dispatch({
            type: "REQUEST_UPDATE_BUCKET_SUCCESS",
            payload: null
          });
          formik.resetForm()
        }
      }, [updateSuccess, updateError])

      useEffect(() => {  
        if(buckets?.data){
          var searchResult =  search(buckets?.data, searchText)
          if(searchResult && searchText !== ""){
            var firstIndex = (page * 10) - 10
            var lastIndex = (page * 10)
            const productArrayFiltered = searchResult.slice(firstIndex, lastIndex);
            setBucketlist(productArrayFiltered)
            setAllUsersCount(searchResult.length)
          }
          else{
              var firstIndex = (page * 10) - 10
              var lastIndex = (page * 10)
              const productArrayFiltered = buckets.data.slice(firstIndex, lastIndex);
              setBucketlist(productArrayFiltered)
              setAllUsersCount(buckets.data.length)
            }
        }
      }, [buckets, page])


      // added condition to identify impersoneted user company.
      useEffect(() => {  
        if(bucketCode === ""){
          if(impersonateUserInfo?.status === "success"){
            let impersonateUserInfoData = impersonateUserInfo?.data
            setDefaultCompany("")
            setCompanyId(impersonateUserInfoData.company)
            formik.setFieldValue("company", impersonateUserInfoData.company_name)
          }
          else {
            setDefaultCompany("")
            setCompanyId(user.company)
            formik.setFieldValue("company", user.company_name)
          }
        }else{
          
        }
      }, [bucketCode])


      

      useEffect(() => {
        setWorkspaceAdmin(user.is_workspace_admin)
      }, [user])

      useEffect(() => {    
        if(companyListSuccess) {
            setCompanyList(companyListSuccess.data)
        }
      },[companyListSuccess]);

      // fetch user company (impersonet/normal) from api 
      useEffect(() => {
        let companyId = null;
        if(impersonateUserInfo?.status === "success"){
          let impersonateUserInfoData = impersonateUserInfo?.data
          companyId = impersonateUserInfoData.company;
        }
        else {
          companyId = user.company;
        }
        let payload = {
          companyid: companyId
        }  
        dispatch(getUserCompanyList(payload))
      },[])

      useEffect(() => {    
        if(userCompanyListSuccess) {
          setNewCompanyList(userCompanyListSuccess.data)
          setCompanyName(userCompanyListSuccess.data[0].company_name)
        }
      },[userCompanyListSuccess]);

      const errorBlockBtnClick = () => {
        let paginationPage = page;
        let paginationOffset = 0;
        if(page > 1){
          paginationPage = paginationPage - 1;
          paginationPage = paginationPage * 10;
          paginationOffset = paginationPage;
        }
        let payload = {
          offset: paginationOffset
        }  
        dispatch(getAllBuckets(payload))
      }

      useEffect(() => {  
        if(downloadLogsSuccess){
          // const url = new Blob([downloadLogsSuccess.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadLink = document.createElement('a');
          downloadLink.href = api_settings.url_base + downloadLogsSuccess.data.url;
          // downloadLink.download = 'example.xlsx';

          // Append the link to the body and trigger a click
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Cleanup: Remove the link from the DOM
          document.body.removeChild(downloadLink);
          dispatch({
            type: "REQUEST_DOWNLOAD_XDATA_LOGS_SUCCESS",
            payload: null
          });

        }
        // if(downloadLogsSuccess?.status === 'success'){
        //   var base64replace1 = downloadLogsSuccess.data.replace("b'", "");
        //   var base64replace2 = base64replace1.replace("'", "");
          
        //   var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        //   var blob1 = b64toBlob(base64replace2, contentType);
        //   const blobUrl = URL.createObjectURL(blob1);
        //   var a = document.createElement("a");
        //   document.body.appendChild(a);
        //   a.style = "display: none";
        //     a.href = blobUrl;
        //     a.download = "downloads-log";
        //     a.click();
        //     dispatch({
        //       type: "REQUEST_DOWNLOAD_XDATA_LOGS_SUCCESS",
        //       payload: null
        //     });
        // }
      }, [downloadLogsSuccess])

      
      const b64toBlob =(b64Data, contentType, sliceSize) =>  {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
        
          var byteArray = new Uint8Array(byteNumbers);
          
          byteArrays.push(byteArray);
        }
        
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

      useEffect(() => {  
        if(create500Error){
          toast.error(t('XdataBuckets.bucketCreateFailed') , {
            duration: 8000,
            position: 'top-right',
            style: {fontSize: 20, fontFamily: 'Roboto'}
          });
          dispatch({
            type: "REQUEST_CREATE_BUCKET_FAILED_500",
            payload: null
          })
        }
    
        if(downloadLogs500Error){
          toast.error(t('XdataBuckets.downloadLogsFailed'), {
            duration: 8000,
            position: 'top-right',
            style: {fontSize: 20, fontFamily: 'Roboto'}
          });
          dispatch({
            type: "REQUEST_DOWNLOAD_SNAPSHOT_FAILED_500",
            payload: null
          })
        }

        if(deleteBucketError500){
          toast.error(t('XdataBuckets.xdataDeleteFailed'), {
            duration: 8000,
            position: 'top-right',
            style: {fontSize: 20, fontFamily: 'Roboto'}
          });
          dispatch({
            type: "REQUEST_DELETE_BUCKET_FAILED_500",
            payload: null
          })
        }
        
      }, [create500Error, downloadLogs500Error, deleteBucketError500])

      const viewMoreClick = () => {
        setEmailSent(true)
      }
    
      const emailSentClose = () => {
        setEmailSent(false)
      }
    
      const emaailSentContent = (
        <>
          <div className={classes.emailtext}>      
            {t("companyAdd.infoText")} <br/>  <span style={{color: '#C8102E'}}> {process.env.REACT_APP_EMAIL_ID}</span> {t("companyAdd.followingDetails")}
            
          </div>
          <ul style={{paddingLeft: 40, fontFamily: 'Roboto',display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)'}}>
          <li>{t("companyAdd.companyname")}</li>
          <li>{t("companyAdd.country")}</li>
          <li>{t("companyAdd.city")}</li>
          <li>{t("companyAdd.address")}</li>
          <li>{t("companyAdd.zipcode")}</li>
          <li>{t("companyAdd.phone")}</li>
          <li>{t("companyAdd.vat")}</li>
      </ul>
          <div className={classes.emailbutton}>
                      <Button
                      
                      variant="contained" 
                      onClick={() => setEmailSent(false)}
                      size="medium"
                      >
                          {t("companyAdd.close")}
                      </Button>
          </div> 
    
        </>
    );

  return (
    <DashboardLayout pageLoading={downloadLogsLoading}>
      <Toaster />
        <BreadCrumb linkData={linkData}/>
        
        <CustomTable 
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('XdataBuckets.XDataBuckets')}
            thead={thead}
            tdata={bucketlist}
            maxRow={10}
            handleRowClick={handleRowClick}
            handleDeleteClick={handleDeleteClick} 
            handleEditClick={handleEditClick}           
            headerActions={headerActions}
            height={isSmallerDesktop?617:767}
            loadPagination={setPage}
            action={
               { 
                default: workspaceAdmin ?  ["edit", "delete"] : ["delete"],
               }
              }
            currentPage={page}
            clickableColumn={0}
            errorStatus={error}
            errorBlockBtnClick={errorBlockBtnClick}
        />
        { userIdentity === "yes" ? (
          <Modals 
            handleclose={handleClose} 
            open={open} 
            content={bigHtmlContent}                   
          />) : (
            <Modals 
            handleclose={handleClose} 
            open={open} 
            content={bigHtmlContentnew}                   
          />
          )
        }
        <Modals 
            handleclose={buttonClose} 
            open={btnOpen} 
            content={bigHtmlContent1}                   
        />  
        <Modals handleclose={emailSentClose} open={emailSent} content={emaailSentContent} />            
       
    </DashboardLayout>
  )
}
