import React,{useState, useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery } from "@mui/material";
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { CustomTextField } from 'elements';
import { useFormik } from "formik";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import {getAllThemes, createThemes, deleteTheme} from "../../redux/actions/themes/themesActions";
import {RiErrorWarningLine} from "react-icons/ri";
import * as Yup from "yup";
import {  string } from "yup";
import Swal from 'sweetalert2';
import {useTranslation} from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';
 

const useStyles = makeStyles(styles)

export default function Themes() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [allusersCount, setAllUsersCount] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(); 
  const [themeList, setThemelist] = useState([]);
  const handleOpen = () => setOpen(true);
  const {t} = useTranslation('common');
 
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading,    
    themes,  
    createData,    
    deleteSuccess,
    createError500,
    deleteError500,
    error } = useSelector((state) => state.themes.themes);

    const linkData = [ 
      {
        displayName:t('themes.themes'),
        link:"/dashboard/themes"
      }
    ]
    
    const thead = [
      {
        name:"name",
        displayName:t('themes.name'),
        type:"text",
        width:30,  //percentage
      },
      {
        name:"id",
        displayName:t('themes.code'),
        type:"text",
        width:60
      },
      {
        name:"actions",
        displayName:t('themes.actions'),
        type:"action",
        width:10
      },
    ]
    

  const formik = useFormik({
      initialValues: {    
        name: "",   
    },
    validationSchema: Yup.object({        
      name: string().required(t('themes.enterThemeName')).max(255,t('themes.nameTooLong')),                                          
    }),
    onSubmit : (values, formikHelpers)=> {
        dispatch(createThemes(values))
        
    }
  });

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  };
  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));

  const handleRowClick = (item) => () => {
    navigate("/dashboard/themes/details/"+item.id+"/")
  }

  const handleEditClick = (e) => {
    // 
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset
    }  
    dispatch(getAllThemes(payload))  
      
  },[page, createData]);

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset
    }  
    dispatch(getAllThemes(payload))  
  }

  useEffect(() => {  
    if(themes?.data){
      setThemelist(themes.data)
      setAllUsersCount(themes.count)
    }
  }, [themes])

  useEffect(() => {  
    if(createData?.status === "success"){
      setOpen(false) 
      Swal.fire({
        title: t('themes.success'),
        text: t('themes.themeCreated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })    
      formik.resetForm();   
      dispatch({
        type: "REQUEST_CREATE_THEMES_SUCCESS",
        payload: null
      });
    }
  }, [createData])

  const handleDeleteClick = (item) => (e) =>{
    e.stopPropagation();
    setCurrentTheme(item)
    setDeleteModal(!deleteModal)
  }

  const deleteContent = (
    <>
    <div className={classes.logo1}>
        <RiErrorWarningLine color="#C8102E" size={"100px"} />
    </div>
    <div className={classes.warning}>
        {t('themes.areYouSure')}
    </div>
    <div className={classes.text} >
       {t('themes.deleteConfirmation')}
            
    </div>
    <div className={classes.button2}>
            <Button  
            onClick={()=> {
              setCurrentTheme()
              setDeleteModal(!deleteModal)}}  
            className="appBtnCancel"                                   
              size="large"
            >
               {t('themes.cancel')}
            </Button>
            <div className={classes.ok}>
                <Button     
                className="appBtn"                     
                  size="large"
                  onClick={() =>   dispatch(deleteTheme(currentTheme))}
                >
                    {t('themes.ok')}
                </Button>
            </div>
    </div>
  </>     
  )

  useEffect(() => { 
    if(deleteSuccess?.status === 204) {
      setCurrentTheme()
      setDeleteModal(!deleteModal)
        Swal.fire({
          title: t('themes.success'),
          text: t('themes.theme') + currentTheme.name + t('themes.removed'),
          icon: 'success',
          iconColor: '#C8102E',
          confirmButtonColor: '#C8102E'
        })
        let payload = {
          offset: 0
        }  
        setPage(1)
        dispatch(getAllThemes(payload))  
        dispatch({
          type: "REQUEST_DELETE_THEME_SUCCESS",
          payload: null
        });
    }
  }, [deleteSuccess]);

  const headerActions = (
    <Button style={{minHeight: 'unset'}} onClick={handleOpen}><PlusIcon /> {t('themes.createNew')}</Button>
  )

  const bigHtmlContent = (
    <>                         
      <div className={classes.head}>{t('themes.createTheme')}</div>
            <Divider  className={classes.divide}/>
            <form onSubmit={formik.handleSubmit}>
            <CustomTextField

              label={t('themes.themeName')}
              name="name"
              type="name"
              placeholder={t('themes.themeName')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name} 
              labelColor="#000"
              labelTextSize={16}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholderColor="#A5A5A5"
              helpertextcolor="#C8102E"  
              height={56}            
                   
            />
            
            <div className={classes.buttons} >                   
                <Button 
                className='appBtnCancel'                  
                    size="small"
                    onClick={handleClose}
                >
                    {t('themes.cancel')}
                </Button>                                          
                <div className={classes.sub}>
                    <Button 
                    className='appBtn'                   
                        type="submit"
                        size="meduim"
                    >
                        {t('themes.ok')}
                    </Button>
                </div>                                  
            </div>  
            </form>                     
    </>
  )

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        
        dispatch(deleteTheme(currentTheme))
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [currentTheme]);

  useEffect(() => {  
    if(createError500){
      toast.error(t('themes.themeCreation') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_CREATE_THEMES_FAILED_500",
        payload: null
      })
    }
    
    if(deleteError500){
      toast.error(t('themes.themeDeletion') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DELETE_THEME_FAILED_500",
        payload: null
      })
    }
    
  }, [createError500, deleteError500])
  
  return (
    <DashboardLayout >
      <Toaster />
      <BreadCrumb linkData={linkData}/>
        <CustomTable 
            loading={loading}
            totalPageCount={allusersCount}
            title ={t('themes.themes')}
            thead={thead}
            tdata={themeList}
            maxRow={10}
            handleRowClick={handleRowClick}
            height={isSmallerDesktop?683:813}
            action={
              {
                admin:["delete"],
                default:["delete"]
              }
            }
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            headerActions={headerActions}
            loadPagination={setPage}
            currentPage={page}
            clickableColumn={0}
            errorStatus={error}
            errorBlockBtnClick={errorBlockBtnClick}
        />
        <Modals 
                handleclose={handleClose} 
                open={open} 
                content={bigHtmlContent}                   
        />
        <Modals 
            handleclose={() => setDeleteModal(!deleteModal)} 
            open={deleteModal} 
            content={deleteContent}                   
        />
    </DashboardLayout>
    
  )
}
